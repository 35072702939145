import { defineStore } from "pinia";
import { CStyleInstance, type IDomElementInterface } from "@/stores/layer";
import type {
	TObjEntries,
	TStyleInterface,
	TStyleObjType,
	TCssStyle,
	TStyleInstances,
} from "./definition/globalTypes";
import { DomElementInstance } from "./layer";
import omit from "lodash.omit";
import loMerge from "lodash.merge";
import { type TGroupSelection } from "./page";
import { InfoConsole } from "@/helpers/helpers";
import {
	PrivateElementInstance,
	type IPrivateKeyInterface,
} from "./class/element/private";

// TODO -1 See how to define groups, as a separate instance or a calc from elements??
export type TGroupPlainPayload = {
	id: string;
	elements: IDomElementInterface[];
	breakpoints: string[];
	_private?: IPrivateKeyInterface;
};
export type TGroupPayload = Omit<TGroupPlainPayload, "elements"> & {
	elements: DomElementInstance[];
};
export type TGroupSavePayload = Omit<TGroupPlainPayload, "elements"> & {
	childrenEls: ModelInstance[];
};

export type TModelInstanceGroup = DomElementInstance | GroupedInstance;

export class ModelInstance {
	id: string;
	children?: any[] = [];
	style: TStyleInterface = {};
	_private = new PrivateElementInstance();

	constructor(id: string | undefined) {
		this.id = id || ModelInstance.createUniqueId();
	}

	getPrivateOptions(): PrivateElementInstance {
		return this._private;
	}

	/**
	 * Gets the plain object data (not instance)
	 * @param excludeKeys
	 */
	getRawInstanceData<T extends IDomElementInterface>(excludeKeys = ["id"]): T {
		const omitted = omit(this.toPlainData(), excludeKeys);
		const newInstanceObj = JSON.parse(JSON.stringify(omitted));
		return newInstanceObj;
	}

	updateStyle(
		domObj: TCssStyle,
		breakpointName?: string | null,
		isUpdateMerge = true,
	): boolean {
		const updateBreakpointStyles = (breakpointName: string) => {
			const styleBreakpoint = this.style[breakpointName];
			if (styleBreakpoint) {
				if (isUpdateMerge) {
					const entries = Object.entries(domObj) as TObjEntries<TCssStyle>;
					for (const [key, newVal] of entries) {
						const currentVal = styleBreakpoint?.toPlainCss?.[key];
						if (typeof newVal === "string") {
							if (currentVal === newVal) {
								// Ignored | Same value
								// console.warn("[IGNORING] Same val", currentVal, newVal);
							} else {
								hasAnyValueChanged = true;
								type TStringableStyle = Omit<
									TStyleObjType,
									keyof TStyleInstances
								>;
								// styleBreakpoint;
								styleBreakpoint.setValue = {
									[key as keyof TStringableStyle]: newVal,
								};
							}
						} else {
							hasAnyValueChanged = true;
							if (currentVal) {
								// TEST Maybe: This should update remaining instances
								styleBreakpoint.toForm[key] = newVal;
							} else {
								console.log("[Saving new object]:", key, newVal);
								styleBreakpoint.setValue = { [key]: newVal };
							}
						}
					}
				} else {
					this.style[breakpointName] = new CStyleInstance(domObj);
					hasAnyValueChanged = true;
				}
			} else {
				console.warn(`Invalid breakpoint: ${breakpointName}`, this.style);
			}
		};

		let hasAnyValueChanged = false;
		const parsedBreakpoint = breakpointName;
		if (parsedBreakpoint) {
			updateBreakpointStyles(breakpointName);
		} else {
			const canvasStore = useCanvasStore();
			const breakpointNames = canvasStore.getAllCanvasTabs.map(
				(item) => item.name,
			);
			console.warn("Updating all breakpoints", domObj, breakpointNames);
			for (const breakpointName of breakpointNames) {
				updateBreakpointStyles(breakpointName);
			}
		}
		// if (!hasAnyValueChanged) {
		//   console.warn("[DEBUG]", domObj);
		// }
		return hasAnyValueChanged;
	}

	updatePrivate(domObj = {} as IPrivateKeyInterface) {
		for (const key of Object.keys(domObj) as [keyof IPrivateKeyInterface]) {
			const value = domObj[key];
			if (key === "layer") {
				this._private.layer?.updateLayer(value as any);
			} else {
				if (value !== undefined) {
					// @ts-expect-error
					this._private[key] = value;
				}
			}
		}
		return this;
	}

	getStyleBreakpoints(): string[] {
		return Object.keys(this.style);
	}

	getBreakpointStyle(breakpointName: string): CStyleInstance | null {
		if (this.getStyleBreakpoints().includes(breakpointName)) {
			return this.style[breakpointName] as CStyleInstance;
		}
		console.warn(
			`Missing breakpoint style ${breakpointName}`,
			this.style,
			this,
		);
		return null;
	}

	getActiveStyle(): CStyleInstance {
		const canvasStore = useCanvasStore();
		const bn = canvasStore.getActiveBreakpointObject?.name;
		if (bn) {
			return this.getBreakpointStyle(bn) || new CStyleInstance();
		}
		throw new Error(`No active breakpoint style ${bn}`);
	}

	/* Add a new breakpoint safely, and copies the data from the current active instance */
	transferBreakpointData(newName: string, oldName: string) {
		const oldStyle = this.getBreakpointStyle(oldName);
		if (!oldStyle) {
			console.error(`No previous breakpoint ${oldName}`);

			return false;
		}

		if (!this.getStyleBreakpoints().includes(newName)) {
			// TODO 3 Maybe adapt styles for mobile view | Adapt styles for other breakpoints [Complex]
			console.warn(`Added new style ${newName}`);

			this.style[newName] = new CStyleInstance(oldStyle.toPlainCss);
			return true;
		}
		return false;
	}

	toPlainData(): Partial<Record<keyof ModelInstance, any>> {
		const generatePlainStyles = () => {
			const tempStyleObj: Record<string, Partial<TCssStyle>> = {};
			for (const breakpointName of Object.keys(this.style)) {
				const styleBr = this.style[breakpointName];

				if (styleBr) {
					tempStyleObj[breakpointName] = styleBr.toPlainCss;
				}
			}
			return tempStyleObj;
		};

		const payload: Partial<Record<keyof ModelInstance, any>> = {
			...this,
			style: generatePlainStyles(),
		};

		if (Array.isArray(this.children) && this.children.length) {
			payload.children = this.children?.map((instance) =>
				instance.toPlainData(),
			);
		}

		return payload;
	}

	static createUniqueId() {
		return Math.random()
			.toString(36)
			.replace(/[^a-z]+/g, "")
			.slice(0, 7);
	}
}

export class GroupedInstance
	extends ModelInstance
	implements TGroupSavePayload
{
	childrenEls: DomElementInstance[] = [];
	breakpoints: string[] = [];
	style: TStyleInterface = {};

	/**
	 * Groups should have element instances before use
	 */
	constructor(dataObj: TGroupPayload) {
		console.warn("CREATING GROUP", dataObj);

		super(dataObj.id);
		if (!dataObj.elements || !dataObj.breakpoints) {
			console.error("Invalid group init, missing payload", dataObj);
			return;
		}

		const elementInstances = dataObj.elements.map((el) =>
			el instanceof DomElementInstance ? el : new DomElementInstance(el),
		);
		this.childrenEls = elementInstances;
		this.breakpoints = dataObj.breakpoints;
		if (this.childrenEls.length && this.breakpoints.length) {
			this.setStyle();
		}
		if ("_private" in dataObj) {
			this.updatePrivate(dataObj._private);
		}
	}

	setStyle() {
		const pendingStyle = {} as GroupedInstance["style"];

		const parseSetStyleLogic = (
			instances: DomElementInstance[],
			breakpointName: string,
		) => {
			for (const instance of instances) {
				const elPlainStyle = instance.style[breakpointName]?.toPlainCss;
				if (!elPlainStyle) {
					console.error("No breakpoint style", breakpointName);
					return;
				}

				const unitLeft = DomElementInstance.getStyleUnit(elPlainStyle.left);
				const unitTop = DomElementInstance.getStyleUnit(elPlainStyle.top);
				const unitWidth = DomElementInstance.getStyleUnit(elPlainStyle.width);
				const unitHeight = DomElementInstance.getStyleUnit(elPlainStyle.height);

				const pendingStyleInstance =
					pendingStyle[breakpointName] || new CStyleInstance({});
				const pendingPlainStyle = pendingStyleInstance.toPlainCss;
				const pleftVal = pendingPlainStyle.left;
				const ptopVal = pendingPlainStyle.top;
				const pwidthVal = pendingPlainStyle.width;
				const pheightVal = pendingPlainStyle.height;

				pendingStyleInstance.setValue = {
					left: pendingPlainStyle.left
						? `${Math.min(
								parseInt(pendingPlainStyle.left),
								parseInt(elPlainStyle.left),
							)}${unitLeft}`
						: elPlainStyle.left,
					top: pendingPlainStyle.top
						? `${Math.min(
								parseInt(pendingPlainStyle.top),
								parseInt(elPlainStyle.top),
							)}${unitTop}`
						: elPlainStyle.top,
				};

				const pendingRight = pleftVal
					? parseInt(pleftVal) + parseInt(pwidthVal)
					: 0;
				const pendingBottom = ptopVal
					? parseInt(ptopVal) + parseInt(pheightVal)
					: 0;
				const elRight =
					parseInt(elPlainStyle.left) + parseInt(elPlainStyle.width);
				const elBottom =
					parseInt(elPlainStyle.top) + parseInt(elPlainStyle.height);
				if (pendingRight < elRight) {
					pendingStyleInstance.setValue = {
						width: `${
							elRight - parseInt(pendingStyleInstance.toPlainCss.left)
						}${unitWidth}`,
					};
				}
				if (pendingBottom < elBottom) {
					pendingStyleInstance.setValue = {
						height: `${
							elBottom - parseInt(pendingStyleInstance.toPlainCss.top)
						}${unitHeight}`,
					};
				}

				pendingStyle[breakpointName] = pendingStyleInstance;
			}
		};

		const instances = this.childrenEls;
		for (const breakpointName of this.breakpoints) {
			parseSetStyleLogic(instances, breakpointName);
		}

		this.style = pendingStyle;
	}

	addElement(...instances: DomElementInstance[]): DomElementInstance[] {
		const addedIds = new Set(this.childrenEls.map((child) => child.id));
		for (const instance of instances) {
			if (!addedIds.has(instance.id)) {
				this.childrenEls.push(instance);
			}
		}

		this.setStyle();
		return this.childrenEls;
	}

	removeElement(...instances: DomElementInstance[]): DomElementInstance[] {
		const tempArr = [];
		for (const instance of instances) {
			const index = this.childrenEls.findIndex(
				(child) => child.id === instance.id,
			);

			if (index !== -1) {
				continue;
			}
			tempArr.push(instance);
		}
		this.childrenEls = tempArr;

		this.setStyle();
		return this.childrenEls;
	}

	updateGroup(
		dataObj: Partial<Record<keyof GroupedInstance, any>>,
		isUpdateMerge = false,
		breakpointName?: string | null,
	): boolean {
		for (const key of Object.keys(dataObj) as [keyof GroupedInstance]) {
			if (key === "style") {
				// Groups can only be modified with position styles
				return this.updateStyle(dataObj.style, breakpointName, isUpdateMerge);
			} else if (key === "_private") {
				this.updatePrivate(dataObj._private);
			} else {
				if (isUpdateMerge) {
					if (dataObj[key] && typeof dataObj[key] === "object") {
						loMerge(this[key], dataObj[key]);
					} else {
						this[key] = dataObj[key];
					}
				} else {
					this[key] = dataObj[key];
				}
			}
		}
		return true;
	}

	static isValidPayload(...payloadData: TGroupSavePayload[]): boolean {
		return payloadData.every((dataObj) => {
			if (!dataObj.childrenEls) return false;
			if (!dataObj.breakpoints) return false;
			return true;
		});
	}
}

export class SmallGroupInstance {
	id: string;
	elements = new Set<DomElementInstance["id"]>();

	constructor(elements: DomElementInstance["id"][]) {
		this.id = ModelInstance.createUniqueId();
		this.elements = new Set(elements);
	}

	get() {
		return this.elements;
	}
	set(elements: DomElementInstance["id"][]): typeof this.elements {
		return (this.elements = new Set(elements));
	}
	add(elements: DomElementInstance["id"][]): void {
		for (const element of elements) {
			this.elements.add(element);
		}
	}
	remove(elements: DomElementInstance["id"][]): void {
		for (const element of elements) {
			this.elements.delete(element);
		}
	}

	get hasData(): boolean {
		return this.elements.size !== 0;
	}

	toRawData() {
		return Array.from(this.elements);
	}
}

export const useGroupStore = defineStore("group", () => {
	const pageStore = usePageStore();
	const snapshotStore = useSnapshotStore();
	const canvasStore = useCanvasStore();
	const layerStore = useLayerInstancesStore();

	// Getters
	const getInstanceGroups = computed<GroupedInstance[]>(() => {
		return pageStore.getCurrentPage?.groupsData || [];
	});
	const getAllModelData = computed<TModelInstanceGroup[]>(() => {
		const groups = getInstanceGroups.value;
		const elements = pageStore.getCurrentPage?.elementsData || [];
		return [...groups, ...elements];
	});

	const getParentGroups = computed<GroupedInstance[]>(() => {
		return getSortedModels().filter(
			(model) => model instanceof GroupedInstance,
		) as GroupedInstance[];
	});
	const getFlatModels = computed(() => {
		return getSortedModels();
	});

	const getSelectedModels = computed<TModelInstanceGroup[] | null>(() => {
		const currPage = pageStore.getCurrentPage;
		const modSel = currPage?.groupSelected;
		if (!modSel) return null;
		return getSortedModels().filter((model) => modSel.elements.has(model.id));
	});
	const getSelectedGroups = computed<GroupedInstance[] | null>(() => {
		const currPage = pageStore.getCurrentPage;
		const modSel = currPage?.groupSelected;
		if (!modSel) return null;
		const tempInstances: GroupedInstance[] = [];
		for (const id of modSel.toRawData()) {
			const groupInstance = getGroupById.value(id);
			if (groupInstance) {
				tempInstances.push(groupInstance);
			}
		}
		return tempInstances;
	});
	const getModelById = computed(() => {
		return (id: string): TModelInstanceGroup | undefined => {
			const instances = layerStore.getFlatInstances;
			return getFlatModels.value
				.concat(instances)
				.find((model) => model.id === id);
		};
	});
	const getGroupById = computed(() => {
		return (id: string): GroupedInstance | undefined => {
			return (
				getSortedModels().filter(
					(model) => model instanceof GroupedInstance,
				) as GroupedInstance[]
			).find((group) => group.id === id);
		};
	});
	const getInstanceGroupByElement = computed(() => {
		return (
			ids: ModelInstance["id"][],
		): { item: GroupedInstance; index: number } | undefined => {
			const currentPage = pageStore.getCurrentPage;
			if (currentPage) {
				const selGroups = getSelectedGroups.value?.filter(
					(mod) => mod instanceof GroupedInstance,
				);
				if (selGroups) {
					const index = selGroups.findIndex((group) => {
						const hasSomeElement = group.childrenEls.some((instance) =>
							ids.includes(instance.id),
						);
						return hasSomeElement;
					});
					if (index !== -1) {
						return {
							item: selGroups[index],
							index,
						};
					}
				}
			}
			return undefined;
		};
	});
	const isGroupedEls = computed(() => {
		return (instances: DomElementInstance[]): boolean => {
			const groupName = instances[0]._private.groupName;
			if (!groupName) return false;

			for (let index = 1; index < instances.length; index++) {
				const instance = instances[index];
				if (instance.getPrivateOptions().groupName !== groupName) {
					return false;
				}
			}
			return true;
		};
	});

	// Actions
	function getSortedModels(
		order: "asc" | "dsc" = "asc",
		includeHidden = true,
	): TModelInstanceGroup[] {
		return (
			pageStore.getCurrentPage?.getSortedModels(
				getAllModelData.value,
				order,
				includeHidden,
			) || []
		);
	}
	function checkHasInvalidIds(modelIds: ModelInstance["id"][] = []) {
		if (!modelIds.length) {
			return false;
		}

		return modelIds.some((id) => !getModelById.value(id));
	}
	function setHoverableModels(id: ModelInstance["id"][] | null) {
		const currPage = usePageStore().getCurrentPage;
		if (currPage) {
			currPage.modelsHovered = id;
		}
	}
	// function setMovingModels(id: ModelInstance["id"][]) {
	// 	const currPage = usePageStore().getCurrentPage;
	// 	if (currPage) {
	// 		currPage.modelsMoving = id;
	// 	}
	// }

	const GroupSelection = {
		set: (
			modelIds: ModelInstance["id"][] | null | undefined = null,
		): TGroupSelection => {
			const currPage = pageStore.getCurrentPage;
			if (!currPage) {
				return undefined;
			}

			if (!modelIds) {
				return (currPage.groupSelected = null);
			}

			const hasInvalidIds = checkHasInvalidIds(modelIds);
			if (hasInvalidIds) {
				console.warn("Some element ids not valid 3", modelIds);
			} else {
				const selEls = currPage.groupSelected;
				if (selEls) {
					selEls.set(modelIds);
				} else {
					return (currPage.groupSelected = new SmallGroupInstance(modelIds));
				}
				return currPage.groupSelected;
			}
			return undefined;
		},
		add: (modelIds: ModelInstance["id"][] = []): TGroupSelection => {
			const currPage = pageStore.getCurrentPage;
			if (!currPage) {
				return undefined;
			}

			const hasInvalidIds = checkHasInvalidIds(modelIds);
			if (hasInvalidIds) {
				console.warn("Some element ids not valid 4", modelIds);
			} else {
				const selEls = currPage.groupSelected;
				if (selEls) {
					selEls.add(modelIds);
				} else {
					currPage.groupSelected = new SmallGroupInstance(modelIds);
				}
				return currPage.groupSelected;
			}
			return undefined;
		},
		remove: (modelIds: ModelInstance["id"][] = []): TGroupSelection => {
			const currPage = pageStore.getCurrentPage;
			if (!currPage) {
				return undefined;
			}

			const selEls = currPage.groupSelected;
			if (selEls) {
				selEls.remove(modelIds);
				return selEls;
			} else {
				InfoConsole.e(`Group selection not found ${modelIds.join(",")}`);
			}
			return undefined;
		},
	};

	function modifyGroupEls(instances: DomElementInstance[], shouldGroup = true) {
		const currentPage = pageStore.getCurrentPage;
		if (!currentPage) {
			console.error("No current page");
			return;
		}

		const instIds = instances.map((inst) => inst.id);
		GroupSelection.remove(instIds);

		if (shouldGroup) {
			const bn = canvasStore.getActiveBreakpointObject?.name || "";
			const groupName = instances[0].getPrivateOptions().groupName || "";
			const grData: TGroupPayload = {
				id: groupName,
				elements: instances,
				breakpoints: [bn],
			};
			const group = new GroupedInstance(grData);
			currentPage.groupsData.push(group);

			GroupSelection.add([group.id]);
			snapshotStore.addUndoStack();
		} else {
			for (const instance of instances) {
				const instanceGroupObj = getInstanceGroupByElement.value([instance.id]);
				if (instanceGroupObj) {
					// Remove elements from group
					const availableEls = instanceGroupObj.item.removeElement(instance);

					// Remove group if no elements
					if (availableEls.length === 0) {
						currentPage.groupsData.splice(instanceGroupObj.index, 1);

						GroupSelection.remove([instanceGroupObj.item.id]);
						GroupSelection.add(instIds);
						snapshotStore.addUndoStack();
					}
					// groups.push(instanceGroupObj);
				} else {
					console.error("No gr-instance", instance.id);
				}
			}
		}
	}
	function modifyGroups(groupInstances: GroupedInstance[], shouldGroup = true) {
		const currentPage = pageStore.getCurrentPage;
		if (!currentPage) {
			console.error("No current page");
			return;
		}

		if (shouldGroup) {
			console.error("Not implemented / needed?");

			// addSelectedModels(groupIds);
		} else {
			// Remove groups
			const ids = groupInstances.map((group) => group.id);
			GroupSelection.remove(ids);
			const remainingGroups = getInstanceGroups.value.filter(
				(group) => !ids.includes(group.id),
			);
			currentPage.groupsData = remainingGroups;

			// Remove group name from element instances
			const instances = layerStore.getVisibleInstances;
			const modifiedInstances = [];
			for (const instance of instances) {
				const groupName = instance.getPrivateOptions().groupName || "";
				if (ids.includes(groupName)) {
					instance.getPrivateOptions().groupName = "";
					modifiedInstances.push(instance.id);
				}
			}

			// Re-select el instances
			GroupSelection.add(modifiedInstances);
		}
		snapshotStore.addUndoStack();
	}

	return {
		getParentGroups,
		getSelectedModels,
		getSelectedGroups,
		getModelById,
		getGroupById,
		getInstanceGroupByElement,
		getInstanceGroups,
		isGroupedEls,
		getSortedModels,
		modifyGroupEls,
		modifyGroups,
		setHoverableModels,
		// setMovingModels,
		checkHasInvalidIds,
		GroupSelection,
	};
});
