<script setup lang="ts">
import { useFavicon, useTitle } from "@vueuse/core";
import {
	addCookieMessageListener,
	getLocalStorageReac,
	handleErrorLog,
	setupAppMenuBarForParent,
} from "./helpers/helpers";

import { useDefaultStore } from "./stores";
// import { useSnapshotStore } from "./stores/snapshot";
import { usePageStore } from "./stores/page";
import { appMenuBarList } from "@/components/topBar/appMenuBarActions";
import { type IAppMenuBarItem } from "@/components/shared/menuBar/AppMenuBar.vue";
import { type Ref } from "vue";
import { useSnapshotStore } from "./stores/snapshot";
import { useCanvasStore } from "./stores/canvas";
import { AppGlobal } from "./global/appGlobal";
// import { debounce } from "@/helpers/helpers";

// const snapshotStore = useSnapshotStore();
const pageStore = usePageStore();
const isAppLoaded = ref(false);
const isSpinnerBlockActive = computed(
	() => useDefaultStore().isSpinnerBlockActive,
);
const title = useTitle();

const appChangedTitle = computed(() => {
	return pageStore.isAnyPageDirty ? "🔴 " : "";
});
const appMetaTitle = computed(() => {
	const ogVal =
		(pageStore.getCurrentPage?.canvas.globalCanvasOptions.getValue(
			"title",
		) as string) || "";
	const fullTitle = `${appChangedTitle.value}${ogVal}`;
	return fullTitle;
});
const appMetaFavicon = computed(() => {
	return pageStore.getCurrentPage?.canvas.globalCanvasOptions.getValue(
		"favicon",
	);
});

function addNormalIframeListener() {
	const findAppMenuBarItem = (
		arr: any = [],
		data: IAppMenuBarItem,
	): IAppMenuBarItem | null => {
		if (Array.isArray(arr)) {
			for (const arrItem of arr) {
				if (arrItem.id && arrItem.id === data.id) {
					return arrItem;
				}
				if (arrItem.children) {
					const foundItem = findAppMenuBarItem(arrItem.children, data);
					if (foundItem) return foundItem;
				}
			}
		} else {
			console.error("Not an array", arr);
		}
		return null;
	};

	window.addEventListener("message", (event) => {
		try {
			const data =
				event.data && typeof event.data === "string"
					? JSON.parse(event.data)
					: event.data;
			if (!data.id) return; // Protocol scheme

			const appMenuItem = findAppMenuBarItem(
				appMenuBarList.value,
				data.data.action,
			);
			if (appMenuItem) {
				switch (data.command) {
					case "left-click": {
						appMenuItem.onClick?.();

						break;
					}
					case "right-click": {
						appMenuItem.onRightClick?.();

						break;
					}
					case "shift-click": {
						appMenuItem.onShiftKeyClick?.();

						break;
					}
					// No default
				}
			} else {
				console.warn("App Menu Bar item not found", data);
			}
		} catch (err: unknown) {
			handleErrorLog(err, `Error Iframe protocol reading ${event.data} >> `);
		}
	});
}

watch(appMenuBarList, () => {
	setupAppMenuBarForParent();
});

watch(appMetaTitle, (value) => {
	setupAppMenuBarForParent();
	title.value = value;
});

watch(appMetaFavicon, (value) => {
	// NOTE: Null not working with reactive vueuse favicon plugin
	useFavicon((value as string) || null);
});

function checkSetAppDarkTheme() {
	const settingReac = getLocalStorageReac(
		"app-color-scheme",
		false,
		"auto",
	) as Ref<any>;
	watch(
		settingReac,
		(isSetting: string) => {
			const prefersDark =
				window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches;
			if (isSetting === "dark" || (prefersDark && isSetting !== "light")) {
				document.documentElement.classList.add("dark");
			} else {
				document.documentElement.classList.remove("dark");
			}
		},
		{ immediate: true },
	);
}
function initialAppLogic() {
	// AppGlobal.setupAppGlobalProps();
	watch(AppGlobal.IS_APP_INITIAL_CHECK_API, () => {
		console.log("Watcher initial app logic");
		initialAppLogic();
	});

	if (!AppGlobal.IS_APP_INITIAL_CHECK_API) {
		console.log("App not loaded");
		return;
	}

	if (AppGlobal.IS_FOR_COOKIE_TEST) {
		addCookieMessageListener();
	} else if (AppGlobal.IS_INTEGRATED) {
		isAppLoaded.value = true;
		setupAppMenuBarForParent();
		addNormalIframeListener();
	} else {
		isAppLoaded.value = true;
	}
}

watch(
	() => useSnapshotStore().isCanvasDirty,
	async (isDirty) => {
		const defaultStore = useDefaultStore();
		if (isDirty && defaultStore.autoSave) {
			await saveCanvas();
			// debounce(saveCanvas, 500, true);
		}
	},
);

async function saveCanvas() {
	console.warn("Saving CANVAS");
	await useCanvasStore().saveCanvas(false);
}

window.addEventListener("beforeunload", (evt) => {
	const isDirty = pageStore.isAnyPageDirty;
	const defaultStore = useDefaultStore();
	// const isDirty = snapshotStore.isCanvasDirty;
	if (isDirty && !defaultStore.autoSave) {
		evt.preventDefault();
		evt.returnValue = "Unsaved changes";
	}
});

onBeforeMount(async () => {
	initialAppLogic();

	checkSetAppDarkTheme();
});
</script>

<template lang="pug">
teleport(to="head title")
	| {{ appMetaTitle }}
router-view(v-if="isAppLoaded")
enon-browser-detect
teleport(to="#spinner-block")
	spinner-block(v-if="isSpinnerBlockActive")
//- teleport(to="#context-menu")
//-   context-menu-wrap(
//-     v-if="contextMenuObj.evt",
//-     :items="contextMenuObj.items",
//-     :evt="contextMenuObj.evt",
//-     :payload="contextMenuObj.payload",
//-     @close-context-menu="onCloseContextMenu"
//-   )
</template>

<style lang="scss">
@import "./assets/css/base.css";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap";

:root {
	--canvas-background: #{$medium-gray-alt};
	--left-sidebar-width: 300px;
	--right-sidebar-width: 300px;
	--left-calc-width: max(300px, var(--left-sidebar-width));
	--right-calc-width: max(300px, var(--right-sidebar-width));
	--app-accent-color: #27d18a;
}

* {
	$light-border: #d3d3d321;

	scrollbar-width: thin;
	scrollbar-color: $light-border #343a40;
	outline: none;

	// outline-color: $light-border;
	// outline-width: 1.5px;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		// background: $light-scrollbar;
		background: #6d6e70;
	}
}

body {
	font-family: Roboto, sans-serif;
	overflow: hidden; // Added for tooltip overflow
}

#app {
	width: 100vw;
	height: 100vh;
	font-family: Roboto, sans-serif;
	color: $text-color;
}

input,
textarea {
	font-family: Roboto, sans-serif;
}
</style>
