import { getLocalStorageReac } from "../helpers/helpers";
import { AppApi } from "./appApi";

interface ISearchParams {
	sid: string;
	integration: string;
	domain: string;
}

export const APP_STORAGE_IDB_KEYS = {
	pages: "pages",
	checkpoint: "checkpoint",
	assets: "assets",
	fonts: "fonts",
};

export const BREAKPOINT_WIDTHS = {
	desktop: "1920px",
	tablet: "768px",
	mobile: "480px",
};

function getPlatform() {
	// @ts-expect-error
	const userAgentData = navigator.userAgentData;

	if (userAgentData !== undefined && userAgentData != null) {
		return userAgentData.platform;
	}
	// Deprecated but still works for most of the browser
	if (navigator.platform !== undefined) {
		if (
			navigator.userAgent !== undefined &&
			navigator.userAgent.toLowerCase().includes("android")
		) {
			// android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
			return "android";
		}
		return navigator.platform;
	}
	return "unknown";
}
function getOsDetected() {
	const platform = getPlatform().toLowerCase();

	const isOSX = /mac/.test(platform); // Mac desktop
	const isIOS = ["iphone", "ipad", "ipod"].indexOf(platform); // Mac iOs
	const isApple = isOSX || isIOS; // Apple device (desktop or iOS)
	const isWindows = /win/.test(platform); // Windows
	const isAndroid = /android/.test(platform); // Android
	const isLinux = /linux/.test(platform); // Linux

	return {
		mac: isOSX,
		ios: isIOS,
		apple: isApple,
		windows: isWindows,
		android: isAndroid,
		linux: isLinux,
	};
}
function getOsName() {
	const osDetected = getOsDetected();

	if (osDetected.windows) {
		return "Windows";
	} else if (osDetected.linux) {
		return "Linux";
	} else if (osDetected.mac) {
		return "Mac";
	} else if (osDetected.android || osDetected.ios) {
		// Unused
		return "Phone";
	}
	return "Windows";
}

class AppGlobalInstance {
	private isSetupInvoked = false;
	IS_APP_INITIAL_CHECK_API = ref(false);
	URL_PARAMS: ISearchParams = {} as ISearchParams;
	IS_INTEGRATED = false;
	IS_SLIM = false;
	IS_FOR_COOKIE_TEST = false;
	IS_DEV_MODE_ON = Boolean(getLocalStorageReac("devMode", false, false).value);
	OS_NAME = getOsName();
	// InfoConsole.l(`${OS_NAME}`);

	constructor() {
		void this.setupAppGlobalProps();
	}

	async setupAppGlobalProps() {
		// This function should be invoked once
		if (this.isSetupInvoked) {
			console.warn("Already invoked.");
		}
		this.isSetupInvoked = true;
		const searchParams = new URLSearchParams(location.search);

		const originalLinkCreator = getLocalStorageReac(
			"original-link-creator",
			false,
			"",
		);
		if (originalLinkCreator.value === "undefined") {
			console.log("Invalid value", originalLinkCreator);
			this.IS_APP_INITIAL_CHECK_API.value = true;
			originalLinkCreator.value = null;
			return;
		}

		if (location.search) {
			// Load online version (from DB)
			const params = new Proxy(new URLSearchParams(location.search), {
				// Reactive
				get: (searchParams, prop: any) => searchParams.get(prop),
			}) as any as ISearchParams;

			// TEST: REWORK THIS LATER -> Remove and use only in inner block after expand
			this.URL_PARAMS = params;
			this.IS_INTEGRATED = searchParams.has("integration");
			this.IS_SLIM = searchParams.has("slim");
			this.IS_FOR_COOKIE_TEST = searchParams.has("cookieTest");

			let resSuccess = false;
			if (params.sid) {
				resSuccess = await AppApi.expandSession(
					AppApi.PARSED_ADMIN_LINKS.expandSession,
					params.sid,
				);
				console.log("Expanded session / first try");
			}

			if (resSuccess) {
				this.URL_PARAMS = params;
				this.IS_INTEGRATED = searchParams.has("integration");
				this.IS_SLIM = searchParams.has("slim");
				this.IS_FOR_COOKIE_TEST = searchParams.has("cookieTest");

				// Remove url params
				// searchParams.delete("integration");
				// searchParams.delete("slim");
				// searchParams.delete("cookieTest");
				// searchParams.delete("sid");
				console.log(
					"Original URL",
					location.href,
					`Going to :${location.origin}`,
				);
				originalLinkCreator.value = location.href;
				// setLocalStorageReac("original-link-creator", location.href);

				history.replaceState(null, "", location.origin);
			}
		} else {
			if (originalLinkCreator.value) {
				// Load online version (usually after reload of page)
				const val: string = originalLinkCreator.value;
				try {
					const url = new URL(val);
					if (url.search) {
						console.log("Loading online version", val);
						history.replaceState(null, "", val);
						location.reload(); // Has to be called to not go back to offline cached version
					}
				} catch (err: any) {
					console.warn(
						err.message,
						1,
						val,
						1,
						"Unable to load online version from localstorage",
					);
					originalLinkCreator.value = null;
				}
			} else {
				// Load local version
				console.log("Loading offline project", originalLinkCreator.value);
				originalLinkCreator.value = null;
			}
		}

		this.IS_APP_INITIAL_CHECK_API.value = true;
	}
}

export const AppGlobal = new AppGlobalInstance();
