// This is used to index standalone APP APIs for object [store]

import type {
	IAssetGetItem,
	ICheckpointSaveType,
	ISavePayload,
} from "../definition/globalTypes";
import { AssetElementInstance, type CheckpointSaveInstance } from "../layer";
import type { TDefaultStore } from "@/stores/index";
import { useIDB } from "@/helpers/helpers";
import { parseSavePayload } from "./apiLogicIndex";
import { APP_STORAGE_IDB_KEYS } from "@/global/appGlobal";

interface IAssetStorageItem extends IAssetGetItem {
	file: File;
}

export const apiStorageIndex = {
	async fetchAssets(params: Record<string, any> = {}) {
		// NOTE: 99% [TEST]
		const assets = await useIDB("get", APP_STORAGE_IDB_KEYS.assets);
		if (assets) {
			const dataArr = (assets || []).map((el: IAssetStorageItem) => {
				const payload = {
					...el,
					link: el.file ? URL.createObjectURL(el.file) : "",
				};

				return new AssetElementInstance(payload);
			});
			(this as TDefaultStore).assetListData = {
				data: [
					// ...((this as DefaultStore).assetListData?.data || []),
					...dataArr,
				],
				meta_info: {},
			};
		}
	},
	async fetchFonts(params: Record<string, any> = {}) {
		// NOTE: 80% - [TEST]
		const fonts: any = await useIDB("get", APP_STORAGE_IDB_KEYS.fonts);
		if (fonts) {
			const dataArr = fonts;
			(this as TDefaultStore).fontListData = {
				data: [
					...((this as TDefaultStore).fontListData?.data || []),
					...dataArr,
				],
				meta_info: {},
			};
		}
	},
	async postUploadAppendAsset(
		assets: (File | string)[],
		callBackUploadProgress: (
			item: File | string,
			index: number,
			progress: number,
			evt?: ProgressEvent,
		) => void,
		controller: AbortController,
	) {
		// NOTE: 100%
		const fileListTransformed: IAssetStorageItem[] = (assets as File[]).map(
			(file: File) => {
				const assetInstance = new AssetElementInstance({ ass_name: file.name });
				const payload = {
					...assetInstance,
					file,
				};
				return payload;
			},
		);
		const currentList = ((await useIDB("get", APP_STORAGE_IDB_KEYS.assets)) ||
			[]) as IAssetStorageItem[];
		const mergedArr = [...currentList, ...fileListTransformed].reverse().filter(
			(value, index, self) =>
				index ===
				self.findIndex((t) => {
					if (!(t.file && value.file)) {
						return false;
					}
					return t.file.name === value.file.name;
				}),
		);
		const reversedArr = [...mergedArr].reverse();
		await useIDB("set", APP_STORAGE_IDB_KEYS.assets, reversedArr);
		void this.fetchAssets(); // No await
	},
	async removeAssets(names: string[], showToast = true) {
		// NOTE: 100%
		// Works for single files only
		const name = names[0];
		const allAssets = await useIDB("get", APP_STORAGE_IDB_KEYS.assets);
		const filteredAssets = ((allAssets || []) as IAssetGetItem[]).filter(
			(asset) => asset.ass_name !== name,
		);

		// Store changes
		await useIDB("set", APP_STORAGE_IDB_KEYS.assets, filteredAssets);
		void this.fetchAssets(); // No await
		// void this.fetchCheckpointData();
		return true;
	},
	async fetchCheckpointData(params: Partial<CheckpointSaveInstance> = {}) {
		// NOTE: 99% - Only returns latest, no option to choose other checkpoints
		const resData = (await useIDB(
			"get",
			APP_STORAGE_IDB_KEYS.checkpoint,
		)) as ICheckpointSaveType | null;

		return parseSavePayload(resData);
	},
	// async removeCheckpointData(
	//   params: Partial<CheckpointSaveInstance> = {},
	//   showToast = true
	// ) {
	//   // NOTE: 100% NOT USED
	//   return null;
	// },
	// async putCheckpointState(params: Partial<CheckpointSaveInstance> = {}) {
	//   // NOTE: 100% NOT USED
	//   return null;
	// },
	// async fetchCheckpointStates(
	//   params: Record<string, any> = {
	//     limit: 30,
	//     isOffset: false,
	//   }
	// ) {
	//   // NOTE: 100% NOT USED
	//   return null;
	// },
	async createCheckpoint(params: {
		sav_save: ISavePayload[];
	}): Promise<string> {
		// NOTE: 99% - Works for a single checkpoint
		await useIDB("set", APP_STORAGE_IDB_KEYS.checkpoint, params);
		return "standalone"; // Hash not used | only for compatibility
	},
	async postCheckDelete(params: IAssetGetItem["ass_name"][]): Promise<any[]> {
		// NOTE: 100%
		const assets = await useIDB("get", APP_STORAGE_IDB_KEYS.assets);
		const validNames = new Set(
			((assets || []) as IAssetGetItem[]).map((asset) => asset.ass_name),
		);
		const excludedAssets = params.filter((name) => !validNames.has(name));
		return excludedAssets;
	},
	async postUploadBuild(params: Record<string, any> = {}, showToast = true) {
		// NOTE: 100% NOT USED
		return null;
	},
	async getPages() {
		// NOTE: 0% Needs implementation
		const pages = await useIDB("get", APP_STORAGE_IDB_KEYS.pages);
		console.warn(pages);
		return pages;
	},
	async postPages(data: any) {
		// NOTE: 0% Needs implementation
		await useIDB("set", APP_STORAGE_IDB_KEYS.pages, data);
		const pages = await useIDB("get", APP_STORAGE_IDB_KEYS.pages);
		console.warn(pages);
		return pages;
	},
};
