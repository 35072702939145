<script setup lang="ts">
import { commandKeyEvent } from "@/html-engine/modules/helper";
import { type IDragBarMoveEvent } from "./DragBar.vue";

export interface IDragInputChangeEvent {
	evt: MouseEvent;
	incVal: number;
	incNum: number;
}

const props = defineProps<{
	value?: string | number;
}>();
const emit = defineEmits<{
	(e: "input", value: IDragInputChangeEvent): void;
	(e: "change", value: IDragInputChangeEvent): void;
}>();

const normalizedValue = computed(() => {
	const parsedVal =
		typeof props.value === "number"
			? props.value
			: parseInt(props.value || "", 10);
	if (parsedVal) {
		return parsedVal;
	}
	return 0;
});

function generatePayload(payload: IDragBarMoveEvent) {
	const currentVal = normalizedValue.value;
	if (typeof currentVal === "number" && !isNaN(currentVal)) {
		const incNum = commandKeyEvent(payload.evt)
			? payload.incrementChangeStep * 10
			: payload.incrementChangeStep;

		const incVal = currentVal + incNum;
		const data = {
			evt: payload.evt,
			incVal,
			incNum,
		};
		return data;
	}
	return null;
}

function onMouseMoveDragWidth(payload: IDragBarMoveEvent) {
	if (payload.isMoved) {
		const data = generatePayload(payload);
		if (data) {
			emit("input", data);
		}
	}
}

function onMouseStopDragging(payload: IDragBarMoveEvent) {
	if (payload.isMoved) {
		const data = generatePayload(payload);
		if (data) {
			emit("change", data);
		}
	}
}
</script>

<template lang="pug">
.drag-bar-inputs
	drag-bar(
		:is-vertical="false",
		@dragging="onMouseMoveDragWidth",
		@drag-stop="onMouseStopDragging"
	)
</template>

<style lang="scss" scoped>
.drag-bar-inputs {
	display: flex;
	height: 100%;

	:deep() .drag-wrap {
		.drag-bar {
			background: transparent;
			width: 3px;
		}
	}
}
</style>
