import { setupTooltip } from "@/helpers/tooltip";
import { type App } from "vue";

const listeners = new Map<HTMLElement, () => void>();

export function setupDirectives(app: App<Element>) {
	app.directive("tooltip", {
		mounted: (
			el: HTMLElement,
			data: {
				value: {
					item: { placeholder?: string; label: string };
					tooltipEl: HTMLElement;
				};
			},
		) => {
			const text = data.value.item.placeholder || data.value.item.label;
			void setupTooltip(
				el,
				data.value.tooltipEl,
				ref(text),
				"bottom",
				true,
				// eslint-disable-next-line promise/prefer-await-to-then
			).then(({ removeListeners }) => {
				listeners.set(el, removeListeners);
			});
		},
		unmounted: (
			el: HTMLElement,
			data: { value: { item: any; tooltipEl: HTMLElement } },
		) => {
			const remItem = listeners.get(el);
			if (remItem) {
				// console.log("Unmounted tooltip >", el, data.value, remItem);
				remItem();
			}
			listeners.delete(el);
		},
	});

	app.directive("focus", {
		mounted: (el) => el.focus(),
	});
}
