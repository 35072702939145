import type { TObjEntries } from "@/stores/definition/globalTypes";
import { isCssValid } from "./helpers";
import omit from "lodash.omit";

export interface IBorderCss {
	border: string;
	borderWidth: string;
	borderStyle: string;
	borderColor: string;
}
interface IBorderRadius {
	borderRadius: string;
	borderTopLeftRadius: string;
	borderTopRightRadius: string;
	borderBottomLeftRadius: string;
	borderBottomRightRadius: string;
}
interface ITextStroke {
	width: string;
	color: string;
}
interface ITextShadow {
	x: string;
	y: string;
	blur: string;
	color: string;
}
interface IBoxShadow extends ITextShadow {
	spread: string;
	inset: "" | "inset" | "outset";
}

const BORDER_RADIUS_REGEX =
	/^(\d+(\.\d+)?)(px|%|in|cm|mm|em|rem|ex|pt|pc)(\s+\d+(\.\d+)?(px|%|in|cm|mm|em|rem|ex|pt|pc)){0,3}$/;
const BORDER_REGEX =
	/^(\d+(\.\d+)?(px|em|rem|%|in|cm|mm|ex|pt|pc))(\s+\d+(\.\d+)?(px|em|rem|%|in|cm|mm|ex|pt|pc)){0,3}\s+(solid|dotted|dashed|double|groove|ridge|inset|outset)\s+(#[\da-fA-F]{3,6}|[a-zA-Z]+)$/;

abstract class CGeneralCss {
	constructor() {
		if (this.constructor === CGeneralCss) {
			throw new Error("Abstract classes can't be instantiated.");
		}
	}
	abstract toString(): string | "none" | "inherit" | "initial";
	abstract getValue(key: string): string;
	abstract setValue(payload: Record<string, string>): CGeneralCss;
	abstract resetVals(): void;
	abstract isInvalid(key: string): boolean;
}

export class CTextStroke extends CGeneralCss {
	private width = "";
	private color = "";

	constructor(input: string) {
		super();

		const hasInvalidChars = input.match(/rgb|hsl/g);
		if (hasInvalidChars) throw new Error(`Has invalid chars: ${input}`);

		// if (input && !isCssValid("webkitTextStroke", input)) {
		// 	console.log(`Invalid CSS ${input}`);
		// }

		const splitted = input.split(" ");
		if (splitted.length === 2) {
			this.width = splitted[0];
			this.color = splitted[1];
		} else if (splitted[0].includes("px")) {
			this.width = splitted[0];
		} else {
			this.color = splitted[0];
		}
	}

	toString(): string | "none" | "initial" | "inherit" {
		return `${this.width} ${this.color}`.replace(/\s\s+/g, " ").trim();
	}
	getValue(key: keyof ITextStroke): string {
		return this[key];
	}
	setValue(payload: Partial<ITextStroke>): this {
		for (const [key, val] of Object.entries(
			payload,
		) as TObjEntries<ITextStroke>) {
			this[key] = val.trim();
		}

		return this;
	}
	resetVals(): void {
		this.width = "";
		this.color = "";
	}
	isInvalid(key: keyof ITextStroke) {
		if (key === "color") {
			return !isCssValid("color", this.color);
		} else if (key === "width") {
			return !isCssValid("width", this.width);
		}
		return false;
	}
}

export class CTextShadow extends CGeneralCss {
	private x = "";
	private y = "";
	private blur = "";
	private color = "";

	/**
	 * @classdesc Doesn't support multiple shadows
	 */
	constructor(input: string) {
		super();

		const hasInvalidChars = input.match(/rgb|hsl/g);
		if (hasInvalidChars) throw new Error(`Has invalid chars: ${input}`);

		// if (input && !isCssValid("textShadow", input)) {
		// 	console.log(`Invalid CSS ${input}`);
		// }

		const multipleVals = input.split(",");
		const firstVal = multipleVals[0];
		const splitted = firstVal.split(" ");
		switch (splitted.length) {
			case 4: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.blur = splitted[2];
				this.color = splitted[3];

				break;
			}
			case 3: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.color = splitted[2];

				break;
			}
			case 2: {
				this.x = splitted[0];
				this.y = splitted[1];

				break;
			}
			default: {
				if (splitted[0]) {
					this.x = splitted[0];
				}
			}
		}
	}

	toString(): string | "none" | "initial" | "inherit" {
		return `${this.x} ${this.y} ${this.blur} ${this.color}`
			.replace(/\s\s+/g, " ")
			.trim();
	}
	getValue(key: keyof ITextShadow | "sizes"): string {
		if (key === "sizes") {
			return `${this.x} ${this.y} ${this.blur}`.trim();
		}
		return this[key];
	}
	setValue(payload: Partial<ITextShadow> | string): this {
		if (!payload) {
			this.resetVals(false);
			return this;
		}

		if (typeof payload === "string") {
			const splitted = payload.trim().split(" ");
			this.x = splitted[0];
			splitted[1] && (this.y = splitted[1]); // Doesn't have a value on start
			splitted[2] && (this.blur = splitted[2]);
			splitted[3] && (this.color = splitted[3]);
		} else {
			for (const [key, val] of Object.entries(
				payload,
			) as TObjEntries<ITextShadow>) {
				this[key] = val.trim();
			}
		}

		return this;
	}
	resetVals(includeColor = false) {
		this.x = "";
		this.y = "";
		this.blur = "";
		if (includeColor) {
			this.color = "";
		}
	}
	isInvalid(key: keyof ITextShadow | "sizes") {
		if (key === "color") return !isCssValid("color", this.color);
		if (key === "x") return !isCssValid("margin", this.x);
		if (key === "y") return !isCssValid("margin", this.y);
		if (key === "blur") return !isCssValid("margin", this.blur);
		if (key === "sizes") {
			if (this.x && this.y && this.blur)
				return !(
					isCssValid("margin", this.x) &&
					isCssValid("margin", this.y) &&
					isCssValid("margin", this.blur)
				);
			if (this.x && this.y)
				return !(isCssValid("margin", this.x) && isCssValid("margin", this.y));
			if (this.x) return !isCssValid("margin", this.x);
			return false;
		}
		return false;
	}
}

export class CBoxShadow extends CGeneralCss {
	private x = "";
	private y = "";
	private blur = "";
	private spread = "";
	private color = "";
	private inset: "" | "inset" | "outset" = "";

	/**
	 * @classdesc Doesn't support multiple shadows
	 */
	constructor(input: string) {
		super();

		const hasInvalidChars = input.match(/rgb|hsl/g);
		if (hasInvalidChars) throw new Error(`Has invalid chars: ${input}`);

		// if (input && !isCssValid("boxShadow", input)) {
		// 	console.log(`Invalid CSS ${input}`);
		// }

		const multipleVals = input.split(",");
		const firstVal = multipleVals[0];
		const splitted = firstVal.split(" ");
		switch (splitted.length) {
			case 6: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.blur = splitted[2];
				this.spread = splitted[3];
				this.inset = splitted[4] as typeof this.inset;
				this.color = splitted[5];

				break;
			}
			case 5: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.blur = splitted[2];
				this.spread = splitted[3];
				this.color = splitted[4];

				break;
			}
			case 4: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.blur = splitted[2];
				this.color = splitted[3];

				break;
			}
			case 3: {
				this.x = splitted[0];
				this.y = splitted[1];
				this.color = splitted[2];

				break;
			}
			case 2: {
				this.x = splitted[0];
				this.y = splitted[1];

				break;
			}
			default: {
				if (splitted[0]) {
					this.x = splitted[0];
				}
			}
		}
	}

	toString(): string | "none" | "initial" | "inherit" {
		return `${this.x} ${this.y} ${this.blur} ${this.spread} ${this.inset} ${this.color}`
			.replace(/\s\s+/g, " ")
			.trim();
	}
	getValue(key: keyof IBoxShadow | "sizes"): string {
		if (key === "sizes") {
			return `${this.x} ${this.y} ${this.blur} ${this.spread} ${this.inset}`.trim();
		}
		return this[key];
	}
	setValue(payload: Partial<IBoxShadow> | string): this {
		if (!payload) {
			this.resetVals(false);
			return this;
		}

		if (typeof payload === "string") {
			const splitted = payload.trim().split(" ");
			this.x = splitted[0];
			this.y = splitted[1] || ""; // Doesn't have a value on start
			this.blur = splitted[2] || "";
			this.spread = splitted[3] || "";
			this.inset = (splitted[4] || "") as typeof this.inset;
			// NOTE: Doesn't support reverse order of inset / color | even if spec does
			splitted[5] && (this.color = splitted[5]);
		} else {
			for (const [key, val] of Object.entries(
				payload,
			) as TObjEntries<IBoxShadow>) {
				this[key] = val.trim() as any;
			}
		}

		return this;
	}
	resetVals(includeColor = false) {
		this.x = "";
		this.y = "";
		this.blur = "";
		this.spread = "";
		this.inset = "";
		if (includeColor) {
			this.color = "";
		}
	}

	isInvalid(key: keyof IBoxShadow | "sizes") {
		if (key === "color") return !isCssValid("color", this.color);
		if (key === "x") return !isCssValid("margin", this.x);
		if (key === "y") return !isCssValid("margin", this.y);
		if (key === "blur") return !isCssValid("margin", this.blur);
		if (key === "spread") return !isCssValid("margin", this.spread);
		if (key === "inset")
			return !(this.inset === "inset" || this.inset === "outset");

		if (key === "sizes") {
			if (this.x && this.y && this.blur && this.spread && this.inset)
				return !(
					isCssValid("margin", this.x) &&
					isCssValid("margin", this.y) &&
					isCssValid("margin", this.blur) &&
					isCssValid("margin", this.spread) &&
					(this.inset === "inset" || this.inset === "outset")
				);
			if (this.x && this.y && this.blur && this.spread)
				return !(
					isCssValid("margin", this.x) &&
					isCssValid("margin", this.y) &&
					isCssValid("margin", this.blur) &&
					isCssValid("margin", this.spread)
				);
			if (this.x && this.y && this.blur)
				return !(
					isCssValid("margin", this.x) &&
					isCssValid("margin", this.y) &&
					isCssValid("margin", this.blur)
				);
			if (this.x && this.y)
				return !(isCssValid("margin", this.x) && isCssValid("margin", this.y));
			if (this.x) return !isCssValid("margin", this.x);
			return false;
		}
		return false;
	}
}

export class CBorder extends CGeneralCss {
	// private static globalVals = [
	// 	"inherit",
	// 	"initial",
	// 	"revert",
	// 	"revert-layer",
	// 	"unset",
	// 	"none",
	// ];
	static borderStyleValues = [
		"dotted",
		"dashed",
		"solid",
		"double",
		"groove",
		"ridge",
		"inset",
		"outset",
		"none",
		"hidden",
	] as const;

	private border = "";
	private borderColor = "";
	private borderStyle: (typeof CBorder.borderStyleValues)[number] = "none";
	private borderWidth = "";

	constructor(input: string) {
		super();

		const hasInvalidChars = input.match(/rgb|hsl/g);
		if (hasInvalidChars) throw new Error(`Has invalid chars: ${input}`);

		// if (input && !isCssValid("border", input)) {
		// 	console.log(`Invalid CSS ${input}`);
		// }

		const payload = CBorder.parseBorderStr(input);
		const entries = Object.entries(payload) as TObjEntries<typeof payload>;
		for (const [key, val] of entries) {
			this[key] = val as any;
		}
	}

	toString(): string | "none" | "initial" | "inherit" {
		if (this.border) {
			return `${this.border}`.replace(/\s\s+/g, " ").trim();
		}
		return `${this.borderWidth} ${this.parsedBorderStyle} ${this.borderColor}`
			.replace(/\s\s+/g, " ")
			.trim();
	}
	getValue(key: keyof IBorderCss): string {
		if (key === "border") {
			return this.border;
		}
		return this[key];
	}
	setValue(payload: Partial<IBorderCss>, resetBorder = true): this {
		for (const [key, val] of Object.entries(
			payload,
		) as TObjEntries<IBorderCss>) {
			if (key === "border") {
				const value = val.trim();
				if (this.isInvalid(value)) {
					console.log("Invalid value", value);
					return this;
				}

				this.border = val.trim();

				// This will set other props
				const payload = CBorder.parseBorderStr(this.border);
				const omitted = omit(payload, "border");
				this.setValue(omitted, false);
			} else {
				if (resetBorder) {
					this.border = "";
				}
				this[key] = val.trim() as any;
			}
		}

		return this;
	}
	resetVals(): void {
		this.border = "";
		this.borderColor = "";
		this.borderStyle = "none";
		this.borderWidth = "";
	}
	isInvalid(value: string = this.border) {
		return CBorder.isInvalidBorderValue(value);
	}

	static isInvalidBorderValue(value: string) {
		const regex = new RegExp(BORDER_REGEX);
		return !regex.test(value);
	}
	static parseBorderStr(input: string): {
		border: string;
		borderWidth: string;
		borderStyle: (typeof CBorder.borderStyleValues)[number];
		borderColor: string;
	} {
		type TBorderStyleValues = (typeof CBorder.borderStyleValues)[number];

		const tempPayload: {
			border: string;
			borderWidth: string;
			borderStyle: TBorderStyleValues;
			borderColor: string;
		} = {
			border: "",
			borderWidth: "",
			borderStyle: "none",
			borderColor: "",
		};
		const multipleVals = input.split(" ");
		switch (multipleVals.length) {
			case 1: {
				if (this.borderStyleValues.includes(input as TBorderStyleValues)) {
					tempPayload.borderStyle = input as TBorderStyleValues;
				} else {
					tempPayload.border = input;
				}

				break;
			}
			case 2: {
				tempPayload.borderWidth = multipleVals[0] || "";
				tempPayload.borderStyle = (multipleVals[1] || "") as TBorderStyleValues;

				break;
			}
			case 3: {
				tempPayload.borderWidth = multipleVals[0] || "";
				tempPayload.borderStyle = (multipleVals[1] || "") as TBorderStyleValues;
				tempPayload.borderColor = multipleVals[2] || "";

				break;
			}
			// No default
		}
		return tempPayload;
	}
	get parsedBorderStyle() {
		return this.borderStyle === "none" ? "" : this.borderStyle;
	}
}

export class CBorderRadius extends CGeneralCss {
	private borderRadius = "";
	private borderTopLeftRadius = "";
	private borderTopRightRadius = "";
	private borderBottomLeftRadius = "";
	private borderBottomRightRadius = "";

	constructor(input: string) {
		super();

		const hasInvalidChars = input.match(/rgb|hsl/g);
		if (hasInvalidChars) throw new Error(`Has invalid chars: ${input}`);

		// if (input && !isCssValid("borderRadius", input)) {
		// 	console.log(`Invalid CSS ${input}`);
		// }

		const multipleVals = input.split(" ");
		this.borderRadius = input;
		switch (multipleVals.length) {
			case 1: {
				this.borderTopLeftRadius = input;
				this.borderTopRightRadius = input;
				this.borderBottomLeftRadius = input;
				this.borderBottomRightRadius = input;

				break;
			}
			case 2: {
				this.borderTopLeftRadius = multipleVals[0] || "";
				this.borderTopRightRadius = multipleVals[1] || "";
				this.borderBottomLeftRadius = multipleVals[1] || "";
				this.borderBottomRightRadius = multipleVals[0] || "";

				break;
			}
			case 4: {
				this.borderTopLeftRadius = multipleVals[0] || "";
				this.borderTopRightRadius = multipleVals[1] || "";
				this.borderBottomLeftRadius = multipleVals[2] || "";
				this.borderBottomRightRadius = multipleVals[3] || "";

				break;
			}
			// No default
		}
	}

	toString(): string | "none" | "initial" | "inherit" {
		return `${this.borderRadius}`.replace(/\s\s+/g, " ").trim();
	}
	getValue(key: keyof IBorderRadius): string {
		if (key === "borderRadius") {
			return this.borderRadius;
		}
		return this[key];
	}
	setValue(payload: Partial<IBorderRadius>): this {
		for (const [key, val] of Object.entries(
			payload,
		) as TObjEntries<IBorderRadius>) {
			if (key === "borderRadius") {
				this.borderRadius = val.trim();
			} else {
				this[key] = val.trim();
			}
		}

		return this;
	}
	resetVals(): void {
		this.borderRadius = "";
		this.borderTopLeftRadius = "";
		this.borderTopRightRadius = "";
		this.borderBottomLeftRadius = "";
		this.borderBottomRightRadius = "";
	}
	isInvalid(value: string = this.borderRadius) {
		const regex = new RegExp(BORDER_RADIUS_REGEX);
		return !regex.test(value);
	}
}
