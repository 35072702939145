<script setup lang="ts">
import { $t } from "@/i18n";
import { type THTMLInputTypeAttribute } from "@/stores/definition/globalTypes";
import { useLayerInstancesStore, type IDomAttributes } from "@/stores/layer";
import { type TKeyValFormParams } from "./KeyValForm.vue";

type TAttributeList = {
	__id: string;
	type: THTMLInputTypeAttribute;
	key: keyof IDomAttributes;
	value: any;
	__pending?: boolean;
};

interface IKeyValFormParamsAttrs extends TKeyValFormParams {
	item: TAttributeList;
}

const emit = defineEmits(["close-modal"]);
const layerStore = useLayerInstancesStore();
const selInstances = computed(() => {
	return layerStore.getSelectedElementInstances || [];
});
const firstInstance = computed(() => selInstances.value[0]);
const list = ref<TAttributeList[]>([]);
const isAnyValueChanged = ref(false);

function addNewRow() {
	list.value.push({
		__id: ModelInstance.createUniqueId(),
		__pending: true,
		type: "text",
		key: "",
		value: "",
	});
}

function removeItem(params: IKeyValFormParamsAttrs) {
	list.value.splice(params.index, 1);
	isAnyValueChanged.value = true;
}

async function onChangeKey(params: IKeyValFormParamsAttrs) {
	const newVal = params.value;
	params.item.key = newVal;
	isAnyValueChanged.value = true;
	delete params.item.__pending;

	if (newVal) {
		const hasAnyBlankKey = list.value.some((item) => !item.key);
		!hasAnyBlankKey && addNewRow();
	} else {
		removeItem({
			item: params.item,
			index: params.index,
			evt: params.evt,
			value: params.value,
		});
	}
}

async function onChangeValue(params: IKeyValFormParamsAttrs) {
	const newVal = params.value;
	params.item.value = newVal;
	isAnyValueChanged.value = true;
}

function onClickSave() {
	if (firstInstance.value) {
		const domObj: IDomAttributes = {};
		for (const listEl of list.value) {
			if (listEl.key && !listEl.__pending) {
				domObj[listEl.key] = listEl.value;
			}
		}

		layerStore.updateModelRaw(
			{ attributes: domObj },
			firstInstance.value,
			"on",
			null,
			false,
		);

		onClickCancel();
	}
}

function onClickCancel() {
	emit("close-modal");
}

function onClickCheckSave() {
	if (isAnyValueChanged) {
		onClickSave();
	}
}

onMounted(() => {
	const attrs = firstInstance.value.attributes;
	if (attrs && Object.keys(attrs).length) {
		list.value = Object.keys(attrs).map((key) => {
			const value = attrs[key];
			const isCheckbox =
				typeof value === "boolean" || value === "false" || value === "true";
			return {
				type: isCheckbox ? "checkbox" : "text",
				__id: ModelInstance.createUniqueId(),
				key,
				// value,
				value: typeof value === "string" ? decodeURI(value) : value,
			};
		});
	}

	addNewRow();
});
</script>

<template lang="pug">
.attributes-dialog
	ul
		li(v-for="(item, index) in list", :key="item.__id")
			key-val-form(
				:item="item",
				:index="index",
				@change-key="onChangeKey",
				@change-val="onChangeValue",
				@click-value-enter="onClickCheckSave",
				@click-remove-item="removeItem"
			)
	.action-bar
		button(:disabled="!isAnyValueChanged", @click="onClickCheckSave")
			span {{ $t("dialog.save") }}
		button(@click="onClickCancel")
			span {{ $t("dialog.cancel") }}
</template>

<style lang="scss" scoped>
.attributes-dialog {
	display: flex;
	flex-direction: column;
	padding: 0 20px 20px;
	height: 500px;
	max-height: 90vh;
	width: 500px;
	overflow: auto;
	user-select: none;
	gap: 20px;

	ul {
		display: flex;
		flex-direction: column;
		overflow: auto;
		gap: 5px;
	}

	li {
		display: flex;
		flex-direction: column;
	}

	.action-bar {
		margin: auto 0 0 auto;
		padding: 5px 10px;
		display: flex;
		gap: 10px;
	}
}
</style>
