import { type IDomElementInterface } from "@/stores/layer";

export const contentEditableTags = [
	"div",
	"span",
	"p",
	"h1",
	"h2",
	"h3",
	"h4",
	"h5",
	"h6",
];

export const validNumberInputKeys = [
	"Backspace",
	"Delete",
	"Enter",
	"Tab",
	"Space",
	"ArrowLeft",
	"ArrowUp",
	"ArrowRight",
	"ArrowDown",
];

const partialFileDomTypes = {
	image: ["img"],
	video: ["video"],
	text: contentEditableTags,
};
export function getFileTypeToDomType(type: string, isReverse = false) {
	if (!type) return null;

	if (isReverse) {
		const ent = Object.entries(partialFileDomTypes);
		for (const [k, v] of ent) {
			if (Array.isArray(v)) {
				if (v.includes(type)) {
					return k;
				}
			} else if (v === type) {
				return k;
			}
		}
	} else {
		const keys = Object.keys(partialFileDomTypes);
		const key = keys.find((key) =>
			type.includes(key),
		) as keyof typeof partialFileDomTypes;
		if (key) {
			const val = partialFileDomTypes[key];
			if (Array.isArray(val)) {
				// Return first tag
				return val[0];
			}
			return val;
		}
	}
	console.warn("Invalid type", type);

	return null;
}

export const ITEM_TAGS_CREATION = {
	video: {
		children: [
			{
				tag: "source",
				_private: {
					parentDataName: "",
				},
			},
		] as IDomElementInterface["children"],
		attributes: {
			controls: true,
			autoplay: true,
			muted: true,
			loop: true,
		},
	},
	audio: {
		children: [
			{
				tag: "source",
				_private: {
					parentDataName: "",
				},
			},
		] as IDomElementInterface["children"],
		attributes: {
			controls: true,
			autoplay: true,
			muted: true,
			loop: true,
		},
	},
	img: {},
	div: {},
	span: {},
	p: {},
};
