export interface IGlobalAppOptions {
	element: {
		isAspectRatio: boolean;
	};
}

export type TGlobalAppOptionKeys = keyof IGlobalAppOptions | "aspect-ratio";

export const globalOptions = reactive<IGlobalAppOptions>({
	element: {
		isAspectRatio: false,
	},
});

export const pageViewProps = reactive({
	zoom: 0.5,
	translate: {
		x: 0,
		y: 0,
	},
	left: 0, // Unused?
	top: 0, // Unused?
	transformOrigin: {
		x: "center",
		y: "center",
	} as {
		x: string;
		y: string;
	},
	gridLines: false,
	meta: {
		lastId: null as string | null,
	},
});
