<script setup lang="ts">
const isOutdatedBrowser = computed(() => {
	const cssProps = {
		"accent-color": "blue", // ~SEP 2021
		inset: "0", // ~NOV 2020
	};
	const supportsCSS = Object.keys(cssProps).every((key) =>
		CSS.supports(key, cssProps[key as keyof typeof cssProps]),
	);

	// eslint-disable-next-line @typescript-eslint/unbound-method
	const supportsJS = Boolean([].at); // ~JUL 2021
	return !supportsJS || !supportsCSS;
});
const options = reactive({
	wrapper: {
		style: {
			background: "rgba(0, 0, 0, 0.7)",
		},
	},
});
</script>

<template lang="pug">
browser-detect(v-if="isOutdatedBrowser", :options="options")
</template>

<style lang="scss" scoped></style>
