<script setup lang="ts">
definePage({
	name: "CatchAllPage",
	redirect: "/",
});
</script>

<template lang="pug">
div Page not found!
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
</style>
