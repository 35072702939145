// NOTE: This needs to be first in order
import { parseErrors } from "@/helpers/helpers";

interface IUserAuthData {
	appFrontVersion: number;
	result: string;
	lastLogin?: string;
	sid?: string;
	user: {
		usr_id: number;
		name: string;
		email: string;
		level: string;
		activeBundle?: any;
		pendingBundle?: any;
	};
}

export class AppApiInstance {
	private EXPAND_TIMEOUT_INSTANCE: ReturnType<typeof setTimeout> | null = null;
	userInfoData = {} as IUserAuthData;
	_authDomainDev = "https://admin-t.mars-server.net/";
	_authDomainLive = "https://admin.mars-server.net/";
	authDomain = this._authDomainDev;

	parseLinks() {
		const adminApiLinks = {
			login: "api_v2/auth/login",
			projectList: "api_v2/projects",
			siteList: "api_v2/sites",
			selectDb: "api_v2/select-db",
			expandSession: "api_v2/expand-session",
			htmlBuilderSaveLatest: "api_v2/html_builder/save/latest",
		};
		const arr = Object.entries(adminApiLinks).map(([key, val]) => [
			[key],
			this.authDomain + val,
		]);
		return Object.fromEntries(arr as any);
	}
	PARSED_ADMIN_LINKS = this.parseLinks();

	async expandSession(url: string, sid: string): Promise<boolean> {
		try {
			const res = await axios.get(url, { params: { sid } });
			this.userInfoData = res.data;

			this.EXPAND_TIMEOUT_INSTANCE = setTimeout(
				() => {
					// 15 Min Expand
					void this.expandSession(url, sid);
				},
				15 * 60 * 1000,
			);
			return true;
		} catch (err: any) {
			const errorMessage = parseErrors(err, "Error expanding session");
			console.warn("Error", err.message, "--", errorMessage);
			this.clearExpandSession();
			// useToastStore().openToastError(errorMessage);
		}
		return false;
	}

	private clearExpandSession() {
		if (this.EXPAND_TIMEOUT_INSTANCE) {
			console.log("Cleared expand sessions");
			clearTimeout(this.EXPAND_TIMEOUT_INSTANCE);
			this.EXPAND_TIMEOUT_INSTANCE = null;
		}
	}

	logoutSession() {
		console.log("Logged out session");
		this.userInfoData.sid = "";
		this.userInfoData.user = {
			name: "",
			usr_id: 0,
		} as IUserAuthData["user"];
	}
}

export const AppApi = new AppApiInstance();
