import { initCanvasTabs } from "@/assets/js/sharedMaps";
import { type IUniTabProp } from "@/components/shared/tabs/UniTabs.vue";
import merge from "lodash.merge";
import { defineStore } from "pinia";
import { useDefaultStore } from ".";
import {
	globalCanvasDefinition,
	GlobalCanvasOptions,
} from "./definition/global";
import type {
	TBreakpointItem,
	IBreakpointItemEdit,
	IMetaObj,
	ISaveInstance,
	ISavePayload,
	TSharedElementItemInterface,
} from "./definition/globalTypes";
import { DomElementInstance, type IDomElementInterface } from "./layer";
import { jsonToObject, useSnapshotStore } from "./snapshot";
import loMerge from "lodash.merge";
import omit from "lodash.omit";
import { PageCanvasOptions } from "./definition/canvas";
import {
	type TModelInstanceGroup,
	type GroupedInstance,
	type ModelInstance,
	type SmallGroupInstance,
	// type TGroupPayload,
} from "./group";

export interface ISnapshotSteps {
	savedStep: number;
	undoStep: number;
}
interface ISnapshotsProps {
	data: string[];
	snapshotStep: ISnapshotSteps;
}

// ** Guide selectedModelIds **
// null - Global view, [] - Canvas view, ["%str%"] - Model view

interface IElementsProps {
	data: DomElementInstance[];
	groups: GroupedInstance[];
	selectedModelIds: ModelInstance["id"][] | null;
	selectGroupData: SmallGroupInstance | null | undefined;
	hoveredModelIds: ModelInstance["id"][] | null;
	movingModelIds: ModelInstance["id"][];
}

export interface IBreakpointDefinition {
	active: TBreakpointItem["name"];
	definition: TBreakpointItem[];
}

export interface IBreakpointDefinitionEdit {
	active: TBreakpointItem["name"];
	definition: IBreakpointItemEdit[];
}

interface ICanvasProps {
	breakpoint: IBreakpointDefinition;
	element: {
		editableFocus: DomElementInstance["id"] | null;
	};
	globalCanvasOptions: GlobalCanvasOptions;
}

interface IPageProps {
	id: string;
	snapshots: ISnapshotsProps;
	elements: IElementsProps;
	canvas: ICanvasProps;
	tabMeta: IUniTabProp;
}
export type TGroupSelection = SmallGroupInstance | null | undefined;

export class PageInstance implements IPageProps {
	id: string = PageInstance.createUniqueId();
	private _snapshots = {
		data: ["{}"] as string[],
		snapshotStep: {
			savedStep: 0,
			undoStep: 0,
		},
	};
	private readonly _elements = {
		data: [] as DomElementInstance[],
		groups: [] as GroupedInstance[], // Groups one / more elements
		selectedModelIds: [] as ModelInstance["id"][] | null, // When selected via click > For prop change in right sidebar
		selectGroupData: undefined as SmallGroupInstance | null | undefined,
		hoveredModelIds: null as ModelInstance["id"][] | null, // When hovered element via layers view, or from canvas
		movingModelIds: [] as ModelInstance["id"][], // When interacting / moving elements in canvas
	};
	private readonly _canvas = {
		breakpoint: {
			active: "",
			definition: [] as TBreakpointItem[],
		},
		element: {
			editableFocus: null as DomElementInstance["id"] | null,
		},
		globalCanvasOptions: new GlobalCanvasOptions(),
	};
	private _tabMeta = {
		name: PageInstance.createUniqueId(),
		label: createUniqueName(
			"Index",
			usePageStore().getAllPages.map((page) => page.tabMeta.label),
		),
	};

	constructor(label?: string, id?: string) {
		this.initPageInstance({ id, label });
	}

	get snapshots() {
		return this._snapshots;
	}
	set snapshots(value: ISnapshotsProps) {
		this._snapshots = value;
	}

	get snapshotsData() {
		return this._snapshots.data;
	}
	set snapshotsData(value: string[]) {
		this._snapshots.data = value;
	}
	get snapshotStep() {
		return this._snapshots.snapshotStep;
	}
	set snapshotStep(value: ISnapshotsProps["snapshotStep"]) {
		merge(this._snapshots.snapshotStep, value);
	}
	get currentSnapshotString(): string {
		const undoStep = this.snapshotStep.undoStep;
		if (!this.snapshotsData) return "{}";
		return undoStep || undoStep === 0
			? this.snapshotsData[undoStep] || "{}"
			: "{}";
	}
	getActiveSnapshot(): ISaveInstance {
		// Getter is expensive to compute [multiple times]
		const undoStep = this.snapshotStep.undoStep;
		const snapString = this.currentSnapshotString;
		const hasSnapString = snapString && snapString !== "{}";
		return (undoStep || undoStep === 0) && hasSnapString
			? jsonToObject(snapString)
			: PageInstance.generateDefaultPage();
	}

	get elements() {
		return this._elements;
	}
	set elements(value: IElementsProps) {
		Object.assign(this._elements, value);
	}

	get elementsData(): DomElementInstance[] {
		return this._elements.data;
	}
	set elementsData(value: DomElementInstance[]) {
		this._elements.data = value;
	}
	get groupsData(): GroupedInstance[] {
		return this._elements.groups;
	}
	set groupsData(value: GroupedInstance[]) {
		this._elements.groups = value;
	}
	get modelsHovered(): ModelInstance["id"][] | null {
		return this._elements.hoveredModelIds;
	}
	set modelsHovered(value: ModelInstance["id"][] | null) {
		this._elements.hoveredModelIds = value;
	}
	get groupSelected(): TGroupSelection {
		return this._elements.selectGroupData;
	}
	set groupSelected(value: TGroupSelection) {
		this._elements.selectGroupData = value;
	}
	// get modelsMoving(): ModelInstance["id"][] {
	// 	return this._elements.movingModelIds;
	// }
	// set modelsMoving(value: ModelInstance["id"][]) {
	// 	this._elements.movingModelIds = value;
	// }
	// TODO 3 REMOVE IN FUTURE
	// snappingPoints(excludeIds: ModelInstance["id"][] = []): {
	// 	left: number[];
	// 	top: number[];
	// } {
	// 	const activeBreakpoint = this.canvas.breakpoint.active;

	// 	const leftCoor = [];
	// 	const topCoor = [];
	// 	for (const el of this.elementsData) {
	// 		if (!excludeIds.includes(el.id)) {
	// 			const styleObj = el.style[activeBreakpoint]?.toPlainCss;
	// 			if (styleObj) {
	// 				leftCoor.push(parseInt(styleObj.left));
	// 				topCoor.push(parseInt(styleObj.top));
	// 			}
	// 		}
	// 	}
	// 	leftCoor.sort((a, b) => a - b);
	// 	topCoor.sort((a, b) => a - b);
	// 	return { left: leftCoor, top: topCoor };
	// }
	getSortedModels(
		arr: TModelInstanceGroup[],
		order: "dsc" | "asc" = "asc",
		includeHidden = true,
	): TModelInstanceGroup[] {
		if (arr.length) {
			// NOTE: In-place sort, may cause sideeffect, but has to be like that because of reactivity
			const sortInstances = (
				arr: TModelInstanceGroup[] = [],
			): TModelInstanceGroup[] => {
				let tempArr = arr;
				if (!includeHidden) {
					const activeBreakpoint = this.canvas.breakpoint.active;
					tempArr = arr.filter((item) => {
						const visibleOn = item.getPrivateOptions().visibleOn;
						if (visibleOn) {
							return visibleOn.includes(activeBreakpoint);
						}
						return true;
					});
				}

				return order === "asc" ? tempArr : tempArr.reverse();
			};

			const instances: TModelInstanceGroup[] = [];
			for (const curr of arr) {
				if (Array.isArray(curr.children)) {
					// TODO 24-2 Improve hidden tree element
					curr.children = sortInstances(curr.children);
				}
				instances.push(curr);
			}

			const sorted = sortInstances(instances);

			return sorted;
		}
		return [];
	}

	get canvas(): ICanvasProps {
		return this._canvas;
	}
	set canvas(value: ICanvasProps) {
		Object.assign(this._canvas, value);
	}
	globalCanvasDefinition(): TSharedElementItemInterface[] {
		return globalCanvasDefinition(this.canvas.globalCanvasOptions);
	}

	get tabMeta(): IUniTabProp {
		return Object.assign(this._tabMeta, { isDirty: this.isPageDirty() });
	}
	set tabMeta(value: IUniTabProp) {
		this._tabMeta = value;
	}

	initPageInstance(
		params: { id?: string; label?: string },
		canInit?: {
			id?: boolean;
			snapshot?: boolean;
			element?: boolean;
			canvas?: boolean;
			tab?: boolean;
		},
	) {
		const can = {
			id: canInit?.id || false,
			snapshot: canInit?.snapshot || false,
			element: canInit?.element || false,
			canvas: canInit && "canvas" in canInit ? canInit.canvas : true,
			tab: canInit?.tab || false,
		};

		// ID
		if (can.id || params.id) {
			this.id = params.id || PageInstance.createUniqueId();
		}

		// Snapshot
		if (can.snapshot) {
			this.snapshotsData = ["{}"];
			this.snapshotStep.savedStep = 0;
			this.snapshotStep.undoStep = 0;
		}

		// Element
		if (can.element) {
			this.elementsData = [];
			this.groupsData = [];
			this.groupSelected = undefined;
		}

		// Canvas
		if (can.canvas) {
			this.canvas.breakpoint.definition = initCanvasTabs();
			this.canvas.breakpoint.active = this.canvas.breakpoint.definition[0].name;
			this.canvas.element.editableFocus = null;
			this.canvas.globalCanvasOptions = new GlobalCanvasOptions();
		}

		// Tabmeta
		if (can.tab || params.label) {
			// this.tabMeta.name = PageInstance.createUniqueId();
			this.tabMeta.label =
				params.label ||
				createUniqueName(
					"Index",
					usePageStore().getAllPages.map((page) => page.tabMeta.label),
				);
		}
	}
	resetPageInstance() {
		this.initPageInstance({}, { snapshot: false, element: true, canvas: true });
	}
	isPageDirty() {
		return (
			this.snapshots.snapshotStep.undoStep !==
			this.snapshots.snapshotStep.savedStep
		);
	}
	changePageId(id: string) {
		// console.log(`Changing page id >> ${id}, was ${this.id}`);
		this.id = id;
	}
	// resetSnapshotData(restoreStep = -1, isSyncCanvas = true) {
	//   this.snapshotsData = ["{}"];
	//   this.changeSnapshotToPoint(restoreStep, isSyncCanvas);
	// }
	changeSnapshotToPoint(restoreStep = -1, isSyncCanvas = true) {
		if (restoreStep !== -1) {
			this.snapshotStep.undoStep = restoreStep;
		}
		if (isSyncCanvas) {
			const activeSnap = this.getActiveSnapshot();
			this.updateCanvasGlobalOptions(activeSnap.meta);

			// Breakpoint
			this.updateBreakpointTab(activeSnap.breakpoint.definition, false, false);
			this.updateActiveBreakpoint(activeSnap.breakpoint.active);
			this.syncInstanceListFromSnapshot(
				// NOTE: This must be cloned instance, or reactivity brakes
				// IDS should stay the same, so we don't lose ref, but undo can break (fixed?)
				(activeSnap.elements || []).map((item) => item.getRawInstanceData([])),
				// activeSnap.elements.map((instance) => instance.getRawInstance(["id"]))
			);
			activeSnap.id && (this.id = activeSnap.id);
			activeSnap.tabMeta && (this.tabMeta = activeSnap.tabMeta);
			return true;
		}
		return false;
	}
	updateCanvasGlobalOptions(payload: Partial<IMetaObj>, addUndo = false) {
		this.canvas.globalCanvasOptions.setValues(payload, addUndo);

		if (addUndo) {
			useSnapshotStore().addUndoStack();
		}
	}
	getMetaForm(): IMetaObj {
		return this.canvas.globalCanvasOptions.getFormValues as IMetaObj;
	}
	cleanMissingElementStyles() {
		for (const instance of this.getSortedModels(this.elementsData)) {
			const visibleOn = instance.getPrivateOptions().visibleOn || [];
			if (visibleOn.length === 0) {
				return;
			}
			const styleBps = Object.keys(instance.style) || [];
			if (styleBps.length === 0) {
				return;
			}
			const firstStyleObj = styleBps[0];
			if (visibleOn.length !== styleBps.length) {
				for (const key of visibleOn) {
					if (!styleBps.includes(key)) {
						instance.style[key] = new CStyleInstance(
							instance.style[firstStyleObj]?.toPlainCss,
						);
					}
				}
			}
		}
	}
	syncInstanceListFromSnapshot(
		elements: IDomElementInterface[] | DomElementInstance[],
	) {
		// Update date with the truth, so elements gets propertly added / deleted
		const tempElements = [];
		for (const item of elements) {
			if (item instanceof DomElementInstance) {
				console.error("Shouldn't invoke!!!", item);

				tempElements.push(item);
			} else {
				tempElements.push(new DomElementInstance(item));
			}
		}

		// const generateGroups = (instances: DomElementInstance[]) => {
		// 	const tempGroups: GroupedInstance[] = [];
		// 	let insGrpId = "";
		// 	for (const instance of instances) {
		// 		insGrpId = instance.getPrivateOptions().groupName || "";
		// 		if (!insGrpId) continue;

		// 		const existingGroup = tempGroups.find((group) => group.id === insGrpId);
		// 		if (existingGroup) {
		// 			existingGroup.addElement(instance);
		// 		} else {
		// 			const breakpoints = instance.getStyleBreakpoints();
		// 			const dataObj: TGroupPayload = {
		// 				id: insGrpId,
		// 				breakpoints: breakpoints,
		// 				elements: [instance],
		// 			};
		// 			tempGroups.push(new GroupedInstance(dataObj));
		// 		}
		// 	}
		// 	return tempGroups;
		// };

		this.elementsData = tempElements;
		// this.groupsData = generateGroups(tempElements);
		this.cleanMissingElementStyles();

		if (this.groupSelected?.hasData) {
			const mappedIds = new Set(
				useGroupStore()
					.getSortedModels()
					.map((item) => item.id),
			);

			for (const selId of this.groupSelected.elements) {
				const isMapped = mappedIds.has(selId);
				if (!isMapped) {
					this.groupSelected?.remove([selId]);
				}
			}
		}
	}
	getBreakpointTab(
		name: string,
	): { index: number; item: IBreakpointItemEdit } | null {
		const breakpointsEdit = PageInstance.getBreakpointItemEdit(
			this.canvas.breakpoint.definition,
			this.canvas.breakpoint.active,
		);

		const tabIndex = breakpointsEdit.findIndex((tab) => tab.name === name);
		if (tabIndex !== -1) {
			return {
				index: tabIndex,
				item: breakpointsEdit[tabIndex],
			};
		}
		return null;
	}
	updateActiveBreakpoint(name: string) {
		if (name) {
			this.canvas.breakpoint.active = name;
			return true;
		}
		return false;
	}
	updateBreakpointTab(
		items: Partial<IBreakpointItemEdit>[],
		hasUndo = true,
		deepMerge = true,
	) {
		if (!Array.isArray(items) || !items.length) {
			console.warn(">>> [Canvas-Breakpoint] No items", items);
			return;
		}

		for (const item of items) {
			if (!item.name) {
				console.error("Breakpoint name not provided", item);
				continue;
			}
			const breakpointItem = this.getBreakpointTab(item.name);
			const omittedStyle = omit(item, "styles");
			const bodyStyles = item.styles?.body;
			if (breakpointItem) {
				if (deepMerge) {
					loMerge(
						this.canvas.breakpoint.definition[breakpointItem.index],
						omittedStyle,
					);
				} else {
					Object.assign(
						this.canvas.breakpoint.definition[breakpointItem.index],
						omittedStyle,
					);
				}
				if (bodyStyles) {
					// Update breakpoint style
					this.canvas.breakpoint.definition[
						breakpointItem.index
					].styles.body.setValues(bodyStyles, false, false);
				}
			} else {
				console.log("[+] Adding new breakpoint", item, breakpointItem);
				const payload = {
					...omittedStyle,
				} as TBreakpointItem;
				if (bodyStyles) {
					payload.styles = {
						body: new PageCanvasOptions(bodyStyles),
					};
				}
				this.canvas.breakpoint.definition.push(payload);
			}
		}
		if (items.length === 1) {
			const name = items[0].name;
			name && this.updateActiveBreakpoint(name);
		}
		hasUndo && console.warn("[Update canvas tab] Creating undo >> Test");
		if (hasUndo) {
			useSnapshotStore().addUndoStack();
		}
	}
	toPlainPageData(): ISavePayload {
		// Getter makes things reactive / brakes saving
		const sortedInstances = this.getSortedModels(this.elementsData).map(
			(instance) => instance.toPlainData(),
		);
		// const getSortedGroups = () => {
		// 	return this.getSortedModels(this.groupsData).map((instance) =>
		// 		instance.toPlainData(),
		// 	);
		// };

		const data = JSON.parse(
			JSON.stringify({
				id: this.id || "",
				meta: this.getMetaForm(),
				elements: sortedInstances,
				// groups: getSortedGroups(),
				breakpoint: {
					active: this.canvas.breakpoint.active,
					definition: PageInstance.getBreakpointItemEdit(
						this.canvas.breakpoint.definition,
						this.canvas.breakpoint.active,
					),
				},
				tabMeta: this.tabMeta || ({} as PageInstance["tabMeta"]),
			}),
		);
		return data;
	}

	static getBreakpointItemEdit(
		dataArr: TBreakpointItem[],
		activeBreakpoint = "",
	): IBreakpointItemEdit[] {
		return dataArr.map((tab) => {
			const isInitActive = activeBreakpoint
				? tab.name === activeBreakpoint
				: false;
			return {
				...tab,
				isInit: isInitActive || tab.isInit || false,
				styles: {
					body: tab.styles.body.getSimpleValues,
				},
			};
		});
	}
	static generateDefaultPage(shouldCreateInstance = true): ISaveInstance {
		const canvasTabs = initCanvasTabs();
		const breakpointItemEdits = PageInstance.getBreakpointItemEdit(canvasTabs);

		return {
			id: PageInstance.createUniqueId(),
			meta: {
				title: "Mars Web Creator",
				description: "",
				favicon: "",
				backgroundColor: "#babdbfff",
			} as IMetaObj,
			breakpoint: {
				active: "",
				definition: breakpointItemEdits,
			},
			elements: [] as DomElementInstance[],
			tabMeta: {
				name: PageInstance.createUniqueId(),
				label: createUniqueName(
					"Index",
					usePageStore().getAllPages.map((page) => page.tabMeta.label),
				),
			},
		};
	}
	static createUniqueId() {
		return Math.random()
			.toString(36)
			.replace(/[^a-z]+/g, "")
			.slice(0, 7);
	}
}

function createUniqueName(
	name: string,
	listOfUsedNames: string[] = [],
): string {
	// Improve so it increments index
	return listOfUsedNames.includes(name) ? name + "- 1" : name;
}

export const usePageStore = defineStore("page", () => {
	const pageDirtyFlag = ref(false);
	const pageList = reactive<PageInstance[]>([]);

	// Getters
	const getAllPages = computed(() => pageList);
	const currentPageIndex = computed(() => {
		const store = useDefaultStore();
		const lastPageId = store.getPageOptions.meta.lastId;
		if (lastPageId) {
			const index = getAllPages.value.findIndex(
				(page) => page.id === lastPageId,
			);
			return index === -1 ? 0 : index;
		}
		return 0;
	});
	const getCurrentPage = computed(() => {
		const index = Math.min(
			getAllPages.value.length - 1,
			Math.max(currentPageIndex.value, 0),
		);
		return getAllPages.value.length ? getAllPages.value[index] : null;
	});
	const getPageFromId = computed(() => {
		return (id?: PageInstance["id"]): PageInstance | null => {
			if (!id) {
				console.warn("No ID", id, pageList);
				return null;
			}
			const obj = getAllPages.value.find((page) => page.id === id);
			if (obj) return obj as PageInstance;
			return null;
		};
	});
	const getPageFromIndex = computed(() =>
		// eslint-disable-next-line unicorn/consistent-function-scoping
		(index: number): PageInstance | null => {
			const page = getAllPages.value[index] as PageInstance | null;
			if (page) return page;
			return null;
		},
	);

	const isAnyPageDirty = computed(
		() =>
			getAllPages.value.some((page) => page.isPageDirty()) ||
			pageDirtyFlag.value,
	);

	watch(
		getCurrentPage,
		(val) => {
			const globalDef = val?.globalCanvasDefinition() || [];
			const accentColorObj = globalDef.find(
				(def) => def.name === "accentColor",
			);
			if (accentColorObj) {
				const value = accentColorObj.value;
				if (value) {
					const isValidColor = CSS.supports("color", value as string);
					if (isValidColor || !value) {
						document.documentElement.style.setProperty(
							"--app-accent-color",
							value as string,
						);
					}
				}
			}
		},
		{
			deep: true,
		},
	);

	// Actions
	function serializePages() {
		const pagesString = JSON.stringify(getAllPages.value);
		console.warn(pagesString);
	}

	function addNewPage(
		label?: string,
		id?: string,
		isDirtySet = false,
	): { index: number; data: PageInstance } {
		const pageInstance = new PageInstance(label, id);
		const pageIndex = pageList.push(pageInstance) - 1;
		if (isDirtySet) {
			pageDirtyFlag.value = true;
		}

		return { index: pageIndex, data: pageInstance };
	}
	function removePage(indexes: number[]) {
		const sortedDesc = [...indexes].sort((a, b) => b - a);
		for (const index of sortedDesc) {
			if (index === -1) {
				console.warn("Invalid index");
				continue;
			}

			if (pageList.length) {
				pageList.splice(index, 1);
				pageDirtyFlag.value = true;
			} else {
				console.warn("Can't remove last tab");
				return;
			}
		}
	}
	function changePageLabel(label: string, currentName: string) {
		const obj = pageList.find((page) => page.tabMeta.name === currentName);
		if (obj) {
			obj.tabMeta.label = label;
			useSnapshotStore().addUndoStack();
		} else {
			console.error("Page name not found", pageList, currentName);
		}
	}
	function changeCurrentPageData(
		key: keyof IPageProps,
		payload: IPageProps[keyof IPageProps],
	) {
		if (getCurrentPage.value?.[key]) {
			merge(getCurrentPage.value[key], payload);
		} else {
			console.error("No current page");
		}
	}
	function changePageIndex(index: number): boolean {
		// NOTE: Don't reset selection on page change, it works as expected
		const pageInstance = getPageFromIndex.value(index);
		if (pageInstance?.id) {
			const store = useDefaultStore();
			store.updateCanvasOptions({ meta: { lastId: pageInstance.id } }, true);
			store.updateLeftTabSidebar();
			return true;
		}
		return false;
	}
	function resetPageDirtyFlag() {
		pageDirtyFlag.value = false;
	}

	return {
		currentPageIndex, // Mostly for debugging
		getAllPages,
		getCurrentPage,
		getPageFromId,
		isAnyPageDirty,
		serializePages,
		addNewPage,
		removePage,
		changePageLabel,
		changeCurrentPageData,
		changePageIndex,
		resetPageDirtyFlag,
	};
});
