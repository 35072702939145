import { BREAKPOINT_WIDTHS } from "@/global/appGlobal";
import { $t } from "@/i18n";
import { PageCanvasOptions } from "@/stores/definition/canvas";
import type {
	TBreakpointItem,
	TMouseActionType,
	TLeftSidebarTab,
} from "@/stores/definition/globalTypes";

const imageModulesBox = import.meta.glob("@/assets/images/box-align/*.svg", {
	eager: true,
});
const imageModulesText = import.meta.glob("@/assets/images/text-align/*.svg", {
	eager: true,
});
const imageModulesCommand = import.meta.glob("@/assets/images/toolbar/*.svg", {
	eager: true,
});

const modEntriesBox = Object.entries(imageModulesBox) as [string, any];
const modEntriesText = Object.entries(imageModulesText) as [string, any];
const modEntriesCommand = Object.entries(imageModulesCommand) as [string, any];

const findImageBox = (
	partKey:
		| "align-left"
		| "align-horizontal"
		| "align-right"
		| "align-bottom"
		| "align-vertical"
		| "align-top",
) =>
	modEntriesBox.find((entry) => entry[0].includes(`${partKey}.svg`))?.[1]
		.default;
const findImageText = (partKey: "left" | "center" | "right" | "justify") =>
	modEntriesText.find((entry) => entry[0].includes(`${partKey}.svg`))?.[1]
		.default;

const findImageCommand = (
	partKey:
		| "pointer"
		| "hand"
		| "rec"
		| "circle"
		| "star"
		| "polygon"
		| "text"
		| "button"
		| "input",
) =>
	modEntriesCommand.find((entry) => entry[0].includes(`${partKey}.svg`))?.[1]
		.default;

export type TActionNamesTextAligner = "left" | "center" | "right" | "justify";
export type TActionNamesBoxAligner =
	| "left"
	| "right"
	| "horizontal"
	| "vertical"
	| "top"
	| "bottom";
export interface IMapElement<T> {
	name: T;
	label: string;
	image: any;
	class?: any[];
	placeholder?: string;
}

export const textAlignActions = computed<
	IMapElement<TActionNamesTextAligner>[]
>(() => [
	{
		name: "left",
		label: $t("sidebar.aligner.left"),
		image: findImageText("left"),
	},
	{
		name: "center",
		label: `${String($t("sidebar.aligner.center"))}`,
		image: findImageText("center"),
	},
	{
		name: "right",
		label: $t("sidebar.aligner.right"),
		image: findImageText("right"),
	},
	{
		name: "justify",
		label: $t("sidebar.aligner.justify"),
		image: findImageText("justify"),
	},
]);
export const boxAlignActions = computed<IMapElement<TActionNamesBoxAligner>[]>(
	() => [
		{
			name: "left",
			label: $t("sidebar.aligner.left"),
			image: findImageBox("align-left"),
		},
		{
			name: "vertical",
			label: `${String($t("sidebar.aligner.horizontal"))} [Alt + h]`,
			image: findImageBox("align-horizontal"),
		},
		{
			name: "right",
			label: $t("sidebar.aligner.right"),
			image: findImageBox("align-right"),
		},
		{
			name: "bottom",
			label: $t("sidebar.aligner.bottom"),
			image: findImageBox("align-bottom"),
		},
		{
			name: "horizontal",
			label: `${String($t("sidebar.aligner.vertical"))} [Alt + v]`,
			image: findImageBox("align-vertical"),
		},
		{
			name: "top",
			label: $t("sidebar.aligner.top"),
			image: findImageBox("align-top"),
		},
	],
);
export const elementCommandActions = computed<IMapElement<TMouseActionType>[]>(
	() => [
		{
			name: "",
			// name: "selection",
			class: ["selection", "stroke"],
			label: $t("toolbar.selection"),
			placeholder: `${$t("toolbar.selectionPlaceholder")} [V]`,
			image: findImageCommand("pointer"),
		},
		{
			name: "hand",
			// name: "hand",
			class: ["hand", "stroke"],
			label: $t("toolbar.hand"),
			placeholder: `${$t("toolbar.handPlaceholder")} [H]`,
			image: findImageCommand("hand"),
		},
		{
			name: "rec",
			class: ["fill"],
			label: $t("toolbar.rectangle"),
			placeholder: `${$t("toolbar.rectanglePlaceholder")} [R]`,
			image: findImageCommand("rec"),
		},
		{
			name: "ellipse",
			class: ["fill"],
			label: $t("toolbar.ellipse"),
			placeholder: `${$t("toolbar.ellipsePlaceholder")} [C]`,
			image: findImageCommand("circle"),
		},
		{
			name: "star",
			class: ["fill"],
			label: $t("toolbar.star"),
			placeholder: `${$t("toolbar.starPlaceholder")} [P]`,
			image: findImageCommand("star"),
		},
		{
			name: "polygon",
			class: ["fill"],
			label: $t("toolbar.polygon"),
			placeholder: `${$t("toolbar.polygonPlaceholder")} [P]`,
			image: findImageCommand("polygon"),
		},
		{
			name: "text",
			class: ["fill"],
			label: $t("toolbar.text"),
			placeholder: `${$t("toolbar.textPlaceholder")} [T]`,
			image: findImageCommand("text"),
		},
		{
			name: "btn",
			class: ["stroke"],
			label: $t("toolbar.button"),
			placeholder: `${$t("toolbar.buttonPlaceholder")} [B]`,
			image: findImageCommand("button"),
		},
		{
			name: "input",
			class: ["stroke"],
			label: $t("toolbar.input"),
			placeholder: `${$t("toolbar.inputPlaceholder")} [I]`,
			image: findImageCommand("input"),
		},
	],
);

export const leftSidebarTabs = computed<TLeftSidebarTab[]>(() => [
	// {
	// 	name: "assets",
	// 	label: $t("sidebar.tab.assets"),
	// },
	// {
	//   name: "custom",
	//   label: transl("sidebar.tab.custom"),
	// },
	{
		name: "layers",
		label: $t("sidebar.tab.elements"),
	},
	{
		name: "assets",
		label: $t("sidebar.tab.files"),
	},
]);

export const initCanvasTabs = () =>
	[
		{
			name: "desktop",
			type: "desktop",
			label: "Desktop",
			styles: {
				body: new PageCanvasOptions({ width: BREAKPOINT_WIDTHS.desktop }),
			},
			isInit: false,
		},
		{
			name: "tablet",
			type: "tablet",
			label: "Tablet",
			styles: {
				body: new PageCanvasOptions({ width: BREAKPOINT_WIDTHS.tablet }),
			},
			isInit: false,
		},
		{
			name: "mobile",
			type: "mobile",
			label: "Mobile",
			styles: {
				body: new PageCanvasOptions({ width: BREAKPOINT_WIDTHS.mobile }),
			},
			isInit: false,
		},
	] as TBreakpointItem[];
