<script setup lang="ts">
import { $t } from "@/i18n";
import { useToastStore } from "@/stores/toast";
import { usePageStore } from "@/stores/page";
import { type IUniTabProp } from "@/components/shared/tabs/UniTabs.vue";
import type { TBreakpointItem } from "@/stores/definition/globalTypes";

const pageStore = usePageStore();
const props = defineProps<{
	currentItem?: TBreakpointItem;
}>();
const emit = defineEmits(["close-modal"]);

const form = ref<IUniTabProp>({
	name: "",
	label: "",
});

const invalidKeys = ref<string[]>([]);
const currentEditItem = ref({ ...props.currentItem });
const isEditing = computed(() =>
	Boolean(currentEditItem.value && Object.keys(currentEditItem.value).length),
);
const canRemoveTab = computed(() => {
	const len = pageStore.getAllPages.length;
	return len > 1 && isEditing.value;
});

function onClickRemove() {
	const currentTabIndx = pageStore.getAllPages.findIndex(
		(tab) => tab.tabMeta.name === form.value.name,
	);
	if (currentTabIndx === -1) {
		console.warn("Can't remove page", form.value);
		useToastStore().openToastError("Can't remove page");
	} else {
		const indexToSwitch = currentTabIndx === 0 ? 1 : currentTabIndx - 1;
		pageStore.changePageIndex(indexToSwitch);
		pageStore.removePage([currentTabIndx]);
		onClickCancel();
	}
}

function onClickCancel() {
	emit("close-modal");
}

function onClickSubmit() {
	const label = form.value.label;
	const notAlreadyExist = () => {
		return !pageStore.getAllPages.some((page) => page.tabMeta.label === label);
	};

	if (label) {
		if (isEditing.value) {
			if (!currentEditItem.value.name) {
				console.error("Invalid current page", currentEditItem.value);
				useToastStore().openToastError("Error while changing a page");
				return;
			}
			pageStore.changePageLabel(label, currentEditItem.value.name);
			onClickCancel();
		} else if (notAlreadyExist()) {
			const pagePayload = pageStore.addNewPage(label, undefined, true);
			pageStore.changePageIndex(pagePayload.index);
			onClickCancel();
		} else {
			console.warn(label, notAlreadyExist());
			useToastStore().openToastError("Error while creating a page");
		}
	}
}

function isInvalid(key: string) {
	return invalidKeys.value.includes(key);
}

function onCheckInvalid(evt: Event) {
	const targetEl = evt.target as HTMLInputElement;
	const elName = targetEl.dataset.name || "";

	if (targetEl.classList.contains("invalid") && targetEl.value) {
		invalidKeys.value = invalidKeys.value.filter((key) => {
			return key !== elName;
		});
	} else if (!targetEl.value) {
		invalidKeys.value.push(elName);
	}
}

onMounted(() => {
	if (isEditing.value) {
		form.value = currentEditItem.value as TBreakpointItem;
	}
});
</script>

<template lang="pug">
.page-manage
	.form
		.group
			label
				span Title
			input(
				v-model="form.label",
				data-name="label",
				:class="{ 'is-invalid': isInvalid('label') }",
				type="text",
				@keydown.enter="onClickSubmit",
				@input="onCheckInvalid"
			)
	.action-bar
		button.remove(v-if="canRemoveTab", @click="onClickRemove")
			span {{ $t("dialog.remove") }}
		button.cancel(@click="onClickCancel")
			span {{ $t("dialog.cancel") }}
		button(:disabled="!form.label", @click="onClickSubmit")
			span {{ $t("dialog.submit") }}
</template>

<style lang="scss" scoped>
.page-manage {
	display: flex;
	flex-direction: column;
	padding: 0 20px 20px;

	// height: 300px;
	height: auto;
	max-height: 90vh;
	width: 500px;
	overflow: auto;
	user-select: none;
	gap: 20px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 20px;

		& > * {
			width: 100%;
		}

		.group {
			display: flex;
			flex-direction: column;
			gap: 5px;

			input {
				&.is-invalid {
					border: 1px solid crimson;
				}
			}

			.input-group-2 {
				display: flex;

				input {
					flex: 1;
				}
			}
		}
	}

	.action-bar {
		margin: auto 0 0;
		padding: 5px 0;
		display: flex;
		gap: 10px;

		.remove {
			margin-right: auto;
		}

		.cancel {
			margin-left: auto;
		}
	}

	:deep() .multiselect {
		border: 1px solid;
		border-color: #42474a;
		border-radius: $input-radius;
		height: 31px;

		.default-text {
			text-indent: 5px;
		}

		.option__flex {
			display: flex;
			align-items: center;
		}
	}
}
</style>
