<script setup lang="ts">
import { build } from "@/html-engine";
import html_formatter from "prettier/plugins/html";
import css_formatter from "prettier/plugins/postcss";
import prettier from "prettier/standalone";
import type { IHTMLEngineResult } from "@/stores/definition/globalTypes";
import { type Ref } from "vue";
import { usePageStore } from "@/stores/page";

const pageStore = usePageStore();

const htmlText = ref("");

const res: Ref<IHTMLEngineResult> = ref<IHTMLEngineResult>({
	all: "",
	html: "",
});

const monacoOptions = reactive({
	readOnly: true,
	automaticLayout: true,
});

async function generateHtml() {
	const html_array = pageStore.getCurrentPage?.getActiveSnapshot();
	if (!html_array) {
		throw new Error("Invalid html array");
	}

	res.value = build(html_array);
	htmlText.value = await prettier.format(res.value.all, {
		parser: "html",
		plugins: [html_formatter, css_formatter],
	});
	// htmlText.value = res.value.all;
}

onMounted(() => {
	void generateHtml();
});

// const store = useDefaultStore();
// const useAbsolutePaths = ref(store.getAbsolutePath);

// watch(useAbsolutePaths, (val) => {
//   store.changeAbsolutePath(val);
//   generateHtml();
// });
</script>

<template lang="pug">
.html-preview
	.editor-wrap
		monaco-editor(:value="htmlText", language="html", :options="monacoOptions")
</template>

<style lang="scss" scoped>
.html-preview {
	display: flex;
	flex-direction: column;
	width: 90vw;
	height: 90vh;
	padding: 0 20px 20px;

	pre {
		overflow: auto;
	}

	.editor-wrap {
		width: 100%;
		height: 100%;
		overflow: hidden;

		> div {
			width: 100%;
			height: 100%;
		}

		.monaco-editor {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
