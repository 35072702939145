import { baseURL, siteDomain } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { appMenuBarList } from "@/components/topBar/appMenuBarActions";

import { ColorTranslator } from "colortranslator";
import {
	get as getIdb,
	set as setIdb,
	getMany as getManyIdb,
	setMany as setManyIdb,
	del as delIdb,
	delMany as delManyIdb,
	clear as clearIdb,
} from "idb-keyval";
import * as idbInstance from "idb-keyval";
import { type Ref, type UnwrapRef } from "vue";
import { type DomElementInstance } from "@/stores/layer";
import {
	type TStyleObjType,
	type TCssStyle,
} from "@/stores/definition/globalTypes";
import { elementCommandActions } from "@/assets/js/sharedMaps";
import { moveableDragging, moveableResizing } from "./moveable";

// @ts-expect-error
export function debounce(func, waitTimer, immediate) {
	let timeout: ReturnType<typeof setTimeout> | null = null;
	return function () {
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		// @ts-expect-error
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

// @ts-expect-error
export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]",
			"*[tabindex]:not(:disabled):not(.hidden)",
		].join(",");

		// eslint-disable-next-line unicorn/prefer-spread
		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						focusEl(evt, nodeList.at(-1) as any);
					}
				});

				// Last el
				(nodeList.at(-1) as any).addEventListener(
					"keydown",
					(evt: KeyboardEvent) => {
						if (evt.key === "Tab" && !evt.shiftKey) {
							focusEl(evt, nodeList[0]);
						}
					},
				);
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

// export function checkOutOfBoundsElement(evt) {
//   // Unused now

//   const canvasWidth = canvasPageRef.value?.clientWidth || 0;
//   const canvasHeight = canvasPageRef.value?.clientHeight || 0;

//   const oobLeft =
//     evt.target.offsetLeft + getParsedStyle(evt.target.style.width) < 0;
//   const oobRight = evt.target.offsetLeft > canvasWidth;
//   const oobTop =
//     evt.target.offsetTop + getParsedStyle(evt.target.style.height) < 0;
//   const oobBottom = evt.target.offsetTop > canvasHeight;

//   if (oobLeft || oobRight || oobTop || oobBottom) {
//     console.log("Element out of bound");
//     // We're removing the element because we're not restricting to the parent
//     // layerStore.removeInstance([evt.target.id]);
//     // toastStore.openToastSuccess("Success removing element");
//   }
// }

export function parseStringToCss(customCss: string) {
	const properties = customCss.split(";");
	const tempCss = Object.fromEntries(
		properties
			.filter((item: string) => {
				const itemProperty = item?.trim().split(":");
				return (
					itemProperty.length === 2 && itemProperty.every((kv) => kv.trim())
				);
			})
			.map((item: string) => {
				const itemProperty = item.trim().split(":");
				const itemKey = itemProperty[0];
				const itemValue = itemProperty[1];
				return [itemKey.trim(), itemValue.trim()];
			}),
	);
	return tempCss || {};
}

export function getCurrentDomain() {
	const domain = siteDomain;
	if (domain) {
		return new URL(domain);
	}
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}

export function generateCssTransform(x: string, y: string, scale?: number) {
	if (x && y) {
		if (scale) {
			return `translate(${x}, ${y}) scale(${scale})`;
		}
		return `translate(${x}, ${y})`;
	} else if (scale) {
		return `scale(${scale})`;
	}
	return "";
}

export function kebabize(str: string) {
	return str.replaceAll(
		/[A-Z]+(?![a-z])|[A-Z]/g,
		($: string, ofs: any) => (ofs ? "-" : "") + $.toLowerCase(),
	);
}

export function isCssValid(key: keyof TCssStyle, value: string) {
	// Using "Width" for positive integers, "Margin" for all integers

	let keyChange = String(key);
	if (keyChange.startsWith("webkit")) {
		keyChange = `-${keyChange}`;
	}
	const kebabName = kebabize(keyChange);
	return CSS.supports(kebabName, value);
}

// export function checkValidCss(
// 	key: keyof TCssStyle,
// 	value: string,
// ): boolean {
// 	const domEl = document.createElement("dom");
// 	const currentValue = domEl.style[key];
// 	domEl.setAttribute("style", `${kebabize(key as string)}: ${value}`);
// 	return currentValue !== domEl.style[key];
// }

export function parseColorToHex(
	value: string,
	forceAlphaChannel = false,
	alphaChannel = 1,
): string {
	if (!value) return "";
	if (value.includes("gradient")) return value;
	try {
		const colorInstance = new ColorTranslator(value);
		if (forceAlphaChannel) {
			// Used when eyedropper gets alpha [0], for some reason
			colorInstance.setA(alphaChannel);
		}
		return colorInstance.HEXA.toLowerCase();
	} catch {
		// console.warn(`[❌ Color parse -> ${value}]`, err.message);
	}
	return value;
}

function getUseStorage<T>(
	key: string,
	shouldParse = false,
	storage: Storage,
	defaultVal: T | null = null, // Undefined is broken (creates invalid value in store)
): Ref<T | UnwrapRef<T> | string | null | undefined> {
	const state = useStorage(key, defaultVal, storage);
	if (!shouldParse || !state.value || state.value === "undefined") return state;

	try {
		return ref(JSON.parse(state.value as string));
	} catch {
		console.error(`Error loading key`, key, state.value, defaultVal);
		return ref(defaultVal);
	}
}

export function getLocalStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, localStorage, defaultVal);
}

export function getSessionStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, sessionStorage, defaultVal);
}
export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (localStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (sessionStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

// LOCAL helpers
export function checkCookiesEnable() {
	let isCookieEnabled = !!window.navigator.cookieEnabled;
	if (window.navigator.cookieEnabled === undefined && !isCookieEnabled) {
		document.cookie = "testcookie";
		isCookieEnabled = document.cookie.includes("testcookie");
	}

	return isCookieEnabled;
}
export function addCookieMessageListener() {
	window.addEventListener("message", (event) => {
		try {
			const data =
				event.data && typeof event.data === "string"
					? JSON.parse(event.data)
					: event.data;
			if (data.test !== "cookie") return;
			const result = checkCookiesEnable();
			if (!result) console.error("Cookies not enabled");

			parent.postMessage(
				JSON.stringify({
					result,
				}),
				event.origin,
			);
		} catch (err: unknown) {
			handleErrorLog(
				err,
				`Error Iframe protocol reading ${String(event.data)} >> `,
			);
		}
	});
}
export function generateFilesListDrop(evt: DragEvent) {
	const payload = {
		fileList: [] as File[],
	};

	if (evt.dataTransfer?.items) {
		// Use DataTransferItemList interface to access the file(s)
		payload.fileList.push(
			// eslint-disable-next-line unicorn/no-array-reduce
			...Array.from(evt.dataTransfer.items).reduce<File[]>((acc, item) => {
				if (item.kind === "file") {
					const file = item.getAsFile();
					file && acc.push(file);
				}
				return acc;
			}, []),
		);
	} else if (evt.dataTransfer?.files) {
		// Use DataTransfer interface to access the file(s)
		payload.fileList.push(
			// eslint-disable-next-line unicorn/no-array-reduce
			...Array.from(evt.dataTransfer.files).reduce<File[]>((acc, file) => {
				file && acc.push(file);
				return acc;
			}, []),
		);
	} else {
		console.warn("No files");
	}

	return payload;
}
export function generateCancelToken() {
	const controller = new AbortController();
	return {
		source: controller,
		token: controller.signal,
	};
}
export function setupAppMenuBarForParent() {
	const mappedAppMenuActions = appMenuBarList.value;
	parent.postMessage(JSON.stringify(mappedAppMenuActions), "*");
}

// export async function parseFileForChunks(
//   file: Blob,
//   callback: (a: string) => Promise<void>,
//   resolve: (value?: any) => void
// ) {
//   const fileSize = file.size;
//   const chunkSize = 64 * 1024; // bytes
//   let offset = 0;
//   const chunkReaderBlock = async function (
//     _offset: number,
//     length: number,
//     _file: Blob
//   ) {
//     const blob = _file.slice(_offset, length + _offset);
//     const r = new FileReader();
//     r.onload = readEventHandler;
//     r.readAsText(blob);
//   };

//   const readEventHandler = async function (evt: ProgressEvent) {
//     console.warn("progress - type", evt);
//     const evTarget = evt.target as FileReader;

//     if (evTarget.error === null) {
//       offset += evt.total;
//       // offset += evTarget.result.length; // Wrong size

//       // Formatting file as string chunk
//       await callback(evTarget.result as string); // callback for handling read chunk
//     } else {
//       console.log("Read error: " + evTarget.error);
//       return;
//     }
//     if (offset >= fileSize) {
//       console.log("Done reading file", offset, fileSize);
//       resolve();
//       return;
//     }

//     // Next block chunk
//     await chunkReaderBlock(offset, chunkSize, file);
//   };

//   // First block
//   await chunkReaderBlock(offset, chunkSize, file);
// }

export async function useIDB(
	action:
		| "all"
		| "get"
		| "set"
		| "getMany"
		| "setMany"
		| "del"
		| "delMany"
		| "clear" = "get",
	key: string | number | string[] = "",
	value?: any,
) {
	switch (action) {
		case "all": {
			// Catch-all
			return idbInstance;
		}
		case "get": {
			return getIdb(key);
		}
		case "set": {
			await setIdb(key, value);
			return;
		}
		case "getMany": {
			return getManyIdb([key]);
		}
		case "setMany": {
			await setManyIdb([key, value]);
			return;
		}
		case "del": {
			await delIdb(key);
			return;
		}
		case "delMany": {
			await delManyIdb([key]);
			return;
		}
		case "clear": {
			await clearIdb();
			return;
		}
		default: {
			console.log("[Warn] Invalid IDB call", action);
			return null;
		}
	}
}

// export const isAppIntegrated = computed(
//   () => "integration" in router.currentRoute.value.query
// );

export function calculateCenterTransform(zoomValue: number) {
	const el = document.querySelector("[data-name='app-canvas']");
	const rectCanvas = el?.getBoundingClientRect();
	if (rectCanvas) {
		const compWid = rectCanvas.width / zoomValue;
		const compHei = rectCanvas.height / zoomValue;
		const centerLeft = compWid / 2 - rectCanvas.width / 2;
		const centerTop = compHei / 2 - rectCanvas.height / 2;
		return {
			x: centerLeft,
			y: centerTop,
		};
	}
	console.warn("No parent element", el, rectCanvas);

	return { x: 0, y: 0 };
}

export function parseErrors(
	error: any,
	defaultMessage = "",
	isMessageFirst = false,
) {
	if (error?.response?.data) {
		const errs = error.response.data.errors;
		const msg = error.response.data.message;
		const getFirstErr = (errs: any) => {
			return errs ? Object.values(errs).map((e: any) => e[0]) : null;
		};

		if (error.response.status === 403) {
			// Hardcoded error because axios fails to parse
			return "You're unauthorized for this action.";
		}

		return isMessageFirst ? msg || getFirstErr(errs) : getFirstErr(errs) || msg;
	}
	return defaultMessage;
}

export function parseAssetTypeToTag(str: string): DomElementInstance["tag"] {
	type TTypeList = Record<string, DomElementInstance["tag"]>;

	const assetTypeList: TTypeList = {
		image: "img",
		video: "video",
	};
	return str in assetTypeList
		? assetTypeList[str]
		: (str as DomElementInstance["tag"]);
}
export function generateTitle(
	instance: DomElementInstance,
	useCustomName = false,
) {
	let res = "";
	const payload = instance.payload;
	const customElementObject = instance.getPrivateOptions().customElementObject;
	if (useCustomName) {
		const layerName = instance.getPrivateOptions().layer.name;
		if (layerName) {
			res = layerName;
			return res;
		}
	}

	if (instance.attributes?.value) {
		res = String(instance.attributes.value);
	} else if (payload?.ass_name) {
		res = payload.ass_name;
	} else if (payload?.ass_type) {
		res = payload.ass_type;
	} else if (payload?.ass_asset) {
		res = payload.ass_asset;
	} else if (customElementObject) {
		const actionObj = elementCommandActions.value.find(
			(act) => act.name === customElementObject,
		);
		res = actionObj?.label || customElementObject;
	} else {
		res = "Element";
	}
	return res;
}

export async function resolveImageDimensions(
	file: Blob,
): Promise<{ width: number; height: number }> {
	return new Promise((resolve, reject) => {
		const image = new Image();
		const url = URL.createObjectURL(file);
		image.addEventListener("load", () => {
			resolve({ width: image.width, height: image.height });
		});
		image.addEventListener("error", reject);
		image.src = url;
	});
}

export function updateDomStyle(
	el: HTMLElement | string,
	props: Record<string, string>,
) {
	let element: HTMLElement | string | null = null;

	element =
		typeof el === "string" ? (document.querySelector(el) as HTMLElement) : el;

	if (element) {
		for (const key of Object.keys(props) as any[]) {
			element.style[key] = props[key];
		}
	} else {
		console.warn("Invalid element", el);
	}
}

export function handleErrorLog(
	err: unknown,
	logMsg: string | null = "",
): "general" | "axios-cancel" | "unknown" {
	if (err instanceof Error) {
		logMsg && console.warn(`[GenErr] ${logMsg}`, err.message);
		return "general";
	} else if (axios.isCancel(err)) {
		logMsg && console.warn(`[AxiosErr] ${logMsg}`, err.message);
		return "axios-cancel";
	} else {
		logMsg && console.warn(`[UnknownErr] ${logMsg}`, err);
		return "unknown";
	}
}

export function addPxEnter(
	evt: Event | null,
	value: string,
	...keys: (keyof TCssStyle)[]
): any {
	const payload: Partial<Record<keyof TStyleObjType, string>> = {};
	for (const key of keys) {
		if ((evt as KeyboardEvent).key === "Enter") {
			if (value.length === parseInt(value).toString().length) {
				payload[key] = `${value}px`;
			}
		} else {
			payload[key] = value;
		}
	}
	return payload as TCssStyle;
}

export function hasBlockedDomElement() {
	const blockedClasses = ["modern-modal-comp", "keyboard-binding"];
	return blockedClasses.some(
		// eslint-disable-next-line unicorn/prefer-query-selector
		(cls) => document.getElementsByClassName(cls).length,
	);
}
export function isElementVisibleInViewport(
	domElement: Element | HTMLElement,
	isPartiallyVisible = false,
) {
	return new Promise((resolve) => {
		const o = new IntersectionObserver(([entry]) => {
			if (isPartiallyVisible) {
				resolve(entry.intersectionRatio !== 0);
			} else {
				resolve(entry.intersectionRatio === 1);
			}
			o.disconnect();
		});
		o.observe(domElement);
	});
}

export async function setupElementGuidelines(
	excludeIds: DomElementInstance["id"][] = [],
): Promise<string[] | undefined> {
	// Works on all elements, even if they form groups
	if (mvbIsDisplayGridGuidelines.value) {
		return;
	}

	const excludeDragInstances = moveableDragging.value;
	const excludeResizeInstances = moveableResizing.value;

	const layerStore = useLayerInstancesStore();
	const allInstances = layerStore.getTreeInstances("asc");
	const allOtherEls: string[] = [];
	for (const aIns of allInstances) {
		const hasExcludedInst = excludeIds.length
			? excludeIds.includes(aIns.id)
			: [...excludeDragInstances, ...excludeResizeInstances].includes(aIns.id);
		if (hasExcludedInst) continue;

		const el = document.querySelector(`[data-id=${aIns.id}]`);
		if (el) {
			const isVisible = await isElementVisibleInViewport(el, true);
			if (!isVisible) continue;
		}
		allOtherEls.push(`[data-id=${aIns.id}]`);
	}
	return allOtherEls;
}

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};
