<script setup lang="ts">
const props = defineProps<{
	list: any[];
}>();
const emit = defineEmits(["on-select"]);

const search = ref("");

const filteredList = computed(() => {
	const searchLabel = search.value.toLowerCase();
	return props.list.filter((item) =>
		item.pro_name.toLowerCase().includes(searchLabel),
	);
});

function onProjectSelect(item: (typeof props.list)[0]) {
	emit("on-select", item);
}
function onCreateProject() {
	console.log("Create project");
}
function onEnterFirstItem() {
	const item = filteredList.value[0];
	if (item) {
		onProjectSelect(item);
	}
}
</script>

<template lang="pug">
.mars-project-select
	.title Project list
	.search
		input(v-model="search", v-focus, @keyup.enter="onEnterFirstItem")
	.content
		.item(tabIndex=0, @click="onCreateProject")
			i-fa-plus
			.label Create a new project
		.list-items
			.item(
				v-for="item in filteredList",
				:key="item.pro_id",
				tabindex=0,
				@click="onProjectSelect(item)"
			)
				i-fa-file
				.label {{ item.pro_name }}
</template>

<style lang="scss" scoped>
.mars-project-select {
	display: flex;
	flex-direction: column;
	width: 100%;
	user-select: none;

	.title {
		font-weight: bold;
		align-self: center;
		margin-bottom: 10px;
	}

	.search {
		margin-bottom: 10px;

		input {
			width: 100%;
		}
	}

	.content {
		display: grid;
		align-self: flex-start;
		width: 100%;

		.list-items {
			display: grid;
			flex-direction: column;
			overflow: auto;
			max-height: 60vh;
		}

		.item {
			padding: 7px 12px;
			display: flex;
			gap: 10px;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;

			.label {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 400px;
			}

			&:focus {
				outline: 1px solid white;
			}

			&:hover {
				background: rgba(192, 192, 192, 0.203);
			}
		}
	}
}
</style>
