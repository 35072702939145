// This is used to index integrated APP APIs for object [store]

import { api } from "@/api";
import { $t } from "@/i18n";
import type {
	IAssetGetItem,
	ISavePayload,
	// SnapshotCheckpoint,
} from "../definition/globalTypes";
import { AssetElementInstance, type CheckpointSaveInstance } from "../layer";
import { useToastStore } from "../toast";
import type { TDefaultStore } from "@/stores/index";
// import router from "@/router";
import { parseSavePayload } from "./apiLogicIndex";
import { usePageStore } from "../page";
import { handleErrorLog } from "@/helpers/helpers";

export const apiIndex = {
	async fetchAssets(params?: Record<string, any>) {
		const tempParams: Record<string, any> = {
			limit: 10,
			isOffset: false,
			...params,
		};
		if (tempParams.isOffset) {
			tempParams.offset = (this as TDefaultStore).assetListData?.data.length;
			tempParams.isOffset = undefined;
		}

		// eslint-disable-next-line no-useless-catch
		try {
			const res = await api.getAssets(tempParams);
			const dataArr = (res.data?.data || []).map(
				(el: IAssetGetItem) => new AssetElementInstance(el),
			);
			const metaInfo = res.data?.meta_info || {};

			if (metaInfo) {
				if (tempParams.offset) {
					(this as TDefaultStore).assetListData = {
						data: [
							...((this as TDefaultStore).assetListData?.data || []),
							...dataArr,
						],
						meta_info: metaInfo,
					};
				} else {
					(this as TDefaultStore).assetListData = {
						data: dataArr,
						meta_info: metaInfo,
					};
				}
			} else {
				console.error("🌐 Invalid data");
			}

			return res;
		} catch (err) {
			throw err;
		}
	},
	async fetchFonts(params: Record<string, any> = {}) {
		const tempParams: Record<string, any> = {
			limit: 30,
			isOffset: false,
			...params,
			type: "font",
		};
		if (tempParams.isOffset) {
			tempParams.offset = (this as TDefaultStore).fontListData?.data.length;
			tempParams.isOffset = undefined;
		}

		// eslint-disable-next-line no-useless-catch
		try {
			const res = await api.getAssets(tempParams);
			const metaInfo = res.data?.meta_info || {};

			if (tempParams.offset) {
				(this as TDefaultStore).fontListData = {
					data: [
						...((this as TDefaultStore).fontListData?.data || []),
						...(res.data?.data || []),
					],
					meta_info: metaInfo,
				};
			} else {
				(this as TDefaultStore).fontListData = res.data;
			}
			return res;
		} catch (err) {
			throw err;
		}
	},
	async postUploadAppendAsset(
		assets: (File | string)[],
		callBackUploadProgress: (
			item: File | string,
			index: number,
			progress: number,
			evt?: ProgressEvent,
		) => void,
		controller: AbortController,
	) {
		const getFileChunks = (file: Blob | File) => {
			const fileChunks: Blob[] = [];
			if (file.size > 1024 * 1024) {
				const fileToChunk = file;
				const chunkSize = 1024 * 1024 * 1; // Size of chunk in MB (it shouldnt go over 20 MB so mars can accept it)
				for (let j = 0; j < Math.floor(fileToChunk.size / chunkSize) + 1; j++) {
					const chunklet = fileToChunk.slice(
						j * chunkSize,
						(j + 1) * chunkSize,
					);
					if (chunklet.size > 0) {
						fileChunks.push(chunklet);
					}
				}
			}
			return fileChunks;
		};

		for (const [indexAsset, itemAsset] of assets.entries()) {
			const getFile = (data: File | string): File => {
				return typeof data === "string"
					? (new Blob([itemAsset], { type: "text/plain" }) as File)
					: (itemAsset as File);
			};

			const fileItem = getFile(itemAsset);
			const blobChunks = getFileChunks(fileItem);

			let firstResponse: any = null;

			await new Promise((resolve, reject) => {
				const sendParts = async () => {
					// Send first part

					try {
						const firstFile = blobChunks[0]
							? new File([blobChunks[0]], fileItem.name)
							: fileItem;
						const fd = new FormData();

						fd.append("assets", firstFile);
						const config = {
							onUploadProgress: (evt: ProgressEvent) => {
								const percentCompleted = Math.round(
									(evt.loaded * 100) / evt.total,
								);
								const chunkPercent = blobChunks.length
									? percentCompleted / blobChunks.length
									: percentCompleted;

								callBackUploadProgress(itemAsset, indexAsset, chunkPercent);
							},
							signal: controller.signal,
						};
						const res = await api.postAssets(fd, config);
						firstResponse = res.data;
					} catch (err: unknown) {
						handleErrorLog(err);
						reject(err);
						return;
					}

					if (blobChunks.length > 1) {
						// Send other parts
						const pathId: number | null = firstResponse.pth_id;
						for (let index = 1; index < blobChunks.length; index++) {
							const fileChunk = blobChunks[index];

							try {
								const fd = new FormData();
								fd.append("file", fileChunk);
								fd.append("pathId", `${String(pathId)}`);
								// fd.append("isText", 'true');
								if (index === blobChunks.length - 1) {
									// fd.append("isLast", "false");
									fd.append("isLastChunk", "true");
								}

								const config = {
									onUploadProgress: (evt: ProgressEvent) => {
										const percentCompleted = Math.round(
											(evt.loaded * 100) / evt.total,
										);

										const chunkSize = 100 / blobChunks.length; // length 5 - 20
										const indexChunkSize = chunkSize * index; // 20 * 1 (for first part - min)
										const incrementCompleted =
											(chunkSize / 100) * percentCompleted;

										const chunkPercent = blobChunks.length
											? indexChunkSize + incrementCompleted
											: percentCompleted;

										callBackUploadProgress(itemAsset, indexAsset, chunkPercent);
									},
									signal: controller.signal,
								};
								await api.postUploadAppendAsset(fd, config);
								let percDone = Math.ceil(100 / blobChunks.length) * (index + 1);
								percDone = percDone > 100 ? 100 : percDone;
							} catch (err: unknown) {
								handleErrorLog(err);
								reject(err);
								return;
							}
						}
					}

					resolve("done");
				};

				void sendParts();
			});
		}
		void this.fetchAssets(); // No await
	},
	async removeAssets(names: string[], showToast = true) {
		// Works for single files only
		const name = names[0];

		try {
			await api.deleteAsset({ name });
			void this.fetchAssets(); // No await
			void this.fetchCheckpointData();
			showToast &&
				useToastStore().openToastSuccess(
					$t("api.delete.success", names.length === 1 ? 0 : 2),
				);
			return true;
		} catch (err) {
			showToast &&
				useToastStore().openToastError(
					$t("api.delete.error", names.length === 1 ? 0 : 2),
				);
			throw err;
		}
	},
	async fetchCheckpointData(
		params: Partial<CheckpointSaveInstance> = {},
	): Promise<boolean> {
		// let res = null;

		// const getHashCheckpoint = async () => {
		//   try {
		//     res = await api.getCheckpointState(params);
		//     const valueString = res.data.data;
		//     const resData = JSON.parse(valueString) as SavePayload[];
		//     await parseSavePayload({ sav_save: resData });
		//   } catch (err) {
		//     usePageStore().addNewPage();
		//     useToastStore().openToastError(transl("api.checkpointLoad.error"));
		//     router.replace({
		//       params: {
		//         ...router.currentRoute.value.params,
		//         sv: undefined,
		//       },
		//     });

		//     await getHashLatest();
		//   }
		// };

		const getHashLatest = async () => {
			try {
				const res = await api.getLatestCheckpointState(params);
				const valueString = res.data.data;

				const isInvalidRes = (valueString: any) => {
					const isInvalidObj =
						typeof valueString === "object" &&
						Object.keys(valueString).length === 0;
					const isEmptyArray =
						Array.isArray(valueString) && valueString.length === 0;

					return isInvalidObj || isEmptyArray;
				};

				if (isInvalidRes(valueString)) {
					console.log("Empty response [JSON]", valueString);
					usePageStore().addNewPage();
				} else {
					const resData = JSON.parse(valueString) as ISavePayload[];
					await parseSavePayload({ sav_save: resData });
				}
			} catch (err) {
				usePageStore().addNewPage();
				useToastStore().openToastError($t("api.checkpointLoad.error"));
				throw err;
			}
		};

		// if (params.sav_hash) {
		//   await getHashCheckpoint();
		// } else {
		await getHashLatest();
		return true;
		// }
	},
	// async removeCheckpointData(
	//   params: Partial<CheckpointSaveInstance> = {},
	//   showToast = true
	// ) {
	//   // eslint-disable-next-line no-useless-catch
	//   try {
	//     await api.deleteCheckpointState(params);
	//     showToast &&
	//       useToastStore().openToastSuccess(
	//         transl("api.checkpointRemove.success")
	//       );
	//     return true;
	//   } catch (err) {
	//     showToast &&
	//       useToastStore().openToastSuccess(transl("api.checkpointRemove.error"));
	//     throw err;
	//   }
	// },
	// async putCheckpointState(params: Partial<CheckpointSaveInstance> = {}) {
	//   // eslint-disable-next-line no-useless-catch
	//   try {
	//     await api.putCheckpointState(params);
	//     useToastStore().openToastSuccess(transl("api.checkpointChange.success"));
	//   } catch (err) {
	//     useToastStore().openToastSuccess(transl("api.checkpointChange.error"));
	//     throw err;
	//   }
	// },
	// async fetchCheckpointStates(
	//   params: Record<string, any> = {
	//     limit: 30,
	//     isOffset: false,
	//   }
	// ) {
	//   const tempParams = { ...params };
	//   if (tempParams.isOffset) {
	//     tempParams.offset = (
	//       this as DefaultStore
	//     ).checkpointPointsDb?.data.length;
	//     tempParams.isOffset = undefined;
	//   }

	//   // eslint-disable-next-line no-useless-catch
	//   try {
	//     const res = await api.getCheckpointStates(tempParams);
	//     const initialData: SnapshotCheckpoint[] = res.data?.data || [];
	//     const dataArr = [
	//       ...initialData.sort((a, b) => {
	//         if (!a.sav_date || a.sav_date > b.sav_date) {
	//           return -1;
	//         } else if (a.sav_date < b.sav_date) {
	//           return 1;
	//         }
	//         return 0;
	//       }),
	//     ].map((el: any) => new CheckpointSaveInstance(el));
	//     const metaInfo = res.data?.meta_info || {};

	//     if (tempParams.offset) {
	//       (this as DefaultStore).checkpointPointsDb = {
	//         data: [
	//           ...((this as DefaultStore).checkpointPointsDb?.data || []),
	//           ...dataArr,
	//         ],
	//         meta_info: metaInfo,
	//       };
	//     } else {
	//       (this as DefaultStore).checkpointPointsDb = {
	//         data: dataArr,
	//         meta_info: metaInfo,
	//       };
	//     }
	//     return res;
	//   } catch (err) {
	//     throw err;
	//   }
	// },
	async createCheckpoint(params: {
		sav_save: ISavePayload[];
	}): Promise<string> {
		const tempParams = params?.sav_save;
		if (!tempParams) {
			useToastStore().openToastError($t("api.save.error"));
			return "";
		}

		const stringParams = {
			sav_save: JSON.stringify(tempParams),
		};

		try {
			const res = await api.postCheckpointState(stringParams);
			const hash = res.data.hash;
			return hash;
		} catch (err: unknown) {
			handleErrorLog(err, "Unhandled Error");
		}
		return "";
	},
	async postCheckDelete(params: IAssetGetItem["ass_name"][]) {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await api.postCheckDelete(params);
			return res.data.data || [];
		} catch (err) {
			throw err;
		}
	},
	async postUploadBuild(params: Record<string, any> = {}, showToast = true) {
		try {
			const res = await api.postUploadBuild(params);
			useToastStore().openToastSuccess($t("api.export.success"));
			return res;
		} catch (err) {
			useToastStore().openToastError($t("api.export.error"));
			throw err;
		}
	},
	async getPages(params: Record<string, any> = {}) {
		try {
			const res = await api.getPages(params);
			return res;
		} catch (err: unknown) {
			handleErrorLog(err);
			throw err;
		}
	},
	async postPages(params: Record<string, any> = {}) {
		try {
			const res = await api.postPages(params);
			useToastStore().openToastSuccess($t("api.save.success"));
			return res;
		} catch (err) {
			useToastStore().openToastError($t("api.save.error"));
			throw err;
		}
	},
};
