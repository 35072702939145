<script setup lang="ts">
const props = defineProps<{
	list: any[];
}>();
const emit = defineEmits(["on-select"]);

const search = ref("");

const filteredList = computed(() => {
	const searchLabel = search.value.toLowerCase();
	return props.list.filter((item) =>
		item.sit_alias.toLowerCase().includes(searchLabel),
	);
});

function onSiteSelect(item: (typeof props.list)[0]) {
	emit("on-select", item);
}
function onCreateSite() {
	console.log("Create site");
}
function onEnterFirstItem() {
	const item = filteredList.value[0];
	if (item) {
		onSiteSelect(item);
	}
}
</script>

<template lang="pug">
.mars-site-select
	.title Site list
	.search
		input(v-model="search", v-focus, @keyup.enter="onEnterFirstItem")
	.content
		.item(tabIndex=0, @click="onCreateSite")
			i-fa-plus
			span Create a new site
		.item(
			v-for="item in filteredList",
			:key="item.sit_id",
			tabindex=0,
			@click="onSiteSelect(item)"
		)
			i-fa-file
			span {{ item.sit_alias }}
</template>

<style lang="scss" scoped>
.mars-site-select {
	display: flex;
	flex-direction: column;
	width: 100%;
	user-select: none;

	.title {
		font-weight: bold;
		align-self: center;
		margin-bottom: 10px;
	}

	.search {
		margin-bottom: 10px;

		input {
			width: 100%;
		}
	}

	.content {
		align-self: flex-start;
		width: 100%;

		.item {
			padding: 7px 12px;
			display: flex;
			gap: 10px;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;

			&:hover {
				background: rgba(192, 192, 192, 0.203);
			}
		}
	}
}
</style>
