import { useEventListener, useBreakpoints } from "@vueuse/core";
import { type IContextMenuitemsType } from "@/components/shared/contextMenu/ContextMenuWrap.vue";
import * as fflate from "fflate";

export function useBreakpointsLogic() {
	const breakpoints = useBreakpoints({
		mobileL: 640,
		tablet: 768,
		laptop: 1024,
		desktop: 1280,
	});

	let isMobile = breakpoints.smaller("tablet");
	useEventListener("resize", () => {
		isMobile = breakpoints.smaller("tablet");
	});

	return { isMobile, breakpoints };
}

export function useContextMenuComposable(items: IContextMenuitemsType[] = []) {
	interface IContextMenuObjectInterface {
		items: IContextMenuitemsType[];
		evt: PointerEvent | null;
		payload?: any;
	}

	const contextMenuObj = reactive<IContextMenuObjectInterface>({
		items,
		evt: null,
		payload: {},
	});

	function onContextMenu(evt: MouseEvent, payload?: any) {
		evt.preventDefault();
		evt.stopPropagation();
		contextMenuObj.payload = payload;
		contextMenuObj.evt = evt as PointerEvent;
	}

	function onCloseContextMenu() {
		contextMenuObj.evt = null;
	}

	return {
		contextMenuObj,
		onContextMenu,
		onCloseContextMenu,
	};
}

/**
 * Uses fflate to manage files and create blobs
 */
export function useZipComposable() {
	const zipInstance = ref<fflate.Zip | null>(null);
	const zipFileChunk = ref<Uint8Array[]>([]);
	const zipFileBlob = ref<Blob | null>(null);

	function setupZipInstance() {
		zipInstance.value = new fflate.Zip((err, chunk, final) => {
			if (err) {
				console.warn("Error while zipping", err);
			} else {
				zipFileChunk.value.push(chunk);
				if (final) {
					zipFileBlob.value = new Blob(zipFileChunk.value);
					zipFileChunk.value = [];
				}
			}
		});
	}

	function addZipFile(path: string, fileData: ArrayBuffer | string): boolean {
		const fileObj = new fflate.ZipDeflate(path);
		if (zipInstance.value) {
			zipInstance.value.add(fileObj);
			let fileDataParsed = null;
			fileDataParsed =
				typeof fileData === "string"
					? new TextEncoder().encode(fileData)
					: new Uint8Array(fileData);

			fileObj.push(fileDataParsed, true);
		} else {
			console.warn("No zip instance");
			return false;
		}
		return true;
	}

	/**
	 * This must be run in order to generate valid zip afterwards
	 */
	function finalizeZip() {
		zipInstance.value?.end();
	}

	function saveBlobFile(content: Blob, fileName: string) {
		const isBlob = content instanceof Blob;
		if (!isBlob) {
			console.error("Not a blob", content);
			return;
		}

		const a = document.createElement("a");
		const url = window.URL.createObjectURL(content);
		a.href = url;
		a.download = fileName;
		a.click();
		if (isBlob) {
			window.URL.revokeObjectURL(url);
		}
	}

	function downloadZip(zipArchiveName: string, shouldFinalize = true) {
		if (shouldFinalize) {
			finalizeZip();
		}

		try {
			const zipBlob = zipFileBlob.value;
			if (zipBlob) {
				saveBlobFile(zipBlob, zipArchiveName);
				zipFileBlob.value = null;
			}
		} catch (err: any) {
			console.warn("Error downloading zip", err.message);
		} finally {
			setupZipInstance();
		}
	}

	return {
		zipInstance,
		setupZipInstance,
		addZipFile,
		finalizeZip,
		downloadZip,
	};
}
