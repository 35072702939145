// import { DomElementInstance } from "@/stores/elementInstances";
import { type ModelInstance } from "@/stores/group";
import type {
	OnDrag,
	OnDragEnd,
	OnDragGroup,
	OnDragGroupEnd,
	OnDragGroupStart,
	OnResize,
	OnResizeEnd,
	OnResizeGroup,
	OnResizeGroupEnd,
	OnResizeGroupStart,
	OnResizeStart,
	OnRotate,
	OnRotateEnd,
	OnRotateGroup,
	OnRotateGroupEnd,
	OnRotateGroupStart,
	OnRotateStart,
	OnSnap,
} from "vue3-moveable";
import { InfoConsole, setupElementGuidelines } from "./helpers";
import { type DomElementInstance } from "@/stores/layer";
import { moveElementToDropzone } from "./moveablehelpers";

export const moveableDragging = ref<ModelInstance["id"][]>([]);
export const moveableResizing = ref<ModelInstance["id"][]>([]);
export const moveableRotating = ref<ModelInstance["id"][]>([]);
const moveableDragOrientation = ref<0 | 1 | null>(null);

export const isMoveableActive = computed(() => {
	return (
		moveableDragging.value.length !== 0 || moveableResizing.value.length !== 0
	);
});

type TMoveGroupEvents = OnDragGroup["events"] | OnResizeGroup["events"];
// | OnRotateGroup["events"];
// type TNumUnd = number | undefined;

// function getLeftResize(dir: number, delta: number, currVal: number): TNumUnd {
// 	if (dir === -1) {
// 		// When update left + width
// 		return currVal - delta;
// 	}
// 	return;
// }
// function getTopResize(dir: number, delta: number, currVal: number): TNumUnd {
// 	if (dir === -1) {
// 		// When update top + width
// 		return currVal - delta;
// 	}
// 	return;
// }
// function getWidResize(dir: number, delta: number, currVal: number): TNumUnd {
// 	return currVal + delta;
// }
// function getHeiResize(dir: number, delta: number, currVal: number): TNumUnd {
// 	return currVal + delta;
// }

type TGroupEventChanges = {
	deltaX?: number;
	deltaY?: number;
	rotate?: number;
	width?: number;
	height?: number;
};
function getMoveDragChanges<T extends TMoveGroupEvents>(
	events: T,
	evt: OnDragGroup,
): Record<string, TGroupEventChanges> {
	const temp = {} as Record<string, TGroupEventChanges>;
	for (const event of events) {
		const id = event.target.dataset.id;
		if (id) {
			temp[id] = {
				deltaX: event.delta[0] / mvbZoomRatio.value,
				deltaY: event.delta[1] / mvbZoomRatio.value,
			};
		} else {
			InfoConsole.e("No dataset ID", event);
		}
	}
	return temp;
}
// function getMoveResizeChanges<T extends OnResizeGroup["events"]>(
// 	events: T,
// 	evt: OnResizeGroup,
// ): Record<string, TGroupEventChanges> {
// 	const temp = {} as Record<string, TGroupEventChanges>;
// 	for (const event of events) {
// 		const id = event.target.dataset.id;
// 		if (id) {
// 			temp[id] = {
// 				deltaX: event.delta[0] / zoomValue.value,
// 				deltaY: event.delta[1] / zoomValue.value,
// 			};
// 		} else {
// 			InfoConsole.e("No dataset ID", event);
// 		}
// 	}
// 	return temp;
// }
// function getMoveRotateChanges<T extends OnRotateGroup["events"]>(
// 	events: T,
// 	evt: OnRotateGroup,
// ): Record<string, TGroupEventChanges> {
// 	const temp = {} as Record<string, TGroupEventChanges>;
// 	for (const event of events) {
// 		const id = event.target.dataset.id;
// 		if (id) {
// 			temp[id] = {
// 				deltaX: event.delta / zoomValue.value,
// 				deltaY: event.delta / zoomValue.value,
// 			};
// 		} else {
// 			InfoConsole.e("No dataset ID", event);
// 		}
// 	}
// 	return temp;
// }

export const soloMove = {
	drag: {
		onDragMoveable: async (evt: OnDrag) => {
			// console.log("!!! DRAG MOVE", evt, moveableDragging.value);

			// Clone element if action is pending
			const canvasStore = useCanvasStore();
			const cloneElementIfPending = async () => {
				if (canvasStore.pendingCopyObj.elements.length) {
					canvasStore.clonePendingInstances();
					canvasStore.setPendingCopyInstances();

					// Set guidelines based on element visibility
					mvbElementGuidelines.value = await setupElementGuidelines();
				}
			};

			await cloneElementIfPending();

			if (moveableDragging.value) {
				const layerStore = useLayerInstancesStore();
				let deltaX = evt.delta[0];
				let deltaY = evt.delta[1];

				if (moveableDragging.value.length > 1) {
					InfoConsole.e(
						`INVALID NUMBER OF ELS :: BLOCKED ${moveableDragging.value.length}`,
					);
					return;
				}

				// Setting orientation
				if (evt.inputEvent.shiftKey) {
					if (moveableDragOrientation.value === null) {
						moveableDragOrientation.value =
							Math.abs(deltaX) > Math.abs(deltaY) ? 0 : 1;
					}
					if (moveableDragOrientation.value === 0) {
						deltaY = 0;
					} else {
						deltaX = 0;
					}
				} else {
					if (moveableDragOrientation.value) {
						// Used total distance instead of delta (but has to account for the delta changes)
						// TODO -1 Implement

						// deltaX = evt.dist[0];
						// deltaY = evt.dist[1];
						console.warn("IMPLEMENT drag solo", evt);
					}
					moveableDragOrientation.value = null;
				}

				for (let index = 0; index < moveableDragging.value.length; index++) {
					const instanceId = moveableDragging.value[index];
					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						const currValLeft = parseFloat(el.style.left);
						const currValTop = parseFloat(el.style.top);
						el.style.left = `${currValLeft + deltaX}px`;
						el.style.top = `${currValTop + deltaY}px`;

						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							const domParams = {
								style: {
									left: el.style.left,
									top: el.style.top,
								},
							};
							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onDragEndMoveable: (evt: OnDragEnd): boolean => {
			moveableDragOrientation.value = null;

			// console.warn("DRAG END", evt);

			if (!evt.isDrag) {
				// No need to drag if clicked on the same pos
				moveableDragging.value = [];
				return false;
			}

			// Moving elements inside / outside
			const moveElementToAnother = () => {
				const inputElement = evt.inputEvent.target as HTMLElement;
				const toOuterScope = !inputElement.classList.contains("moveable-area");
				for (const draggerId of moveableDragging.value) {
					moveElementToDropzone(
						draggerId,
						(inputElement.closest(".moveable-el-box") as HTMLElement | null)
							?.dataset.elementId || "",
						toOuterScope,
					);
				}
			};

			moveElementToAnother();

			if (moveableDragging.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}

			moveableDragging.value = [];
			return true;
		},
	},
	resize: {
		onResizeStartMoveable: async (evt: OnResizeStart) => {
			const target = evt.target;
			const targetId = target.dataset.id;
			console.log(">> START movable >", targetId, evt);
			if (targetId) {
				const setupMoveableEls = (currId: ModelInstance["id"]) => {
					const layerStore = useLayerInstancesStore();
					const selItemIds = (layerStore.getSelectedElementInstances || []).map(
						(item) => item.id,
					);
					if (selItemIds.length === 0) {
						InfoConsole.w("Aborting resize");
						moveableResizing.value = [];
						return;
					}

					// If not already selected
					if (!selItemIds.includes(currId)) {
						console.log("Adding >>", currId, selItemIds);
						selItemIds.push(currId);
					}

					moveableResizing.value = selItemIds;
				};

				setupMoveableEls(targetId);

				// Set guidelines based on element visibility
				mvbElementGuidelines.value = await setupElementGuidelines();
			} else {
				InfoConsole.e(`No target found`, evt);
			}
		},
		onResizeMoveable: (evt: OnResize) => {
			if (moveableResizing.value.length) {
				const layerStore = useLayerInstancesStore();
				const canvasStore = useCanvasStore();

				// This breaks rotation https://github.com/daybrush/moveable/issues/969#issuecomment-1620527389
				// This was for multiple elements
				// const dirX = evt.direction[0];
				// const dirY = evt.direction[1];
				// const deltaX = evt.delta[0];
				// const deltaY = evt.delta[1];

				if (moveableResizing.value.length > 1) {
					InfoConsole.e(
						`INVALID NUMBER OF ELS :: BLOCKED ${moveableResizing.value.length}`,
					);
					return;
				}

				for (let index = 0; index < moveableResizing.value.length; index++) {
					// console.log(deltaX, deltaY);
					const instanceId = moveableResizing.value[index];
					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						// const currValLeft = parseInt(el.style.left);
						// const currValTop = parseInt(el.style.top);
						// const currValWidth = parseInt(el.style.width);
						// const currValHeight = parseInt(el.style.height);
						// const left = getLeftResize(dirX, deltaX, currValLeft);
						// const top = getTopResize(dirY, deltaY, currValTop);
						// const width = getWidResize(dirX, deltaX, currValWidth);
						// const height = getHeiResize(dirY, deltaY, currValHeight);
						const width = evt.width;
						const height = evt.height;
						const transform = evt.transform;

						// deltaX && (el.style.left = `${left}px`);
						// deltaY && (el.style.top = `${top}px`);
						width && (el.style.width = `${width}px`);
						height && (el.style.height = `${height}px`);
						el.style.transform = transform;

						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							const domParams = {
								style: {
									// left: el.style.left,
									// top: el.style.top,
									width: el.style.width,
									height: el.style.height,
									transform: el.style.transform,
								},
							};
							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onResizeEndMoveable: (evt: OnResizeEnd): boolean => {
			if (!evt.isDrag) {
				// No need to resize if clicked on the same pos
				moveableResizing.value = [];
				return false;
			}

			if (moveableResizing.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}
			moveableResizing.value = [];
			return true;
		},
	},
	rotate: {
		onRotateStartMoveable: async (evt: OnRotateStart) => {
			const target = evt.target;
			const targetId = target.dataset.id;
			console.log(">> START movable >", targetId, evt);
			if (targetId) {
				const setupMoveableEls = (currId: ModelInstance["id"]) => {
					const layerStore = useLayerInstancesStore();
					const selItemIds = (layerStore.getSelectedElementInstances || []).map(
						(item) => item.id,
					);
					if (selItemIds.length === 0) {
						InfoConsole.w("Aborting rotate");
						moveableRotating.value = [];
						return;
					}

					// If not already selected
					if (!selItemIds.includes(currId)) {
						console.log("Adding >>", currId, selItemIds);
						selItemIds.push(currId);
					}

					moveableRotating.value = selItemIds;
				};

				setupMoveableEls(targetId);

				// Set guidelines based on element visibility
				mvbElementGuidelines.value = await setupElementGuidelines();
			} else {
				InfoConsole.e(`No target found`, evt);
			}
		},
		onRotateMoveable: (evt: OnRotate) => {
			if (moveableRotating.value.length) {
				const layerStore = useLayerInstancesStore();
				const canvasStore = useCanvasStore();

				if (moveableRotating.value.length > 1) {
					InfoConsole.e(
						`INVALID NUMBER OF ELS :: BLOCKED ${moveableRotating.value.length}`,
					);
					return;
				}

				for (let index = 0; index < moveableRotating.value.length; index++) {
					const instanceId = moveableRotating.value[index];
					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							el.style.transform = evt.transform;
							const domParams = {
								style: {
									transform: el.style.transform,
								},
							};

							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onRotateEndMoveable: (evt: OnRotateEnd): boolean => {
			if (!evt.isDrag) {
				// No need to drag if clicked on the same pos
				moveableRotating.value = [];
				return false;
			}

			if (moveableRotating.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}
			moveableRotating.value = [];
			return true;
		},
	},
	snap: {
		onSnapMoveable: (evt: OnSnap) => {
			// console.log(evt);
		},
	},
};

// (Works on active group)
export const groupMove = {
	drag: {
		onDragGroupStartMoveable: async (evt: OnDragGroupStart) => {
			const targets = evt.targets;
			const targetIds: DomElementInstance["id"][] = [];
			for (const tg of targets) {
				const id = tg.dataset.id;
				if (id) targetIds.push(id);
			}
			// console.log(">> START group movable >", targetIds, evt);
			if (targetIds.length) {
				moveableDragging.value = targetIds;
				// Set guidelines based on element visibility
				mvbElementGuidelines.value = await setupElementGuidelines();
			} else {
				InfoConsole.e(`No target found`, evt, targets, targetIds);
			}
		},
		onDragGroupMoveable: (evt: OnDragGroup) => {
			const canvasStore = useCanvasStore();
			if (moveableDragging.value) {
				const layerStore = useLayerInstancesStore();

				// NOTE MoveableJS has a problem with coordinates when grouped - has to have zoom value
				const resEventChanges = getMoveDragChanges(evt.events, evt);

				// NOTE Implement Setting orientation GROUPS

				for (let index = 0; index < moveableDragging.value.length; index++) {
					const instanceId = moveableDragging.value[index];
					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						const deltaX = resEventChanges[instanceId].deltaX || 0;
						const deltaY = resEventChanges[instanceId].deltaY || 0;

						const currValLeft = parseFloat(el.style.left);
						const currValTop = parseFloat(el.style.top);
						el.style.left = `${currValLeft + deltaX}px`;
						el.style.top = `${currValTop + deltaY}px`;

						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							const domParams = {
								style: {
									left: el.style.left,
									top: el.style.top,
								},
							};
							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onDragGroupEndMoveable: (evt: OnDragGroupEnd): boolean => {
			moveableDragOrientation.value = null;

			if (!evt.isDrag) {
				// No need to drag if clicked on the same pos
				moveableDragging.value = [];
				return false;
			}

			if (moveableDragging.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}

			moveableDragging.value = [];
			return true;
		},
	},
	resize: {
		onResizeGroupStartMoveable: async (evt: OnResizeGroupStart) => {
			const targets = evt.targets;
			const targetIds: DomElementInstance["id"][] = [];
			for (const tg of targets) {
				const id = tg.dataset.id;
				if (id) targetIds.push(id);
			}
			// console.log(">> START group movable >", targetIds, evt);
			if (targetIds.length) {
				moveableResizing.value = targetIds;
				// Set guidelines based on element visibility
				mvbElementGuidelines.value = await setupElementGuidelines();
			} else {
				InfoConsole.e(`No target found`, evt, targets, targetIds);
			}
		},
		onResizeGroupMoveable: (evt: OnResizeGroup) => {
			// console.warn("RESIZE GROUP", evt);

			const canvasStore = useCanvasStore();
			if (moveableResizing.value) {
				const layerStore = useLayerInstancesStore();

				for (let index = 0; index < moveableResizing.value.length; index++) {
					const instanceId = moveableResizing.value[index];
					const foundEvent = evt.events.find(
						(event) => event.target.dataset.id === instanceId,
					);

					if (!foundEvent) {
						InfoConsole.e("Moveable event not found", evt.events, instanceId);
						continue;
					}

					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						const width = foundEvent.width;
						const height = foundEvent.height;
						const transform = foundEvent.transform;

						width && (el.style.width = `${width}px`);
						height && (el.style.height = `${height}px`);
						el.style.transform = transform;

						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							const domParams = {
								style: {
									width: el.style.width,
									height: el.style.height,
									transform: el.style.transform,
								},
							};
							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onResizeGroupEndMoveable: (evt: OnResizeGroupEnd): boolean => {
			moveableDragOrientation.value = null;

			if (!evt.isDrag) {
				// No need to drag if clicked on the same pos
				moveableResizing.value = [];
				return false;
			}

			if (moveableResizing.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}

			moveableResizing.value = [];
			return true;
		},
	},
	rotate: {
		onRotateGroupStartMoveable: async (evt: OnRotateGroupStart) => {
			const targets = evt.targets;
			const targetIds: DomElementInstance["id"][] = [];
			for (const tg of targets) {
				const id = tg.dataset.id;
				if (id) targetIds.push(id);
			}
			// console.log(">> START group movable >", targetIds, evt);
			if (targetIds.length) {
				moveableRotating.value = targetIds;
				// Set guidelines based on element visibility
				mvbElementGuidelines.value = await setupElementGuidelines();
			} else {
				InfoConsole.e(`No target found`, evt, targets, targetIds);
			}
		},
		onRotateGroupMoveable: (evt: OnRotateGroup) => {
			// console.warn("ROTATE GROUP", evt);

			const canvasStore = useCanvasStore();
			if (moveableRotating.value) {
				const layerStore = useLayerInstancesStore();

				for (let index = 0; index < moveableRotating.value.length; index++) {
					const instanceId = moveableRotating.value[index];
					const foundEvent = evt.events.find(
						(event) => event.target.dataset.id === instanceId,
					);

					if (!foundEvent) {
						InfoConsole.e("Moveable event not found", evt.events, instanceId);
						continue;
					}

					const el = document.querySelector(
						"[data-id=" + instanceId + "]",
					) as HTMLElement | null;
					if (el) {
						// const transform = foundEvent.transform; // Doesn't work somehow??? In [Resize] it works
						const transform = foundEvent.style.transform;
						el.style.transform = transform;

						const recInst = layerStore.getInstanceById(instanceId);
						if (recInst.el) {
							const domParams = {
								style: {
									transform: el.style.transform,
								},
							};
							layerStore.updateModelRaw(
								domParams,
								recInst.el,
								"",
								canvasStore.getActiveBreakpointObject?.name,
								true,
							);
						} else {
							InfoConsole.e(`No INSTANCE: ${instanceId}`);
						}
					} else {
						InfoConsole.e(`No EL ${instanceId}`);
					}
				}
			} else {
				InfoConsole.e("No sel items");
			}
		},
		onRotateGroupEndMoveable: (evt: OnRotateGroupEnd): boolean => {
			moveableDragOrientation.value = null;

			if (!evt.isDrag) {
				// No need to drag if clicked on the same pos
				moveableRotating.value = [];
				return false;
			}

			if (moveableRotating.value && evt.lastEvent) {
				useSnapshotStore().addUndoStack();
			} else {
				InfoConsole.e("No sel items");
			}

			moveableRotating.value = [];
			return true;
		},
	},
	snap: {
		onSnapMoveable: (evt: OnSnap) => {
			// console.log(evt);
		},
	},
};
