import type { IAssetGetItem } from "@/stores/definition/globalTypes";
import { type CheckpointSaveInstance } from "@/stores/layer";
import { axios } from "./config";

type TAObj = Record<string, string>;
interface ISingleEntity {
	id: string;
	payload: TAObj;
}

export const api = {
	// Auth
	loginApi: async (params: TAObj) => axios.post("login", params),
	logoutApi: async (sid: string) => axios.post("logout", { sid }),

	// Assets
	getAssets: async (params: TAObj) => axios.get("assets", { params }),
	postAssets: async (params: any, config = {}) =>
		axios.post("assets", params, config),
	getAsset: async (params: ISingleEntity) =>
		axios.get(`assets/${params.id}`, { params }),
	// putAsset: (params: SingleEntity) => axios.put(`assets/${params.id}`, params),
	deleteAsset: async (params: { name: string }) =>
		axios.delete(`assets/${params.name}`, { params }),
	// Upload Assets chunk
	postUploadAppendAsset: async (params: any, config = {}) =>
		axios.post("asset-append", params, config),

	// Checkpoint States
	// getCheckpointStates: (params:AObj) => axios.get("save", { params }),
	postCheckpointState: async (params: TAObj) => axios.post("save", params),
	// getCheckpointState: (params: Partial<CheckpointSaveInstance>) =>
	//   axios.get(`save/${params.sav_hash}`, { params }),
	// putCheckpointState: (params: Partial<CheckpointSaveInstance>) =>
	//   axios.put(`save/${params.sav_hash}`, params),
	// deleteCheckpointState: (params: Partial<CheckpointSaveInstance>) =>
	//   axios.delete(`save/${params.sav_hash}`, { params }),
	getLatestCheckpointState: async (params: Partial<CheckpointSaveInstance>) =>
		axios.get(`save/latest`, { params }),
	postCheckDelete: async (params: IAssetGetItem["ass_name"][]) =>
		axios.post("assets/check-delete", { assets: params }),

	// Config
	getConfig: async () => axios.get("config"),

	// Upload build
	postUploadBuild: async (params: any) => axios.post("upload-build", params),

	// Pages
	getPages: async (params: TAObj) => axios.get("pages", { params }),
	postPages: async (params: TAObj, config = {}) =>
		axios.post("pages", params, config),
};
