import { computePosition, flip, shift, offset } from "@floating-ui/dom";
import { type ComputedRef, type Ref } from "vue";
import { handleErrorLog } from "./helpers";
type TPlacementType = "top" | "right" | "bottom" | "left";

async function update(
	referenceElement: HTMLElement,
	tooltipEl: HTMLElement,
	placement: TPlacementType,
) {
	try {
		const res = await computePosition(referenceElement, tooltipEl, {
			placement,
			middleware: [offset(4), flip(), shift({ padding: 5, crossAxis: true })],
		});
		const { x, y } = res;
		if (tooltipEl) {
			const unit = "px";
			Object.assign(tooltipEl.style, {
				left: `${x}${unit}`,
				top: `${y}${unit}`,
			});
		} else {
			tooltipError(`Compute position - no instance ${x} ${y}`);
		}
	} catch (err: unknown) {
		handleErrorLog(err);
	}
}

function tooltipError(msg = "!!") {
	console.error("Tooltip ERROR -", msg);
}

function updateContentText(tooltipEl: HTMLElement, content = "") {
	const contentEl = tooltipEl.querySelector(".content");
	if (contentEl) {
		contentEl.innerHTML = content;
	} else {
		tooltipError("Update content text - no content");
	}
}

function setupTooltipForEvent(
	referenceElement: HTMLElement,
	tooltipEl: HTMLElement,
	content = ref(""),
	placement: TPlacementType = "top",
) {
	if (tooltipEl) {
		updateContentText(tooltipEl, content.value);
	} else {
		tooltipError("Setup >> No instance");
	}

	async function showTooltip(evt: MouseEvent) {
		if (tooltipEl) {
			// const el = evt.target as HTMLElement;
			updateContentText(tooltipEl, content.value);
			tooltipEl.style.display = "block";
			await update(referenceElement, tooltipEl, placement);
		} else {
			tooltipError("Show tooltip - No instance");
		}
	}

	function hideTooltip() {
		if (tooltipEl) {
			tooltipEl.style.display = "";
			tooltipEl.style.left = "";
			tooltipEl.style.top = "";
			updateContentText(tooltipEl, "");
		} else {
			tooltipError("Hide tooltip - No instance");
		}
	}

	function removeListeners() {
		for (const [event, listener] of [
			["mouseenter", showTooltip],
			["mouseleave", hideTooltip],
			["focus", showTooltip],
			["blur", hideTooltip],
		]) {
			// @ts-expect-error
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			referenceElement?.removeEventListener(event, listener);
		}
	}

	function removeAndCleanHandlers() {
		removeListeners();
		hideTooltip();
	}

	for (const [event, listener] of [
		["mouseenter", showTooltip],
		["mouseleave", hideTooltip],
		["focus", showTooltip],
		["blur", hideTooltip],
	]) {
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		referenceElement?.addEventListener(event, listener);
	}

	return {
		removeListeners: removeAndCleanHandlers,
	};
}

async function setupSimpleTooltip(
	referenceElement: HTMLElement,
	tooltipEl: HTMLElement,
	content = ref(""),
	placement: TPlacementType = "top",
) {
	watch(content, () => {
		if (tooltipEl) {
			updateContentText(tooltipEl, content.value);
		} else {
			tooltipError("No instance - watch");
		}
	});

	async function showTooltip() {
		if (tooltipEl) {
			// const el = evt.target as HTMLElement;
			updateContentText(tooltipEl, content.value);
			tooltipEl.style.display = "block";
			await update(referenceElement, tooltipEl, placement);
		} else {
			tooltipError("Show tooltip - simple");
		}
	}

	function hideTooltip() {
		if (tooltipEl) {
			tooltipEl.style.display = "";
			tooltipEl.style.left = "";
			tooltipEl.style.top = "";
			updateContentText(tooltipEl, "");
		} else {
			tooltipError("Hide tooltip - simple");
		}
	}

	await showTooltip();

	return {
		removeListeners: hideTooltip,
	};
}

export async function setupTooltip(
	referenceElement: HTMLElement,
	tooltipEl: HTMLElement,
	content: Ref<string> | ComputedRef<string> = ref(""),
	placement: "top" | "right" | "bottom" | "left" = "top",
	isEnabledForEventsOnly = false,
) {
	return isEnabledForEventsOnly
		? setupTooltipForEvent(referenceElement, tooltipEl, content, placement)
		: setupSimpleTooltip(referenceElement, tooltipEl, content, placement);
}
