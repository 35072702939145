import { AppGlobal } from "@/global/appGlobal";

export function attributesSetup(arr?: Record<string, string>[]): string {
	let res = "";
	if (arr) {
		for (const el of arr) {
			let key: keyof typeof el;
			for (key in el) {
				res += key + "=" + `"${el[key]}" `;
			}
		}
	}
	return res;
}

export function styleSetup(arr?: Record<string, string>[]): string {
	let res = 'style="';
	if (arr) {
		for (const el of arr) {
			let key: keyof typeof el;
			for (key in el) {
				res += key + ":" + `${el[key]};`;
			}
		}
	} else {
		return "";
	}
	res += '" ';
	return res;
}

export function arraySetup(
	arr?: string[],
	type = "class",
	symbol = " ",
): string {
	if (!arr) {
		return "";
	}
	let res = "";
	res += type + '="';
	res += arr.join(symbol ?? " ") + '" ';
	return res;
}

export function handleUppercaseKeys(key: any): string {
	let t_key = "";
	for (const char of key as string) {
		if (char.toLowerCase() !== char) {
			t_key += "-";
		}
		t_key += char.toLowerCase();
	}
	return t_key;
}

export const commandKeyEvent = (
	evt: MouseEvent | WheelEvent | PointerEvent | KeyboardEvent,
) => {
	const osMac = AppGlobal.OS_NAME === "Mac";
	// InfoConsole.l(
	// 	`${AppGlobal.OS_NAME} >>`,
	// 	"error",
	// 	`Meta`,
	// 	evt.metaKey,
	// 	`Ctrl`,
	// 	evt.ctrlKey,
	// );

	if (osMac) return evt.metaKey || evt.ctrlKey;
	return evt.ctrlKey;
};
