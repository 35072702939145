<script setup lang="ts">
import { type TKeyValFormParams } from "@/components/modal/KeyValForm.vue";
import { kebabize } from "@/helpers/helpers";
import { useCanvasStore } from "@/stores/canvas";
import type {
	TCssStyle,
	IEditorPayloadTypeInterface,
	IEditorViewPayload,
	TMonacoOptions,
} from "@/stores/definition/globalTypes";
import type { DomElementInstance } from "@/stores/layer";
import { useLayerInstancesStore } from "@/stores/layer";
import { usePageStore } from "@/stores/page";
import { useSnapshotStore } from "@/stores/snapshot";

const layerStore = useLayerInstancesStore();
const canvasStore = useCanvasStore();
const isAnyValueChanged = ref(false);
const isRawData = ref(false);
const cssItems = ref<{ key: string; value: any }[]>([]);

const props = defineProps<{
	title?: string;
	editorPayload: IEditorViewPayload;
}>();

const isPendingAddRow = ref(false);
const instance = computed(() => props.editorPayload.instance);
const type = computed(() => props.editorPayload.type);
const customHtmlObj = computed(() => {
	const htmlObj =
		props.editorPayload?.html || ({} as IEditorPayloadTypeInterface);
	return {
		value: htmlObj.value as string,
		isShown: htmlObj.value !== undefined && htmlObj.isShown,
	};
});
const customCssObj = computed(() => {
	const cssObj = ({
		value: instance.value?.getActiveStyle().toPlainCss,
		isShown: true,
	} || {}) as IEditorPayloadTypeInterface;
	// const cssObj = props.editorPayload?.css || ({} as EditorPayloadTypeInterface);
	const getValue = (cssObj: IEditorPayloadTypeInterface) => {
		const cssVal = cssObj.value;
		if (cssVal) {
			return typeof cssVal === "string"
				? cssVal
				: JSON.stringify(cssVal, null, 2);
		}
		return cssVal;
	};
	const value = getValue(cssObj);
	// TODO 5 CSS <> JSON transpiler

	return {
		value,
		isShown: value !== undefined && cssObj.isShown,
	};
});
const customJsObj = computed(() => {
	const jsObj = props.editorPayload?.js || ({} as IEditorPayloadTypeInterface);
	return {
		value: jsObj.value as string,
		isShown: jsObj.value !== undefined && jsObj.isShown,
	};
});

const monOptions = computed(
	() =>
		({
			automaticLayout: true,
			showUnused: true,
			mouseWheelZoom: true,
			wordWrap: "off",
			codeLens: false,
			dragAndDrop: true,
			lineNumbersMinChars: 5,
			contextmenu: false,
			wordBasedSuggestions: "off",
			tabCompletion: "off",
			quickSuggestions: true,
			readOnly: false,
			scrollBeyondLastLine: false,
			fontSize: 16,
			fontFamily: "Monospace", // Mono not recognized on Win
			fontLigatures: false,
			fontWeight: "400",
			mouseWheelScrollSensitivity: 3,
			hover: {
				enabled: false,
				sticky: false, // Causes editor error display bug
			},
			scrollbar: {
				useShadows: false,
				verticalHasArrows: false,
				horizontalHasArrows: false,
				vertical: "auto",
				horizontal: "auto",
				verticalScrollbarSize: 6,
				horizontalScrollbarSize: 9,
				arrowSize: 6,
			},
			guides: {
				bracketPairs: true,
			},
			bracketPairColorization: {
				enabled: true,
				independentColorPoolPerBracketType: false,
			},
			tabSize: 2,
			matchBrackets: "always",
		}) as TMonacoOptions,
);

function onEditorChange(key: keyof DomElementInstance["custom"], evt: any) {
	const value = evt.value;
	if (instance.value?.id) {
		const domObj = {
			custom: {
				[key]: value,
			},
		} as DomElementInstance;
		layerStore.updateModelRaw(domObj, instance.value, "", null, true);
		isAnyValueChanged.value = true;
	} else {
		// NOTE: [Global | Page] don't have HTML and JS
	}
}

function onCssChange(evt: any) {
	// TODO [3 Maybe read data directly from store instead of passing them to modal (so we dont lose reactivity)
	const value = evt.value;
	let domObj = {} as TCssStyle;
	try {
		domObj = JSON.parse(value);
	} catch {
		console.log("Invalid CSS", value);
		return;
	}

	if (instance.value) {
		layerStore.updateModelRaw(
			{ style: domObj },
			instance.value,
			"",
			canvasStore.getActiveBreakpointObject?.name,
			false,
		);

		cssItems.value = parseCssObjVal(customCssObj.value.value);
		if (isPendingAddRow) {
			addNewRow();
		}
	} else {
		if (type.value === "global") {
			const currPage = usePageStore().getCurrentPage;
			if (currPage) {
				currPage.updateCanvasGlobalOptions(domObj);
			}
			// } else if (type.value === "page") {
			//   canvasStore.updateCanvasPageOptions(domObj, true, false);
		} else {
			console.warn("Not implemented", value);
		}
	}

	isAnyValueChanged.value = true;
}

function parseStoreValFormChanges() {
	const domObj = Object.fromEntries(
		cssItems.value
			.filter((cssItem) => Boolean(cssItem.key))
			.map((cssItem) => {
				return [cssItem.key, cssItem.value];
			}),
	);
	onCssChange({ value: JSON.stringify(domObj) });
}

function addNewRow() {
	cssItems.value.push({
		// __id: ModelInstance.createUniqueId(),
		// __pending: true,
		key: "",
		value: "",
	});
	isPendingAddRow.value = false;
}

function removeItem(params: TKeyValFormParams) {
	cssItems.value.splice(params.index, 1);
	parseStoreValFormChanges();
	isAnyValueChanged.value = true;
}

async function onChangeKey(params: TKeyValFormParams) {
	const newVal = params.value;
	params.item.key = newVal;
	isAnyValueChanged.value = true;
	// delete item.__pending;

	if (newVal) {
		const hasAnyBlankKey = cssItems.value.some((item) => !item.key);
		// !hasAnyBlankKey &&  addNewRow();
		!hasAnyBlankKey && (isPendingAddRow.value = true);
	} else {
		removeItem({
			item: params.item,
			index: params.index,
			evt: params.evt,
			value: params.value,
		});
	}

	parseStoreValFormChanges();
}

async function onChangeValue(params: TKeyValFormParams) {
	const newVal = params.value;
	params.item.value = newVal;
	isAnyValueChanged.value = true;

	parseStoreValFormChanges();
}

function initKeyValForm() {
	cssItems.value = parseCssObjVal(customCssObj.value.value);
	addNewRow();
}

function parseCssObjVal(valueStr: string): { key: string; value: any }[] {
	try {
		const obj = JSON.parse(valueStr);
		return Object.keys(obj).map((key) => ({
			key,
			value: obj[key],
		}));
	} catch (err: any) {
		console.warn(err.message);

		return [];
	}
}
function isInvalidCss(data: { key: string; value: any }): boolean {
	if (data.key && data.value) {
		const kebabName = kebabize(data.key);
		return !CSS.supports(kebabName, data.value);
	}
	return false;
}

onMounted(() => {
	initKeyValForm();
});

onUnmounted(() => {
	if (isAnyValueChanged.value) {
		useSnapshotStore().addUndoStack();
	}
});
</script>

<template lang="pug">
.monaco-view(:class="{ 'is-raw': isRawData }")
	.view
		.column.col6(v-if="customHtmlObj.isShown || customJsObj.isShown")
			.group-big(v-if="customHtmlObj.isShown")
				span HTML
				.editor-wrap
					monaco-editor(
						:value="customHtmlObj.value",
						:options="monOptions",
						language="html",
						@change-all="onEditorChange('html', $event)"
					)
			.group-big(v-if="customJsObj.isShown")
				span JS
				.editor-wrap
					monaco-editor(
						:value="customJsObj.value",
						:options="monOptions",
						language="javascript",
						@change-all="onEditorChange('js', $event)"
					)
		.column(:class="{ col4: customHtmlObj.isShown || customJsObj.isShown }")
			.group-big(v-if="customCssObj.isShown")
				span CSS
				template(v-if="isRawData")
					.editor-wrap
						monaco-editor(
							:value="customCssObj.value",
							:options="monOptions",
							language="json",
							@change-all="onCssChange($event)"
						)
						//- language="css",
				.key-form-group(v-if="!isRawData")
					key-val-form(
						v-for="(cssItem, index) in cssItems",
						:key="index",
						:item="cssItem",
						:index="index",
						:is-key-error="isInvalidCss(cssItem)",
						:is-val-error="isInvalidCss(cssItem)",
						@change-key="onChangeKey",
						@change-val="onChangeValue",
						@click-remove-item="removeItem"
					)
				simple-switch(v-model="isRawData", label="Show as JSON")
</template>

<style lang="scss" scoped>
.monaco-view {
	display: flex;
	flex-direction: column;
	gap: 10px;

	// width: 85vw;
	// height: 85vh;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0 20px 20px;
	user-select: none;

	.view {
		width: 100%;
		height: 100%;
		display: flex;

		// flex-basis: 33%;
		flex: 1;
		gap: 5px;

		.group-big {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;
			width: 100%;
		}

		.column {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 5px;

			&.col4 {
				width: 40%;
			}

			&.col6 {
				width: 60%;

				> .group-big {
					height: 100%;
				}
			}
		}
	}

	.editor-wrap {
		display: flex;
		height: 100%;

		> div {
			width: 100%;
			height: 100%;
			flex: 1;
		}
	}

	.monaco-editor {
		width: 100%;
		height: 100%;
	}

	.key-form-group {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.switch-wrapper {
		margin-top: auto;
		display: flex;
		align-self: flex-start;
	}
}
</style>
