<template lang="pug">
.my-toast(:class="[type, { 'dark-theme': isDarkTheme }]")
	slot
		.line
		.content
			.icon
				template(v-if="type === 'error'")
					svg(
						xmlns="http://www.w3.org/2000/svg",
						width="61",
						height="61",
						viewbox="0 0 61 61",
						fill="none"
					)
						circle(
							cx="30.5256",
							cy="30.4348",
							r="29.9309",
							fill="#E44E75",
							fill-opacity="0.1"
						)
						path(
							d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068",
							stroke="#E44E75",
							stroke-width="4",
							stroke-linecap="round",
							stroke-linejoin="round"
						)
				template(v-else-if="type === 'success'")
					svg(
						xmlns="http://www.w3.org/2000/svg",
						width="61",
						height="61",
						viewbox="0 0 61 61",
						fill="none"
					)
						circle(
							cx="30.5256",
							cy="30.4348",
							r="29.9309",
							fill="#33B980",
							fill-opacity="0.1"
						)
						path(
							d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068",
							stroke="#33B980",
							stroke-width="4",
							stroke-linecap="round",
							stroke-linejoin="round"
						)

			.text-content
				.title {{ parsedTitleMsg }}
				.text
					| {{ msg }}
</template>

<script setup lang="ts">
const props = defineProps({
	type: {
		type: String,
		default: "error",
	},
	isDarkTheme: Boolean,
	titleMsg: {
		type: String,
		default: "",
	},
	msg: {
		type: String,
		default: "",
	},
});

const parsedTitleMsg = computed(() => {
	return props.titleMsg || props.type === "error"
		? "There seems to be an error!"
		: "Success";
});
</script>

<style lang="scss">
.Vue-Toastification__toast.my-custom-toast-class {
	$content-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	$border-radius: 6px;

	min-height: 80px;
	background-color: transparent;
	user-select: none;
	border: 0;
	border-radius: $border-radius;
	box-sizing: border-box;
	padding: 0;
	backdrop-filter: blur(20px);
	box-shadow: $content-shadow;
}
</style>
<style lang="scss" scoped>
$content-back: #2a292a80;
$title-color: $text-color;
$content-text: $text-color-light;
$border-color: #47474790;
$green-accent: $success-color;
$red-accent: $error-color;
$dark-theme-back: #33353b;
$dark-theme-title: white;
$dark-theme-text: #d3d3d3;
$content-font: "Open Sans", sans-serif;
$icon-size: 40px;

// $green-accent: #47eaaf;

.my-toast {
	display: flex;
	height: 100%;
	width: 100%;
	background-color: $content-back;

	.line {
		height: 100%;
		width: 5px;
	}

	.content {
		padding: 8px 20px;
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		overflow: hidden;
		font-family: $content-font;
		cursor: pointer;

		.text-content {
			font-size: 16px;
			font-style: normal;
			display: flex;
			flex-direction: column;
			gap: 5px;

			.title {
				color: $title-color;
				font-weight: 600;
				line-height: 17px;
			}

			.text {
				color: $content-text;
			}
		}

		.icon {
			width: $icon-size;
			height: $icon-size;
			display: grid;
			place-content: center;

			svg {
				transform: scale(0.6);
			}
		}
	}

	&.success {
		.line {
			background: $green-accent;
		}
	}

	&.error {
		.line {
			background: $red-accent;
		}
	}

	&.dark-theme {
		.content {
			background-color: $dark-theme-back;
			border: 0;

			.title {
				color: $dark-theme-title;
			}

			.text {
				color: $dark-theme-text;
			}
		}
	}
}
</style>
