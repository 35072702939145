<script setup lang="ts">
import { useCanvasStore } from "@/stores/canvas";
import type { IMetaObj, TMonacoOptions } from "@/stores/definition/globalTypes";
import type { DomElementInstance } from "@/stores/layer";
import { usePageStore } from "@/stores/page";
import { useSnapshotStore } from "@/stores/snapshot";

defineProps<{
	title?: string;
}>();
const emit = defineEmits(["close-modal"]);
const isAnyValueChanged = ref(false);
const isRawData = ref(true);

const editorValue = computed<string>(() => {
	const currPage = usePageStore().getCurrentPage;
	if (currPage) {
		const globalDef = useCanvasStore().getActiveGlobalDefinition;
		if (globalDef) {
			const itemObj = globalDef.find((def) => def.name === "customHead");
			if (itemObj) {
				return itemObj.value as string;
			}
		}
	}
	return "";
});

const monOptions = computed(
	() =>
		({
			automaticLayout: true,
			showUnused: true,
			mouseWheelZoom: true,
			wordWrap: "off",
			codeLens: false,
			dragAndDrop: true,
			lineNumbersMinChars: 5,
			contextmenu: true,
			wordBasedSuggestions: "off",
			tabCompletion: "off",
			quickSuggestions: true,
			readOnly: false,
			scrollBeyondLastLine: false,
			fontSize: 16,
			fontFamily: "Monospace", // Mono not recognized on Win
			fontLigatures: false,
			fontWeight: "400",
			mouseWheelScrollSensitivity: 3,
			hover: {
				enabled: false,
				sticky: false, // Causes editor error display bug
			},
			scrollbar: {
				useShadows: false,
				verticalHasArrows: false,
				horizontalHasArrows: false,
				vertical: "auto",
				horizontal: "auto",
				verticalScrollbarSize: 6,
				horizontalScrollbarSize: 9,
				arrowSize: 6,
			},
			guides: {
				bracketPairs: true,
			},
			bracketPairColorization: {
				enabled: true,
				independentColorPoolPerBracketType: false,
			},
		}) as TMonacoOptions,
);

function onEditorChange(key: keyof DomElementInstance["custom"], evt: any) {
	const value = evt.value;
	const currPage = usePageStore().getCurrentPage;
	if (currPage) {
		const domObj = {
			customHead: value,
		} as IMetaObj;
		currPage.updateCanvasGlobalOptions(domObj);
		isAnyValueChanged.value = true;
	}
}

onUnmounted(() => {
	if (isAnyValueChanged.value) {
		useSnapshotStore().addUndoStack();
	}
});
</script>

<template lang="pug">
.global-css(:class="{ 'is-raw': isRawData }")
	.view
		.column(v-if="isRawData")
			.editor-wrap
				monaco-editor(
					:value="editorValue",
					:options="monOptions",
					language="html",
					@change-all="onEditorChange('js', $event)"
				)
	.action-wrap
		.right-actions
			button(@click="emit('close-modal')")
				span Close
</template>

<style lang="scss" scoped>
.global-css {
	display: flex;
	flex-direction: column;
	gap: 10px;

	// width: 85vw;
	// height: 85vh;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0 20px 20px;
	user-select: none;
	transition: all 0.3s ease;

	.view {
		width: 100%;
		height: 100%;
		display: flex;

		// flex-basis: 33%;
		flex: 1;
		gap: 5px;

		.column {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 5px;
		}
	}

	.editor-wrap {
		display: flex;
		height: 100%;
		overflow: auto;

		> div {
			width: 100%;
			height: 100%;
			flex: 1;
		}
	}

	.action-wrap {
		display: flex;

		.right-actions {
			display: flex;
			margin-left: auto;
		}
	}

	.monaco-editor {
		width: 100%;
		height: 100%;
	}

	&.is-raw {
		min-width: 85vw;
		min-height: 85vh;
	}

	&:not(.is-raw) {
		min-width: 45vw;
		min-height: 45vh;
	}
}
</style>
