import { type ModelInstance } from "@/stores/group";
import { InfoConsole } from "./helpers";

export function moveElementToDropzone(
	dragId: ModelInstance["id"],
	dropId: ModelInstance["id"],
	toOuterScope = false,
): boolean {
	const layerStore = useLayerInstancesStore();
	// console.warn(dragId, dropId, toOuterScope);
	if (toOuterScope) {
		// Get element out of the selector and into the uppermost el
		if (!dragId) {
			InfoConsole.e(`Invalid drag id: ${dragId}`);
			return false;
		}
		return layerStore.moveElementToOuterScope(dragId);
	} else {
		// Get element into another [make a child]
		if (!dragId || !dropId) {
			InfoConsole.e(`Invalid dragdrop id: ${dragId} || ${dropId}`);
			return false;
		}

		return layerStore.moveElementToAnotherEl(dragId, dropId);
	}
}
