// import { getSessionStorageReac } from "@/helpers/helpers";
import { AppGlobal } from "@/global/appGlobal";
import axios from "axios";

const appGlobalObj = AppGlobal as typeof AppGlobal | null;

// Handles DEV and PROD
const integrationLink = appGlobalObj?.URL_PARAMS.integration
	? `${appGlobalObj.URL_PARAMS.integration}api_v2/html_builder`
	: "";

const sidLink = appGlobalObj?.URL_PARAMS.sid;
export const baseURL = integrationLink || import.meta.env.VITE_APP_ROOT_API;
export const siteDomain = appGlobalObj?.URL_PARAMS.domain || "";

const instance = axios.create({
	baseURL,
});

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});

function handleRequestInterceptor(config: any) {
	try {
		const sid = sidLink;
		if (config.params) {
			config.params.sid = sid;
		} else {
			config.params = {
				sid,
			};
		}
	} catch (err: any) {
		console.error("ERRor", err.message);
	}
}

export { instance as axios };
