import { $t } from "@/i18n";
import { type IEditorViewPayload } from "@/stores/definition/globalTypes";
import { type IGlobalModal } from "@/components/modal/ModernModal.vue";
import { useModalStore } from "@/stores/modal";
import FontListViewVue from "@/components/rightSidebar/comps/FontListView.vue";
import EditorViewVue from "@/components/shared/editor/EditorView.vue";
import HtmlPreviewVue from "@/components/canvas/HtmlPreview.vue";
import HtmlExportVue from "@/components/canvas/HtmlExport.vue";
// import SaveSnapshotListVue from "@/components/modal/SaveSnapshotList.vue";
import UploadManageVue from "@/components/upload/UploadManage.vue";
import AttributesManageVue from "@/components/modal/AttributesManage.vue";
import BreakpointManageVue from "@/components/modal/BreakpointManage.vue";
import PageManageVue from "@/components/modal/PageManage.vue";
import GlobalCssVue from "@/components/modal/GlobalCss.vue";
import GlobalJsVue from "@/components/modal/GlobalJs.vue";
import GlobalHeadVue from "@/components/modal/GlobalHead.vue";
import AuthModuleVue from "@/components/auth/AuthModule.vue";
import AppStorageSelectionVue from "@/components/modal/AppStorageSelection.vue";
// import { AppGlobal } from "./globals";
// import ConfirmDialogVue from "@/components/modal/ConfirmDialog.vue";

export function openModal(
	comp: any,
	config?: Record<string, any>,
	formStyle?: Record<string, any>,
) {
	const modalStore = useModalStore();
	modalStore.updateModalData({
		comp,
		config,
		formStyle,
	} as IGlobalModal);
}

export function openEditorViewModal(payload = {} as IEditorViewPayload) {
	const config = {
		canResize: true,
		isOverlayTransparent: true,
		hasOverlay: true,
		// hasOverlay: false,
		slotProps: {
			title: `${$t("editor.label")}`,
			confirmLabel: $t("dialog.confirm"),
			cancelLabel: $t("dialog.cancel"),
			editorPayload: {
				...payload,
			},
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	const formStyle = {
		width: "85vw",
		height: "85vh",
	};
	openModal(EditorViewVue, config, formStyle);
}

export function openFontListModal(payloadData: any) {
	const config = {
		slotProps: {
			title: `${$t("editor.label")}`,
			confirmLabel: $t("dialog.confirm"),
			cancelLabel: $t("dialog.cancel"),
			payload: {},
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(FontListViewVue, config);
}

export function openPreviewCanvasModal(payload: Record<string, any> = {}) {
	const config = {
		title: `${$t("menuBar.preview")}`,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(HtmlPreviewVue, config);
}

export function openExportCanvasModal(payload: Record<string, any> = {}) {
	const config = {
		title: `${$t("menuBar.export")}`,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(HtmlExportVue, config);
}

// export function openPreviousSavesModal(payload: Record<string, any> = {}) {
//   // Forbid in standaloen app
//   if (!AppGlobal.IS_INTEGRATED) return null;

//   const config = {
//     title: `${transl("menuBar.switchPreviousSaves")}`,
//     isOverlayTransparent: true,
//     slotProps: {
//       onCloseModal: () => {},
//       ...payload,
//     },
//   } as Partial<IGlobalModal["config"]>;

//   openModal(SaveSnapshotListVue, config);
// }

export function openUploadManageModal(payload: Record<string, any> = {}) {
	const config = {
		title: `${$t("menuBar.uploadPreview")}`,
		isOverlayTransparent: true,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(UploadManageVue, config);
}

// export function openClassManageModal(payload: Record<string, any> = {}) {
//   const config = {
//     title: `${transl("menuBar.uploadPreview")}`,
//     isOverlayTransparent: true,
//     slotProps: {
//       onCloseModal: () => {},
//       ...payload,
//     },
//   } as Partial<IGlobalModal["config"]>;

//   openModal(UploadManageVue, config);
// }

export function openAttributeManageModal(payload: Record<string, any> = {}) {
	const config = {
		title: `${$t("app.attributes")}`,
		isOverlayTransparent: false,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(AttributesManageVue, config);
}

export function openBreakpointManageModal(
	payload: Record<string, any> = {},
	isEdit = false,
) {
	const titleLabel = isEdit
		? $t("app.breakpoint.manage")
		: $t("app.breakpoint.create");

	const config = {
		title: titleLabel,
		isOverlayTransparent: false,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(BreakpointManageVue, config);
}

export function openPageManageModal(
	payload: Record<string, any> = {},
	isEdit = false,
) {
	const titleLabel = isEdit ? $t("app.page.manage") : $t("app.page.create");

	const config = {
		title: titleLabel,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(PageManageVue, config);
}

export function openGlobalCssModal(
	payload: Record<string, any> = {},
	isEdit = false,
) {
	const titleLabel = $t("app.globalCss");

	const config = {
		title: titleLabel,
		canResize: true,
		isOverlayTransparent: true,
		hasOverlay: true,
		// hasOverlay: false,
		slotProps: {
			title: `${$t("editor.label")}`,
			confirmLabel: $t("dialog.confirm"),
			cancelLabel: $t("dialog.cancel"),
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalCssVue, config);
}

export function openGlobalJsModal(
	payload: Record<string, any> = {},
	isEdit = false,
) {
	const titleLabel = $t("app.globalJs");

	const config = {
		title: titleLabel,
		canResize: true,
		isOverlayTransparent: true,
		hasOverlay: true,
		// hasOverlay: false,
		slotProps: {
			title: `${$t("editor.label")}`,
			confirmLabel: $t("dialog.confirm"),
			cancelLabel: $t("dialog.cancel"),
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalJsVue, config);
}

export function openGlobalCustomHead(
	payload: Record<string, any> = {},
	isEdit = false,
) {
	const titleLabel = $t("app.customHead");

	const config = {
		title: titleLabel,
		canResize: true,
		isOverlayTransparent: true,
		hasOverlay: true,
		// hasOverlay: false,
		slotProps: {
			title: `${$t("editor.label")}`,
			confirmLabel: $t("dialog.confirm"),
			cancelLabel: $t("dialog.cancel"),
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalHeadVue, config);
}

export function openLoginModal(
	payload: Record<string, any>,
	callBack?: (a: any) => void,
) {
	const config = {
		title: "",
		canResize: false,
		isOverlayTransparent: false,
		hasOverlay: true,
		isShowCloseBtn: false,
		// hasOverlay: false,
		slotProps: {
			onLoggedIn: () => {
				console.warn("LOAD / OVERWRITE project");
				// Load new project from mars site | Display the option
			},
			// confirmLabel: transl("dialog.confirm"),
			// cancelLabel: transl("dialog.cancel"),
			// onCloseModal: () => {},
		},
		...payload.config,
	} as Partial<IGlobalModal["config"]>;
	openModal(AuthModuleVue, config);
}

export function openAppStorageModal(
	payload: Record<string, any> = {},
	callBack?: (a: any) => void,
) {
	const config = {
		title: "Choose how to save project",
		canResize: false,
		isOverlayTransparent: false,
		hasOverlay: true,
		canCloseModal: false,
		// hasOverlay: false,
		slotProps: {},
	} as Partial<IGlobalModal["config"]>;
	openModal(AppStorageSelectionVue, config);
}
