<script setup lang="ts">
// const props = defineProps<{}>();
const emit = defineEmits(["load-existing", "overwrite"]);

function onLoadProject() {
	emit("load-existing");
}
function onOverwriteProject() {
	emit("overwrite");
}
</script>

<template lang="pug">
.mars-site-select
	.title Existing project found on Admin Mars
	.subtitle Choose an option:
	.content
		.item(@click="onLoadProject()")
			i-fa-trash-can
			span Discard current work and load from selected Mars project
		.item(@click="onOverwriteProject()")
			i-fa-floppy-disk
			span Save current work and overwrite selected Mars project
</template>

<style lang="scss" scoped>
.mars-site-select {
	display: flex;
	flex-direction: column;
	width: 100%;
	user-select: none;

	.title {
		font-weight: 800;
		align-self: center;
		margin-bottom: 10px;
	}

	.subtitle {
		font-weight: 600;
		margin-bottom: 10px;
	}

	.content {
		align-self: flex-start;
		width: 100%;

		.item {
			padding: 5px 10px;
			display: flex;
			gap: 9px;
			align-items: center;
			cursor: pointer;

			&:hover {
				background: rgba(192, 192, 192, 0.203);
				color: white;
			}
		}
	}
}
</style>
