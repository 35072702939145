import { useCanvasStore } from "@/stores/canvas";
import {
	DomElementInstance,
	type IDomElementInterface,
	type TUndoCreating,
	useLayerInstancesStore,
	type CStyleInstance,
} from "@/stores/layer";
import {
	type TElementTags,
	type TSharedElementItemInterface,
} from "../globalTypes";
import { addPxEnter, parseColorToHex } from "@/helpers/helpers";
import { openAttributeManageModal } from "@/helpers/modals";
import {
	CTextStroke,
	CBoxShadow,
	CTextShadow,
	CBorder,
	CBorderRadius,
} from "@/helpers/cssClasses";
// import omit from "lodash.omit";

const TEXT_TAGS: TElementTags[] = [
	"a",
	"button",
	"p",
	"label",
	"div",
] as TElementTags[];

export interface IElementInterface {
	padding: string;
	margin: string;
	lineHeight: string;
	fontFamily?: string;
	fontSize?: string;
	fontWeight?: string;
	fontStyle?: string;
	textDecoration?: string;
	color?: string;
	backgroundColor?: string;
	background?: string; // Used mostly for fallback
	borderRadius: CBorderRadius;
	webkitTextStroke: CTextStroke;
	textShadow: CTextShadow;
	border: CBorder;
	boxShadow: CBoxShadow;
	overflow: string;
	zIndex: string;
	tag: string;
	value: string;
	placeholder: string;
	autoplay: boolean;
	controls: boolean;
	muted: boolean;
	loop: boolean;
}

export class ElementOptionsInstance {
	getValue(
		key: keyof IElementInterface,
		instanceChildKey: Extract<
			keyof DomElementInstance,
			"tag" | "style" | "attributes"
		> = "style",
	): any {
		const selInstances =
			useLayerInstancesStore().getSelectedElementInstances || [];
		switch (instanceChildKey) {
			case "style": {
				const mergedStyles = selInstances.map(
					(instance) => instance.getActiveStyle().toForm,
				);
				const styleKey = key as keyof CStyleInstance["form"];
				const firstStyleObj = mergedStyles[0];
				const firstStyleObjVal = firstStyleObj[styleKey];
				let isSameValue = true;
				for (let index = 1; index < mergedStyles.length; index++) {
					const styleObj = mergedStyles[index];
					const styleObjVal = styleObj[styleKey];
					if (
						typeof firstStyleObjVal === "string" &&
						typeof styleObjVal === "string"
					) {
						if (styleObjVal !== firstStyleObjVal) {
							isSameValue = false;
							break;
						}
					} else {
						// Instance checks
						if (styleObjVal?.toString() !== firstStyleObjVal?.toString()) {
							isSameValue = false;
							break;
						}
					}
				}

				if (isSameValue) {
					if (firstStyleObjVal === undefined) {
						// Debug nonexistant values
						// console.log("[Debug nonexistant values]", styleKey, firstStyleObj);
					} else {
						return firstStyleObjVal;
					}
				} else {
					// console.warn("Not same value", firstStyleObj);
				}

				break;
			}
			case "attributes": {
				let isSameValue = true;
				const firstAttrsVal = selInstances[0][instanceChildKey]?.[key];
				if (firstAttrsVal) {
					for (let index = 1; index < selInstances.length; index++) {
						const instanceAttrsVal =
							selInstances[index][instanceChildKey]?.[key];
						if (firstAttrsVal !== instanceAttrsVal) {
							isSameValue = false;
							break;
						}
					}
					return isSameValue ? firstAttrsVal : "";
				}

				break;
			}
			case "tag": {
				let isSameValue = true;
				const firstInstance = selInstances[0];
				for (let index = 1; index < selInstances.length; index++) {
					const instance = selInstances[index];
					if (firstInstance[instanceChildKey] !== instance[instanceChildKey]) {
						isSameValue = false;
						break;
					}
				}
				return isSameValue ? firstInstance.tag : "";
			}
			// No default
		}

		return "";
	}

	setValues(
		payloadObj: Partial<IElementInterface>,
		undoValue: TUndoCreating = "",
		instanceChildKey?: keyof IDomElementInterface,
		isCssProp = true, // Inverse value because more of non CSS props
		selInstances = useLayerInstancesStore().getSelectedElementInstances || [],
	) {
		const entries = Object.entries(payloadObj) as [
			keyof IElementInterface,
			any,
		][];

		const hasAnyValidValue = entries.some(([key, value]) => {
			const isValidCss = isCssProp;
			// const isValidCss = isCssProp && isCssValid(key as any, value);
			return isValidCss || !value || !isCssProp;
		});

		for (let index = 0; index < selInstances.length; index++) {
			const instance = selInstances[index];
			if (hasAnyValidValue) {
				const layerStore = useLayerInstancesStore();
				if (isCssProp) {
					layerStore.updateModelRaw(
						{ style: payloadObj },
						instance,
						index === selInstances.length - 1 ? undoValue : "",
						useCanvasStore().getActiveBreakpointObject?.name,
						true,
					);
				} else {
					layerStore.updateModelRaw(
						instanceChildKey ? { [instanceChildKey]: payloadObj } : payloadObj,
						instance,
						index === selInstances.length - 1 ? undoValue : "",
						null,
						true,
					);
				}
			}
		}
	}
}

const hasGetValue = (...refEls: (string | null | undefined)[]) => {
	const filteredRefEls = refEls.filter(Boolean) as string[];
	for (const refEl of filteredRefEls) {
		if (typeof refEl === "string") {
			return refEl;
		} else if ("value" in refEl) {
			return refEl;
		}
	}

	return filteredRefEls[0] ?? "";
};
export const elementDefinition = (formInstance: ElementOptionsInstance) => {
	const backgroundColorVal = hasGetValue(
		formInstance.getValue("backgroundColor") as string,
		formInstance.getValue("background") as string,
	);
	const currentTag = formInstance.getValue("tag", "tag");
	const textStrokeInstance =
		(formInstance.getValue("webkitTextStroke") as CTextStroke) ||
		new CTextStroke("");
	const textShadowInstance =
		(formInstance.getValue("textShadow") as CTextShadow) || new CTextShadow("");
	const boxShadowInstance =
		(formInstance.getValue("boxShadow") as CBoxShadow) || new CBoxShadow("");
	const borderRadiusInstance =
		(formInstance.getValue("borderRadius") as CBorderRadius) ||
		new CBorderRadius("");
	const borderInstance =
		(formInstance.getValue("border") as CBorder) || new CBorder("");

	return reactive<TSharedElementItemInterface[]>([
		{
			name: "attributes",
			label: "Attributes",
			buttonLabel: "Change",
			type: "button",
			onClick(evt: Event, oldValue) {
				openAttributeManageModal();
			},
		},
		{
			name: "tag",
			label: "Tag",
			type: "dropdown",
			value: currentTag,
			vBind: {
				options: [
					"button",
					"input",
					"div",
					"span",
					"p",
					"img",
					"label",
					"video",
					"audio",
				],
				searchable: true,
				closeOnSelect: true,
				canDeselect: false,
			},
			onInput(evt: any, oldValue) {
				formInstance.setValues({ tag: evt }, "on", undefined, false);
			},
		},
		{
			name: "value",
			label: "Value",
			value: decodeURI(formInstance.getValue("value", "attributes")),
			// includeForTags: ["button", "input"],
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				formInstance.setValues({ value }, "on", "attributes", false);
			},
		},
		{
			name: "placeholder",
			label: "Placeholder",
			value: decodeURI(formInstance.getValue("placeholder", "attributes")),
			includeForTags: ["input"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				formInstance.setValues(
					{ placeholder: value },
					"on",
					"attributes",
					false,
				);
			},
		},
		// {
		// NEEDS TO REWORK CSS TO JSON <-> Because pseudoelements don't work
		// 	name: "placeholderColor",
		// 	label: "Placeholder color",
		// 	value: formInstance.getValue("::placeholder"),
		// 	inputType: "color",
		// 	includeForTags: ["input"],
		// 	onColorInput(evt: Event, currValue) {
		// 		formInstance.setValues({ "::placeholder": currValue }, "", "style");
		// 	},
		// 	onColorChange(evt: Event, currValue) {
		// 		const hexValue = parseColorToHex(currValue);
		// 		formInstance.setValues({ "::placeholder": hexValue }, "force", "style");
		// 	},
		// 	onSelectEyeDropper(value: string, key?: keyof ElementInterface) {
		// 		formInstance.setValues({ "::placeholder": value }, "on", "style");
		// 	},
		// },
		{
			name: "autoplay",
			label: "Autoplay",
			inputType: "checkbox",
			checked: formInstance.getValue("autoplay", "attributes"),
			includeForTags: ["video", "audio"],
			onChange(evt: Event, oldValue) {
				const checked = (evt.target as HTMLInputElement).checked;
				formInstance.setValues(
					{ autoplay: checked },
					"on",
					"attributes",
					false,
				);
			},
		},
		{
			name: "controls",
			label: "Controls",
			inputType: "checkbox",
			checked: formInstance.getValue("controls", "attributes"),
			includeForTags: ["video", "audio"],
			onChange(evt: Event, oldValue) {
				const checked = (evt.target as HTMLInputElement).checked;
				formInstance.setValues(
					{ controls: checked },
					"on",
					"attributes",
					false,
				);
			},
		},
		{
			name: "muted",
			label: "Muted",
			inputType: "checkbox",
			checked: formInstance.getValue("muted", "attributes"),
			includeForTags: ["video", "audio"],
			onChange(evt: Event, oldValue) {
				const checked = (evt.target as HTMLInputElement).checked;
				formInstance.setValues({ muted: checked }, "on", "attributes", false);
			},
		},
		{
			name: "loop",
			label: "Loop",
			inputType: "checkbox",
			checked: formInstance.getValue("loop", "attributes"),
			includeForTags: ["video", "audio"],
			onChange(evt: Event, oldValue) {
				const checked = (evt.target as HTMLInputElement).checked;
				formInstance.setValues({ loop: checked }, "on", "attributes", false);
			},
		},
		{
			name: "padding",
			label: "Padding",
			value: formInstance.getValue("padding"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "padding");
				formInstance.setValues(payload, "on", "style");
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ padding: value }, "", "style");
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ padding: value }, "on", "style");
			},
		},
		{
			name: "margin",
			label: "Margin",
			value: formInstance.getValue("margin"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "margin");
				formInstance.setValues(payload, "on", "style");
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ margin: value }, "", "style");
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ margin: value }, "on", "style");
			},
		},
		{
			name: "lineHeight",
			label: "Line Height",
			value: formInstance.getValue("lineHeight"),
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				formInstance.setValues({ lineHeight: value }, "on", "style");
			},
			onDraggerInput(evt, oldValue) {
				const value = `${String(evt.incVal)}`;
				formInstance.setValues({ lineHeight: value }, "", "style");
			},
			onDraggerChange(evt, oldValue) {
				const value = `${String(evt.incVal)}`;
				formInstance.setValues({ lineHeight: value }, "on", "style");
			},
		},
		{
			name: "fontFamily",
			label: "Font Family",
			value: formInstance.getValue("fontFamily"),
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				formInstance.setValues({ fontFamily: value }, "on", "style");
			},
		},
		{
			name: "fontSize",
			label: "Font Size",
			value: formInstance.getValue("fontSize"),
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "fontSize");
				formInstance.setValues(payload, "on", "style");
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ fontSize: value }, "", "style");
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				formInstance.setValues({ fontSize: value }, "on", "style");
			},
		},
		{
			name: "fontWeight",
			label: "Font Weight",
			value: formInstance.getValue("fontWeight"),
			type: "dropdown",
			vBind: {
				options: [
					"100",
					"200",
					"300",
					"400",
					"500",
					"600",
					"700",
					"800",
					"900",
					"bold",
					"bolder",
					"lighter",
					"normal",
				],
				searchable: true,
				closeOnSelect: true,
				canDeselect: true,
			},
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt: any, oldValue) {
				formInstance.setValues({ fontWeight: evt }, "on", "style");
			},
		},
		{
			name: "fontStyle",
			label: "Font Style",
			value: formInstance.getValue("fontStyle"),
			type: "dropdown",
			vBind: {
				options: ["normal", "italic", "oblique"],
				searchable: true,
				closeOnSelect: true,
				canDeselect: true,
			},
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt: any, oldValue) {
				formInstance.setValues({ fontStyle: evt }, "on", "style");
			},
		},
		{
			name: "textDecoration",
			label: "Font Decoration",
			value: formInstance.getValue("textDecoration"),
			type: "dropdown",
			vBind: {
				options: [
					"overline",
					"line-through",
					"underline",
					"underline overline",
				],
				searchable: true,
				closeOnSelect: true,
				canDeselect: true,
			},
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio", "iframe"],
			onInput(evt: any, oldValue) {
				formInstance.setValues({ textDecoration: evt }, "on", "style");
			},
		},
		{
			name: "color",
			label: "Color",
			value: formInstance.getValue("color"),
			inputType: "color",
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio"],
			onColorInput(evt: Event, currValue) {
				formInstance.setValues({ color: currValue }, "", "style");
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				formInstance.setValues({ color: hexValue }, "force", "style");
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				formInstance.setValues({ color: value }, "on", "style");
			},
		},
		{
			name: "backgroundColor",
			label: "Background",
			value: backgroundColorVal,
			inputType: "color",
			excludeFromTags: ["img", "video", "audio"],
			onColorInput(evt: Event, currValue) {
				formInstance.setValues({ backgroundColor: currValue }, "", "style");
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				formInstance.setValues({ backgroundColor: hexValue }, "force", "style");
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				formInstance.setValues({ backgroundColor: value }, "on", "style");
			},
		},
		{
			name: "border",
			label: "Border",
			type: "borderInput",
			value: borderInstance.getValue("border"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				borderInstance.setValue({ borderWidth: value });
				formInstance.setValues({ border: borderInstance }, "", "style");
			},
			onChange(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				borderInstance.setValue({ borderWidth: value });
				formInstance.setValues({ border: borderInstance }, "force", "style");
			},
		},
		{
			name: "borderRadius",
			label: "Border Radius",
			value: borderRadiusInstance.getValue("borderRadius"),
			isInvalid: borderRadiusInstance.isInvalid(),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				borderRadiusInstance.setValue({ borderRadius: value });
				formInstance.setValues(
					{ borderRadius: borderRadiusInstance },
					"on",
					"style",
				);
			},
			onDraggerInput(evt, oldValue) {
				if (oldValue.split(" ").length !== 1) return;
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				borderRadiusInstance.setValue({ borderRadius: value });
				formInstance.setValues(
					{ borderRadius: borderRadiusInstance },
					"",
					"style",
				);
			},
			onDraggerChange(evt, oldValue) {
				if (oldValue.split(" ").length !== 1) return;
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				borderRadiusInstance.setValue({ borderRadius: value });
				formInstance.setValues(
					{ borderRadius: borderRadiusInstance },
					"on",
					"style",
				);
			},
		},
		{
			name: "borderColor",
			label: "Border Color",
			value: borderInstance.getValue("borderColor"),
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				borderInstance.setValue({ borderColor: currValue });
				formInstance.setValues({ border: borderInstance }, "", "style");
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				borderInstance.setValue({ borderColor: hexValue });
				formInstance.setValues({ border: borderInstance }, "force", "style");
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				borderInstance.setValue({ borderColor: value });
				formInstance.setValues({ border: borderInstance }, "on", "style");
			},
		},
		{
			name: "strokeSize",
			label: "Stroke Size",
			value: textStrokeInstance.getValue("width"),
			isInvalid: textStrokeInstance.isInvalid("width"),
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "width");
				textStrokeInstance.setValue(payload);
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"on",
					"style",
				);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				textStrokeInstance.setValue({ width: value });
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"",
					"style",
				);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				textStrokeInstance.setValue({ width: value });
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"on",
					"style",
				);
			},
		},
		{
			name: "strokeColor",
			label: "Stroke Color",
			value: textStrokeInstance.getValue("color"),
			isInvalid: textStrokeInstance.isInvalid("color"),
			inputType: "color",
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio"],
			onColorInput(evt: Event, currValue) {
				textStrokeInstance.setValue({ color: currValue });
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"",
					"style",
				);
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				textStrokeInstance.setValue({ color: hexValue });
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"force",
					"style",
				);
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				textStrokeInstance.setValue({ color: value });
				formInstance.setValues(
					{ webkitTextStroke: textStrokeInstance },
					"on",
					"style",
				);
			},
		},
		{
			name: "textShadowSize",
			label: "Text Shadow",
			value: textShadowInstance.getValue("sizes"),
			isInvalid: textShadowInstance.isInvalid("sizes"),
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio"],
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				textShadowInstance.setValue(value);
				formInstance.setValues(
					{ textShadow: textShadowInstance },
					"on",
					"style",
				);
			},
		},
		{
			name: "textShadowColor",
			label: "Text Shadow Color",
			value: textShadowInstance.getValue("color"),
			isInvalid: textShadowInstance.isInvalid("color"),
			inputType: "color",
			includeForTags: TEXT_TAGS,
			excludeFromTags: ["img", "video", "audio"],
			onColorInput(evt: Event, currValue) {
				textShadowInstance.setValue({ color: currValue });
				formInstance.setValues({ textShadow: textShadowInstance }, "", "style");
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				textShadowInstance.setValue({ color: hexValue });
				formInstance.setValues(
					{ textShadow: textShadowInstance },
					"force",
					"style",
				);
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				textShadowInstance.setValue({ color: value });
				formInstance.setValues(
					{ textShadow: textShadowInstance },
					"on",
					"style",
				);
			},
		},
		{
			name: "boxShadowSize",
			label: "Box Shadow",
			value: boxShadowInstance.getValue("sizes"),
			excludeFromTags: TEXT_TAGS,
			isInvalid: boxShadowInstance.isInvalid("sizes"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				boxShadowInstance.setValue(value);
				formInstance.setValues({ boxShadow: boxShadowInstance }, "on", "style");
			},
		},
		{
			name: "boxShadowColor",
			label: "Box Shadow Color",
			value: boxShadowInstance.getValue("color"),
			isInvalid: boxShadowInstance.isInvalid("color"),
			excludeFromTags: TEXT_TAGS,
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				boxShadowInstance.setValue({ color: currValue });
				formInstance.setValues({ boxShadow: boxShadowInstance }, "", "style");
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				boxShadowInstance.setValue({ color: hexValue });
				formInstance.setValues(
					{ boxShadow: boxShadowInstance },
					"force",
					"style",
				);
			},
			onSelectEyeDropper(value: string, key?: keyof IElementInterface) {
				boxShadowInstance.setValue({ color: value });
				formInstance.setValues({ boxShadow: boxShadowInstance }, "on", "style");
			},
		},
		{
			name: "zIndex",
			label: "Z-index",
			value: formInstance.getValue("zIndex"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				formInstance.setValues({ zIndex: value }, "on", "style");
			},
			onDraggerInput(evt, oldValue) {
				const value = `${String(evt.incVal)}`;
				formInstance.setValues({ zIndex: value }, "", "style");
			},
			onDraggerChange(evt, oldValue) {
				const value = `${String(evt.incVal)}`;
				formInstance.setValues({ zIndex: value }, "on", "style");
			},
		},
	]);
};
