<template lang="pug">
.switch-wrapper.pa-1
	.label(v-if="label", @click="$emit('update:modelValue', !modelValue)").
		{{ label }}
	label.switch
		input(:checked="modelValue", type="checkbox", @change="emitChange($event)")
		span.slider.round
</template>

<script lang="ts">
export default {
	name: "SimpleSwitch",
	props: {
		label: {
			type: String,
			required: false,
			default: "",
		},
		modelValue: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		function emitChange(e: any) {
			emit("update:modelValue", e.target.checked);
		}

		return {
			emitChange,
		};
	},
};
</script>

<style lang="scss" scoped>
.switch-wrapper {
	background: transparent;
	transition: all 0.3s ease;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;

	.label {
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		color: $text-color;
	}
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	inset: 0;
	background-color: #3d4144;
	transition: 0.3s;
}

.slider::before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	transition: 0.3s;
}

input:checked + .slider {
	background-color: var(--app-accent-color);
}

input:focus + .slider {
	box-shadow: none;
}

input:checked + .slider::before {
	transform: translateX(12px);
	background-color: #3d4144;
}

.switch-wrapper:hover {
	.slider {
		border-radius: 6px;
		transition-duration: 0.3s;
	}

	input:checked ~ .slider {
		background-color: var(--app-accent-color);
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round::before {
	border-radius: 50%;
}
</style>
