// import cloneDeep from "lodash.clonedeep";
import { defineStore } from "pinia";
import type {
	IAssetGetItem,
	ISaveInstance,
	ISavePayload,
} from "./definition/globalTypes";
import { DomElementInstance, type IDomElementInterface } from "./layer";
import { type ISnapshotSteps, usePageStore, PageInstance } from "./page";
import { apiLogicIndex } from "./api/apiLogicIndex";
import { useToastStore } from "./toast";
import { $t } from "@/i18n";

const getElements = (elements: IDomElementInterface[]) => {
	return (
		elements?.map((item) => {
			const newInst = new DomElementInstance(item);
			return newInst;
		}) || []
	);
};
export function jsonToObject(str: string): ISaveInstance {
	try {
		const parsedObj: ISavePayload = JSON.parse(str);
		if (parsedObj) {
			return {
				id: parsedObj.id,
				meta: parsedObj.meta || {},
				breakpoint: parsedObj.breakpoint || {},
				elements: getElements(parsedObj.elements),
				tabMeta: parsedObj.tabMeta,
			};
		} else {
			console.warn("Empty json snapshot");
		}
	} catch {
		console.error("Invalid json snapshot", str);
	}
	return PageInstance.generateDefaultPage();
}

const updatePageData = (
	pageInstance: PageInstance,
	pageParams: ISavePayload,
) => {
	pageInstance.id = pageParams.id;
	pageInstance.updateCanvasGlobalOptions?.(pageParams.meta);

	const definition = pageParams.breakpoint.definition;
	if (definition?.length) {
		pageInstance.updateBreakpointTab?.(definition, false, true);
		pageInstance.canvas.breakpoint.active = pageParams.breakpoint.active;
	} else {
		console.log("[Error] No breakpoint definition");
	}

	pageInstance.syncInstanceListFromSnapshot?.(pageParams.elements);
	pageInstance.changePageId?.(pageParams.id);
	pageParams.tabMeta && (pageInstance.tabMeta = pageParams.tabMeta);

	// Update snapshot data directly from page instance (to get realistic data)
	const cleanSnapshotStateStringified = JSON.stringify(
		pageInstance.toPlainPageData(),
	);
	pageInstance.snapshotsData = [cleanSnapshotStateStringified];
	// pageInstance.snapshotsData = stringSnapshot;
};

export const useSnapshotStore = defineStore("snapshot", () => {
	const pageStore = usePageStore();
	const currentPageInstance = computed(() => pageStore.getCurrentPage);

	const getSnapshotSteps = computed<ISnapshotSteps>(() => {
		if (currentPageInstance.value) {
			return currentPageInstance.value.snapshotStep;
		}
		return {
			savedStep: 0,
			undoStep: 0,
		};
	});
	const isCanvasDirty = computed(
		() =>
			currentPageInstance.value &&
			Boolean(currentPageInstance.value.isPageDirty()),
	);
	const isUndoAvailable = computed<boolean>(
		() => getSnapshotSteps.value.undoStep > 0,
	);
	const isRedoAvailable = computed<boolean>(() => {
		if (currentPageInstance.value) {
			const snapLen = currentPageInstance.value.snapshotsData.length || 0;
			if (snapLen) {
				return getSnapshotSteps.value.undoStep < snapLen - 1;
			}
		}
		return false;
	});

	function undoCanvas(step = -1) {
		if (isUndoAvailable.value) {
			const stepPoint = getSnapshotSteps.value.undoStep + step;
			usePageStore().getCurrentPage?.changeSnapshotToPoint(stepPoint);
		}
	}
	function redoCanvas(step = 1, isSyncCanvas = true): number {
		if (isRedoAvailable.value) {
			const stepPoint = getSnapshotSteps.value.undoStep + step;
			usePageStore().getCurrentPage?.changeSnapshotToPoint(
				stepPoint,
				isSyncCanvas,
			);
			return step;
		}
		return -1;
	}
	function updateSavedStep() {
		console.log(
			"> 💾 Saving new snaphost step",
			getSnapshotSteps.value.undoStep,
		);
		getSnapshotSteps.value.savedStep = getSnapshotSteps.value.undoStep;
		usePageStore().resetPageDirtyFlag();
	}
	function addUndoStack() {
		if (currentPageInstance.value) {
			// console.warn(
			// 	`> 🛒 Adding undo stack: ${getSnapshotSteps.value.undoStep + 1}`,
			// );

			// NOTE: Don't use -> Debounce adds jitter to the elements, not really needed
			const payload = currentPageInstance.value.toPlainPageData();
			syncAppToSnapshot(payload);
		} else {
			console.warn("> 🛒 Aborting undo stack");
		}
	}
	function syncAppToSnapshot(payload: ISavePayload) {
		if (currentPageInstance.value) {
			// const clonedInstance = cloneDeep(instances);
			const jsonSnapshot = JSON.stringify(payload);
			const snapLen = currentPageInstance.value.snapshotsData.length || 0;
			const undoStep = getSnapshotSteps.value.undoStep;
			if (undoStep < snapLen - 1) {
				currentPageInstance.value.snapshotsData.splice(
					undoStep + 1,
					currentPageInstance.value.snapshotsData.length - undoStep,
				);
			}
			currentPageInstance.value.snapshotsData.push(jsonSnapshot);
			redoCanvas(1, false);
			// const redoStep = redoCanvas(1, false);
			// console.warn(`> 🛒 Syncing App to: ${undoStep} - Redo step ${redoStep}`);
		} else {
			console.warn("> 🛒 Aborting sync app to snapshot");
		}
	}
	function serializeSnapshots(): string {
		if (!currentPageInstance.value) return "";
		return JSON.stringify(currentPageInstance.value.snapshotsData);
	}
	const getPageInstance = (id: string) => {
		const pageIns = pageStore.getPageFromId(id);
		return pageIns || null;
	};
	async function initSnapshot(payload: ISavePayload | ISavePayload[]) {
		let pages: ISavePayload[] = [];

		const getFilteredInstances = async (page: ISavePayload) => {
			let excludeItems: IAssetGetItem["ass_name"][] = [];
			const elements = page.elements || [];

			const fileNames = elements.map((instance) => {
				if (instance.payload && "ass_name" in instance.payload) {
					return instance.payload.ass_name || "";
				}
				return "";
			});

			if (fileNames.length) {
				try {
					excludeItems = (await apiLogicIndex.postCheckDelete(
						fileNames,
					)) as IAssetGetItem["ass_name"][];
					// useSnapshotStore().resetCanvas();
				} catch {
					useToastStore().openToastError($t("api.checkpointLoad.error"));
				}
			} else {
				console.log(`> 🛒 Page ${page.id} has no elements`, elements);
				// TODO 1 CHANGE THIS
				//   useSnapshotStore().resetCanvas();
			}

			return elements.filter((instance) => {
				const payload = instance.payload;
				if (payload?.ass_name) {
					// Exclude invalid names
					return !excludeItems.includes(payload.ass_name);
				}
				return true;
			});
		};

		if (!Array.isArray(payload) && payload.elements) {
			// Old - Single page save
			pages = [payload];
		} else if (Array.isArray(payload)) {
			// New - Multiple pages save
			pages = payload;
		} else {
			console.error("No pages", payload);
			pages = [];
		}

		for (const page of pages) {
			const elements = await getFilteredInstances(page);
			const pageParams: ISavePayload = {
				id: page.id,
				meta: page.meta,
				groups: page.groups,
				breakpoint: {
					active: page.breakpoint?.active || "",
					definition: page.breakpoint?.definition || [],
				},
				elements,
				tabMeta: page.tabMeta,
			};

			const pageInstance = getPageInstance(page.id);

			if (pageInstance) {
				// Merge page
				updatePageData(pageInstance, pageParams);
			} else {
				// Create page
				const pagePayload = pageStore.addNewPage(page.tabMeta?.label, page.id);
				if (pagePayload.data) {
					updatePageData(pagePayload.data, pageParams);
				} else {
					console.error(
						"Page instance not found",
						page.id,
						page,
						pageStore.getAllPages,
					);
				}
			}
		}
	}

	function restoreCanvas() {
		usePageStore().getCurrentPage?.changeSnapshotToPoint(0);
	}
	// function resetCanvas() {
	//   if (currentPageInstance.value) {
	//     currentPageInstance.value.resetSnapshotData(0);
	//   } else {
	//     console.warn("No current instance");
	//   }
	// }

	return {
		getSnapshotSteps,
		isCanvasDirty,
		isUndoAvailable,
		isRedoAvailable,
		undoCanvas,
		redoCanvas,
		updateSavedStep,
		addUndoStack,
		syncAppToSnapshot,
		serializeSnapshots,
		initSnapshot,
		restoreCanvas,
		// resetCanvas,
	};
});
