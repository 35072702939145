<script setup lang="ts">
// import { transl } from "@/i18n";

import { openLoginModal } from "@/helpers/modals";
import type { IGlobalModal } from "./ModernModal.vue";

const emit = defineEmits(["close-modal"]);

function onClickCancel() {
	emit("close-modal");
}

function onClickCard(type: "local" | "mars") {
	if (type === "local") {
		onClickCancel();
	} else if (type === "mars") {
		const config = {
			canCloseModal: false,
		} as Partial<IGlobalModal["config"]>;
		openLoginModal({ config });
	}
}

onMounted(() => {});
</script>

<template lang="pug">
.attributes-dialog
	.head
		.title
			div You can save you project in your web browser or you can store it in the cloud.
			//- div You can continue your work from anywhere if you store it on our cloud servers.
			div If you store your project on our cloud servers, you can continue your work from anywhere.
			div If you choose to store it locally, you can transfer it to the cloud at a later time
	.cards
		.card(@click.once="onClickCard('local')")
			.logo-wrap
				i-fa-hard-drive
			span Local Storage
		.card(@click.once="onClickCard('mars')")
			.logo-wrap
				img(
					src="@/assets/images/MARSLogoSVG.svg",
					alt="mars-logo",
					:draggable="false"
				)
			span Cloud Storage
</template>

<style lang="scss" scoped>
.attributes-dialog {
	display: flex;
	flex-direction: column;
	padding: 0 20px 20px;
	max-height: 90vh;
	overflow: auto;
	user-select: none;
	width: 90vw;
	height: auto;
	gap: 20px;

	.head {
		text-align: center;
	}

	.cards {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		.card {
			display: flex;
			flex-direction: column;
			width: 100%;
			border: 2px solid #494949;
			color: white;
			padding: 10px 20px;
			align-items: center;
			user-select: none;
			font-size: 15px;
			font-weight: 600;
			transition: background 0.3s ease;
			cursor: pointer;

			.logo-wrap {
				max-width: 100%;
				width: 100%;
				margin: auto;
				text-align: center;

				img {
					height: 20px;
					width: 100%;
					z-index: 0;
					opacity: 0.7;

					@media screen and (width >= 768px) {
						height: 30px;
					}
				}

				.faico {
					font-size: 28px;
				}
			}

			&:hover {
				background: rgba(255, 255, 255, 0.1);
			}

			&.active {
				background: rgba(255, 255, 255, 0.2);
				color: black;

				img {
					filter: invert(1) brightness(0);
				}
			}
		}
	}

	.action-bar {
		margin: auto 0 0 auto;
		padding: 5px 10px;
		display: flex;
		gap: 10px;
	}

	@media screen and (width >= 768px) {
		width: 700px;

		.cards {
			flex-wrap: nowrap;
		}
	}
}
</style>
