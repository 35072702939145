/* eslint-disable @typescript-eslint/unbound-method */
// This is used to process logic for integrated / standalone index APIs for object [store]

import { apiIndex as ai } from "./apiIndex";
import { apiStorageIndex as asi } from "./apiStorageIndex";
import { useSnapshotStore } from "../snapshot";
import type {
	ICheckpointSaveType,
	ISavePayload,
} from "../definition/globalTypes";
import { usePageStore } from "../page";
import { AppGlobal } from "@/global/appGlobal";

const isi = AppGlobal?.IS_INTEGRATED;
console.warn("Checking api logic:", isi); // This is false, runs before expand session
// TODO 3 Make a protocol for initial spinner loader (!!!)

// prettier-ignore
export const apiLogicIndex = {
  fetchAssets: isi ? ai.fetchAssets : asi.fetchAssets,
  fetchFonts: isi ? ai.fetchFonts : asi.fetchFonts,
  postUploadAppendAsset: isi ? ai.postUploadAppendAsset : asi.postUploadAppendAsset,
  removeAssets: isi ? ai.removeAssets : asi.removeAssets,
  fetchCheckpointData: isi ? ai.fetchCheckpointData : asi.fetchCheckpointData,
  // removeCheckpointData: isIntergrated ? apiIndex.removeCheckpointData : apiStorageIndex.removeCheckpointData,
  // putCheckpointState: isIntergrated ? apiIndex.putCheckpointState : apiStorageIndex.putCheckpointState,
  // fetchCheckpointStates: isIntergrated ? apiIndex.fetchCheckpointStates : apiStorageIndex.fetchCheckpointStates,
  createCheckpoint: isi ? ai.createCheckpoint : asi.createCheckpoint,
  postCheckDelete: isi ? ai.postCheckDelete : asi.postCheckDelete,
  postUploadBuild: isi ? ai.postUploadBuild : asi.postUploadBuild,
  getPages: isi ? ai.getPages : asi.getPages,
  postPages: isi ? ai.postPages : asi.postPages,
};

export const parseSavePayload = async (
	data: ICheckpointSaveType | null,
): Promise<boolean> => {
	const dataSave = data?.sav_save;
	if (!dataSave) {
		usePageStore().addNewPage();
		return false;
	}

	let pagesParsed = [] as ISavePayload[];
	if (Array.isArray(dataSave)) {
		pagesParsed = dataSave;
	} else if (dataSave && typeof dataSave === "object") {
		// Legacy code - Single page
		pagesParsed = [dataSave as ISavePayload];
	}

	await useSnapshotStore().initSnapshot(pagesParsed);
	return true;
};
