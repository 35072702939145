<script setup lang="ts">
// import { transl } from "@/i18n";
import { useDefaultStore } from "@/stores";
// import { useCanvasStore } from "@/stores/canvas";
import { useModalStore } from "@/stores/modal";
// import { useToastStore } from "@/stores/toast";
import { useLayerInstancesStore } from "@/stores/layer";
import * as monacoEditor from "monaco-editor/esm/vs/editor/editor.api";
import marsThemeDefinition from "@/components/theme/marsThemeDefinition";
import styles from "@/assets/css/style.module.scss";
// import { getLocalStorageReac } from "@/helpers/helpers";
import { appMenuBarList } from "@/components/topBar/appMenuBarActions";
import { commandKeyEvent } from "@/html-engine/modules/helper";
import { openAppStorageModal } from "@/helpers/modals";
import { InfoConsole } from "@/helpers/helpers";
import { useEventListener } from "@vueuse/core";
import type ModernModalVue from "@/components/modal/ModernModal.vue";
import { AppGlobal } from "@/global/appGlobal";

const store = useDefaultStore();
const modalStore = useModalStore();
// const canvasStore = useCanvasStore();
const layerStore = useLayerInstancesStore();
// const toastStore = useToastStore();

definePage({
	name: "HomePage",
});

const modernModalRef = ref<null | InstanceType<typeof ModernModalVue>>(null);
const canShowFirstHeader = computed(() => {
	return !AppGlobal.IS_SLIM;
});
const headerHeightComputed = computed(() => {
	if (canShowFirstHeader.value) {
		return `${parseInt(styles.firstNavBar)}px`;
		// return `${parseInt(firstNavBar) + parseInt(secondNavBar)}px`;
	}
	return styles.secondNavBar;
});
const isUiShown = computed(() => store.uiShow);

function importMonacoTheme() {
	monacoEditor.editor.defineTheme("mars-ui", marsThemeDefinition);
}

function onKeyUpEvent(evt: KeyboardEvent) {
	if (evt.code === "KeyM" && commandKeyEvent(evt)) {
		// NOFIX Can't use CTRL + n, because browser doesn't allow
		// + Maybe fixable with PWA
		evt.preventDefault();
		evt.stopPropagation();
		layerStore.clearAllInstances();
	}
}

async function loadInitialData() {
	const res = await store.setupLoadCheckpoint();
	if (!res) {
		openAppStorageModal();
	}
	// const value = getLocalStorageReac("syncBreakpoints", true);
	// if (value.value) {
	//   canvasStore.setSyncBreakpoints(value.value as boolean);
	// }
}

function onClickOverlay() {
	useDefaultStore().updateUiState({ shortcutDialog: false });
}

function initHomepageShortcuts() {
	const instance = new KeyboardShortcut("toggleKeyboardDialog", {
		callback: () =>
			store.updateUiState({
				shortcutDialog: !store.uiShow.shortcutDialog,
			}),
	});
	keyboardInstances.value.push(instance);
}

async function populateListOfFonts() {
	const permState = await navigator.permissions.query({
		// @ts-expect-error
		name: "local-fonts",
	});
	if ("queryLocalFonts" in window && permState.state === "granted") {
		// The Local Font Access API is supported
		try {
			const availableFonts = await window.queryLocalFonts();
			store.listOfAvailableFonts = availableFonts;
		} catch (err) {
			InfoConsole.e("Error listing fonts", err);
		}
	} else {
		await document.fonts.ready;
		// TODO 2 IMPROVE THIS
		// const fontAvailable = new Set();

		// for (const font of fontCheck.values()) {
		// 	if (document.fonts.check(`12px "${font}"`)) {
		// 		fontAvailable.add(font);
		// 	}
		// }
		// store.listOfAvailableFonts = [...fontAvailable.values()].map((item) => ({
		// 	fullName: item,
		// }));
	}
}

function onCloseModalComp() {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null, true);
	} else {
		console.error("No modal instance");
	}
}

useEventListener(document, "keyup", onKeyUpEvent);

onMounted(() => {
	useEventListener(
		document,
		"click",
		() => {
			void populateListOfFonts();
		},
		{ once: true },
	);
	void loadInitialData();
	importMonacoTheme();

	initHomepageShortcuts();
});

window.addEventListener("beforeunload", (evt) => {
	store.commitAppOptionsToStore();
});

useEventListener("transitionend", (evt) => {
	const el = evt.target as HTMLElement;
	if (el.dataset.name === "panel" && el.classList.contains("hidden-ui")) {
		el.style.visibility = "hidden";
	}
});
useEventListener("transitionstart", (evt) => {
	const el = evt.target as HTMLElement;
	if (el.dataset.name === "panel" && !el.classList.contains("hidden-ui")) {
		el.style.visibility = "visible";
	}
});
</script>

<template lang="pug">
#home-page
	template(v-if="!store.appCanvasLoading")
		.top-section(:class="{ 'hidden-ui': !isUiShown.top }")
			header-bar(
				v-if="canShowFirstHeader",
				:app-menu-bar-list="appMenuBarList",
				:can-show-first-header="canShowFirstHeader"
			)
			//- v-if="canShowFirstHeader",
			second-header-bar(
				data-name="panel",
				:can-show-first-header="canShowFirstHeader",
				:class="{ 'hidden-ui': !isUiShown.top }"
			)
		.content-section
			web-canvas
			//- .resolution-view-wrap
			.left-sidebar-fixed(
				data-name="panel",
				:class="{ 'hidden-ui': !isUiShown.left }"
			)
				left-sidebar
			.right-sidebar-fixed(
				data-name="panel",
				:class="{ 'hidden-ui': !isUiShown.right }"
			)
				right-sidebar
transition(name="fade")
	.overlay(v-if="isUiShown.shortcutDialog", @click="onClickOverlay")
.bottom-section(@click.stop)
	transition(name="fade-slide")
		keyboard-binding(
			v-if="isUiShown.shortcutDialog",
			@close-dialog="onClickOverlay"
		)
modern-modal(
	v-if="modalStore.isGlobalModalVisible",
	:key="modalStore.getModalData('comp')",
	ref="modernModalRef",
	:config="modalStore.getModalData('config', {})",
	:form-style="modalStore.getModalData('formStyle', {})",
	@close-modal="modalStore.isGlobalModalVisible = null"
)
	template(#content="{ slotProps }")
		component(
			:is="modalStore.getModalData('comp')",
			v-bind="slotProps",
			@close-modal="onCloseModalComp"
		)
upload-spinner(v-if="store.directUploadController")
</template>

<style lang="scss" scoped>
#home-page {
	--header-height: v-bind(headerHeightComputed);

	$top-header-height: var(--header-height);

	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	overflow: hidden;

	.top-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: $top-header-height;
		z-index: 1;
		user-select: none;

		& > * {
			transition: 0.3s height ease;
			height: 100%;
		}

		&.hidden-ui {
			& > * {
				overflow: hidden;
				height: 0;
				min-height: 0;
			}
		}
	}

	.content-section {
		display: grid;

		// grid-template: "a . b" 1fr / var(--left-calc-width) 1fr var(
		grid-template: "a . b" 100% / var(--left-calc-width) 1fr var(
				--right-calc-width
			);
		flex: 1;
		justify-content: space-between;
		width: 100%;
		overflow: hidden; // So the sidebars can be scrolled

		// align-items: center;
		// justify-content: center;

		.left-sidebar-fixed,
		.right-sidebar-fixed {
			height: 100%;
			display: flex;
			transition:
				left 0.1s ease,
				right 0.1s ease;
		}

		.left-sidebar-fixed {
			left: 0;
			grid-area: a;

			&.hidden-ui {
				left: calc(var(--left-calc-width) * -1);
			}
		}

		.right-sidebar-fixed {
			right: 0;
			grid-area: b;

			&.hidden-ui {
				right: calc(var(--left-calc-width) * -1);
			}
		}
	}

	// .resolution-view-wrap {
	//   margin-top: 5px;
	//   position: absolute;
	//   left: 50%;
	//   transform: translateX(-50%);
	//   mix-blend-mode: exclusion;
	// }

	// :deep() {
	//   .app-menu-bar-list {
	//     background-color: rgba($background-color, 0.95);
	//     backdrop-filter: blur(10px);
	//   }
	// }
}

.hidden-ui {
	pointer-events: none;
	user-select: none;
	touch-action: none;
}

.overlay {
	position: fixed;
	inset: 0;
	background: #0008;
	z-index: 10;
}

.bottom-section {
	width: 100%;
	background: #333e;
	backdrop-filter: blur(10px);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 11;

	@include fade-slide-enter-transition($directionX: false, $offset: 30);
}

@include fade-transition;
</style>
