<script setup lang="ts">
import { useCanvasStore } from "@/stores/canvas";
import {
	type TCssStyle,
	type IEditorPayloadTypeInterface,
	type TMonacoOptions,
} from "@/stores/definition/globalTypes";
import { usePageStore } from "@/stores/page";
import { useSnapshotStore } from "@/stores/snapshot";
import { kebabize } from "../../helpers/helpers";
import omit from "lodash.omit";
import { type TKeyValFormParams } from "./KeyValForm.vue";

const canvasStore = useCanvasStore();

defineProps<{
	title?: string;
}>();
const emit = defineEmits(["close-modal"]);

const isAnyValueChanged = ref(false);
const isRawData = ref(false);
const cssItems = ref<{ key: string; value: any }[]>([]);

const type = computed(() => "page");
const customCssObj = computed(() => {
	const css = useCanvasStore().getPageStyle;
	const excludeKeys: string[] = [];
	const filteredCss = omit(css, excludeKeys);
	const payload = {
		isShown: true,
		value: filteredCss,
	};
	const cssObj = payload || ({} as IEditorPayloadTypeInterface);
	const getValue = (cssObj: IEditorPayloadTypeInterface) => {
		const cssVal = cssObj.value;
		if (cssVal) {
			return typeof cssVal === "string"
				? cssVal
				: JSON.stringify(cssVal, null, 2);
		}
		return cssVal;
	};
	const value = getValue(cssObj);
	// TODO 5 CSS <> JSON transpiler

	return {
		value,
		isShown: value !== undefined,
	};
});

watch(isRawData, () => {
	if (!isRawData.value) {
		initKeyValForm();
	}
});

const monOptions = computed(
	() =>
		({
			automaticLayout: true,
			showUnused: true,
			mouseWheelZoom: true,
			wordWrap: "off",
			codeLens: false,
			dragAndDrop: true,
			lineNumbersMinChars: 5,
			contextmenu: true,
			wordBasedSuggestions: "off",
			tabCompletion: "off",
			quickSuggestions: true,
			readOnly: false,
			scrollBeyondLastLine: false,
			fontSize: 16,
			fontFamily: "Monospace", // Mono not recognized on Win
			fontLigatures: false,
			fontWeight: "400",
			mouseWheelScrollSensitivity: 3,
			hover: {
				enabled: false,
				sticky: false, // Causes editor error display bug
			},
			scrollbar: {
				useShadows: false,
				verticalHasArrows: false,
				horizontalHasArrows: false,
				vertical: "auto",
				horizontal: "auto",
				verticalScrollbarSize: 6,
				horizontalScrollbarSize: 9,
				arrowSize: 6,
			},
			guides: {
				bracketPairs: true,
			},
			bracketPairColorization: {
				enabled: true,
				independentColorPoolPerBracketType: false,
			},
		}) as TMonacoOptions,
);

function onCssChange(evt: any) {
	// TODO [3 Maybe read data directly from store instead of passing them to modal (so we dont lose reactivity)
	const value = evt.value;
	let domObj = {} as TCssStyle;
	try {
		domObj = JSON.parse(value);
	} catch {
		console.log("Invalid Value", value);
		return;
	}

	if (type.value === "global") {
		// Unused
		const currPage = usePageStore().getCurrentPage;
		if (currPage) {
			currPage.updateCanvasGlobalOptions(domObj);
		}
	} else if (type.value === "page") {
		canvasStore.updateCanvasPageOptions(domObj, true, false);
	} else {
		console.warn("Not implemented", value);
	}

	isAnyValueChanged.value = true;
}

function parseStoreValFormChanges() {
	const domObj = Object.fromEntries(
		cssItems.value
			.filter((cssItem) => Boolean(cssItem.key))
			.map((cssItem) => {
				return [cssItem.key, cssItem.value];
			}),
	);
	canvasStore.updateCanvasPageOptions(domObj, true, false);
}

function addNewRow() {
	cssItems.value.push({
		// __id: ModelInstance.createUniqueId(),
		// __pending: true,
		key: "",
		value: "",
	});
}

function removeItem(params: TKeyValFormParams) {
	cssItems.value.splice(params.index, 1);
	parseStoreValFormChanges();
	isAnyValueChanged.value = true;
}

async function onChangeKey(params: TKeyValFormParams) {
	const tempVal = params.value;
	params.item.key = tempVal;
	isAnyValueChanged.value = true;
	// delete item.__pending;

	if (tempVal) {
		const hasAnyBlankKey = cssItems.value.some((item) => !item.key);
		!hasAnyBlankKey && addNewRow();
	} else {
		removeItem({
			item: params.item,
			index: params.index,
			evt: params.evt,
			value: params.value,
		});
	}

	parseStoreValFormChanges();
}

async function onChangeValue(params: TKeyValFormParams) {
	const tempVal = params.value;
	params.item.value = tempVal;
	isAnyValueChanged.value = true;

	parseStoreValFormChanges();
}

function initKeyValForm() {
	cssItems.value = parseCssObjVal(customCssObj.value.value);
	addNewRow();
}

function parseCssObjVal(valueStr: string): { key: string; value: any }[] {
	try {
		const obj = JSON.parse(valueStr);
		return Object.keys(obj).map((key) => ({
			key,
			value: obj[key],
		}));
	} catch (err: any) {
		console.warn(err.message);

		return [];
	}
}
function isInvalidCss(data: { key: string; value: any }): boolean {
	if (data.key && data.value) {
		const kebabName = kebabize(data.key);
		return !CSS.supports(kebabName, data.value);
	}
	return false;
}

onMounted(() => {
	initKeyValForm();
});

onUnmounted(() => {
	if (isAnyValueChanged.value) {
		useSnapshotStore().addUndoStack();
	}
});
</script>

<template lang="pug">
.global-css(:class="{ 'is-raw': isRawData }")
	.view
		.column(v-if="isRawData")
			.editor-wrap
				monaco-editor(
					:value="customCssObj.value",
					:options="monOptions",
					language="json",
					@change-all="onCssChange($event)"
				)
		.column(v-else-if="!isRawData")
			key-val-form(
				v-for="(cssItem, index) in cssItems",
				:key="index",
				:item="cssItem",
				:index="index",
				:is-key-error="isInvalidCss(cssItem)",
				:is-val-error="isInvalidCss(cssItem)",
				@change-key="onChangeKey",
				@change-val="onChangeValue",
				@click-remove-item="removeItem"
			)
	.action-wrap
		simple-switch(v-model="isRawData", label="Show as JSON")
		.right-actions
			button(@click="emit('close-modal')")
				span Close
</template>

<style lang="scss" scoped>
.global-css {
	display: flex;
	flex-direction: column;
	gap: 10px;

	// width: 85vw;
	// height: 85vh;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0 20px 20px;
	user-select: none;
	transition: all 0.3s ease;

	.view {
		width: 100%;
		height: 100%;
		display: flex;

		// flex-basis: 33%;
		flex: 1;
		gap: 5px;

		.column {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 5px;
		}
	}

	.editor-wrap {
		display: flex;
		height: 100%;
		overflow: auto;

		> div {
			width: 100%;
			height: 100%;
			flex: 1;
		}
	}

	.action-wrap {
		display: flex;

		.right-actions {
			display: flex;
			margin-left: auto;
		}
	}

	.monaco-editor {
		width: 100%;
		height: 100%;
	}

	&.is-raw {
		min-width: 85vw;
		min-height: 85vh;
	}

	&:not(.is-raw) {
		min-width: 45vw;
		min-height: 45vh;
	}
}
</style>
