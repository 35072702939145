import { defineStore } from "pinia";
import type {
	IAssetGetItem,
	TBreakpointTypeList,
	TStyleInterface,
	TElementTags,
	TStyleObjType,
	TWrapperTags,
	ISnapshotCheckpoint,
	TObjEntries,
	TCssStyle,
	TStyleInstances,
} from "./definition/globalTypes";
import { useCanvasStore } from "./canvas";
import { useToastStore } from "./toast";
import { useSnapshotStore } from "./snapshot";
import { $t } from "@/i18n";
import loMerge from "lodash.merge";
import { sharableLinkProps } from "./definition/elements/sharableLinkProps";
import { type TSharedElementItemInterface } from "./definition/globalTypes";
import { contentEditableTags } from "@/helpers/maps";
import { InfoConsole, getCurrentDomain } from "@/helpers/helpers";
import omit from "lodash.omit";
import { DateInstance } from "@/assets/js/dateHelper";
import { extension_map } from "@/helpers/extension";
import { usePageStore } from "./page";
import { useDefaultStore } from ".";
import {
	CBorder,
	CBorderRadius,
	CBoxShadow,
	CTextShadow,
	CTextStroke,
} from "@/helpers/cssClasses";
import {
	ModelInstance,
	type TModelInstanceGroup,
	useGroupStore,
	GroupedInstance,
} from "./group";
import { AppGlobal } from "@/global/appGlobal";
import {
	PrivateElementInstance,
	type IPrivateKeyInterface,
	type TPrivateObjectPayload,
} from "./class/element/private";

export type TValidDomParentName = "app-canvas" | "app-page" | "";
export type TUndoCreating = "" | "on" | "force";
type TStyleUnit = "px" | "%" | "rm" | "rem" | "em" | "";

export interface IDomAttributes {
	[k: string]: undefined | string | boolean;
	href?: string;
	target?: string;
	autoplay?: boolean;
	controls?: boolean;
	muted?: boolean;
	loop?: boolean;
	type?: string;
}

export interface IDomElementInterface {
	id?: string; // Id has to be optional, only when generated instance
	tag: TElementTags;
	class?: string[];
	style?: TStyleInterface; // Maybe move styles into a new interface
	content?: {
		text?: string;
	};
	name?: string;
	attributes?: IDomAttributes;
	custom?: {
		html: string;
		js: string;
	};
	children?: IDomElementInterface[];
	payload?: AssetElementInstance | Partial<AssetElementInstance> | null; // Used to store asset data
	_private: Partial<TPrivateObjectPayload>;
}

interface IRecursiveInstance {
	el: DomElementInstance | null;
	parent: DomElementInstance | null;
}

export class DomElementInstance
	extends ModelInstance
	implements IDomElementInterface
{
	tag: TElementTags;
	class: string[] = [];
	content = {
		text: "",
	};

	name?: string;
	attributes: IDomElementInterface["attributes"] = {};
	custom = {
		html: "",
		js: "",
	};

	children?: DomElementInstance[] = [];
	payload: AssetElementInstance | null = null;

	constructor(domObj: IDomElementInterface) {
		// console.warn(`Creating element with ID: ${domObj.id}`, domObj);

		super(domObj.id);
		this.tag = domObj.tag;
		domObj.payload && (this.payload = new AssetElementInstance(domObj.payload));
		const omittedObj = omit(
			domObj,
			"id",
			"tag",
			"payload",
		) as Partial<IDomElementInterface>;

		if (omittedObj) {
			for (const key of Object.keys(omittedObj) as [
				keyof IDomElementInterface,
			]) {
				const value = omittedObj[key] as any;
				if (value !== undefined) {
					if (key === "children") {
						const children = value;
						if (children && Array.isArray(children)) {
							this[key] = children.map(
								(child) => new DomElementInstance(child),
							);
						}
					} else if (key === "_private") {
						this._private = new PrivateElementInstance(value);
					} else {
						if (key === "style") {
							const breakpointKeys = Object.keys(value);
							const completeStyles = {} as TStyleInterface;
							for (const breakpointKey of breakpointKeys) {
								completeStyles[breakpointKey] = new CStyleInstance(
									value[breakpointKey],
								);
							}

							this.style = completeStyles;
						} else {
							this[key] = value;
						}
					}
				}
			}
		}

		if (omittedObj.style) {
			const canvasStore = useCanvasStore();

			if (canvasStore.getSyncBreakpoints) {
				const keys = canvasStore.getAllCanvasTabs.map((el) => el.name);
				this._private.visibleOn = keys;
			} else {
				const keys = Object.keys(omittedObj.style) as TBreakpointTypeList[];
				this._private.visibleOn = keys;
			}
		}
	}

	updateElement(
		dataObj: Partial<Record<keyof DomElementInstance, any>>,
		isUpdateMerge = false,
		breakpointName?: string | null,
	): boolean {
		for (const key of Object.keys(dataObj) as [keyof IDomElementInterface]) {
			if (key === "style") {
				return this.updateStyle(dataObj.style, breakpointName, isUpdateMerge);
			} else if (key === "_private") {
				this.updatePrivate(dataObj._private);
			} else {
				if (isUpdateMerge) {
					if (typeof dataObj[key] === "object") {
						if (key === "payload") {
							if (this[key] instanceof AssetElementInstance) {
								this[key]?.updateAssetInstance(dataObj[key]);
							} else {
								console.error("Payload not inited properly");
								loMerge(this[key], dataObj[key]);
							}
						} else {
							loMerge(this[key], dataObj[key]);
						}
					} else {
						this[key] = dataObj[key];
					}
				} else {
					this[key] = dataObj[key];
				}
			}
		}
		return true;
	}

	// parameters: list of breakpoints, styles to assign to dose breakpoints.
	// checking for sync - if sync is enabled, styles are created for all breakpoints.
	static generateStyles(
		breakpointNames: string[],
		styles: TCssStyle,
		shouldCreateUndo = true,
	): TStyleInterface {
		const canvasStore = useCanvasStore();
		const res: TStyleInterface = {};

		const allBreakpoints = canvasStore.getSyncBreakpoints
			? canvasStore.getAllCanvasTabs.map((el) => el.name)
			: breakpointNames;
		for (const breakpointName of allBreakpoints) {
			res[breakpointName] = new CStyleInstance(styles);
		}

		if (shouldCreateUndo) {
			console.warn("Adding undo for genereting styles");
			useSnapshotStore().addUndoStack();
		}

		return res;
	}

	getCustomHtml(): string {
		return this.custom?.html || "";
	}

	getCustomJs(): string {
		return this.custom?.js || "";
	}

	// get payload(): AssetElementInstance | null {
	//   return this._payload;
	// }

	// set payload(value: AssetElementInstance | null) {
	//   this._payload = value;
	// }

	getLinkOptions(): IDomElementInterface["attributes"] {
		// const generateAssetLink = () => {
		//   const payload = this.payload;
		//   return payload?.hasLinkProp() ? payload.getAssetLink() : "";
		// };
		const hrefLink = this.attributes?.href;
		// const hrefLink = this.attributes?.href || generateAssetLink();
		if (hrefLink) {
			return {
				href: hrefLink,
				target:
					this.attributes && "target" in this.attributes
						? this.attributes.target
						: "_blank",
			};
		}
		return {
			href: "",
			target: "",
		};
	}

	getAssetType(type: string) {
		// StartsWith check
		return Boolean(this.payload?.ass_type.startsWith(type));
	}

	// getAssetContentType(type: string) {
	//   return Boolean(this.payload?.ass_content_type?.startsWith(type));
	// }

	getIsItemText() {
		return contentEditableTags.includes(this.tag);
	}

	getIsElementTransparent() {
		const as = this.getActiveStyle().toPlainCss;
		const isTransparent = !(
			as.background ||
			as.color ||
			as.backgroundColor ||
			as.backgroundImage
		);
		// Probably should expand in future
		const contentHasTags: TWrapperTags[] = ["div", "span", "p"];
		if (contentHasTags.includes(this.tag as TWrapperTags)) {
			if (this.payload?.ass_asset) {
				return false;
			}
			return isTransparent;
		}
		// Should always have some content
		return false;
	}

	static getStyleUnit(prop = "", fallback = "px" as TStyleUnit): TStyleUnit {
		return prop.includes("px")
			? "px"
			: prop.includes("%")
				? "%"
				: prop.includes("rm")
					? "rm"
					: prop.includes("rem")
						? "rem"
						: prop.includes("em")
							? "em"
							: fallback;
	}
	static calcStyleProps(
		operation: "+" | "-" | "*" | "/" | "%" | "**" = "+",
		...props: string[]
	): string {
		if (props.length === 1) return props[0];
		if (props.length === 0) {
			console.error("Invalid number of props", 0);
			return "";
		}

		const firstNum = parseInt(props[0]);
		// eslint-disable-next-line unicorn/no-array-reduce
		const calcOp = props.slice(1).reduce<number>((acc, item) => {
			if (operation === "+") return acc + parseInt(item, 10);
			if (operation === "-") return acc - parseInt(item, 10);
			if (operation === "*") return acc * parseInt(item, 10);
			if (operation === "/") return acc / parseInt(item, 10);
			if (operation === "%") return acc % parseInt(item, 10);
			if (operation === "**") return acc ** parseInt(item, 10);
			return 0;
		}, firstNum);
		return `${calcOp}${this.getStyleUnit(props[0], "")}`;
	}
}

export class AssetElementInstance implements Partial<IAssetGetItem> {
	hash_id: string;
	ass_id: number;
	ass_name: string;
	ass_type: string;
	ass_asset: string;
	ass_content_type: string | undefined;
	file: Blob | File | null | undefined; // Most likely unused
	link: string;

	constructor(domObj: Partial<AssetElementInstance>) {
		this.ass_id = domObj.ass_id || 0;
		this.ass_name = domObj.ass_name || "";
		this.hash_id = domObj.hash_id || this.createUniqueHash(this.ass_name); // Used for standalone APP
		this.ass_type = this.getTagFromExtension(this.ass_name) || "";
		this.ass_asset = domObj.ass_asset || "";
		this.ass_content_type = domObj.ass_content_type || undefined;
		this.link = domObj.link || "";
		if (domObj.file) {
			this.file = domObj.file;
		}
	}

	hasLinkProp(): boolean {
		return Boolean(this.link);
	}

	getAssetLink(link = this.link): string {
		if (link) {
			const domainLink = AppGlobal.IS_INTEGRATED
				? getCurrentDomain().origin
				: "";
			const generatedLink = domainLink ? `${domainLink}/${link}` : link;
			return generatedLink;
		}
		return "";
	}

	getAssetType(types: string[] = [], checkContentType = false) {
		if (checkContentType && this.ass_content_type) {
			return types.includes(this.ass_content_type);
		}
		return types.includes(this.ass_type);
	}

	getTagFromExtension(name: string): string {
		if (!name) {
			return "";
		}
		// ANTI PATTERN
		let file_name: string | RegExpMatchArray | null | string[] =
			name.match(/.+\..+$/);

		if (!file_name) {
			return "";
		}
		file_name = file_name[0].split(".");

		if (file_name) {
			const extension = file_name.at(-1)?.toLowerCase() || "";
			let res = extension_map.get(extension);
			if (!res) {
				res = "a";
			}
			return res;
		}
		return "a";
	}

	createUniqueHash(name: string) {
		if (!name) {
			return `a${ModelInstance.createUniqueId()}`;
		}

		let hash = 0;
		for (let i = 0; i < name.length; i++) {
			const char = name.codePointAt(i);
			hash = (hash << 5) - hash + (char || 0);
			hash = hash & hash; // Convert to 32bit integer
		}
		return `a${hash}`;
	}

	updateAssetInstance(domObj: Partial<IAssetGetItem>) {
		typeof domObj.ass_id === "number" && (this.ass_id = domObj.ass_id);
		if ("ass_name" in domObj) {
			this.ass_name = domObj.ass_name || "";
			this.hash_id = this.createUniqueHash(this.ass_name); // Used for standalone APP
			this.ass_type = this.getTagFromExtension(this.ass_name);
		}
		typeof domObj.ass_asset === "string" && (this.ass_asset = domObj.ass_asset);
		"ass_content_type" in domObj &&
			(this.ass_content_type = domObj.ass_content_type);
		typeof domObj.link === "string" && (this.link = domObj.link);
	}
}

export class CheckpointSaveInstance implements ISnapshotCheckpoint {
	sav_id: number | undefined;
	sav_date: string;
	sav_description: string | null = null;
	sav_hash: string;

	constructor(objData: any) {
		this.sav_id = objData.sav_id;
		this.sav_date = objData.sav_date;
		this.sav_description = objData.sav_description;
		this.sav_hash = objData.sav_hash;
	}

	getDateFormatted(format?: string): string {
		return this.sav_date
			? new DateInstance(this.sav_date).formatDate(format)
			: "";
	}
}

export class CStyleInstance {
	private form: TStyleObjType = { left: "0", top: "0" };

	constructor(plainCss: Partial<TCssStyle> = {}) {
		this.form = this.parseCss(plainCss);
	}

	parseCss(plainCss: Partial<TCssStyle>): TStyleObjType {
		let tempObj = {} as TStyleObjType;
		const excludeKeys = new Set<keyof TStyleObjType>([
			"borderWidth",
			"borderStyle",
			"borderColor",
		]);

		const entries = Object.entries(plainCss) as TObjEntries<TCssStyle>;
		for (const [cssKey, cssVal] of entries) {
			let keyDashRem: keyof TCssStyle = cssKey;
			const stringKey = String(cssKey);
			if (stringKey.startsWith("-")) {
				// Should remove -webkit & others
				keyDashRem = stringKey.slice(1) as keyof TCssStyle;
			}

			if (excludeKeys.has(cssKey) && "border" in plainCss) {
				// For keys added elsewhere
				continue;
			}

			switch (keyDashRem) {
				case "webkitTextStroke": {
					tempObj.webkitTextStroke = new CTextStroke(cssVal);

					break;
				}
				case "textShadow": {
					tempObj.textShadow = new CTextShadow(cssVal);

					break;
				}
				case "boxShadow": {
					tempObj.boxShadow = new CBoxShadow(cssVal);

					break;
				}
				case "border": {
					tempObj.border = new CBorder(cssVal);
					plainCss.borderWidth &&
						tempObj.border.setValue({
							borderWidth: plainCss.borderWidth,
						});
					plainCss.borderStyle &&
						tempObj.border.setValue({
							borderStyle: plainCss.borderStyle,
						});
					plainCss.borderColor &&
						tempObj.border.setValue({
							borderColor: plainCss.borderColor,
						});

					break;
				}
				case "borderRadius": {
					tempObj.borderRadius = new CBorderRadius(cssVal);

					break;
				}
				default: {
					if (typeof cssVal === "string" && typeof keyDashRem === "string") {
						tempObj[keyDashRem] = cssVal;
					} else if (typeof cssVal === "object") {
						// Legacy format -> when breakpoint is in another breakpoint | BUG
						if (entries.length === 1) {
							InfoConsole.l(`[Legacy 🐛] | Will try to parse '${cssKey}'`);
							tempObj = cssVal;
						} else {
							InfoConsole.l(`[Legacy 🐛] Mixed -> Ignoring '${cssKey}'`);
						}
					} else {
						console.error("UNHANDLED!", cssKey, cssVal);
					}
				}
			}
		}
		return tempObj;
	}

	get toForm(): TStyleObjType {
		return this.form;
	}

	get toPlainCss(): TCssStyle {
		const tempObj = {} as TCssStyle;
		for (const [formKey, formVal] of Object.entries(this.form) as [
			keyof TCssStyle,
			any,
		]) {
			const keyChange: keyof TStyleObjType = formKey;
			// if (keyChange.startsWith("webkit")) {
			// 	keyChange = `-${keyChange}`;
			// }

			if (typeof formVal === "string" && typeof keyChange === "string") {
				tempObj[keyChange] = formVal;
			} else {
				if ("toString" in formVal) {
					tempObj[keyChange] = formVal.toString();
				} else {
					console.warn("Unhandled key", keyChange);
				}
			}
		}

		return tempObj;
	}

	// eslint-disable-next-line accessor-pairs
	set setValue(payload: Partial<TStyleObjType>) {
		for (const [key, val] of Object.entries(payload)) {
			type TStringableStyle = Omit<TStyleObjType, keyof TStyleInstances>;
			if (typeof val === "string") {
				this.form[key as keyof TStringableStyle] = val;
			} else {
				this.form[key as keyof TStyleInstances] = val as any;
			}
		}
	}
}

function getParsedStyleForCentering(
	id: DomElementInstance["id"],
	action: "left" | "top" | undefined,
	referenceLeft: number,
	referenceTop: number,
) {
	const el = document.querySelector(`[data-id=${id}]`) as HTMLElement | null;
	if (!el) {
		console.error("No element for centering", id);
	}

	const widthHalf = (el?.offsetWidth || 0) / 2;
	const heightHalf = (el?.offsetHeight || 0) / 2;
	const unit = "px";
	const stylePayload = {
		left: `${referenceLeft - widthHalf}${unit}`,
		top: `${referenceTop - heightHalf}${unit}`,
	};

	if (action === "left") {
		return omit(stylePayload, "left");
	} else if (action === "top") {
		return omit(stylePayload, "top");
	}
	return stylePayload;
}

function getParsedStyleForEdges(
	id: DomElementInstance["id"],
	action: "left" | "top" | "right" | "bottom",
	referenceLeft: number,
	referenceTop: number,
	referenceRight = 0,
	referenceBottom = 0,
) {
	const el = document.querySelector(`[data-id=${id}]`) as HTMLElement | null;
	if (!el) {
		console.error("No element for edges", id);
	}

	const width = el?.offsetWidth || 0;
	const height = el?.offsetHeight || 0;

	const unit = "px";
	switch (action) {
		case "left": {
			return { left: `${referenceLeft}${unit}` };
		}
		case "top": {
			return { top: `${referenceTop}${unit}` };
		}
		case "right": {
			return { left: `${referenceRight - width}${unit}` };
		}
		case "bottom": {
			return { top: `${referenceBottom - height}${unit}` };
		}
		default: {
			console.error("Invalid parameters", action);

			return {};
		}
	}
}

function recursiveSearchInstances(
	instances: DomElementInstance[] = [],
	id: ModelInstance["id"],
	parent: DomElementInstance | null,
): IRecursiveInstance {
	for (const instance of instances) {
		if (instance.id === id) {
			return { el: instance, parent };
		}
		if (Array.isArray(instance.children)) {
			const recInst = recursiveSearchInstances(instance.children, id, instance);
			if (recInst.el) {
				return recInst;
			}
		}
	}
	return { el: null, parent: null };
}

function getRefInstanceSpacing(
	instances: TModelInstanceGroup[],
	key: "left" | "top",
): TModelInstanceGroup {
	let tempInstance = instances[0];
	for (let index = 1; index < instances.length; index++) {
		const currInstance = instances[index];
		const currInstanceStyle = currInstance.getActiveStyle().toPlainCss;
		const tempInstanceStyle = tempInstance.getActiveStyle().toPlainCss;

		if (parseInt(currInstanceStyle[key]) < parseInt(tempInstanceStyle[key])) {
			tempInstance = currInstance;
		}
	}
	return tempInstance;
}
function filterSortInstancesForSpacing(
	instances: TModelInstanceGroup[],
	refInstance: TModelInstanceGroup,
	key: "left" | "top",
): TModelInstanceGroup[] {
	// This should be stable as selInstances should always re-sort
	const tempInstances = instances
		.filter((instance) => instance.id !== refInstance.id)
		.sort((a, b) => {
			const aInstanceStyle = parseInt(a.getActiveStyle().toPlainCss[key]);
			const bInstanceStyle = parseInt(b.getActiveStyle().toPlainCss[key]);

			if (aInstanceStyle < bInstanceStyle) {
				return -1;
			} else if (aInstanceStyle > bInstanceStyle) {
				return 1;
			}
			return 0;
		});
	tempInstances.unshift(refInstance);
	return tempInstances;
}
const getSortedList = (
	first: number,
	second: number,
	list: DomElementInstance[],
) => {
	return list
		.filter((_, index) => {
			return index >= first && index <= second;
		})
		.map((instance) => instance.id);
};

export const useLayerInstancesStore = defineStore("layer", {
	state: () => {
		return {
			referenceInstanceForSpacingLeft: null as TModelInstanceGroup | null,
			referenceInstanceForSpacingTop: null as TModelInstanceGroup | null,
			tempLayerName: "",
		};
	},
	getters: {
		getTreeInstances() {
			return (
				order: "asc" | "dsc" = "asc",
				includeHidden = true,
			): DomElementInstance[] => {
				return useGroupStore()
					.getSortedModels(order, includeHidden)
					.filter(
						(model) => model instanceof DomElementInstance,
					) as DomElementInstance[];
			};
		},
		getFlatInstances(): DomElementInstance[] {
			const getInstances = (list: DomElementInstance[] = []) => {
				const tempList: DomElementInstance[] = [];
				for (const curr of list) {
					tempList.push(curr);
					if (Array.isArray(curr.children)) {
						tempList.push(...getInstances(curr.children));
					}
				}
				return tempList;
			};

			return getInstances(this.getTreeInstances());
		},
		getVisibleInstances(): DomElementInstance[] {
			return useGroupStore()
				.getSortedModels("asc", false)
				.filter(
					(model) => model instanceof DomElementInstance,
				) as DomElementInstance[];
		},
		getSelectedElementInstances(state): DomElementInstance[] | null {
			const currPage = usePageStore().getCurrentPage;
			const modSel = currPage?.groupSelected;
			if (!modSel) return null;
			const tempInstances: DomElementInstance[] = [];
			for (const id of modSel.toRawData()) {
				const recInst = this.getInstanceById(id);
				if (recInst.el) {
					tempInstances.push(recInst.el);
				}
			}
			return tempInstances;
		},
		getInstanceById() {
			return (id: string): IRecursiveInstance => {
				const recInst = recursiveSearchInstances(
					this.getTreeInstances(),
					id,
					null,
				);
				return recInst;
			};
		},
		isElementHovered() {
			return (id: string): boolean =>
				usePageStore().getCurrentPage?.modelsHovered?.includes(id) || false;
		},
		getAllHoveredModels() {
			return usePageStore().getCurrentPage?.modelsHovered;
		},
		isElementSelected() {
			// TODO -2 Improve selection on undo / redo history
			// TODO -2 Improve selection on dropping element onto another (to no select other els) groupStore.GroupSelection.set([])
			return (id: string): boolean =>
				!!usePageStore().getCurrentPage?.groupSelected?.elements.has(id);
		},
		getGroupSelected() {
			return usePageStore().getCurrentPage?.groupSelected;
		},
		// isElementMoving() {
		// 	return (id: string): boolean =>
		// 		!!usePageStore().getCurrentPage?.modelsMoving?.includes(id);
		// },
		// getAllMovingModels() {
		// 	return usePageStore().getCurrentPage?.modelsMoving;
		// },
		getSharableLinkDefinition(): TSharedElementItemInterface[] | undefined {
			const firstInstance = this.getSelectedElementInstances?.[0];
			if (firstInstance) {
				return sharableLinkProps(firstInstance);
			}
			return undefined;
		},
	},
	actions: {
		batchCreateInstances(
			domArr: IDomElementInterface[] = [],
			shouldCreateUndo = true,
		): DomElementInstance[] | null {
			const currPage = usePageStore().getCurrentPage;
			if (!currPage) {
				return null;
			}

			const tempInstances: DomElementInstance[] = [];
			for (const domObj of domArr) {
				const instance = new DomElementInstance(domObj);
				tempInstances.push(instance);
			}
			if (tempInstances.length) {
				currPage.elementsData.push(...tempInstances);
				shouldCreateUndo && useSnapshotStore().addUndoStack();
				return tempInstances;
			}
			return null;
		},
		updateModelRaw(
			domObj: Partial<
				Record<keyof DomElementInstance | keyof GroupedInstance, any>
			>,
			instance: DomElementInstance | GroupedInstance,
			shouldCreateUndo: TUndoCreating = "on",
			breakpointName?: string | null,
			isUpdateMerge = false,
		) {
			if (instance) {
				// console.error(">>> Updating instance", instance.id, domObj);

				// Also needs to update child elements
				const isUpdated =
					instance instanceof GroupedInstance
						? instance.updateGroup(
								domObj as GroupedInstance,
								isUpdateMerge,
								breakpointName,
							)
						: instance.updateElement(
								domObj as IDomElementInterface,
								isUpdateMerge,
								breakpointName,
							);
				if (
					(shouldCreateUndo === "on" && isUpdated) ||
					shouldCreateUndo === "force"
				) {
					useSnapshotStore().addUndoStack();
				}
				return instance;
			} else {
				console.error("Invalid instance >>", instance);
			}
			return null;
		},
		updateContentEditableInstance(
			instanceId: DomElementInstance["id"],
			value: string,
		) {
			const recInstance = this.getInstanceById(instanceId);
			if (recInstance.el) {
				// const encodedStr = (value && encodeURI(value)) || "";
				const encodedStr = value;
				const domObj = {
					// payload: { ass_asset: value && encodeURI(value) },
					attributes: { value: encodedStr },
				} as Partial<IDomElementInterface>;
				this.updateModelRaw(domObj, recInstance.el, "on", null, true);
			} else {
				console.warn("[CEdit] Instance not found", instanceId);
			}
		},
		hideInstances(
			ids: ModelInstance["id"][],
			isDisplayToast = false,
			shouldCreateUndo = true,
		): boolean {
			const currPage = usePageStore().getCurrentPage;
			if (!currPage) {
				return false;
			}

			const toastStore = useToastStore();
			const canvasStore = useCanvasStore();
			const hiddenIds = [];
			// TODO [4 Add/Change to toggleVisibilityInstances function

			if (canvasStore.getSyncBreakpoints) {
				// hide from all breakpoints
				// TEST: Needs testing
				const filterRecInstances = (list: DomElementInstance[]) => {
					const tempList: DomElementInstance[] = [];
					for (const curr of list) {
						const hasRemovalId = ids.includes(curr.id);
						if (hasRemovalId) {
							hiddenIds.push(curr.id);
						} else {
							if (Array.isArray(curr.children)) {
								const rChildren = filterRecInstances(curr.children);
								curr.children = rChildren;
							}

							tempList.push(curr);
						}
					}

					return tempList;
				};

				currPage.elementsData = filterRecInstances(this.getTreeInstances());
			} else {
				// hide from active breakpoint
				const foundInstances = this.getTreeInstances().filter((instance) =>
					ids.includes(instance.id),
				);
				for (const foundInstance of foundInstances) {
					const params = {
						visibleOn: Object.values(
							foundInstance.getPrivateOptions().visibleOn ?? {},
						).filter(
							(el) => el !== canvasStore.getActiveBreakpointObject?.name,
						),
					};
					foundInstance.updatePrivate(params as IPrivateKeyInterface);
					hiddenIds.push(foundInstance.id);
				}
			}

			currPage.groupSelected?.remove(ids);

			if (shouldCreateUndo) {
				useSnapshotStore().addUndoStack();
			}

			if (hiddenIds.length > 0) {
				isDisplayToast &&
					toastStore.openToastSuccess(
						$t("element.instance.hide.success", hiddenIds.length <= 1 ? 1 : 2),
					);
				return true;
			} else {
				isDisplayToast &&
					toastStore.openToastError(
						$t("element.instance.hide.error", hiddenIds.length <= 1 ? 1 : 2),
					);
				return false;
			}
		},
		removeInstances(
			ids: ModelInstance["id"][],
			isDisplayToast = false,
			shouldCreateUndo = true,
		): boolean {
			const currPage = usePageStore().getCurrentPage;
			if (!currPage) {
				return false;
			}

			const toastStore = useToastStore();
			const removedIds = [];

			const filterRecInstances = (list: DomElementInstance[]) => {
				const tempList: DomElementInstance[] = [];
				for (const curr of list) {
					const hasRemovalId = ids.includes(curr.id);
					if (hasRemovalId) {
						removedIds.push(curr.id);
					} else {
						if (Array.isArray(curr.children)) {
							const rChildren = filterRecInstances(curr.children);
							curr.children = rChildren;
						}

						tempList.push(curr);
					}
				}

				return tempList;
			};

			currPage.elementsData = filterRecInstances(this.getTreeInstances());
			currPage.groupSelected?.remove(ids);

			if (shouldCreateUndo) {
				useSnapshotStore().addUndoStack();
			}

			if (removedIds.length > 0) {
				isDisplayToast &&
					toastStore.openToastSuccess(
						$t(
							"element.instance.remove.success",
							removedIds.length <= 1 ? 1 : 2,
						),
					);
				return true;
			} else {
				isDisplayToast &&
					toastStore.openToastError(
						$t("element.instance.remove.error", removedIds.length <= 1 ? 1 : 2),
					);
				return false;
			}
		},
		/**
		 * @param order This is the order that will be injected to (REAL DATA) [Not what is rendered]
		 */
		updateOrder(id: DomElementInstance["id"], order: number | "last") {
			// TODO - 1 Only works for single levels, needs recursion

			const currentElData = usePageStore().getCurrentPage?.elementsData || [];
			const instanceIndex = currentElData.findIndex(
				(instance) => instance.id === id,
			);

			if (instanceIndex === -1) {
				InfoConsole.e("Element not found", id);
			} else {
				// If exists, remove it
				const removedEl = currentElData.splice(instanceIndex, 1)[0];
				if (!removedEl) {
					InfoConsole.w("Couldn't remove element", instanceIndex);
					return;
				}

				let orderToInject = -1;
				if (order === "last") {
					orderToInject = currentElData.length + 1;
				} else if (typeof order === "number") {
					orderToInject = order;
				} else {
					InfoConsole.e("Unknown order", order);
				}

				if (orderToInject === -1) {
					InfoConsole.e("Unknown generated order", orderToInject, order);
				} else {
					// Insert it into new order
					currentElData.splice(orderToInject, 0, removedEl);
				}
			}
		},
		clearAllInstances() {
			const currPage = usePageStore().getCurrentPage;
			if (!currPage) {
				return;
			}

			// NOTE: Doesn't prompt for saving because using undo stack
			currPage.groupSelected = undefined;
			currPage.elementsData = [];
			useCanvasStore().reInitSettings();
			useSnapshotStore().addUndoStack();
		},
		centerElementToReference(
			instanceArr: TModelInstanceGroup[] = [],
			action?: "left" | "top",
			isPageReference = true,
		) {
			let elements = [];
			const referenceStyle = {
				refLeft: 0,
				refTop: 0,
			};

			const canvasStore = useCanvasStore();
			if (isPageReference) {
				elements = instanceArr;
				const ab = canvasStore.getActiveBreakpointBodyInstance;
				if (!ab) {
					console.error("No active breakpoint body");
					return;
				}

				const pageWidth = parseInt(ab.getValue("width"), 10) / 2;
				const minHeight = parseInt(ab.getValue("minHeight"), 10) / 2;
				referenceStyle.refLeft = pageWidth;
				referenceStyle.refTop = minHeight;
			} else {
				const referenceInstance = instanceArr[0];
				elements = instanceArr.slice(1);

				const refElement = document.querySelector(
					`[data-id=${referenceInstance.id}]`,
				) as HTMLElement | null;
				if (!refElement) {
					console.warn("No reference element found");
					useToastStore().openToastError("Error aligning elements");

					return;
				}

				referenceStyle.refLeft =
					refElement.offsetLeft + refElement.offsetWidth / 2;
				referenceStyle.refTop =
					refElement.offsetTop + refElement.offsetHeight / 2;
			}

			console.log(
				`>>> Moving element to ${referenceStyle.refLeft} ${referenceStyle.refTop}`,
			);

			if (elements?.length) {
				for (let index = 0; index < elements.length; index++) {
					const instance = elements[index];
					this.updateModelRaw(
						{
							style: getParsedStyleForCentering(
								instance.id,
								action,
								referenceStyle.refLeft,
								referenceStyle.refTop,
							),
						},
						instance,
						index === elements.length - 1 ? "on" : "",
						canvasStore.getActiveBreakpointObject?.name,
						true,
					);
				}
			}
		},
		changeElementPositionToReference(
			instanceArr: TModelInstanceGroup[] = [],
			action: "left" | "top" | "right" | "bottom",
			isPageReference = true,
		) {
			let elements = [];
			const referenceStyle = {
				refLeft: 0,
				refTop: 0,
				refRight: 0,
				refBottom: 0,
			};

			const canvasStore = useCanvasStore();
			if (isPageReference) {
				elements = instanceArr;
				const ab = canvasStore.getActiveBreakpointBodyInstance;
				if (!ab) {
					console.error("No active breakpoint body");
					return;
				}

				const pageWidth = parseInt(ab.getValue("width"), 10);
				const minHeight = parseInt(ab.getValue("minHeight"), 10);
				referenceStyle.refLeft = 0;
				referenceStyle.refTop = 0;
				referenceStyle.refRight = pageWidth;
				referenceStyle.refBottom = minHeight;
			} else {
				const referenceInstance = instanceArr[0];
				elements = instanceArr.slice(1);

				const refElement = document.querySelector(
					`[data-id=${referenceInstance.id}]`,
				) as HTMLElement | null;
				if (!refElement) {
					console.warn("No reference element found");
					useToastStore().openToastError("Error aligning elements");

					return;
				}

				referenceStyle.refLeft = refElement.offsetLeft;
				referenceStyle.refTop = refElement.offsetTop;
				referenceStyle.refRight =
					refElement.offsetLeft + refElement.offsetWidth;
				referenceStyle.refBottom =
					refElement.offsetTop + refElement.offsetHeight;
			}

			console.log(
				`>>> Moving element to ${referenceStyle.refLeft} ${referenceStyle.refTop}
         ${referenceStyle.refRight} ${referenceStyle.refBottom}`,
			);

			if (elements?.length) {
				for (let index = 0; index < elements.length; index++) {
					const instance = elements[index];
					this.updateModelRaw(
						{
							style: getParsedStyleForEdges(
								instance.id,
								action,
								referenceStyle.refLeft,
								referenceStyle.refTop,
								referenceStyle.refRight,
								referenceStyle.refBottom,
							),
						},
						instance,
						index === elements.length - 1 ? "on" : "",
						canvasStore.getActiveBreakpointObject?.name,
						true,
					);
				}
			}
		},
		autoFitContent(instanceArr: DomElementInstance[] = []): boolean {
			const canvasStore = useCanvasStore();
			const activeBreakpointName = canvasStore.getActiveBreakpointObject?.name;
			let hasError = false;
			for (let index = 0; index < instanceArr.length; index++) {
				const instance = instanceArr[index];
				const id = instance.id;
				const el = document.querySelector(
					`[data-id=${id}]`,
				) as HTMLElement | null;
				if (el) {
					const newNode = el.cloneNode(true) as HTMLElement;
					newNode.style.width = "auto";
					newNode.style.height = "auto";
					const parentEl = document.querySelector("[data-name=app-page]");
					if (parentEl) {
						parentEl.append(newNode);
						const rect = newNode.getBoundingClientRect();
						if (!rect.height || !rect.width) {
							hasError = true;
							continue;
						}

						newNode.remove();

						const nWid = rect.width / useDefaultStore().getPageOptions.zoom;
						const nHei = rect.height / useDefaultStore().getPageOptions.zoom;
						const unit = "px";
						this.updateModelRaw(
							{
								style: {
									width: `${Math.ceil(nWid)}${unit}`,
									height: `${Math.ceil(nHei)}${unit}`,
								},
							},
							instance,
							index === instanceArr.length - 1 ? "on" : "",
							activeBreakpointName,
							true,
						);
					} else {
						console.error("Parent el [app-page] not found!!");
					}
				}
			}

			if (hasError) {
				return false;
			}
			return true;
		},
		createCanvasSpace(yCoordinate = 0, offset = 10) {
			const canvasStore = useCanvasStore();

			// Only works for increasing bottom of the page
			const instances = this.getSelectedElementInstances?.length
				? this.getSelectedElementInstances
				: this.getTreeInstances();

			for (const instance of instances) {
				const currentTop = instance.getActiveStyle().toPlainCss.top;
				const currentTopNum = parseInt(currentTop, 10);
				const unit = DomElementInstance.getStyleUnit(currentTop);
				const isInstanceValidForMove = this.getSelectedElementInstances?.length
					? true
					: yCoordinate < currentTopNum;
				if (isInstanceValidForMove) {
					const domObj = {
						style: {
							top: `${currentTopNum + offset}${unit}`,
						} as TStyleObjType,
					};
					this.updateModelRaw(
						domObj,
						instance,
						"",
						canvasStore.getActiveBreakpointObject?.name,
						true,
					);
				}
			}

			// Update page height [minHeight]
			const rawHeight =
				canvasStore.getPageStyle.height || canvasStore.getPageStyle.minHeight;
			const currentHeight = parseInt(rawHeight || "", 10);
			const unit = DomElementInstance.getStyleUnit(rawHeight);
			const height = `${currentHeight + offset}${unit}`;
			const payload = {
				minHeight: height,
				height,
			};
			canvasStore.updateCanvasPageOptions(payload, true);
		},
		getInstancesBetween(
			startId: ModelInstance["id"],
			endId: ModelInstance["id"],
			instanceList = [],
			sort = false,
		): ModelInstance["id"][] {
			const list = instanceList.length ? instanceList : this.getFlatInstances;
			const stIndex = list.findIndex((instanceId) => instanceId.id === startId);
			const endIndex = list.findIndex((instanceId) => instanceId.id === endId);

			if (stIndex !== -1 && endIndex !== -1) {
				if (sort) {
					const firstIndex = Math.min(stIndex, endIndex);
					const lastIndex = Math.max(stIndex, endIndex);
					return getSortedList(firstIndex, lastIndex, list);
				} else {
					return stIndex < endIndex
						? getSortedList(stIndex, endIndex, list)
						: getSortedList(
								list.length - 1 - stIndex,
								list.length - 1 - endIndex,
								[...list].reverse(),
							);
				}
			}

			return [];
		},
		moveElementsToFront(instanceArr: DomElementInstance[] = []) {
			// Reversed so the order stays correct
			const idsRev = instanceArr.map((instance) => instance.id).reverse();
			for (const id of idsRev) {
				this.updateOrder(id, "last");
			}

			useSnapshotStore().addUndoStack();

			// const updateOrderWrapper = (arr: DomElementInstance[]) => {
			// 	let orderIndex = 0;
			// 	for (let index = 0; index < arr.length; index++) {
			// 		const instance = arr[index];
			// 		if (ids.includes(instance.id)) {
			// 			continue;
			// 		}
			// 		this.updateOrder(`${orderIndex++}`, instance.id);
			// 	}

			// 	for (let index = 0; index < ids.length; index++) {
			// 		const id = ids[index];
			// 		this.updateOrder(`${orderIndex++}`, id);
			// 	}
			// };

			// for (const instanceId of ids) {
			// 	const instanceRec = this.getInstanceById(instanceId);
			// 	if (instanceRec.parent) {
			// 		// Child els
			// 		updateOrderWrapper(instanceRec.parent?.children || []);
			// 	} else {
			// 		updateOrderWrapper(this.getTreeInstances());
			// 	}
			// }
		},
		moveElementsToBack(instanceArr: DomElementInstance[] = []) {
			const ids = instanceArr.map((instance) => instance.id);
			for (const [index, instanceId] of ids.entries()) {
				this.updateOrder(instanceId, index);
			}

			useSnapshotStore().addUndoStack();

			// const updateOrderWrapper = (arr: DomElementInstance[]) => {
			// 	let orderIndex = 0;
			// 	const leftOverIds = [];
			// 	const instanceIds = arr.map((instance) => instance.id);
			// 	for (let index = 0; index < instanceIds.length; index++) {
			// 		const id = instanceIds[index];
			// 		if (ids.includes(id)) {
			// 			this.updateOrder(`${orderIndex++}`, id);
			// 		} else {
			// 			leftOverIds.push(id);
			// 		}
			// 	}

			// 	for (let index = 0; index < leftOverIds.length; index++) {
			// 		const id = leftOverIds[index];
			// 		this.updateOrder(`${orderIndex++}`, id);
			// 	}
			// };

			// for (const instanceId of ids) {
			// 	const instanceRec = this.getInstanceById(instanceId);
			// 	if (instanceRec.parent) {
			// 		// Child els
			// 		updateOrderWrapper(instanceRec.parent?.children || []);
			// 	} else {
			// 		updateOrderWrapper(this.getTreeInstances());
			// 	}
			// }
		},
		moveElementToAnotherEl(
			dragId: DomElementInstance["id"],
			dropId: DomElementInstance["id"],
		): boolean {
			if (dragId === dropId) {
				// Ignore same element
				return false;
			}
			const dragInstance = this.getInstanceById(dragId);
			const dropInstance = this.getInstanceById(dropId);
			if (dragInstance.el && dropInstance.el) {
				// console.warn(
				// 	"::: Element moved to child",
				// 	dragId,
				// 	dropId,
				// 	dragInstance,
				// );
				const instance = this.moveElementToElement(dragInstance, dropInstance);
				if (instance?.el) {
					// Multiple logic - Flex (Grid) - Everything else
					const dropStyles = dropInstance.el.getActiveStyle().toPlainCss;
					const flexGridDisplayOpt = [
						"flex",
						"inline-flex",
						"grid",
						"inline-grid",
					];

					const domObj = { style: {} as TCssStyle };
					if (flexGridDisplayOpt.includes(dropStyles.display)) {
						// TODO -1 Improve dropzone styles
						domObj.style.position = "static";
						domObj.style.left = "unset";
						domObj.style.top = "unset";
					} else {
						const dragStyles = dragInstance.el.getActiveStyle().toPlainCss;
						const newLeft = DomElementInstance.calcStyleProps(
							"-",
							dragStyles.left,
							dropStyles.left,
						);
						const newTop = DomElementInstance.calcStyleProps(
							"-",
							dragStyles.top,
							dropStyles.top,
						);
						domObj.style.position = "absolute";
						domObj.style.left = newLeft;
						domObj.style.top = newTop;
					}

					this.updateModelRaw(
						domObj,
						instance.el,
						"on",
						useCanvasStore().getActiveBreakpointObject?.name,
						true,
					);
					return true;
				}
			} else {
				console.error("No instances", dragInstance, dropInstance);
			}
			return false;
		},
		moveElementToOuterScope(dragId: DomElementInstance["id"]): boolean {
			const dragInstance = this.getInstanceById(dragId);
			if (!dragInstance.parent) {
				// Ignore already in parent
				return false;
			}

			if (dragInstance.el) {
				const instance = this.moveElementToElement(dragInstance);

				if (instance?.el && instance.parent) {
					const dropStyles = instance.parent?.getActiveStyle().toPlainCss;
					const dragStyles = dragInstance.el.getActiveStyle().toPlainCss;
					const newLeft = DomElementInstance.calcStyleProps(
						"+",
						dragStyles.left,
						dropStyles.left,
					);
					const newTop = DomElementInstance.calcStyleProps(
						"+",
						dragStyles.top,
						dropStyles.top,
					);

					const domObj = {
						style: {
							position: "absolute",
							left: newLeft,
							top: newTop,
						},
					};

					this.updateModelRaw(
						domObj,
						instance.el,
						"on",
						useCanvasStore().getActiveBreakpointObject?.name,
						true,
					);
					return true;
				}
			} else {
				console.error("No instances", dragInstance);
			}
			return false;
		},
		moveElementToElement(
			dragInstance: IRecursiveInstance,
			dropInstance?: IRecursiveInstance,
		) {
			if (dropInstance?.el) {
				if (!Array.isArray(dropInstance.el.children)) {
					dropInstance.el.children = [];
				}

				if (dragInstance.el) {
					if (dragInstance.parent?.children) {
						// Move element from one to another
						const index = dragInstance.parent.children.findIndex(
							(item) => item.id === (dragInstance.el as DomElementInstance).id,
						);
						if (index === -1) {
							console.error("Invalid IRecursiveInstance type", dragInstance);
						} else {
							const instance = dragInstance.parent.children?.splice(index, 1);
							dropInstance.el.children.push(instance[0]);
							console.log(">> Move element from one to another");
						}
					} else {
						// Move element from global to other
						const elements = usePageStore().getCurrentPage?.elementsData || [];
						const index = elements.findIndex(
							(item) => item.id === (dragInstance.el as DomElementInstance).id,
						);

						if (index === -1) {
							console.error("Invalid IRecursiveInstance type", dragInstance);
						} else {
							const instance = elements?.splice(index, 1);
							dropInstance.el.children.push(instance[0]);
							console.log(">> Move element from global to other");
						}
					}
				} else {
					console.error("No drag instance", dragInstance);
				}

				return dragInstance;
			} else if (dragInstance.el && dragInstance.parent?.children) {
				// Move to outer scope from inner one
				console.log(">> Move to outer scope from inner one");

				const index = dragInstance.parent.children.findIndex(
					(item) => item.id === (dragInstance.el as DomElementInstance).id,
				);
				const instance = dragInstance.parent.children?.splice(index, 1);
				const elements = usePageStore().getCurrentPage?.elementsData || [];
				elements.push(instance[0]);

				return dragInstance;
			} else {
				console.error("[MOVE EL] Not handled!", dragInstance, dropInstance);
			}
			return null;
		},
		getSpacingEls(
			instances: TModelInstanceGroup[],
			orientation: "horz" | "vert" = "horz",
		): string {
			let spaceBetween = "";
			if (orientation === "horz") {
				const refInstance = getRefInstanceSpacing(instances, "left");
				this.referenceInstanceForSpacingLeft = refInstance;
				const otherInstances = filterSortInstancesForSpacing(
					instances,
					refInstance,
					"left",
				);

				for (let index = 1; index < otherInstances.length; index++) {
					const prevEl = otherInstances[index - 1];
					const prevElStyle = prevEl.getActiveStyle().toPlainCss;
					const prevElCalc =
						parseInt(prevElStyle.left) + parseInt(prevElStyle.width || "0");
					const currEl = otherInstances[index];
					const currElStyle = currEl.getActiveStyle().toPlainCss;
					const range = String(parseInt(currElStyle.left) - prevElCalc);
					if (spaceBetween) {
						if (spaceBetween === range) {
							continue;
						} else {
							return "";
						}
					} else {
						spaceBetween = range;
					}
				}
				return spaceBetween;
			} else if (orientation === "vert") {
				const refInstance = getRefInstanceSpacing(instances, "top");
				this.referenceInstanceForSpacingTop = refInstance;
				const otherInstances = filterSortInstancesForSpacing(
					instances,
					refInstance,
					"top",
				);

				for (let index = 1; index < otherInstances.length; index++) {
					const prevEl = otherInstances[index - 1];
					const prevElStyle = prevEl.getActiveStyle().toPlainCss;
					const prevElCalc =
						parseInt(prevElStyle.top) + parseInt(prevElStyle.height || "0");
					const currEl = otherInstances[index];
					const currElStyle = currEl.getActiveStyle().toPlainCss;
					const range = String(parseInt(currElStyle.top) - prevElCalc);
					if (spaceBetween) {
						if (spaceBetween === range) {
							continue;
						} else {
							return "";
						}
					} else {
						spaceBetween = range;
					}
				}
				return spaceBetween;
			}

			return "";
		},
		getLowestSpacingRange(
			instances: TModelInstanceGroup[],
			orientation: "horz" | "vert" = "horz",
		): string {
			if (instances.length < 2) {
				console.error("Invalid number of instances", instances);
				return "";
			}

			if (orientation === "horz") {
				const leftCoors = instances.map((instance) =>
					parseInt(instance.getActiveStyle().toPlainCss.left),
				);
				const leftCoorsSort = leftCoors.sort((a, b) => a - b);
				const minVal = leftCoorsSort[1] - leftCoorsSort[0];
				return String(minVal);
			} else if (orientation === "vert") {
				const topCoors = instances.map((instance) =>
					parseInt(instance.getActiveStyle().toPlainCss.top),
				);
				const topCoorsSort = topCoors.sort((a, b) => a - b);
				const minVal = topCoorsSort[1] - topCoorsSort[0];
				return String(minVal);
			}

			return "";
		},
		createSpacingEls(
			instances: TModelInstanceGroup[],
			orientation: "horz" | "vert" = "horz",
			spaceVal: string,
			shouldCreateUndo = false,
		) {
			if (instances.length < 2) {
				console.error("Invalid number of instances", instances);
				return;
			}

			if (orientation === "horz") {
				const refInstance =
					this.referenceInstanceForSpacingLeft ||
					getRefInstanceSpacing(instances, "left");
				this.referenceInstanceForSpacingLeft = refInstance;
				const otherInstances = filterSortInstancesForSpacing(
					instances,
					refInstance,
					"left",
				);

				for (let index = 1; index < otherInstances.length; index++) {
					const prevEl = otherInstances[index - 1];
					const prevElStyle = prevEl.getActiveStyle().toPlainCss;
					const prevElCalc =
						parseInt(prevElStyle.left) + parseInt(prevElStyle.width || "0");
					const currEl = otherInstances[index];
					const unit = DomElementInstance.getStyleUnit(prevElStyle.left);
					const domObj = {
						style: {
							left: `${prevElCalc + parseInt(spaceVal)}${unit}`,
						},
					};

					this.updateModelRaw(
						domObj,
						currEl,
						"",
						useCanvasStore().getActiveBreakpointObject?.name,
						true,
					);
				}
			} else if (orientation === "vert") {
				const refInstance =
					this.referenceInstanceForSpacingTop ||
					getRefInstanceSpacing(instances, "top");
				this.referenceInstanceForSpacingTop = refInstance;
				const otherInstances = filterSortInstancesForSpacing(
					instances,
					refInstance,
					"top",
				);

				for (let index = 1; index < otherInstances.length; index++) {
					const prevEl = otherInstances[index - 1];
					const prevElStyle = prevEl.getActiveStyle().toPlainCss;
					const prevElCalc =
						parseInt(prevElStyle.top) + parseInt(prevElStyle.height || "0");
					const currEl = otherInstances[index];
					const unit = DomElementInstance.getStyleUnit(prevElStyle.top);
					const domObj = {
						style: {
							top: `${prevElCalc + parseInt(spaceVal)}${unit}`,
						},
					};

					this.updateModelRaw(
						domObj,
						currEl,
						"",
						useCanvasStore().getActiveBreakpointObject?.name,
						true,
					);
				}
			} else {
				console.error("No key provided");
				return;
			}

			if (shouldCreateUndo) {
				useSnapshotStore().addUndoStack();
			}
		},
		updateAndGroupEls(instances: DomElementInstance[], shouldGroup = true) {
			const groupName = ModelInstance.createUniqueId();
			for (let index = 0; index < instances.length; index++) {
				const instance = instances[index];
				const domObj = {
					_private: { groupName: shouldGroup ? groupName : "" },
				};
				this.updateModelRaw(
					domObj,
					instance,
					index === instances.length - 1 ? "on" : "",
					null,
					true,
				);
			}

			useGroupStore().modifyGroupEls(instances, shouldGroup);
		},
	},
});
