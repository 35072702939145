<script setup lang="ts">
import { $t } from "@/i18n";

defineProps<{
	index: number;
	item: any;
	isKeyError?: boolean;
	isValError?: boolean;
}>();
const emit = defineEmits([
	"change-key",
	"change-val",
	"click-value-enter",
	"click-remove-item",
]);

export type TKeyValFormParams = {
	evt: Event;
	item: any;
	index: number;
	value: string;
};

function onChangeKey(evt: Event, item: any, index: number) {
	const value = (evt.target as HTMLInputElement).value;
	emit("change-key", { evt, item, index, value });
}

function onChangeValue(evt: Event, item: any, index: number) {
	const value = (evt.target as HTMLInputElement).value;
	emit("change-val", { evt, item, index, value });
}

function onClickValueEnter() {
	emit("click-value-enter");
}

function removeItem(item: any, index: number) {
	emit("click-remove-item", { item, index });
}
</script>

<template lang="pug">
.group
	.input-holder.key
		.label(v-if="index === 0") Name
		.input-group
			input(
				:value="item.key",
				:class="{ error: isKeyError }",
				type="text",
				placeholder="Key",
				@change="onChangeKey($event, item, index)"
			)
	.input-holder.val
		.label(v-if="index === 0") Value
		.input-group
			input(
				:value="item.value",
				:class="{ error: isValError }",
				:type="item.type",
				placeholder="Value",
				@change="onChangeValue($event, item, index)",
				@keyup.enter="onClickValueEnter"
			)
	span.remove-wrap(:class="{ first: index === 0 }")
		i-fa-xmark.del(
			v-if="!item.__pending && item.key",
			:title="$t('dialog.removeItem')",
			@click.stop="removeItem(item, index)"
		)
</template>

<style lang="scss" scoped>
.group {
	display: flex;

	// justify-content: space-between;
	gap: 10px;
	align-items: center;

	// padding: 5px 10px;
	border: 1px solid transparent;
	user-select: none;
	outline: none;

	.input-holder {
		padding: 0;

		.input-group {
			padding: 0;
		}

		input {
			&.error {
				border-color: $error-color;
			}
		}

		&.key {
			.input-group {
				input {
					width: 160px;
				}
			}
		}

		&.val {
			width: 100%;
		}

		.label {
			margin-bottom: 5px;
		}
	}

	.date {
		min-width: 100px;
	}

	.hash {
		min-width: 80px;
		font-weight: bold;
		text-align: left;
	}

	.remove-wrap {
		$size: 12px;

		width: $size;
		min-width: $size;
		display: flex;
		align-items: center;

		.faico {
			opacity: 0;
			transition: all 0.1s ease;
			width: 100%;
		}

		&.first {
			align-self: flex-end;
			margin-bottom: 8px;
		}
	}

	.key {
		flex: 1;
	}

	// &:not(:hover) {
	//   .key {
	//     input:not(:focus) {
	//       border-color: transparent;
	//     }
	//   }
	// }

	&:hover {
		.remove-wrap {
			.faico {
				opacity: 1;
			}
		}
	}

	&:focus {
		border: 1px solid gray;
	}
}
</style>
