<script setup lang="ts">
import { parseErrors } from "@/helpers/helpers";
import { useToastStore } from "@/stores/toast";

const props = defineProps<{ urls: any }>();
const emit = defineEmits(["cancel", "logged-in", "is-progress-loading"]);

const form = reactive({
	email: "",
	pass: "",
});
const isProgressLoading = ref(false);

const isValidForm = computed(() => {
	return form.email && form.pass;
});

async function onLogin() {
	if (!isValidForm.value) {
		return;
	}

	const data = form;
	isProgressLoading.value = true;

	try {
		const res = await axios.post(props.urls.login, data);
		if (res.data) {
			// useToastStore().openToastSuccess("Success logging in");
			emit("logged-in", { res, data: res.data, form });
		} else {
			console.warn("Invalid login", res);
			useToastStore().openToastError("Invalid login");
		}
	} catch (err: any) {
		const errorMessage = parseErrors(err, "Error logging in");
		console.warn("Error", err.message, "--", errorMessage);
		useToastStore().openToastError(errorMessage);
	}
	isProgressLoading.value = false;
}
// function onCancel() {
//   emit("cancel");
// }
</script>

<template lang="pug">
loading-spinner(v-if="isProgressLoading")
.login-comp(v-else)
	.form
		.field.email
			.label Email
			input(v-model="form.email", placeholder="Email", @keydown.enter="onLogin")
		.field.pass
			.label Password
			input(
				v-model="form.pass",
				placeholder="Password",
				type="password",
				@keyup.enter="onLogin"
			)
			a.forgot-pass(
				href="https://admin.mars-server.net/auth/forgot",
				target="_blank"
			)
				| Forgot password?
	.action-bar
		.top
			.links
				.register
					span Not registered?
					a.register(
						href="https://admin.mars-server.net/auth/register",
						target="_blank"
					) Create an account
		.bottom
			button.login-btn(:disabled="!isValidForm", @click="onLogin")
				| Login
			//- button(@click="onCancel")
			//-   | Cancel
</template>

<style lang="scss" scoped>
.login-comp {
	display: flex;
	flex-direction: column;
	gap: 35px;
	padding: 10px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.field {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		input {
			min-width: 300px;
			width: 100%;
		}
	}

	.forgot-pass {
		align-self: flex-end;
		color: #949494;
		font-style: italic;

		// font-size: 13px;
	}

	.action-bar {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		flex-direction: column;

		.top,
		.bottom {
			display: flex;
			justify-content: center;
			gap: 5px;

			.login-btn {
				width: 110px;
			}
		}

		.links {
			display: flex;
			flex: 1;
			justify-content: center;
			gap: 5px;

			> * {
				display: flex;
				gap: 5px;
			}
		}
	}

	a {
		color: var(--app-accent-color);
	}
}
</style>
