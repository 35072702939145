export const mvbIsElementSnappable = computed(() => {
	return useDefaultStore().isCanvasSnappable;
});
// export const mvbSnapPointsPure = computed(() => {
// 	const currPage = pageStore.getCurrentPage;
// 	if (currPage) {
// 		const selItems = groupStore.getSelectedModels || [];
// 		const ids = selItems.map((instance) => instance.id) || [];
// 		const snappingPoints = currPage.snappingPoints(ids);
// 		return snappingPoints;
// 	}
// 	return { left: [], top: [] };
// });

// SNAP ELEMENTS
export const mvbElementGuidelines = ref<string[] | undefined>([]);
export const mvbElementSnapDirections = computed(() => {
	if (mvbIsDisplayGridGuidelines.value) {
		return {
			left: true,
			top: true,
			right: false,
			bottom: false,
			center: false,
			middle: false,
		};
	}

	return {
		left: true,
		top: true,
		right: true,
		bottom: true,
		center: true,
		middle: true,
	};
});

// SNAP POINTS
export const mvbHorizontalGuidelines = computed<any[]>(() => {
	// return snapPointsPure.value.top;
	if (mvbIsDisplayGridGuidelines.value) {
		return [];
	}

	const canvasStore = useCanvasStore();
	const endCoorY = parseInt(canvasStore.getPageStyle.minHeight || "0");
	return [
		{
			pos: 0,
			className: "red-line",
		},
		{
			pos: endCoorY,
			className: "red-line",
		},
	];
});
export const mvbVerticalGuidelines = computed<any[]>(() => {
	// console.warn("LEFt", snapPointsPure.value.left);
	// return snapPointsPure.value.left;
	if (mvbIsDisplayGridGuidelines.value) {
		return [];
	}

	const canvasStore = useCanvasStore();
	const endCoorX = parseInt(canvasStore.getPageStyle.width || "0");
	return [
		{
			pos: 0,
			className: "red-line",
		},
		{
			pos: endCoorX,
			className: "red-line",
		},
	];
});

// SNAP GRID
export const mvbIsDisplayGridGuidelines = computed<boolean>(() => {
	const store = useDefaultStore();
	return store.getPageOptions.gridLines;
});
export const mvbSnapGridWidth = computed(() => {
	const store = useDefaultStore();
	if (mvbIsDisplayGridGuidelines.value) {
		return store.getComputedGridSize;
	}
	return 0;
});
export const mvbSnapGridHeight = computed(() => {
	const store = useDefaultStore();
	if (mvbIsDisplayGridGuidelines.value) {
		return store.getComputedGridSize;
	}
	return 0;
});

// SNAP Other
export const mvbSnapThreshold = computed(() => {
	if (mvbIsDisplayGridGuidelines.value) {
		return mvbSnapGridWidth.value;
	}
	return 20; // Default is 5
});
export const mvbKeepRatio = computed<boolean>(() => {
	const store = useDefaultStore();
	return store.getGlobalOptions("aspect-ratio");
});
export const mvbZoomRatio = computed(() => {
	const store = useDefaultStore();
	return store.getPageOptions.zoom;
});
export const mvbSnapContainer = computed(() => "[data-name=app-page]");
