<script setup lang="ts">
import { AppGlobal } from "@/global/appGlobal";
import {
	generateCancelToken,
	generateFilesListDrop,
	handleErrorLog,
} from "@/helpers/helpers";
import { $t } from "@/i18n";
import { useDefaultStore } from "@/stores";
import { useToastStore } from "@/stores/toast";
import { type Ref } from "vue";

const store = useDefaultStore();
const toastStore = useToastStore();

const props = defineProps<{
	fileList: File[];
}>();

const emit = defineEmits(["close-modal"]);

const files = ref(props.fileList);
const isUploadingFiles = ref(false);
const listBox = ref<HTMLElement | null>(null);
const inProgressIndexes = reactive<any>({});

const abortSignal = ref<AbortController | null>(null);

async function uploadAssets(fileList: Ref<(File | string)[]>) {
	const callBackUploadProgress = (
		item: File | string,
		index: number,
		percentCompleted: number,
	) => {
		if (typeof item === "object") {
			inProgressIndexes[index as keyof typeof inProgressIndexes] =
				percentCompleted.toFixed(0);
			// (files.value[index] as any).__progress = percentCompleted;
		} else {
			console.warn("Unsupported file for upload", item);
		}
	};

	abortSignal.value = generateCancelToken().source;
	try {
		await store.postUploadAppendAsset(
			fileList.value,
			callBackUploadProgress,
			abortSignal.value,
		);
		toastStore.openToastSuccess($t("asset.successUpload"));
		// files.value = []; // Not needed
		emit("close-modal");
	} catch (err: unknown) {
		const res = handleErrorLog(err);
		if (res !== "axios-cancel") {
			toastStore.openToastError($t("asset.errorUpload"));
		}
	}
}

async function onClickUploadAssets() {
	if (files.value.length === 0) return;
	isUploadingFiles.value = true;
	await uploadAssets(files);
	isUploadingFiles.value = false;
}

function parseItemSize(item: File) {
	const sizeMb = item.size / 1024 / 1024;
	return `${sizeMb.toFixed(2)}MB`;
}

function getItemProgress(item: File | any, index: number) {
	const percent = inProgressIndexes[index];
	return percent ? `${percent}%` : "0%";

	// console.log(item.__progress ? `${item.__progress}%` : "");
	// return item.__progress ? `${item.__progress}%` : "";
}

function onClickCancelUpload() {
	abortSignal.value?.abort("Upload cancelled");
}

function onClickAddMoreAssets() {
	const input = document.createElement("input");
	input.type = "file";
	input.multiple = true;
	input.addEventListener("change", (_this) => {
		if (input.files?.length) {
			const fileList = Array.from(input.files);
			files.value.push(...fileList);
		}
	});
	input.click();
}

async function removeGroup(item: File, index: number) {
	files.value.splice(index, 1);
}

function onDropList(evt: DragEvent) {
	const payload = generateFilesListDrop(evt);
	files.value.push(...payload.fileList);
	listBox.value?.classList.remove("dragover");
}
function onDragOver(evt: DragEvent) {
	listBox.value?.classList.add("dragover");
}
function onDragLeave(evt: DragEvent) {
	listBox.value?.classList.remove("dragover");
}
</script>

<template lang="pug">
.upload-manage
	.list(
		ref="listBox",
		@drop.prevent="onDropList",
		@dragover.prevent="onDragOver",
		@dragleave.prevent="onDragLeave"
	)
		li(v-for="(item, index) in files", :key="index")
			span.title {{ item.name }}
			span.size {{ parseItemSize(item) }}
			span.progress(v-if="AppGlobal.IS_INTEGRATED") {{ getItemProgress(item, index) }}
			span.remove-wrap(
				v-if="!isUploadingFiles",
				:title="$t('dialog.removeItem')",
				@mousedown.stop
			)
				i-fa-xmark.del(@click.stop="removeGroup(item, index)")
	.actions
		button.btn2.comp-asset.upload(@click="onClickAddMoreAssets")
			span {{ $t("asset.uploadMore") }}
			img(src="@/assets/images/icons/attachment.svg", alt="Attach")
		button(:disabled="files.length === 0", @click="onClickUploadAssets")
			span {{ files.length === 1 ? $t("asset.upload") : $t("asset.upload", 2) }}
		button(v-if="isUploadingFiles", @click="onClickCancelUpload")
			span {{ $t("asset.cancelUpload") }}
//- .dropzone(v-if='')
</template>

<style lang="scss" scoped>
.upload-manage {
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-height: 500px;
	width: 90vw;
	height: 100%;
	overflow: hidden;
	padding: 0 20px 20px;
	user-select: none;

	@media screen and (width >= 768px) {
		min-width: 400px;
		width: 100%;
	}

	.list {
		flex: 1;
		border: 2px solid transparent;

		&.dragover {
			border: 2px dashed white;
		}

		li {
			display: flex;
			align-items: center;
			gap: 10px;
			list-style-type: none;
			height: 40px;
			border-bottom: 1px solid $border-color1;

			.title {
				width: 100%;
			}

			.size {
				margin-left: auto;
			}

			.progress {
				width: 35px;
			}

			.remove-wrap {
				.faico {
					opacity: 0;
					transition: all 0.1s ease;
					cursor: pointer;
				}
			}

			&:hover {
				.remove-wrap {
					.faico {
						opacity: 1;
					}
				}
			}
		}
	}

	.actions {
		margin-top: auto;
		margin-left: auto;
		display: flex;
		gap: 10px;
	}
}

.dropzone {
	// Not implemented / working
	position: fixed;
	left: 0;
	top: 0;
	width: 150vw;
	height: 150vh;
	transform: translate(-40%, -30%);
	background-color: red;
}
</style>
