import { defineStore } from "pinia";
import pcImage from "@/assets/images/pc.svg";
import tabletImage from "@/assets/images/tablet.svg";
import mobileImage from "@/assets/images/mobile.svg";
import { DomElementInstance, type IDomElementInterface } from "./layer";
import { useSnapshotStore } from "./snapshot";
import { useDefaultStore } from ".";
import { useToastStore } from "./toast";
import { $t } from "@/i18n";
import {
	type PageCanvasOptions,
	pageCanvasDefinition,
} from "./definition/canvas/index";
import type {
	TBreakpointItem,
	IBreakpointItemEdit,
	IFont,
	IMetaObj,
	TSharedElementItemInterface,
	TMouseActionType,
	TCssStyle,
	TBreakpointTypeList,
} from "./definition/globalTypes";
import { setLocalStorageReac } from "@/helpers/helpers";
// import router from "@/router";
import { PageInstance, usePageStore } from "./page";
import router from "@/router";
import { AppGlobal } from "@/global/appGlobal";

export interface ICanvasTab extends TBreakpointItem {
	image: any;
}

const breakpointImages = {
	desktop: pcImage,
	tablet: tabletImage,
	mobile: mobileImage,
};

export const useCanvasStore = defineStore("canvas", {
	state: () => ({
		isSyncBreakpoints: false,
		mouseAction: "" as TMouseActionType,
		zoomRange: [0.04, 60],
		pendingCopyObj: {
			elements: [] as DomElementInstance[],
			originBreakpoint: "",
		},
	}),
	getters: {
		getAllBreakpointsDb(): IBreakpointItemEdit[] {
			const pageStore = usePageStore();
			const currPage = pageStore.getCurrentPage;
			if (currPage) {
				return PageInstance.getBreakpointItemEdit(
					currPage.canvas.breakpoint.definition,
					currPage.canvas.breakpoint.active,
				);
			}
			return [];
		},
		getAllCanvasTabs(): ICanvasTab[] {
			const currPage = usePageStore().getCurrentPage;
			if (currPage) {
				return currPage.canvas.breakpoint.definition.map((tab) => ({
					...tab,
					image: breakpointImages[tab.type],
				}));
			}
			return [];
		},
		getActiveBreakpointObject(): ICanvasTab | null {
			return (
				this.getAllCanvasTabs.find(
					(tab) =>
						tab.name ===
						usePageStore().getCurrentPage?.canvas.breakpoint.active,
				) ||
				this.getAllCanvasTabs.find((tab) => tab.isInit) ||
				null
			);
		},
		getActiveBreakpointBodyInstance(): PageCanvasOptions | null {
			return this.getActiveBreakpointObject?.styles.body || null;
		},
		getActiveBreakpointBodyDefinition(): TSharedElementItemInterface[] {
			const instance = this.getActiveBreakpointBodyInstance;
			if (instance) {
				return pageCanvasDefinition(instance);
			}
			return [];
		},
		getActiveGlobalDefinition(): TSharedElementItemInterface[] | null {
			const currPage = usePageStore().getCurrentPage;
			return currPage ? currPage.globalCanvasDefinition() : null;
		},
		getBreakpointTab() {
			return (name: string) => {
				const currPage = usePageStore().getCurrentPage;
				return currPage?.getBreakpointTab(name);
			};
		},
		getSyncBreakpoints(state) {
			return state.isSyncBreakpoints;
		},
		getPageStyle(state): Partial<TCssStyle> {
			const pagePropsSimple =
				this.getActiveBreakpointBodyInstance?.getSimpleValues ||
				({} as Partial<TCssStyle>);

			return pagePropsSimple;
		},
		getCurrentMouseAction(state) {
			return state.mouseAction;
		},
		getContentEditableId(state): string | null {
			const currPage = usePageStore().getCurrentPage;
			if (currPage) {
				return currPage.canvas.element.editableFocus;
			}
			return null;
		},
		getMetaForm(): Partial<IMetaObj> {
			const currPage = usePageStore().getCurrentPage;
			return currPage ? currPage.getMetaForm() : {};
		},
	},
	actions: {
		async saveCanvas(isShowToast = true) {
			if (usePageStore().isAnyPageDirty) {
				// if (useSnapshotStore().isCanvasDirty) {

				const pagesData = usePageStore().getAllPages.map(
					// (page) => JSON.parse(page.currentSnapshotString)
					// Getter makes things reactive / brakes saving
					// (page) => page.parsedJsonPayload
					(page) => page.toPlainPageData(),
				);

				const payload = { sav_save: pagesData };
				const hashData = await useDefaultStore().createCheckpoint(payload);
				if (hashData) {
					if (AppGlobal.IS_INTEGRATED) {
						await router.replace({
							query: {
								...router.currentRoute.value.query,
								// sv: hashData,
							},
						});
					}
					useSnapshotStore().updateSavedStep();
					isShowToast &&
						useToastStore().openToastSuccess($t("api.save.success"));
				} else {
					isShowToast && useToastStore().openToastError($t("api.save.error"));
				}
			}
		},
		setSyncBreakpoints(payload: boolean) {
			this.isSyncBreakpoints = payload;
			setLocalStorageReac("syncBreakpoints", payload);
		},
		changeCanvasTab(tab: ICanvasTab, hasUndo = true) {
			// eslint-disable-next-line unicorn/consistent-function-scoping
			const updateBreakpoint = (newTabName: string, currentTabName: string) => {
				this.transferElementsBreakpoints(newTabName, currentTabName);
				// if (!isSuccess) {
				//   toastStore.openToastError(transl("app.breakpoint.error"));
				//   return false;
				// }
				return true;
			};

			const newTabName = tab.name;
			const isBreakpointInit = this.getBreakpointTab?.(tab.name)?.item.isInit;

			const currentTabName = this.getActiveBreakpointObject?.name;
			if (!currentTabName) {
				console.error("No current tab! | NEW TAB =>", tab);

				return;
			}

			if (isBreakpointInit) {
				updateBreakpoint(newTabName, currentTabName);
				if (hasUndo) {
					console.log("[NEW] Adding undo stack for changing pages");

					useSnapshotStore().addUndoStack();
				}
			} else {
				// TODO 5 TEST THIS, maybe updateBreakpoint is not needed || needs change?
				updateBreakpoint(newTabName, currentTabName);
				usePageStore().getCurrentPage?.updateBreakpointTab([
					{ name: newTabName, isInit: true },
				]);
			}
		},
		transferElementsBreakpoints(name: string, oldName: string) {
			const currPage = usePageStore().getCurrentPage;
			if (name && oldName && currPage) {
				const isAnyBreakpointTransfered = currPage.elementsData.some(
					(instance) => instance.transferBreakpointData(name, oldName),
				);
				currPage.canvas.breakpoint.active = name;
				return isAnyBreakpointTransfered;
			}

			return false;
		},
		changeMouseState(state: TMouseActionType) {
			this.mouseAction = state;
		},
		updateEditableInstanceId(val: DomElementInstance["id"] | null) {
			const currPage = usePageStore().getCurrentPage;
			if (currPage) {
				currPage.canvas.element.editableFocus = val;
			}

			if (!val) {
				// Reset focus to parent element
				window.getSelection()?.removeAllRanges();
				(document.activeElement as HTMLElement | null)?.blur();
			}
		},
		updateCanvasPageOptions(
			payload: Partial<TCssStyle>,
			addUndo = false,
			isUpdateMerge = true,
		) {
			const activeBodyInstance = this.getActiveBreakpointBodyInstance;
			if (!activeBodyInstance) {
				console.error("No active breakpoint body");
				return;
			}

			activeBodyInstance.setValues(payload, false, isUpdateMerge);

			if (addUndo) {
				useSnapshotStore().addUndoStack();
			}
		},
		addFontToGlobalOptions(font: IFont) {
			usePageStore().getCurrentPage?.canvas.globalCanvasOptions.addFont(font);
		},
		removeFontFromGlobalOptions(index: number) {
			usePageStore().getCurrentPage?.canvas.globalCanvasOptions.removeFont(
				index,
			);
		},
		setPendingCopyInstances(
			instances: DomElementInstance[] = [],
			originBreakpoint?: string,
		) {
			this.pendingCopyObj.elements = instances;
			this.pendingCopyObj.originBreakpoint = originBreakpoint || "";
		},
		clonePendingInstances(shouldSelect = false): boolean {
			const pendingEls = this.pendingCopyObj.elements;
			const destinationBr = this.getActiveBreakpointObject?.name;
			const originBr = this.pendingCopyObj
				.originBreakpoint as TBreakpointTypeList;
			const swapStyles =
				destinationBr && originBr && originBr !== destinationBr;
			if (pendingEls.length) {
				const pastedInstances = pendingEls.map((instance) => {
					const tempInstance =
						instance.getRawInstanceData<IDomElementInterface>();
					if (tempInstance.style && swapStyles) {
						tempInstance.style[destinationBr] = tempInstance.style[originBr];
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete tempInstance.style[originBr];
						tempInstance._private.visibleOn = [destinationBr];
						// TODO -2 Improve order of elements [Cloned should be below the original so dragging should work]
						// tempInstance.getPrivateOptions().order =
					}
					// console.warn(":: Pasting element [clone]");

					return new DomElementInstance(tempInstance);
				});

				// Create and select models
				const layerStore = useLayerInstancesStore();
				const groupStore = useGroupStore();
				const createdInstances =
					layerStore.batchCreateInstances(pastedInstances);
				if (shouldSelect && createdInstances) {
					const ids = createdInstances.map((instance) => instance.id);
					groupStore.GroupSelection.set(ids);
				}
				return true;
			}
			return false;
		},
		reInitSettings() {
			const currPage = usePageStore().getCurrentPage;
			currPage?.resetPageInstance();
		},
	},
});
