import { defineStore } from "pinia";
import { apiLogicIndex } from "./api/apiLogicIndex";
import type {
	IGroupDataItem,
	TFontReturnType,
	TLeftSidebarTab,
} from "./definition/globalTypes";
import {
	type AssetElementInstance,
	type CheckpointSaveInstance,
	useLayerInstancesStore,
} from "./layer";
import { useCanvasStore } from "./canvas";
import {
	elementDefinition,
	ElementOptionsInstance,
} from "./definition/elements/element";
import {
	globalOptions,
	pageViewProps,
	type IGlobalAppOptions,
	type TGlobalAppOptionKeys,
} from "./options/appOptions";
import loMerge from "lodash.merge";
import {
	generateCssTransform,
	getLocalStorageReac,
	setLocalStorageReac,
} from "@/helpers/helpers";
import router from "@/router";
import { useToastStore } from "./toast";
import { usePageStore } from "./page";
import { $t } from "@/i18n";
import { leftSidebarTabs } from "@/assets/js/sharedMaps";
import { AppGlobal } from "@/global/appGlobal";

type TEntityResponseApi = Record<"data" | "meta_info", any>;

export const useDefaultStore = defineStore("main", {
	state: () => ({
		appOptions: {
			global: globalOptions,
			page: pageViewProps,
		},
		uiShow: {
			top: true,
			left: true,
			right: true,
			shortcutDialog: false,
			rulers: false,
		},
		// localAuthedApp: {
		//   sid: "",
		//   userInfo: {},
		// },
		activeLeftSidebarTab: null as TLeftSidebarTab | null,
		listOfAvailableFonts: [] as TFontReturnType[] | null,
		directUploadController: null as AbortController | null,
		autoSave: import.meta.env.PROD
			? // eslint-disable-next-line no-unneeded-ternary
				AppGlobal.IS_INTEGRATED
				? false
				: false // TRUE
			: false, // TODO 1 Change to JSON schema
		gridSize: "20px",
		isCanvasSnappable: true,
		isDraggingCanvas: false,
		isSpinnerBlockActive: false,
		appCanvasLoading: true,
		assetsLoaded: AppGlobal.IS_INTEGRATED ? 20 : 2000,
		assetListData: {
			data: [] as AssetElementInstance[],
			meta_info: null,
		} as TEntityResponseApi,
		fontListData: {
			data: [],
			meta_info: null,
		} as TEntityResponseApi,
		checkpointPointsDb: {
			data: [] as CheckpointSaveInstance[],
			meta_info: null,
		} as TEntityResponseApi,
	}),
	getters: {
		getPropGroups: (): IGroupDataItem[] => {
			const currPage = usePageStore().getCurrentPage;
			if (!currPage) {
				return [];
			}

			const canvasStore = useCanvasStore();
			const groupStore = useGroupStore();
			const layerStore = useLayerInstancesStore();

			const allGroupData: IGroupDataItem[] = [
				{
					name: "app",
					label: $t("app.appProp"),
					custom_filter: "app",
					props: canvasStore.getActiveGlobalDefinition || undefined,
				},
				{
					name: "global",
					label: $t("app.globalProp"),
					custom_filter: "global",
					props: canvasStore.getActiveGlobalDefinition || undefined,
				},
				{
					name: "canvas",
					label: $t("app.canvasProp"),
					custom_filter: "canvas",
					props: canvasStore.getActiveBreakpointBodyDefinition,
				},
				// {
				//   name: "attribute",
				//   label: "Attribute Properties",
				//   custom_filter: "active_child",
				//   // props: canvasStore.pageCanvasDefinition,
				// },
				// {
				// 	name: "group",
				// 	label: $t("app.groupProp"),
				// 	custom_filter: "group",
				// },
				{
					name: "element",
					label: $t("app.elementProp"),
					custom_filter: "active_child",
				},
				{
					name: "link",
					label: $t("app.linkProp"),
					custom_filter: "active_child",
					props: layerStore.getSharableLinkDefinition,
				},
			];

			const selectedInstances = layerStore.getSelectedElementInstances;
			const groupedInstances = groupStore.getSelectedGroups;
			if (selectedInstances?.length) {
				const activeElementGroups = allGroupData.filter(
					(group) => group.custom_filter === "active_child",
				);

				const mapped = activeElementGroups.map((group) => {
					// This should add only sharable properties into the mix, discard others
					// <-- Maybe for future fix -->
					const firstInstance = selectedInstances[0];
					if (firstInstance) {
						if (group.props) {
							return group;
						}

						const definitionMap = elementDefinition(
							new ElementOptionsInstance(),
						).filter((element) => {
							if ("includeForTags" in element) {
								return element.includeForTags?.includes(firstInstance.tag);
							} else if ("excludeFromTags" in element) {
								return !element.excludeFromTags?.includes(firstInstance.tag);
							}
							return true;
						});

						return {
							...group,
							props: definitionMap,
						};
					} else {
						console.error(
							"[PROP GROUP] Instance not found",
							firstInstance,
							group,
							selectedInstances,
						);
					}

					return [];
				}) as IGroupDataItem[];

				return mapped;
			} else if (groupedInstances?.length) {
				return allGroupData.filter((group) => group.custom_filter === "group");
			} else if (selectedInstances?.length === 0) {
				return allGroupData.filter((group) => group.custom_filter === "canvas");
			} else {
				const globalKeys = new Set(["app", "global"]);
				const groupData = allGroupData.filter(
					(group) => group.custom_filter && globalKeys.has(group.custom_filter),
				);

				for (const group of groupData) {
					if (group.custom_filter === "app" && group.props) {
						group.props = group.props.filter(
							(prop) => prop.sidebarGroup === "app",
						);
					} else if (group.custom_filter === "global" && group.props) {
						group.props = group.props.filter(
							(prop) => prop.sidebarGroup !== "app",
						);
					}
				}

				return groupData;
			}
		},
		arePanelsHidden(state) {
			return !state.uiShow.left && !state.uiShow.right && !state.uiShow.top;
		},
		getComputedGridSize(state): number {
			return Math.round(parseFloat(state.gridSize) / this.getPageOptions.zoom);
		},
		// getAbsolutePath(state) {
		//   return !!state.config.absolutePath;
		// },
		getPageOptions(state): typeof pageViewProps {
			return state.appOptions.page;
		},
		getAppStyles() {
			const pageOptions: typeof pageViewProps = this.getPageOptions;
			return {
				// transformOrigin: `${pageOptions.transformOrigin.x} ${pageOptions.transformOrigin.y}px`,
				transform: generateCssTransform(
					`${pageOptions.translate.x}px`,
					`${pageOptions.translate.y}px`,
					pageOptions.zoom,
				),
			};
		},
		getGlobalOptions(state) {
			const layerStore = useLayerInstancesStore();
			const selectedInstances = layerStore.getSelectedElementInstances;
			const hasAnyElementAspectRatio = selectedInstances?.some(
				(instance) => instance.getPrivateOptions().isAspectRatio,
			);

			return (key?: TGlobalAppOptionKeys): any => {
				if (!key) {
					return state.appOptions.global;
				}

				switch (key) {
					case "aspect-ratio": {
						return (
							state.appOptions.global.element.isAspectRatio ||
							hasAnyElementAspectRatio
						);
					}
					default: {
						return state.appOptions.global[key];
					}
				}
			};
		},
		getFilteredAssets(): AssetElementInstance[] | null | undefined {
			const currentDataArr = this.assetListData.data;
			if (Array.isArray(currentDataArr) && currentDataArr.length) {
				return currentDataArr.slice(0, this.assetsLoaded);
			}
			return currentDataArr;
		},
	},
	actions: {
		updateUiState(
			payload?: Partial<Record<keyof typeof this.uiShow, boolean>>,
		) {
			if (payload) {
				Object.assign(this.uiShow, payload);
			} else {
				// Without payload for panels only
				const hidden = this.arePanelsHidden; // Don't change this as it's reactive
				this.uiShow.top = hidden;
				this.uiShow.left = hidden;
				this.uiShow.right = hidden;
			}
		},
		checkSesStorage(sidebarID: string): string {
			const sideWidth = getLocalStorageReac(sidebarID).value as string;
			const parsedWidth = parseInt(sideWidth, 10);
			if (sideWidth && !isNaN(parsedWidth)) {
				const sidebarMinimumWidth = 200;
				// console.log('Importing sidebar width from session')
				return parsedWidth < sidebarMinimumWidth ? "0px" : sideWidth;
			}
			return "";
		},
		// changeAbsolutePath(payload: boolean): void {
		//   this.config.absolutePath = payload;
		// },
		updateCanvasOptions(
			payload: Partial<typeof pageViewProps> = {},
			deepMerge = false,
		) {
			if (deepMerge) {
				loMerge(this.getPageOptions, payload);
			} else {
				Object.assign(this.getPageOptions, payload);
			}
		},
		updateGlobalAppOptions(
			payload: Partial<IGlobalAppOptions>,
			deepMerge = false,
		) {
			if (deepMerge) {
				this.appOptions.global = payload as IGlobalAppOptions;
			} else {
				loMerge(this.appOptions.global, payload);
			}
		},
		commitAppOptionsToStore() {
			setLocalStorageReac("appOptions", this.appOptions);
		},
		loadAppOptionsFromStore() {
			const value = getLocalStorageReac("appOptions", true);
			if (value.value) {
				loMerge(this.appOptions, value.value);
			}
		},
		changeSpinnerBlock(
			state = false,
			delayTime = 0,
		): ReturnType<typeof setTimeout> | null {
			if (delayTime) {
				const timeout = setTimeout(() => {
					this.isSpinnerBlockActive = state;
				}, 100);
				return timeout;
			} else {
				this.isSpinnerBlockActive = state;
			}
			return null;
		},
		async fetchSetupCheckpoint(timeout: NodeJS.Timeout | null) {
			const routeQuerySv = computed(() => router.currentRoute.value.query.sv);

			const checkpointPayload = {} as CheckpointSaveInstance;
			if (routeQuerySv.value) {
				checkpointPayload.sav_hash = routeQuerySv.value as string;
			}

			const res = await this.fetchCheckpointData(checkpointPayload);
			timeout && clearTimeout(timeout);
			return res;
		},
		async setupLoadCheckpoint() {
			const timeout = this.changeSpinnerBlock(true, 100);
			// Don't add watcher to fetch checkpoint on route change, use event system

			const res = await this.fetchSetupCheckpoint(timeout);
			this.loadAppOptionsFromStore();

			this.appCanvasLoading = false;
			this.changeSpinnerBlock(false);
			return res;
		},
		incrementAssetLoadedOffset() {
			if (this.assetsLoaded < (this.getFilteredAssets || []).length) {
				this.assetsLoaded += 10;
			}
		},
		updateCanvasResolution(val: string) {
			const currPage = usePageStore().getCurrentPage;
			const activeBreakpoint = useCanvasStore().getActiveBreakpointObject?.name;
			if (!currPage || !activeBreakpoint) {
				console.error("Update canvas error", currPage, activeBreakpoint);
				useToastStore().openToastError(
					"Error changing resolution with broken config",
				);

				return;
			}

			const splittedVals = val.split("x");
			const widthUnit = DomElementInstance.getStyleUnit(splittedVals[0]);
			const heightUnit = DomElementInstance.getStyleUnit(splittedVals[1]);
			const width = parseInt(splittedVals[0].trim(), 10);
			const height = parseInt(splittedVals[1].trim(), 10);
			if (isNaN(width) || isNaN(height)) {
				useToastStore().openToastError("Error during changing resolution");
				return;
			}

			// const activeBreakpoint = currPage.canvas.breakpoint.active; // Can cause problems if active is null
			currPage.updateBreakpointTab([
				{
					name: activeBreakpoint,
					styles: {
						body: {
							width: `${width}${widthUnit}`,
							minHeight: `${height}${heightUnit}`,
							height: `${height}${heightUnit}`,
						},
					},
				},
			]);
		},
		updateLeftTabSidebar() {
			const instances = useLayerInstancesStore().getTreeInstances();
			if (
				this.activeLeftSidebarTab?.name === "layers" &&
				instances.length === 0 &&
				leftSidebarTabs.value.length > 1
			) {
				this.activeLeftSidebarTab = leftSidebarTabs.value[1];
			}
		},
		...apiLogicIndex,
	},
});

export type TDefaultStore = ReturnType<typeof useDefaultStore>;
