import { type DomElementInstance } from "@/stores/layer";
import { handleUppercaseKeys } from "./helper";
import { AppGlobal } from "@/global/appGlobal";

function getRequiredAttribute(tag: string): string {
	switch (tag) {
		case "img":
		case "video":
		case "iframe":
		case "audio": {
			return "src";
		}
		case "a": {
			return "href";
		}
		default: {
			return "";
		}
	}
}

/**
 * Generates HTML for provided Element
 *
 * @param item DomElementInstance
 * @param findAttribute Optional, handles existance of required attribute for that element, if it doesn't exists it tries to find it in the payload of the item
 * @param noContent Optional signifies if element doesn't have DomElementInstance content
 * @returns Generated HTML
 */
function defaultToHtml(
	item: DomElementInstance,
	noContent = false,
): HTMLElement {
	const tag = item.tag === "button" ? "input" : item.tag;
	const res = document.createElement(tag);
	item.id && (res.dataset.id = item.id);
	const findAttribute = getRequiredAttribute(item.tag);
	let att_found = !findAttribute;
	let content = decodeURI(item.payload?.ass_asset || "") || null;
	let href_value = "";
	let a_target = "";
	if (item.tag === "button") {
		res.setAttribute("type", "button");
	}
	if (item.attributes) {
		for (const key of Object.keys(item.attributes) as [
			keyof typeof item.attributes,
		]) {
			const t_key = handleUppercaseKeys(key);
			if (findAttribute && t_key === findAttribute) {
				att_found = true;
			}
			if (key === "href" && item.tag !== "a") {
				href_value = item.attributes.href || "";
				continue;
			}
			if (key === "target" && item.attributes[key]) {
				a_target = item.attributes[key] || "";
			}
			res.setAttribute(t_key, String(item.attributes[key]) || "");
		}
	}

	if (findAttribute && !att_found) {
		const assetLink = item.payload?.getAssetLink?.();
		if (assetLink) {
			const linkOrName = AppGlobal.IS_INTEGRATED
				? assetLink
				: item.payload?.ass_name || "";
			res.setAttribute(findAttribute, linkOrName);
			content = content || item.payload?.ass_name || "";
		} else {
			console.warn(`${item.tag} tag with no ${findAttribute}`);
			console.warn(item);
		}
	}

	if (!noContent) {
		if (item.custom.html) {
			res.innerHTML = item.custom.html;
		} else if (
			item.attributes?.value &&
			typeof item.attributes.value === "string"
		) {
			res.innerText = item.attributes.value;
		} else if (!item.custom?.html) {
			res.innerText = content || "";
		} else if (item.payload?.ass_asset) {
			res.innerText = decodeURI(item.payload.ass_asset);
		} else {
			console.log(">> Not handled");
		}
	}

	let a_wrap = null;
	if (href_value) {
		a_wrap = document.createElement("a");
		a_wrap.setAttribute("href", href_value);
		a_wrap.append(res);
		if (a_target) {
			a_wrap.setAttribute("target", a_target);
		}
	}
	return a_wrap || res;
}

export { defaultToHtml };
