import type {
	IBreakpointItemEdit,
	TStyleInterface,
	IMetaObj,
	TMetaTags,
	IFont,
	TBuilderOptions,
	TCssStyle,
} from "@/stores/definition/globalTypes";
import { handleUppercaseKeys } from "./helper";
import { text } from "./image";
import type { IPrivateKeyInterface } from "@/stores/class/element/private";

interface IBreakpointItemMeta {
	id?: string;
	style: TStyleInterface;
	meta: IPrivateKeyInterface;
}

export class Meta {
	title: string;
	description?: string;
	tags?: TMetaTags[];
	breakpoints: IBreakpointItemEdit[];
	style: IBreakpointItemMeta[] = [];
	fonts?: IFont[];
	script: string[] = [];
	customHead = "";
	globalJs = "";
	options: Partial<TBuilderOptions>;

	constructor(
		params: IMetaObj,
		breakpoints: IBreakpointItemEdit[],
		options: Partial<TBuilderOptions>,
	) {
		this.title = params.title;
		this.description = params.description;
		this.tags = params.meta;
		this.fonts = params.fonts;
		this.breakpoints = breakpoints;
		this.customHead = params.customHead || "";
		this.globalJs = params.globalJs || "";
		this.options = options;
	}

	private handleBodyTags(body: Partial<TCssStyle>): string {
		const excludedKeys = new Set<keyof Partial<TCssStyle>>([
			"width",
			"minWidth",
		]);
		let res = "body {\n";
		for (const key of Object.keys(body) as [keyof Partial<TCssStyle>]) {
			if (excludedKeys.has(key)) {
				continue;
			}
			const t_key = handleUppercaseKeys(key);
			res += t_key + ":" + body[key] + ";\n";
		}
		res += "}\n";
		return res;
	}

	private metaTagToHtml(item: TMetaTags): HTMLElement {
		const res = document.createElement("meta");
		if (item.name) {
			res.setAttribute("name", item.name);
		} else {
			res.setAttribute("property", item.property || "");
		}
		res.setAttribute("content", item.content);
		return res;
	}
	toHtml(): HTMLElement {
		const head = document.createElement("head");
		const title = document.createElement("title");
		title.innerText = this.title;
		if (this.description) {
			const description = document.createElement("description");
			description.innerText = this.description;
			head.append(description);
		}
		if (this.tags) {
			for (const el of this.tags) {
				head.append(this.metaTagToHtml(el));
			}
		}

		if (this.options.link_pages) {
			const css_link = document.createElement("link");
			css_link.setAttribute("rel", "stylesheet");
			css_link.setAttribute("href", `./${this.options.name}.css`);
			head.append(css_link);
		}

		const charset = document.createElement("meta");
		// eslint-disable-next-line unicorn/text-encoding-identifier-case
		charset.setAttribute("charset", "utf-8");

		const viewport = document.createElement("meta");
		viewport.setAttribute("viewport", "width=device-width,initial-scale=1.0");

		const color_scheme = document.createElement("meta");
		color_scheme.setAttribute("color-scheme", "only light");

		head.append(charset, viewport, color_scheme);
		head.innerHTML += this.customHead;

		return head;
	}
	getStyleTag(): string {
		let res = "<style>";
		if (this.fonts) {
			for (const el of this.fonts) {
				res += `@import url(${el.link});`;
			}
		}
		const main_breakpoint = this.breakpoints[0];
		for (const breakpoint of this.breakpoints) {
			const isMainBrakepoint: boolean =
				main_breakpoint.name === breakpoint.name;
			if (!isMainBrakepoint) {
				const width = breakpoint.styles.body.width || "";
				res += `@media screen and (max-width: ${width}) {`;
			}
			res += this.handleBodyTags(breakpoint.styles.body);
			for (const item of this.style) {
				res += `[data-id="${item.id}"] {`;
				if (item.meta.visibleOn?.some((el: string) => el === breakpoint.name)) {
					if (!item.meta.visibleOn.includes(main_breakpoint.name)) {
						res += "display: block;";
					}
					const activeStyle = item.style[breakpoint.name]?.toPlainCss;
					// res += "z-index: " + item.meta.order + ";";
					if (activeStyle) {
						for (const key of Object.keys(activeStyle) as [keyof TCssStyle]) {
							const t_key = handleUppercaseKeys(key);
							res += `${t_key}: ${activeStyle[key]};`;
						}
					}
				} else {
					res += "display: none;";
				}
				res += "}";
			}
			if (!isMainBrakepoint) {
				res += "}";
			}
		}
		res += "</style>";
		return res;
	}
	getScriptTag(): string {
		let res = "<script>";
		for (const script of this.script) {
			res += script + "\n";
		}
		if (this.globalJs) {
			res += this.globalJs;
		}
		return (res += "</script>");
	}
	/**
	 *
	 * @param style
	 * @param meta
	 * @param id id of the {@link /elements/Element Element}
	 * @throws Error if id is non existant, this shouldn't happen ever, if you encounter this error then the id provided from the element is missing and the problem is somewhere else
	 * @returns
	 */
	addStyleItem(
		style: TStyleInterface,
		meta: IPrivateKeyInterface,
		id?: string,
	): this {
		if (!id) {
			/**  @see DomElementInterface */
			const stack = import.meta.env.VITE_APP_STACK_TRACE;
			if (stack && Number(stack) === 1) {
				console.log(text);
				throw new Error("Id hAs tO Be oPtIoNaL, oNlY WhEn gEnErAtEd iNsTaNcE");
			} else {
				throw new Error("No id for element");
			}
		}
		this.style.push({
			id,
			style,
			meta,
		});
		return this;
	}
	addScriptItem(script: string): void {
		this.script.push(script);
	}
}
