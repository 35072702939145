/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { $t } from "../../i18n";
import { useDefaultStore } from "@/stores";
import { useCanvasStore } from "@/stores/canvas";
import { useSnapshotStore } from "@/stores/snapshot";
import { useLayerInstancesStore } from "@/stores/layer";
import {
	openPageManageModal,
	// openPreviousSavesModal,
	openPreviewCanvasModal,
	openExportCanvasModal,
} from "@/helpers/modals";
import { type IAppMenuBarItem } from "@/components/shared/menuBar/AppMenuBar.vue";
// import router from "@/router";
import { calculateCenterTransform } from "@/helpers/helpers";
// import { usePageStore } from "@/stores/page";

export const appMenuBarList = computed<IAppMenuBarItem[]>(() => {
	const store = useDefaultStore();
	const canvasStore = useCanvasStore();
	const snapshotStore = useSnapshotStore();
	// const pageStore = usePageStore();
	const layerStore = useLayerInstancesStore();
	const ab = canvasStore.getActiveBreakpointBodyInstance;
	const overflowValue = ab?.getValue("overflow");
	const isSnappable = store.isCanvasSnappable;
	const gridSize = store.getComputedGridSize;
	const getTransl = (num: number) => $t("menuBar.zoom.zoomTo", [num]);
	const labelZoom25 = getTransl(25);
	const labelZoom50 = getTransl(50);
	const labelZoom100 = getTransl(100);
	const labelZoom150 = getTransl(150);
	const labelZoom200 = getTransl(200);
	const labelZoom300 = getTransl(300);
	const labelZoom500 = getTransl(500);
	const labelZoom1000 = getTransl(1000);
	const labelCenterPage = $t("menuBar.page.center");

	return [
		{
			label: $t("menuBar.file"),
			isExpand: true,
			children: [
				{
					id: "file-new",
					label: $t("menuBar.new"),
					onClick: () => openPageManageModal(),
				},
				{
					isBreakpoint: true,
				},
				{
					id: "file-save",
					label: $t("menuBar.save"),
					rightLabel: "Ctrl+S",
					isDisabled: !snapshotStore.isCanvasDirty,
					isHidden: store.autoSave,
					// isDisabled: !(snapshotStore.isCanvasDirty || pageStore.isAnyPageDirty),
					onClick: async () => canvasStore.saveCanvas(),
				},
				{
					id: "file-autosave",
					label: $t("menuBar.autosave"),
					isChecked: store.autoSave,
					onClick: () => (store.autoSave = !store.autoSave),
				},
				{
					id: "file-preview",
					label: $t("menuBar.preview"),
					rightLabel: "Ctrl+P",
					onClick: () => openPreviewCanvasModal(),
				},
				{
					id: "file-export",
					label: $t("menuBar.export"),
					rightLabel: "Ctrl+E",
					onClick: () => openExportCanvasModal(),
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-clear-instances",
					label: $t("menuBar.clearElements"),
					rightLabel: "Ctrl+M",
					onClick: () => layerStore.clearAllInstances(),
				},
				// {
				//   id: "app-view-saves",
				//   label: transl("menuBar.viewPreviousSaves"),
				//   rightLabel: "Ctrl+O",
				//   isHidden: !AppGlobal.IS_INTEGRATED,
				//   onClick: () => openPreviousSavesModal(),
				// },
				// {
				//   id: "app-goto-latest-checkpoint",
				//   label: transl("menuBar.gotoLatestCheckpoint"),
				//   rightLabel: "",
				//   onClick: async () => {
				//     await router.replace({
				//       query: {
				//         ...router.currentRoute.value.query,
				//         sv: undefined,
				//       },
				//     });
				//     useSnapshotStore().resetCanvas();
				//     useDefaultStore().setupLoadCheckpoint();
				//   },
				// },
			],
		},
		{
			label: $t("menuBar.edit"),
			isExpand: true,
			children: [
				{
					id: "app-undo",
					label: $t("menuBar.undo"),
					rightLabel: "Ctrl+Z",
					isDisabled: !snapshotStore.isUndoAvailable,
					onClick: () => snapshotStore.undoCanvas(),
				},
				{
					id: "app-redo",
					label: $t("menuBar.redo"),
					rightLabel: "Ctrl+Y",
					isDisabled: !snapshotStore.isRedoAvailable,
					onClick: () => snapshotStore.redoCanvas(),
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-restore-default",
					label: $t("menuBar.restoreDefault"),
					isDisabled:
						!snapshotStore.isUndoAvailable && !snapshotStore.isRedoAvailable,
					onClick: () => snapshotStore.restoreCanvas(),
				},
				{
					isBreakpoint: true,
				},
				// {
				//   id: "canvas-sync",
				//   label: transl("menuBar.sync"),
				//   isChecked: canvasStore.getSyncBreakpoints,
				//   onClick: () => {
				//     canvasStore.setSyncBreakpoints(!canvasStore.getSyncBreakpoints);
				//   },
				// },
				{
					id: "canvas-add-general-div",
					label: $t("menuBar.addGeneralDiv"),
					onClick: () => canvasStore.changeMouseState("rec"),
				},
				{
					id: "canvas-add-ellipse",
					label: $t("menuBar.addGeneralEllipse"),
					onClick: () => canvasStore.changeMouseState("ellipse"),
				},
				{
					id: "canvas-add-text",
					label: $t("menuBar.addText"),
					onClick: () => canvasStore.changeMouseState("text"),
				},
				{
					id: "canvas-add-button",
					label: $t("menuBar.addGeneralButton"),
					onClick: () => canvasStore.changeMouseState("btn"),
				},
				{
					id: "canvas-add-input",
					label: $t("menuBar.addGeneralInput"),
					onClick: () => canvasStore.changeMouseState("input"),
				},
				// {
				//   id: "app-reset-default",
				//   label: transl("menuBar.resetDefault"),
				//   onClick: () => snapshotStore.resetCanvas(),
				// },
			],
		},
		{
			label: $t("menuBar.view"),
			isExpand: true,
			// isHidden: true,
			children: [
				{
					id: "app-hide-sidebar",
					label: $t("menuBar.showUI"),
					isChecked: !store.arePanelsHidden,
					rightLabel: "Ctrl+\\",
					onClick: () => {
						store.updateUiState();
					},
				},
				{
					id: "toggle-left-sidebar",
					label: $t("sidebar.toggleLeft"),
					isChecked: store.uiShow.left,
					rightLabel: "Ctrl+[",
					onClick: () => {
						const newVal = !store.uiShow.left;
						newVal && (store.uiShow.top = true);
						store.uiShow.left = newVal;
					},
				},
				{
					id: "toggle-right-sidebar",
					label: $t("sidebar.toggleRight"),
					isChecked: store.uiShow.right,
					rightLabel: "Ctrl+]",
					onClick: () => {
						const newVal = !store.uiShow.right;
						newVal && (store.uiShow.top = true);
						store.uiShow.right = newVal;
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-show-shortcuts",
					label: $t("menuBar.showKeyboardDialog"),
					isChecked: store.uiShow.shortcutDialog,
					rightLabel: "Ctrl+Shift+?",
					onClick: () => {
						store.updateUiState({
							shortcutDialog: !store.uiShow.shortcutDialog,
						});
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "limit-page-overflow",
					label: $t("menuBar.limitPageOverflow"),
					isChecked: overflowValue === "hidden",
					onClick: () => {
						const cssObj = {
							overflow:
								ab?.getValue("overflow") === "hidden" ? "visible" : "hidden",
						};
						ab?.setValues(cssObj, true);
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "canvas-snappable",
					label: $t("menuBar.canvasSnapping"),
					rightLabel: "Ctrl+Shift+'",
					isChecked: isSnappable,
					onClick: () => {
						store.isCanvasSnappable = !store.isCanvasSnappable;
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-zoom-25",
					label: labelZoom25,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 0.25 });
					},
				},
				{
					id: "app-zoom-50",
					label: labelZoom50,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 0.5 });
					},
				},
				{
					id: "app-zoom-100",
					label: labelZoom100,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 1 });
					},
				},
				{
					id: "app-zoom-150",
					label: labelZoom150,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 1.5 });
					},
				},
				{
					id: "app-zoom-200",
					label: labelZoom200,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 2 });
					},
				},
				{
					id: "app-zoom-300",
					label: labelZoom300,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 3 });
					},
				},
				{
					id: "app-zoom-500",
					label: labelZoom500,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 5 });
					},
				},
				{
					id: "app-zoom-1000",
					label: labelZoom1000,
					onClick: () => {
						store.updateCanvasOptions({ zoom: 10 });
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-center-page",
					label: labelCenterPage,
					rightLabel: "Ctrl+/",
					onClick: () => {
						const zoomLevel = useDefaultStore().getPageOptions.zoom;
						const translate = calculateCenterTransform(zoomLevel);
						store.updateCanvasOptions({
							translate,
						});
					},
				},
				{
					isBreakpoint: true,
				},
				{
					id: "app-toggle-gridlines",
					label: `${$t("menuBar.gridLines")} (${gridSize}x${gridSize})`,
					isChecked: store.getPageOptions.gridLines,
					rightLabel: "Ctrl+G",
					onClick: () => {
						store.updateCanvasOptions({
							gridLines: !store.getPageOptions.gridLines,
						});
					},
				},
			],
		},
	] as IAppMenuBarItem[];
});
