import type { TMouseActionType } from "@/stores/definition/globalTypes";
import type { TValidDomParentName } from "@/stores/layer";

export interface IPrivateKeyInterface {
	parentDataName: TValidDomParentName;
	customElementObject: TMouseActionType;
	visibleOn: string[];
	isLocked: boolean;
	isAspectRatio: boolean;
	groupName?: string;
	order?: string;
	layer?: LayerElementInstance;
}
export type TLayerInstance = {
	name: string;
	isReadOnly: boolean;
};
export type TPrivateObjectPayload = {
	visibleOn: string[];
	isLocked: boolean;
	isAspectRatio: boolean;
	customElementObject: TMouseActionType;
	parentDataName: TValidDomParentName;
	groupName: string;
	order: string;
	layer: TLayerInstance;
};

export class PrivateElementInstance implements IPrivateKeyInterface {
	visibleOn: string[] = [];
	isLocked = false;
	isAspectRatio = false;
	customElementObject: TMouseActionType = "";
	parentDataName: TValidDomParentName = "";
	groupName?: string = "";
	layer = new LayerElementInstance();
	order?: string;

	constructor(payload?: Partial<TPrivateObjectPayload>) {
		if (payload) {
			const keys = Object.keys(payload) as (keyof TPrivateObjectPayload)[];
			for (const key of keys) {
				switch (key) {
					case "layer": {
						const value = payload[key];
						if (value) {
							this.layer?.updateLayer(value);
						} else {
							console.warn("Invalid payload", payload, key);
						}
						break;
					}
					default: {
						const value = payload[key];
						if (value) {
							// @ts-expect-error
							this[key] = value;
						}
						break;
					}
				}
			}
		}
	}

	updateVisibleKeys(keys: string[]) {
		this.visibleOn = keys || [];
	}
}

class LayerElementInstance implements TLayerInstance {
	name: string;
	isReadOnly = true;

	constructor(name = "") {
		this.name = name;
	}

	updateLayer(obj: TLayerInstance) {
		"name" in obj && (this.name = obj.name || "");
		"isReadOnly" in obj && (this.isReadOnly = obj.isReadOnly || true);
	}
}
