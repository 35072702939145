<script setup lang="ts">
import { useCanvasStore } from "@/stores/canvas";
import type {
	TBreakpointItem,
	IBreakpointItemEdit,
	TBreakpointTypeList,
} from "@/stores/definition/globalTypes";
import { DomElementInstance } from "@/stores/layer";
import { $t } from "@/i18n";
import { useToastStore } from "@/stores/toast";
import { type IDragInputChangeEvent } from "@/components/shared/dragBar/InputDragBar.vue";
import { usePageStore } from "@/stores/page";
import { ModelInstance } from "@/stores/group";
import { BREAKPOINT_WIDTHS } from "@/global/appGlobal";

const canvasStore = useCanvasStore();
const typeList: TBreakpointTypeList[] = ["desktop", "tablet", "mobile"];
const props = defineProps<{
	currentItem?: TBreakpointItem;
}>();
const emit = defineEmits(["close-modal"]);

const form = ref<IBreakpointItemEdit>({
	name: ModelInstance.createUniqueId(),
	label: "",
	type: "desktop",
	isInit: true,
	styles: {
		body: {
			width: BREAKPOINT_WIDTHS.desktop,
		},
	},
});
const bodyWidth = computed(() => form.value.styles.body.width || "");

const invalidKeys = ref<string[]>([]);
const currentEditItem = ref({ ...props.currentItem });
const isEditing = computed(() =>
	Boolean(currentEditItem.value && Object.keys(currentEditItem.value).length),
);
const canRemoveTab = computed(() => {
	const len = canvasStore.getAllCanvasTabs.length;
	return len > 1 && isEditing.value;
});

function onClickRemove() {
	const currentTabIndx = canvasStore.getAllCanvasTabs.findIndex(
		(tab) => tab.name === form.value.name,
	);
	if (currentTabIndx === -1) {
		console.warn("Can't remove breakpoint", form.value);
		useToastStore().openToastError("Can't remove breakpoint");
	} else {
		const indexToSwitch = currentTabIndx === 0 ? 1 : currentTabIndx - 1;
		canvasStore.changeCanvasTab(canvasStore.getAllCanvasTabs[indexToSwitch]);
		usePageStore().getCurrentPage?.canvas.breakpoint.definition.splice(
			currentTabIndx,
			1,
		);
		onClickCancel();
	}
}

function onClickCancel() {
	emit("close-modal");
}

function onClickSubmit() {
	const hasInvalidKeys = () => {
		const invalidKeys = [];
		const keys = Object.keys(form.value) as (keyof TBreakpointItem)[];
		for (const key of keys) {
			const value: any = form.value[key];
			const isInvalid = typeof value === "string" && !value;
			if (isInvalid) invalidKeys.push(key);
		}
		return Array.from(new Set(invalidKeys));
	};

	invalidKeys.value = hasInvalidKeys();
	if (invalidKeys.value.length) return false;

	const brObj: Required<IBreakpointItemEdit> = {
		name: form.value.name,
		label: form.value.label,
		type: form.value.type,
		styles: form.value.styles,
		// styles: {
		//   body: form.value.styles.body.getSimpleValues,
		// },
		isInit: form.value.isInit,
	};

	usePageStore().getCurrentPage?.updateBreakpointTab([brObj]);
	onClickCancel();
}

function toCapitalCase(label: string) {
	return label.slice(0, 1).toUpperCase() + label.slice(1);
}

function isInvalid(key: string) {
	return invalidKeys.value.includes(key);
}

function onCheckInvalid(evt: Event) {
	const targetEl = evt.target as HTMLInputElement;
	const elName = targetEl.dataset.name || "";

	if (targetEl.classList.contains("invalid") && targetEl.value) {
		invalidKeys.value = invalidKeys.value.filter((key) => {
			return key !== elName;
		});
	} else if (!targetEl.value) {
		invalidKeys.value.push(elName);
	}
}

function onChangeType(type: TBreakpointTypeList) {
	form.value.styles.body = {
		width: BREAKPOINT_WIDTHS[type],
	};
}

function onInputWidth(evt: Event) {
	const value = (evt.target as HTMLInputElement).value;
	form.value.styles.body.width = value;

	onCheckInvalid(evt);
}

function onDraggerInput(evt: IDragInputChangeEvent) {
	const unit = DomElementInstance.getStyleUnit(form.value.styles.body.width);
	const value = `${evt.incVal}${unit}`;
	form.value.styles.body.width = value;
}

onMounted(() => {
	if (isEditing.value) {
		const editObj = {
			name: currentEditItem.value.name,
			label: currentEditItem.value.label,
			type: currentEditItem.value.type,
			styles: {
				body: currentEditItem.value.styles?.body.getSimpleValues || {
					width: "",
				},
			},
			isInit: currentEditItem.value.isInit,
		} as IBreakpointItemEdit;
		form.value = editObj;
	}
});
</script>

<template lang="pug">
.breakpoint-manage
	.form
		.group
			label
				span Title
			input(
				v-model="form.label",
				data-name="label",
				:class="{ 'is-invalid': isInvalid('label') }",
				type="text",
				@keydown.enter="onClickSubmit",
				@input="onCheckInvalid"
			)
		.group
			label Icon
			multiselect-form(
				v-model="form.type",
				:options="typeList",
				:searchable="true",
				@input="onChangeType"
			)
				template(#option-single="dropdownProps")
					.option__flex
						span.text
							span {{ toCapitalCase(dropdownProps.props.option.label) }}
				template(#option-pre="dropdownProps")
					.option__flex
						span.text
							span {{ toCapitalCase(dropdownProps.props.option.label) }}
		.group
			label Width
			.input-group-2
				input(
					:value="bodyWidth",
					data-name="width",
					:class="{ 'is-invalid': isInvalid('width') }",
					type="text",
					placeholder="Width",
					@keydown.enter="onClickSubmit",
					@input="onInputWidth"
				)
				.input-drag-wrap
					input-drag-bar(:value="bodyWidth", @input="onDraggerInput")
	.action-bar
		button.remove(v-if="canRemoveTab", @click="onClickRemove")
			span {{ $t("dialog.remove") }}
		button.cancel(@click="onClickCancel")
			span {{ $t("dialog.cancel") }}
		button(@click="onClickSubmit")
			span {{ $t("dialog.submit") }}
</template>

<style lang="scss" scoped>
.breakpoint-manage {
	display: flex;
	flex-direction: column;
	padding: 0 20px 20px;
	width: 500px;
	height: 300px;
	max-height: 90vh;
	overflow: auto;
	user-select: none;
	gap: 20px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 20px;

		& > * {
			width: 100%;
		}

		.group {
			display: flex;
			flex-direction: column;
			gap: 5px;

			input {
				&.is-invalid {
					border: 1px solid crimson;
				}
			}

			.input-group-2 {
				display: flex;

				.input-drag-wrap {
					position: absolute;
					right: 0;
					height: 100%;
				}

				input {
					flex: 1;
				}
			}
		}
	}

	.action-bar {
		margin: auto 0 0;
		padding: 5px 0;
		display: flex;
		gap: 10px;

		.remove {
			margin-right: auto;
		}

		.cancel {
			margin-left: auto;
		}
	}

	:deep() .multiselect {
		height: 31px;

		.option__flex {
			span:not(.text) {
				padding-left: 7px;
			}
		}
	}

	// :deep() .multiselect {
	//   border: 1px solid;
	//   border-color: #42474a;
	//   border-radius: $input-radius;
	//   height: 31px;

	//   .default-text {
	//     text-indent: 5px;
	//   }

	//   .option__flex {
	//     display: flex;
	//     align-items: center;
	//   }
	// }
}
</style>
