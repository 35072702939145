import { addPxEnter, isCssValid, parseColorToHex } from "@/helpers/helpers";
import { useSnapshotStore } from "@/stores/snapshot";
import type {
	TObjEntries,
	IBreakpointItemEdit,
	TSharedElementItemInterface,
	TCssStyle,
} from "../globalTypes";
import { openGlobalCssModal } from "@/helpers/modals";

type TSharedCanvasType = {
	name: keyof Partial<TCssStyle> | "customCss";
} & TSharedElementItemInterface;

export class PageCanvasOptions {
	form = reactive<Partial<TCssStyle>>({
		minHeight: "2000px",
		width: "0",
		color: "#1c1c1cff",
		backgroundColor: "#fffffff1",
		fontFamily: "Arial",
		fontSize: "14px",
		margin: "0",
		padding: "0",
	});

	constructor(cssObj?: Partial<TCssStyle>) {
		if (cssObj) {
			const entries = Object.entries(cssObj) as TObjEntries<TCssStyle>;
			for (const [key, value] of entries) {
				this.form[key] = value as any;
			}
		}
	}

	getValue(key: keyof TCssStyle): string {
		if (key && key in this.form) {
			return this.form[key] || "";
		}
		return "";
	}

	setValues(cssObj: Partial<TCssStyle>, hasUndo = false, isUpdateMerge = true) {
		const entries = Object.entries(cssObj) as TObjEntries<TCssStyle>;
		for (const [key, value] of entries) {
			this.form[key] = value as any;
		}

		const hasAnyValidValue = entries.some(([key, value]) => {
			return isCssValid(key, value) || !value;
		});

		if (!isUpdateMerge) {
			// Remove any old values not found in cssObj
			const formKeys = Object.keys(this.form) as (keyof TCssStyle)[];
			for (const key of formKeys) {
				if (!(key in cssObj)) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete this.form[key];

					// // TODO 0 Use in future, but needs testing
					// this.form[key] = undefined;
				}
			}
		}

		if (hasAnyValidValue && hasUndo) {
			useSnapshotStore().addUndoStack();
		}
	}

	get getSimpleValues(): IBreakpointItemEdit["styles"]["body"] {
		return this.form;
		// return Object.fromEntries(
		//   Object.entries(this.form).map(([itemK, itemV]) => [itemK, itemV?.value])
		// );
	}
}

export const pageCanvasDefinition = (instance: PageCanvasOptions) => {
	const overflowValue = instance.getValue("overflow") || "visible";

	return reactive<TSharedCanvasType[]>([
		{
			name: "minHeight",
			label: "Min Page Height",
			value: instance.getValue("minHeight"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "height", "minHeight");
				instance.setValues(payload, true);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				const cssObj = {
					minHeight: value,
					height: value,
				} as TCssStyle;
				instance.setValues(cssObj, false);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				const cssObj = {
					minHeight: value,
					height: value,
				} as TCssStyle;
				instance.setValues(cssObj, true);
			},
		},
		{
			name: "width",
			label: "Page Width",
			value: instance.getValue("width"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "width");
				instance.setValues(payload, true);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ width: value }, false);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ width: value }, true);
			},
		},
		{
			name: "fontFamily",
			label: "Font Family",
			value: instance.getValue("fontFamily"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				instance.setValues({ fontFamily: value }, true);
			},
		},
		{
			name: "fontSize",
			label: "Font Size",
			value: instance.getValue("fontSize"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "fontSize");
				instance.setValues(payload, true);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ fontSize: value }, false);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ fontSize: value }, true);
			},
		},
		{
			name: "color",
			label: "Color",
			value: instance.getValue("color"),
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				const payload = { color: currValue };
				instance.setValues(payload, false);
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				instance.setValues({ color: hexValue }, true);
			},
			onSelectEyeDropper(value: string, key?: keyof TCssStyle) {
				instance.setValues({ [key || "color"]: value }, true);
			},
		},
		{
			name: "backgroundColor",
			label: "Background",
			value: instance.getValue("backgroundColor"),
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				instance.setValues({ backgroundColor: currValue }, false);
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				instance.setValues({ backgroundColor: hexValue }, true);
			},
			onSelectEyeDropper(value: string, key?: keyof TCssStyle) {
				instance.setValues({ [key || "backgroundColor"]: value }, true);
			},
		},
		{
			name: "margin",
			label: "Margin",
			value: instance.getValue("margin"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "margin");
				instance.setValues(payload, true);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ margin: value }, false);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ margin: value }, true);
			},
		},
		{
			name: "padding",
			label: "Padding",
			value: instance.getValue("padding"),
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				const payload = addPxEnter(evt, value, "padding");
				instance.setValues(payload, true);
			},
			onDraggerInput(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ padding: value }, false);
			},
			onDraggerChange(evt, oldValue) {
				const unit = DomElementInstance.getStyleUnit(oldValue);
				const value = `${String(evt.incVal)}${unit}`;
				instance.setValues({ padding: value }, true);
			},
		},
		{
			name: "overflow",
			label: "Overflow",
			type: "dropdown",
			value: overflowValue,
			vBind: {
				options: ["visible", "hidden", "scroll", "auto"],
				searchable: true,
				closeOnSelect: true,
			},
			onInput(evt: any) {
				const value = evt;
				instance.setValues({ overflow: value }, true);
			},
		},
		{
			name: "customCss",
			label: "Global CSS",
			value: "",
			type: "button",
			buttonLabel: "Edit CSS",
			onClick(evt: Event, oldValue) {
				openGlobalCssModal();
			},
		},
	]);
};
