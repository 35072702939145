import type {
	IHTMLEngineResult,
	TBuilderOptions,
	ISaveInstance,
} from "@/stores/definition/globalTypes";
import { defaultToHtml } from "./modules/elements";
import { Meta } from "./modules/meta";

const default_options: TBuilderOptions = {
	prod: false,
	name: "",
	link_pages: false,
};

// function decodeElement(item: DomElementInterface): Element {
//   switch (item.tag) {
//     case "div":
//       return new Div(item);
//     case "p":
//       return new P(item);
//     case "span":
//       return new Span(item);
//     case "img":
//       return new Img(item);
//     case "video":
//       return new Video(item);
//     case "iframe":
//       return new Iframe(item);
//     case "h1":
//       return new H(item, 1);
//     case "h2":
//       return new H(item, 2);
//     case "h3":
//       return new H(item, 3);
//     case "h4":
//       return new H(item, 4);
//     case "h5":
//       return new H(item, 5);
//     case "h6":
//       return new H(item, 6);
//     case "a":
//       return new A(item);
//     case "label":
//       return new Label(item);
//     case "audio":
//       return new Audio(item);
//     case "button":
//       return new Button(item);
//     case "input":
//       return new Input(item);

//     default:
//       console.error(item);
//       throw new Error("invalid-element-type");
//   }
// }

export function build(
	htmlArray: ISaveInstance,
	options: TBuilderOptions = default_options,
): IHTMLEngineResult {
	const elementArray = [];
	const html = document.createElement("html");
	const body = document.createElement("body");

	const html_result: IHTMLEngineResult = {
		all: "",
		html: "",
		js: "",
		css: "",
	};

	const meta = new Meta(htmlArray.meta, htmlArray.breakpoint.definition, {
		name: options.name,
		link_pages: options.link_pages,
	});
	html.append(meta.toHtml());

	const elements = htmlArray.elements || [];
	for (const el of elements) {
		if (el.style) {
			meta.addStyleItem(el.style, el._private, el.id);
		}
		if (el.custom?.js) {
			meta.addScriptItem(el.custom.js);
		}
		elementArray.push(el);
		// >> If children ever added, handle children
	}

	for (const el of elementArray) {
		body.append(defaultToHtml(el));
	}

	html.append(body);

	let res = html.innerHTML;

	const meta_style = meta.getStyleTag();
	res += meta_style;
	const meta_script = meta.getScriptTag();
	res += meta_script;

	if (!options.prod) {
		html_result.all =
			new XMLSerializer().serializeToString(document.doctype as Node) +
			"<html>" +
			res.replace(/>/g, ">\n") +
			"</html>";
	}
	if (
		options.link_pages &&
		meta_script.replace(/<\/?script>/g, "").length > 0
	) {
		const script_tag = document.createElement("script");
		let name = options.name || "";
		if (name) {
			name = "./" + name + ".js";
		}
		script_tag.setAttribute("src", name);
		html.append(script_tag);
	}
	html_result.css = meta_style.replace(/<\/?style>/g, "");
	html_result.html =
		new XMLSerializer().serializeToString(document.doctype as Node) +
		html.outerHTML;
	html_result.js = meta_script.replace(/<\/?script>/g, "");

	return html_result;
}
