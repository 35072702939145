import { isCssValid, parseColorToHex } from "@/helpers/helpers";
import {
	openFontListModal,
	openGlobalCustomHead,
	openGlobalJsModal,
} from "@/helpers/modals";
import { useSnapshotStore } from "@/stores/snapshot";
import { type TSharedElementItemInterface } from "../globalTypes";
import { type IMetaObj, type IFont, type TMetaTags } from "../globalTypes";
import { AppGlobal } from "@/global/appGlobal";

export class GlobalCanvasOptions {
	form = reactive<Required<IMetaObj>>({
		title: "Mars Web Creator",
		description: "",
		backgroundColor: "#babdbfff",
		favicon: "",
		fontList: [] as string[],
		fonts: [] as IFont[],
		meta: [] as TMetaTags[],
		accentColor: "",
		customHead: "",
		globalJs: "",
	});

	constructor(payload?: any) {
		const val = getComputedStyle(document.documentElement).getPropertyValue(
			"--app-accent-color",
		);
		this.form.accentColor = val.trim();

		if (payload) {
			for (const key of Object.keys(payload)) {
				this.form[key as keyof IMetaObj] = payload[key];
			}
		}
	}

	getValue(key: keyof IMetaObj): string | string[] | IFont[] | TMetaTags[] {
		if (key && key in this.form) {
			return this.form[key] || "";
		}
		return "";
	}

	setValues(
		itemObj: Partial<IMetaObj>,
		hasUndo = false,
		isCssProp = false, // Inverse value because more of non CSS props
	) {
		const entries = Object.entries(itemObj) as [keyof IMetaObj, any][];
		for (const [key, value] of entries) {
			this.form[key] = value;

			// if (key === "accentColor") {
			//   const isValidColor = CSS.supports("color", value);
			//   if (isValidColor) {
			//     document.documentElement.style.setProperty(
			//       "--app-accent-color",
			//       value
			//     );
			//   }
			// }
		}

		const hasAnyValidValue = entries.some(([key, value]) => {
			return (
				(isCssProp && isCssValid(key as any, value)) || !value || !isCssProp
			);
		});

		if (hasAnyValidValue && hasUndo) {
			useSnapshotStore().addUndoStack();
		}
	}

	addFont(value: IFont) {
		this.form.fonts.push(value);
	}
	removeFont(index: number) {
		this.form.fonts.splice(index, 1);
	}

	get getFormValues(): Partial<IMetaObj> {
		return toRaw(this.form as unknown as IMetaObj);
	}
}

export const globalCanvasDefinition = (gcInstance: GlobalCanvasOptions) => {
	const items = reactive<TSharedElementItemInterface[]>([
		{
			name: "site-title",
			label: "Title",
			value: gcInstance.getValue("title") as string,
			onInput(evt, oldValue: string) {
				const value = (evt?.target as HTMLInputElement).value;
				gcInstance.setValues({ title: value }, true);
			},
		},
		{
			name: "site-description",
			label: "Description",
			type: "textarea",
			value: gcInstance.getValue("description") as string,
			onInput(evt, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				gcInstance.setValues({ description: value }, true);
			},
		},
		{
			name: "backgroundColor",
			label: "Background Color",
			value: gcInstance.getValue("backgroundColor") as string,
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				gcInstance.setValues({ backgroundColor: currValue }, false, true);
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				gcInstance.setValues({ backgroundColor: hexValue }, true, true);
			},
			onSelectEyeDropper(value: string) {
				gcInstance.setValues({ backgroundColor: value }, true);
			},
		},
		{
			name: "accentColor",
			label: "Accent color",
			value: gcInstance.getValue("accentColor") as string,
			sidebarGroup: "app",
			inputType: "color",
			onColorInput(evt: Event, currValue) {
				gcInstance.setValues({ accentColor: currValue }, false, true);
			},
			onColorChange(evt: Event, currValue) {
				const hexValue = parseColorToHex(currValue);
				gcInstance.setValues({ accentColor: hexValue }, true, true);
			},
			onSelectEyeDropper(value: string) {
				gcInstance.setValues({ accentColor: value }, true);
			},
		},
		{
			name: "favicon",
			label: "Favicon",
			value: gcInstance.getValue("favicon") as string,
			onChange(evt: Event, oldValue) {
				const value = (evt?.target as HTMLInputElement).value;
				gcInstance.setValues({ favicon: value }, true);
			},
		},
		{
			name: "fontList",
			label: "Font List",
			value: null,
			type: "button",
			buttonLabel: "Change font list",
			isVisible: () => AppGlobal.IS_INTEGRATED,
			onClick(evt: Event, oldValue) {
				const payload = {
					value: gcInstance.getValue("fontList"),
				};
				openFontListModal(payload);
			},
		},
		{
			name: "customHead",
			label: "Custom head",
			type: "button",
			buttonLabel: "Edit page head",
			value: gcInstance.getValue("customHead") as string,
			onClick(evt: Event, oldValue) {
				openGlobalCustomHead();
				// const value = (evt?.target as HTMLInputElement).value;
				// gcInstance.setValues({ customHead: value }, true);
			},
		},
		{
			name: "globalJs",
			label: "Global JS",
			type: "button",
			buttonLabel: "Edit JS",
			value: gcInstance.getValue("globalJs") as string,
			onClick(evt: Event, oldValue) {
				openGlobalJsModal();
				//     const value = (evt?.target as HTMLInputElement).value;
				//     gcInstance.setValues({ globalJs: value }, true);
			},
		},
	]);

	return items.filter((item) => {
		const visible = item.isVisible?.();
		if (visible === false) return false;
		return true;
	});
};
