import { $t } from "@/i18n";
import {
	type DomElementInstance,
	useLayerInstancesStore,
} from "@/stores/layer";
import { usePageStore } from "@/stores/page";
import { type TSharedElementItemInterface } from "../globalTypes";

export const linkItemNames = {
	linkSource: "a-link-source",
	linkOpenNewTab: "a-link-open-new-tab",
};

export const sharableLinkProps = (instance: DomElementInstance) => {
	const link = instance.getLinkOptions()?.href;

	return reactive<TSharedElementItemInterface[]>([
		{
			name: linkItemNames.linkSource,
			label: $t("link.source"),
			value: link,
			title: "Right click to link element to a page",
			onChange(evt: Event, oldValue: string) {
				const value = (evt.target as HTMLInputElement).value;
				const domObj: Partial<DomElementInstance> = {
					attributes: {
						href: value,
					},
				};
				useLayerInstancesStore().updateModelRaw(
					domObj,
					instance,
					"on",
					null,
					true,
				);
			},
			onContextMenu(evt: Event, prop: TSharedElementItemInterface) {
				evt.preventDefault();
				// NOTE: Maybe open context menu to add page
				const currentVal = prop.value as string;
				const pageNames = usePageStore().getAllPages.map(
					(page) => page.tabMeta.label,
				);
				while (pageNames.length) {
					// const onePage = pageNames.shift();
					const currentIndex = pageNames.findIndex((name) =>
						currentVal.startsWith(name),
					);
					if (currentIndex !== -1) {
						pageNames.push(pageNames[0]);
					}

					const onePage =
						currentIndex === -1 ? pageNames[0] : pageNames[currentIndex + 1];
					if (onePage) {
						const domObj: Partial<DomElementInstance> = {
							attributes: {
								href: `${onePage}.html`,
							},
						};

						useLayerInstancesStore().updateModelRaw(
							domObj,
							instance,
							"on",
							null,
							true,
						);
						break;
					}
				}
			},
		},
		{
			name: linkItemNames.linkSource,
			label: $t("link.openNewTab"),
			type: "switch",
			// inputType: "checkbox",
			checked: instance.getLinkOptions()?.target === "_blank",
			onChange(evt: Event, oldValue: any) {
				const checked = evt as unknown as boolean;
				// const checked = (evt.target as HTMLInputElement).checked;
				const domObj: Partial<DomElementInstance> = {
					attributes: {
						target: checked ? "_blank" : "",
					},
				};
				useLayerInstancesStore().updateModelRaw(
					domObj,
					instance,
					"on",
					null,
					true,
				);
			},
		},
	]);
};
