<script setup lang="ts">
import { onLongPress } from "@vueuse/core";

export interface IContextMenuitemsType {
	label: string;
	name?: string;
	disabled?: boolean;
	hidden?: boolean;
	rightLabel?: string;
	shouldCloseContextMenu?: boolean;
	onClick: (payload?: any, a?: IContextMenuitemsType) => void;
	onShiftKeyClick?: (payload?: any, a?: IContextMenuitemsType) => void;
}

const props = withDefaults(
	defineProps<{
		items: IContextMenuitemsType[];
		evt?: PointerEvent | null;
		payload?: Record<string, any>;
		longPressDelay?: number;
	}>(),
	{
		items: () => [],
		evt: undefined,
		payload: undefined,
		longPressDelay: 500,
	},
);

const emit = defineEmits(["close-context-menu"]);

const contextMenuOriginalEvent = ref<PointerEvent | null>(null);
const contextMenuRef = ref<HTMLElement | null>(null);
const contextMenuHandlerRef = ref<HTMLElement | null>(null);

function setupContextMenuPos() {
	const evt = contextMenuOriginalEvent.value;
	if (!evt) {
		console.log("No mouse event", props);

		return;
	}

	const calcPageAxis = (
		axis: typeof axisObj,
		offsetNum = 0,
	): typeof axisObj => {
		const tempAxis = { ...axis };

		const el = contextMenuRef.value;
		if (!el) {
			console.error("No element found", contextMenuRef.value);

			return { x: 0, y: 0 };
		}

		const contextHeight = el.offsetHeight;
		const contextWidth = el.offsetWidth;

		const clientCoor = {
			w: window.innerWidth,
			h: window.innerHeight,
		};

		const overlapX = clientCoor.w - contextWidth - axis.x;
		const overlapY = clientCoor.h - contextHeight - axis.y;

		// Revert context side
		tempAxis.x =
			overlapX < 0 ? axis.x - contextWidth - offsetNum : axis.x + offsetNum;

		if (overlapY < 0) {
			tempAxis.y =
				contextHeight < axis.y
					? axis.y - contextHeight - offsetNum // Inverse
					: clientCoor.h / 2 - contextHeight / 2; // If context menu overlaps window, move it to the center
		} else {
			tempAxis.y = axis.y + offsetNum;
		}
		// console.warn(
		//   `overlap Y > Axis: ${axis.y} | ContextHeight: ${contextHeight} | tempAxisY: ${tempAxis.y}`
		// );

		return tempAxis;
	};

	let axisObj = {
		x: evt.clientX,
		y: evt.clientY,
	};

	axisObj = calcPageAxis(axisObj, 5);
	if (!axisObj.x && !axisObj.y) {
		console.log("Invalid calculation for axis", axisObj);

		return;
	}

	const unit = "px";
	contextMenuStylePos.value = {
		left: `${axisObj.x}${unit}`,
		top: `${axisObj.y}${unit}`,
	};
}

function calcContextMenuStyle() {
	void nextTick(() => {
		setupContextMenuPos();
	});
}

function displayName(item: IContextMenuitemsType) {
	return item.label || item.name;
}

function onMouseDownEvent(evt: MouseEvent) {
	const el = evt.target as HTMLVideoElement;
	const isElementWithin = el.closest(".context-menu");
	if (!isElementWithin) {
		closeContextMenu();
	}
}

function onMouseUpListEvent(evt: MouseEvent, item: IContextMenuitemsType) {
	const isRightClick = evt.button === 2;
	if (item.disabled || isRightClick) {
		return;
	}

	if (item.shouldCloseContextMenu !== false) {
		closeContextMenu();
	}

	setTimeout(() => {
		// Used so modal & popups can be focused
		if (evt.shiftKey && item.onShiftKeyClick) {
			item.onShiftKeyClick(props.payload, item);
		} else if (item.onClick) {
			item.onClick(props.payload, item);
		} else {
			console.warn("No click handler added");
		}
	}, 10);
}

function closeContextMenu() {
	contextMenuOriginalEvent.value = null;
	emit("close-context-menu");
}

function onKeyDownContextMenu(evt: KeyboardEvent) {
	if (evt.key === "Escape") {
		closeContextMenu();
	}
}

const contextMenuStylePos = ref<Record<string, string>>({
	visibility: "hidden",
});

const filteredItems = computed(() =>
	props.items.filter((item: IContextMenuitemsType) => !item.hidden),
);

watchEffect(() => {
	if (props.evt) {
		contextMenuOriginalEvent.value = props.evt;
	}
});
watchEffect(() => {
	if (contextMenuOriginalEvent.value) {
		calcContextMenuStyle();
	}
});

function simulateReClick(evt: PointerEvent) {
	const initEvent = (hasDelay = true) => {
		if (hasDelay) {
			setTimeout(() => {
				// Timeout to simulate native context menu
				contextMenuOriginalEvent.value = evt;
			}, 40);
		} else {
			contextMenuOriginalEvent.value = evt;
		}
	};

	if (!contextMenuOriginalEvent.value) {
		initEvent();
	}

	const shouldHaveDelay = Boolean(contextMenuOriginalEvent.value);
	contextMenuOriginalEvent.value = null;
	initEvent(shouldHaveDelay);
}
function onLongPressCallbackHook(evt: PointerEvent) {
	simulateReClick(evt);
}
function onContextMenuHandler(evt: MouseEvent) {
	simulateReClick(evt as PointerEvent);
}

onMounted(() => {
	if (props.items.length === 0) {
		console.error("Context menu should have a list of items");
	}

	if (props.evt) {
		contextMenuOriginalEvent.value = props.evt;
		calcContextMenuStyle();
	}

	document.addEventListener("mousedown", onMouseDownEvent);
	document.addEventListener("keydown", onKeyDownContextMenu);

	if (contextMenuHandlerRef.value) {
		onLongPress(contextMenuHandlerRef.value, onLongPressCallbackHook, {
			modifiers: { prevent: true },
			delay: props.longPressDelay,
		});
	}
});

onUnmounted(() => {
	document.removeEventListener("mousedown", onMouseDownEvent);
	document.removeEventListener("keydown", onKeyDownContextMenu);
});

const slots = defineSlots<{
	handler?: (props: any) => any;
	item?: (props: { props: IContextMenuitemsType }) => any;
}>();
</script>

<template lang="pug">
.handler(
	v-if="slots.handler",
	ref="contextMenuHandlerRef",
	@contextmenu.prevent="onContextMenuHandler"
)
	slot(name="handler")
teleport(v-if="contextMenuOriginalEvent", to="body")
	ul.context-menu(ref="contextMenuRef", :style="contextMenuStylePos")
		li(
			v-for="(item, index) in filteredItems",
			:key="index",
			:class="{ disabled: item.disabled }",
			@contextmenu.self.prevent="",
			@mouseup="onMouseUpListEvent($event, item)"
		)
			slot(name="item", :props="item")
				span {{ displayName(item) }}
				span(v-if="item.rightLabel") {{ item.rightLabel }}
</template>

<style lang="scss" scoped>
.context-menu {
	$dark-gray: #1d1d1f;
	$back: lighten(
		$color: $dark-gray,
		$amount: 8,
	);
	$front: rgb(243, 243, 243);
	$text-color: gray;

	position: fixed;

	// position: absolute; // Don't use this
	list-style-type: none;
	margin: 0;
	width: auto;
	display: flex;
	flex-direction: column;
	background: $back;
	color: $front;
	user-select: none;
	z-index: 200;
	padding: 5px 0;
	border: 1px solid lighten($color: $back, $amount: 3);

	> li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		gap: 20px;
		padding: 3px 10px;
		width: 100%;
		color: $text-color;
		overflow: hidden;
		border-bottom: 1px solid rgb(74, 74, 74);

		&.disabled {
			color: $text-color;
		}

		&:last-child {
			border-bottom: none;
		}

		&:not(.disabled) {
			cursor: pointer;
			color: inherit;

			&:hover {
				background: lighten($color: $dark-gray, $amount: 15);
			}
		}
	}
}

.handler {
	margin: 0 !important;
	padding: 0 !important;
	height: fit-content !important;
	width: fit-content !important;
}
</style>
