<script setup lang="ts">
import { AppApi } from "@/global/appApi";
import { parseErrors, setSessionStorageReac } from "@/helpers/helpers";
import { useToastStore } from "@/stores/toast";

const emit = defineEmits(["close-modal", "logged-in"]);

const toastStore = useToastStore();
const isLoading = ref(false);
const isLoggedIn = ref(false);
const selectedComp = ref<"" | "project" | "site" | "load-project">("");
const projectList = ref([]);
const siteList = ref([]);
const selectedProject = ref<any>(null);
const selectedSite = ref<any>(null);
const userInfo = ref<any>(null);

async function onLoggedIn(evt: { res: any; data: any; form: any }) {
	const sid = setSessionStorageReac("sid", evt.data.sid);
	AppApi.userInfoData.sid = sid.value;
	userInfo.value = evt.data.user;
	isLoggedIn.value = true;
	isLoading.value = false;
	selectedComp.value = "project";

	await onLoadProjects(sid.value);
}
async function onLoadProjects(sid: string) {
	try {
		const res = await axios.get(AppApi.PARSED_ADMIN_LINKS.projectList, {
			params: { sid },
		});
		projectList.value = res.data?.data || [];
	} catch (err: any) {
		const errorMessage = parseErrors(err, "Error loading projects");
		console.warn("Error", err.message, "--", errorMessage);
		toastStore.openToastError(errorMessage);
	}

	if (projectList.value.length === 1) {
		selectedComp.value = "";
		selectedProject.value = projectList.value[0];
		await onLoadSites(sid);
	}
}

async function onLoadSites(sid: string) {
	if (!selectedProject.value) {
		console.warn("No selected project");
		return;
	}

	selectedComp.value = "site";
	const params = {
		sid,
		pro_id: selectedProject.value.pro_id,
	};
	try {
		const res = await axios.get(AppApi.PARSED_ADMIN_LINKS.siteList, { params });
		siteList.value = res.data?.payload?.sites || [];
	} catch (err: any) {
		const errorMessage = parseErrors(err, "Error loading sites");
		console.warn("Error", err.message, "--", errorMessage);
		toastStore.openToastError(errorMessage);
	}

	if (siteList.value.length === 1) {
		await onSiteSelect(siteList.value[0]);
	}
}
async function onProjectSelect(item: any) {
	selectedProject.value = item;
	selectedComp.value = "";

	const sid = AppApi.userInfoData.sid || "";
	await onLoadSites(sid);
}
async function onSiteSelect(item: any) {
	selectedSite.value = item;
	const sid = AppApi.userInfoData.sid || "";
	const isSuccess = await onSelectDb(sid);
	if (isSuccess) {
		const timeout = setTimeout(() => {
			isLoading.value = true;
		}, 200);
		const hasData = await onFetchCheckpointLatest(sid);
		clearTimeout(timeout);
		isLoading.value = false;

		if (hasData) {
			// Add choices to load / overwrite
			// TODO [1] Add logic for load / overwrite  in COMP
			selectedComp.value = "load-project";
		} else {
			// TODO [0]
			// Keep the current project >> Overwrite mars files
		}
	}
}
async function onSelectDb(sid: string) {
	const payload = {
		sid,
		db: selectedSite.value.sit_name,
	};

	try {
		const res = await axios.post(AppApi.PARSED_ADMIN_LINKS.selectDb, payload);
		if (res.data.result === "OK") {
			toastStore.openToastSuccess("Successfully logged in");
			AppApi.userInfoData.user = userInfo.value;
			const loggedPayload = {
				sid,
				user: userInfo,
			};
			emit("logged-in", loggedPayload);
			return true;
		}
	} catch (err: any) {
		const errorMessage = parseErrors(err, "Error loading sites");
		console.warn("Error", err.message, "--", errorMessage);
		toastStore.openToastError(errorMessage);
	}
	return false;
}
async function onFetchCheckpointLatest(sid: string) {
	try {
		const res = await axios.get(
			AppApi.PARSED_ADMIN_LINKS.htmlBuilderSaveLatest,
			{ params: { sid } },
		);
		const resData = res.data.data;
		if (resData) {
			return true;
		}
	} catch (err: any) {
		const errorMessage = parseErrors(err, "Error loading sites");
		console.warn("Error", err.message, "--", errorMessage);
		// toastStore.openToastError(errorMessage);
	}
	return false;
}
function getMarsDomain() {
	const link = `http://${selectedSite.value.sit_name}.${selectedSite.value.srv_name}.mars-hosting.com`;
	return link;
}
function onOverwriteProject() {
	emit("close-modal");
}
function onLoadExisting() {
	const qParams = {
		integration: AppApi.authDomain,
		domain: getMarsDomain(),
		sid: AppApi.userInfoData.sid || "",
	};
	if (!qParams.sid) {
		console.error("Overwrite project - no SID");
		toastStore.openToastError("Invalid session. Please try again later.");
		return;
	}
	const str = decodeURIComponent(
		new URLSearchParams(Object.entries(qParams)).toString(),
	);

	location.search = str;
	emit("close-modal");
}
function onClickBack() {
	switch (selectedComp.value) {
		case "load-project": {
			selectedComp.value = siteList.value.length === 1 ? "project" : "site";
			break;
		}
		case "site": {
			selectedComp.value = "project";
			break;
		}
	}
}
</script>

<template lang="pug">
.auth-module
	.logo-wrap
		img(
			src="@/assets/images/MARSLogoSVG.svg",
			alt="mars-logo",
			:draggable="false"
		)
		span(style="text-align: center; color: yellow") USING {{ AppApi.authDomain }}
	template(v-if="isLoading")
		loading-spinner
	template(v-else)
		login-comp(
			v-if="!isLoggedIn",
			:urls="AppApi.PARSED_ADMIN_LINKS",
			@cancel="$emit('close-modal')",
			@is-progress-loading="isLoading = $event",
			@logged-in="onLoggedIn"
		)
		template(v-else-if="isLoggedIn")
			.go-back(v-if="selectedComp !== 'project'", @click="onClickBack")
				i-fa-chevron-left
				span Go back
			mars-project-select(
				v-if="selectedComp === 'project'",
				:list="projectList",
				@on-select="onProjectSelect"
			)
			mars-site-select(
				v-else-if="selectedComp === 'site'",
				:list="siteList",
				@on-select="onSiteSelect"
			)
			select-load-project(
				v-else-if="selectedComp === 'load-project'",
				@overwrite="onOverwriteProject",
				@load-existing="onLoadExisting"
			)
</template>

<style lang="scss" scoped>
.auth-module {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	gap: 20px;
	font-size: 0.8rem;
	background: #323639;
	min-width: 400px;

	.logo-wrap {
		max-width: 80%;
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 5px;

		img {
			height: 20px;
			width: 100%;
			z-index: 0;
			opacity: 0.7;

			@media screen and (width >= 768px) {
				height: 25px;
			}
		}
	}

	.go-back {
		display: flex;
		align-items: center;
		margin-right: auto;
		gap: 5px;
		cursor: pointer;

		&:hover {
			color: white;
			transition: all 0.3s ease;
		}
	}
}
</style>
