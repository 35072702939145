<script setup lang="ts">
import { build } from "@/html-engine";
import html_formatter from "prettier/plugins/html";
import css_formatter from "prettier/plugins/postcss";
import prettier from "prettier/standalone";
import { useDefaultStore } from "@/stores";
import type {
	IAssetGetItem,
	IHTMLEngineResult,
} from "@/stores/definition/globalTypes";
import { type Ref } from "vue";
import { usePageStore } from "@/stores/page";
import { handleErrorLog, useIDB } from "@/helpers/helpers";
import { APP_STORAGE_IDB_KEYS, AppGlobal } from "@/global/appGlobal";

const pageStore = usePageStore();
const emit = defineEmits(["close-modal"]);

const htmlText = ref("");

const { setupZipInstance, addZipFile, downloadZip } = useZipComposable();

const res: Ref<IHTMLEngineResult> = ref<IHTMLEngineResult>({
	all: "",
	html: "",
});

async function generateHtml() {
	const html_array = pageStore.getCurrentPage?.getActiveSnapshot();
	if (!html_array) {
		throw new Error("Invalid html array");
	}

	res.value = build(html_array);
	htmlText.value = await prettier.format(res.value.all, {
		parser: "html",
		plugins: [html_formatter, css_formatter],
	});
	// htmlText.value = res.value.all;
}

async function download(filename: string) {
	setupZipInstance();

	const pages = pageStore.getAllPages;
	for (const page of pages) {
		const page_result = build(page.getActiveSnapshot(), {
			prod: true,
			name: page.tabMeta.label,
			link_pages: true,
		});

		const packHtml = async () => {
			const name = `${page.tabMeta.label}.html`;
			const file = await prettier.format(page_result.html, {
				parser: "html",
				plugins: [html_formatter, css_formatter],
			});
			addZipFile(name, file);
		};
		const packCss = async () => {
			if (page_result.css) {
				const name = `${page.tabMeta.label}.css`;
				const file = await prettier.format(page_result.css, {
					parser: "css",
					plugins: [css_formatter],
				});
				addZipFile(name, file);
			}
		};
		const packJs = () => {
			if (page_result.js) {
				const name = `${page.tabMeta.label}.js`;
				const file = page_result.js;
				addZipFile(name, file);
			}
		};

		await packHtml();
		await packCss();
		packJs();
	}

	// Shared assets
	if (!AppGlobal.IS_INTEGRATED) {
		// TODO [P2-1] Add only assets used and their links
		console.warn(">> Add only assets used and their links");
		const assets = (await useIDB("get", APP_STORAGE_IDB_KEYS.assets)) as
			| IAssetGetItem[]
			| null;

		if (assets) {
			for (const fileObj of assets) {
				const name = fileObj.ass_name;
				const file = fileObj.file;
				if (file) {
					const arrayBuffer = await file.arrayBuffer();
					addZipFile(name, arrayBuffer);
				}
			}
		}
	}

	downloadZip(filename);
}

// const store = useDefaultStore();
// const useAbsolutePaths = ref(store.getAbsolutePath);

// watch(useAbsolutePaths, (val) => {
//   store.changeAbsolutePath(val);
//   generateHtml();
// });

async function onSaveHtml() {
	try {
		const params = {
			data: {
				html: prettier.format(res.value.html, {
					parser: "html",
					plugins: [html_formatter, css_formatter],
				}),
				css:
					(await prettier.format(res.value.css || "", {
						parser: "css",
						plugins: [css_formatter],
					})) || "",
				js: res.value.js || "",
			},
		};
		// console.log(params);
		await useDefaultStore().postUploadBuild(params);
	} catch (err: unknown) {
		handleErrorLog(err);
	}
}

// Start file download.
async function onDownloadHtml(min: boolean) {
	if (min) {
		// await download("index.min.html", htmlText.value.replace(/\t\n\r/g, ""));
	} else {
		await download("build.zip");
		emit("close-modal");
	}
}

onMounted(() => {
	void generateHtml();
});
</script>

<template lang="pug">
.html-preview
	.text
		template(v-if="AppGlobal.IS_INTEGRATED")
			span Clicking
				b &nbsp;SAVE&nbsp;
				| will generate files and save it to the mars file system
			br
		span Clicking
			b &nbsp;DOWNLOAD&nbsp;
			| will generate and zip all html / css / js files
	.action-bar
		.toggle-wrap
			//- simple-switch(
			//-   v-model="useAbsolutePaths",
			//-   label="Enable absolute paths for links"
			//- )
		.controller-wrap
			button.btn(v-if="AppGlobal.IS_INTEGRATED", @click="onSaveHtml()")
				span Save
			button.btn(@click="onDownloadHtml(false)")
				span Download Zip
			//- button.btn(@click="onDownloadHtml(true)")
			//-   span Download Minimized
</template>

<style lang="scss" scoped>
.html-preview {
	display: flex;
	flex-direction: column;
	width: 40vw;
	height: 20vh;
	padding: 0 20px 20px;

	pre {
		overflow: auto;
	}

	.text {
		text-align: center;
	}

	.action-bar {
		margin-top: auto;
		display: flex;
		justify-content: space-between;

		.controller-wrap {
			display: flex;
			gap: 15px;

			button {
				cursor: pointer;
				height: 36px;
				font-weight: 500;
			}
		}
	}
}
</style>
