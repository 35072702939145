<script setup lang="ts">
import { getCurrentDomain } from "@/helpers/helpers";
import { useDefaultStore } from "@/stores";
import { type IFont } from "@/stores/definition/globalTypes";
import { useModalStore } from "@/stores/modal";
import { usePageStore } from "@/stores/page";

const store = useDefaultStore();
const pageStore = usePageStore();
const modalStore = useModalStore();

const default_title = "Custom fonts on project";
const add_title = "Don't forget to save after you add a font";
const emit = defineEmits(["close-modal"]);

const fonts = ref<IFont[]>([
	...(pageStore.getCurrentPage?.getActiveSnapshot().meta.fonts ?? []),
]);
const starting_point = ref<IFont[]>([
	...(pageStore.getCurrentPage?.getActiveSnapshot().meta.fonts ?? []),
]);
const uploadedFonts = ref<any[]>([]);
const addNewFont = ref(false);
const addNewFontName = ref("");
const addNewFontLink = ref("");
const editingElement = ref<number | null>(null);

const fontsChanged = computed(() => {
	if (fonts.value.length !== starting_point.value.length) {
		return true;
	}

	for (let index = 0; index < fonts.value.length; index++) {
		if (
			fonts.value[index].name !== starting_point.value[index].name ||
			fonts.value[index].link !== starting_point.value[index].link
		) {
			return true;
		}
	}
	return false;
});

onMounted(async () => {
	document.addEventListener("keyup", handleKeyUp);
	changeTitle(default_title);
	const res = await store.fetchFonts();
	uploadedFonts.value = res?.data?.data || [];
});

onBeforeUnmount(() => {
	document.removeEventListener("keyup", handleKeyUp);
});

function handleKeyUp(ev: KeyboardEvent) {
	if (ev.key === "Escape" && editingElement.value !== null) {
		ev.preventDefault();
		resetEdit();
	}
}

function changeState(val: boolean) {
	addNewFont.value = val;
	resetEdit();
	changeTitle(val ? add_title : default_title);
}

function addFont(font: any) {
	addNewFontLink.value = fullFontLink(font.link);
	addNewFontName.value = font.ass_name;
}

function addToFontList() {
	if (
		!addNewFontLink.value ||
		addNewFontLink.value.trim().length === 0 ||
		!addNewFontName.value ||
		addNewFontName.value.trim().length === 0
	) {
		return;
	}
	fonts.value.push({ name: addNewFontName.value, link: addNewFontLink.value });
	addNewFontLink.value = "";
	addNewFontName.value = "";
	addNewFont.value = false;
}

function saveFonts() {
	const currPage = usePageStore().getCurrentPage;
	if (currPage) {
		currPage.updateCanvasGlobalOptions({ fonts: fonts.value }, true);
	}
	cancel();
}

function fullFontLink(link: string) {
	return `${getCurrentDomain().origin}/${link}`;
}

function handleFontClick(index: number) {
	if (index === editingElement.value) {
		return;
	}
	if (editingElement.value === null) {
		handleEscapePrevention();
	}
	editingElement.value = index;
	addNewFontLink.value = fonts.value[index].link;
	addNewFontName.value = fonts.value[index].name ?? "";
}

function handleEscapePrevention(val = false) {
	modalStore.updateModalData(
		Object.assign(modalStore.getModalData("config"), {
			handleEscClose: val,
		}),
		"config",
	);
}

function handleInputName(ev: Event) {
	addNewFontName.value = (ev.target as HTMLInputElement).value;
}

function handleInputLink(ev: Event) {
	addNewFontLink.value = (ev.target as HTMLInputElement).value;
}

function editFont(index: number) {
	if (
		!addNewFontLink.value ||
		addNewFontLink.value.trim().length === 0 ||
		!addNewFontName.value ||
		addNewFontName.value.trim().length === 0
	) {
		return;
	}
	fonts.value.splice(index, 1, {
		name: addNewFontName.value,
		link: addNewFontLink.value,
	});
	fonts.value = [...fonts.value];
	resetEdit();
}

function removeFont(index: number) {
	fonts.value.splice(index, 1);
	fonts.value = [...fonts.value];
}

function changeTitle(title: string) {
	modalStore.updateModalData(
		Object.assign(modalStore.getModalData("config"), {
			title,
		}),
		"config",
	);
}

function resetEdit() {
	editingElement.value = null;
	addNewFontLink.value = "";
	addNewFontName.value = "";
	handleEscapePrevention(true);
}

function cancel() {
	emit("close-modal");
}
</script>

<template lang="pug">
.wrap
	.font-list-view(v-if="!addNewFont")
		.top-wrap
			p(v-if="fonts.length > 0") You can double click a font to edit it, or click the button on the right to remove it.
			ul(v-if="fonts.length > 0")
				li(v-for="(font, index) in fonts", :key="index")
					.font-wrap(@dblclick="handleFontClick(index)")
						.left
							.input-group
								span Name
								.input-holder
									input(
										type="text",
										:disabled="index !== editingElement",
										:value="editingElement === index ? addNewFontName : font.name",
										placeholder="Name",
										@input="handleInputName"
									)
							.input-group
								span Link
								.input-holder
									input(
										type="text",
										:disabled="index !== editingElement",
										:value="editingElement === index ? addNewFontLink : font.link",
										placeholder="Link",
										@input="handleInputLink"
									)
						.right
							.edit-icons(v-if="editingElement === index")
								i-fa-check(@click="editFont(index)")
								i-fa-xmark.del(@click="resetEdit()")
							.icons(v-else)
								i-fa-trash-can(@click="removeFont(index)")

			p(v-else) It seems you don't have any custom fonts added, click the button bellow to add one.
		.control-wrap
			.left
				button.btn(@click="cancel") Cancel
			.right
				button.btn(@click="changeState(true)") Add new font
				button.btn(:disabled="!fontsChanged", @click="saveFonts") Save
	.font-list-add(v-else)
		.top-wrap
			p(v-if="uploadedFonts.length > 0") Choose a font from the list of uploaded fonts, you can change the name of the font when you choose it!
			ul(v-if="uploadedFonts.length > 0")
				li(v-for="font in uploadedFonts", :key="font.ass_id")
					.font-wrap(@click="addFont(font)")
						.left
							.input-group
								span Name
								.input-holder
									input(
										type="text",
										disabled="true",
										:value="font.ass_name",
										placeholder="Name"
									)
							.input-group
								span Link
								.input-holder
									input(
										type="text",
										disabled="true",
										:value="fullFontLink(font.ass_id)",
										placeholder="Link"
									)
			p.middle-text {{ uploadedFonts.length > 0 ? "Or add" : "Add" }} a link to the font yourself!
			div
				.input-group
					span Name
					.input-holder
						input(v-model="addNewFontName", type="text", placeholder="Name")
				.input-group
					span Link
					.input-holder
						input(v-model="addNewFontLink", type="text", placeholder="Link")
		.control-wrap
			button.btn(@click="changeState(false)") Close
			button.btn(@click="addToFontList") Add font
</template>

<style lang="scss" scoped>
.wrap {
	display: flex;
	padding: 0 29px 20px;
	width: 500px;
	max-width: 500px;
	height: 80vh;

	li {
		list-style: none;
	}

	.input-group {
		cursor: pointer;

		&:first-child {
			margin-bottom: 10px;
		}

		input:disabled {
			cursor: pointer;
		}

		span {
			min-width: 50px;
		}
	}

	ul {
		max-height: 60%;
		overflow: auto;
		margin-top: 20px;
		margin-bottom: 42px;
	}
}

.font-list-add {
	h3 {
		margin-bottom: 30px;
	}
}

.control-wrap {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.btn {
		cursor: pointer;
	}
}

.font-list-add,
.font-list-view {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.top-wrap {
	flex: 1 1;
	margin-top: 25px;

	p {
		margin-bottom: 15px;
	}
}

.font-wrap {
	cursor: pointer;
	margin: 10px 0;
	padding-bottom: 13px;
	border-bottom: 1px $border-color1 solid;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	.left {
		flex: 1 1;
	}

	.edit-icons {
		display: flex;
		gap: 10px;
	}
}

.middle-text {
	margin-bottom: 40px;
}

.right {
	display: flex;
	gap: 7px;
}
</style>
