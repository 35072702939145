import { useToast, TYPE } from "vue-toastification";

import { defineStore } from "pinia";
import ToastComp from "@/components/shared/toast/ToastComp.vue";
import type { ToastComponent } from "vue-toastification/dist/types/types";

type TSuccessOpt = Parameters<typeof toastInstance.success>[1];
type TErrorOpt = Parameters<typeof toastInstance.error>[1];

const toastInstance = useToast();
export const useToastStore = defineStore("toasts", {
	state: () => ({}),
	getters: {},
	actions: {
		openToastCore<T>(msg: string, type: TYPE, opt: T) {
			if (type === "error") {
				toastInstance.error(
					{
						component: ToastComp,
						props: {
							type,
							msg,
							...opt,
						},
					} as ToastComponent,
					opt as TErrorOpt,
				);
			} else if (type === "success") {
				toastInstance.success(
					{
						component: ToastComp,
						props: {
							type,
							msg,
							...opt,
						},
					} as ToastComponent,
					opt as TSuccessOpt,
				);
			}
		},
		openToastSuccess(msg: string, opt: TSuccessOpt = {}) {
			this.openToastCore<TSuccessOpt>(msg, TYPE.SUCCESS, opt);
		},
		openToastError(msg: string, opt: TErrorOpt = {}) {
			this.openToastCore<TErrorOpt>(msg, TYPE.ERROR, opt);
		},
	},
});
