// export const extension_map = new Map([
//   ["img", ["jpg", "jpeg", "png", "gif", "svg", "apng", "ico", "cur"]],

//   ["video", ["mp4", "mov", "mkv", "avi", "webm", "ogg"]],
//   ["audio", ["mp3", "ogg", "wav"]],
// ]);

/**
 * Meant to be used with Array.reduce, transforms array that has multiple keys correspoding to the same value to flat structure.
 * @example [[["a", "b", "c"], "d"], ["e", "f"]] = [["a", "d"], ["b", "d"], ["c", "d"], ["e", "f"]]
 */
const multipleKeysOneValueMapReducer = (
	arr: any[],
	[keys, val]: any,
): any[] => [
	...arr,
	...(Array.isArray(keys) ? keys.map((key) => [key, val]) : [[keys, val]]),
];

/**
 * If asset types need to be added, add new array if type doesn't exist, otherwise add extension to existing type array
 */
export const extension_map = new Map<string, string>(
	[
		[["jpg", "jpeg", "png", "gif", "svg", "apng", "ico", "cur"], "img"],
		// [["svg"], "svg"],
		[["mp4", "mov", "mkv", "avi", "webm", "ogg"], "video"],
		[["mp3", "wav"], "audio"],
		// eslint-disable-next-line unicorn/no-array-callback-reference, unicorn/no-array-reduce
	].reduce(multipleKeysOneValueMapReducer, []),
);
