import _definePage_default_0 from '/home/enon/Git Projects/mars-html-builder/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/enon/Git Projects/mars-html-builder/src/pages/[...path].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'HomePage',
    component: () => import('/home/enon/Git Projects/mars-html-builder/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('/home/enon/Git Projects/mars-html-builder/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  )
]
