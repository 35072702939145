<script setup lang="ts">
// 2023-1

import { useEventListener } from "@vueuse/core";

export interface IDragCoordinates {
	x: number;
	y: number;
}

export interface IDragBarMoveEvent {
	evt: MouseEvent;
	isMoved: boolean;
	startPosition: IDragCoordinates;
	lastPosition: IDragCoordinates;
	incrementChangeFromStart: number;
	incrementChangeStep: number;
}

const props = withDefaults(
	defineProps<{
		isVertical?: boolean;
		disabled?: boolean;
		title?: string;
		useSimplePayload?: boolean;
	}>(),
	{
		isVertical: false,
		disabled: false,
		title: "",
	},
);
const emit = defineEmits(["dblclick", "dragging", "drag-stop"]);

const isDraggingHandler = ref(false);
const isInitialMove = ref(false);
const originalCoor = ref<IDragCoordinates>({ x: 0, y: 0 });
const lastCoor = ref<IDragCoordinates>({ x: 0, y: 0 });
const previousCoor = ref<IDragCoordinates>({ x: 0, y: 0 });

const mouseResizeStyle = computed(() => {
	if (props.isVertical) {
		return "n-resize";
	}
	return "e-resize";
});

const dragWrapClasses = computed(() => {
	return {
		active: isDraggingHandler,
		vertical: props.isVertical,
		"prevent-resize": props.disabled,
	};
});

async function onMouseDownDragBar(evt: MouseEvent, eventType: 1 | 2) {
	(evt.target as HTMLElement).requestPointerLock();

	document.addEventListener("mouseup", onStopDragging);

	if (evt.detail === 2) {
		// Double click event
		emit("dblclick", evt);
		return;
	}

	const isMouse = eventType === 1;
	const isLeftClick = evt.button === 0;
	if (props.disabled || (isMouse && !isLeftClick)) {
		document.body.style.removeProperty("cursor");
		return;
	}

	if (!originalCoor.value.x && !originalCoor.value.y) {
		originalCoor.value.x = evt.clientX;
		originalCoor.value.y = evt.clientY;
	}

	lastCoor.value.x = evt.clientX;
	lastCoor.value.y = evt.clientY;
	previousCoor.value.x = evt.clientX;
	previousCoor.value.y = evt.clientY;

	isDraggingHandler.value = true;
	document.body.style.cursor = mouseResizeStyle.value;
}
function generateEventPayload(evt: MouseEvent) {
	const payload = {
		evt,
		isMoved: isInitialMove.value,
		startPosition: originalCoor.value,
		lastPosition: lastCoor.value,
	} as IDragBarMoveEvent;

	if (!props.useSimplePayload) {
		const getIncrementFromStart = () => {
			return props.isVertical
				? lastCoor.value.y - originalCoor.value.y
				: lastCoor.value.x - originalCoor.value.x;
		};

		const getIncrementStep = () => {
			if (props.isVertical) {
				if (previousCoor.value.y - lastCoor.value.y < 0) {
					return 1;
				} else if (previousCoor.value.y - lastCoor.value.y > 0) {
					return -1;
				}
			} else {
				if (previousCoor.value.x - lastCoor.value.x < 0) {
					return 1;
				} else if (previousCoor.value.x - lastCoor.value.x > 0) {
					return -1;
				}
			}

			return 0;
		};

		const incrementChangeFromStart = getIncrementFromStart();
		const incrementChangeStep = getIncrementStep();

		payload.incrementChangeFromStart = incrementChangeFromStart;
		payload.incrementChangeStep = incrementChangeStep;
	}

	return payload;
}
function handleMouseMove(evt: MouseEvent) {
	isInitialMove.value = true;
	const payload = generateEventPayload(evt);
	emit("dragging", payload);

	previousCoor.value.x = lastCoor.value.x;
	previousCoor.value.y = lastCoor.value.y;
	lastCoor.value.x += evt.movementX;
	lastCoor.value.y += evt.movementY;
}
function onStopDragging(evt: MouseEvent) {
	if (isDraggingHandler.value) {
		document.removeEventListener("mouseup", onStopDragging);

		document.exitPointerLock();
		// console.log("[DRAG] Stop");

		const payload = generateEventPayload(evt);
		emit("drag-stop", payload);

		originalCoor.value.x = 0;
		originalCoor.value.y = 0;
		lastCoor.value.x = 0;
		lastCoor.value.y = 0;
		previousCoor.value.x = 0;
		previousCoor.value.y = 0;
		isDraggingHandler.value = false;
		document.body.style.removeProperty("cursor");
	} else {
		console.warn("No dragging!");
	}
}

function onMouseMove(evt: MouseEvent) {
	if (isDraggingHandler.value) {
		handleMouseMove(evt);
	}
}

useEventListener(document, "mousemove", onMouseMove, { capture: true });
</script>

<template lang="pug">
.drag-wrap(:class="dragWrapClasses")
	.overlay(v-if="isDraggingHandler")
	.drag-bar(
		:title="title",
		:class="{ active: isDraggingHandler }",
		@mousedown="onMouseDownDragBar($event, 1)"
	)
</template>

<style lang="scss" scoped>
.drag-wrap {
	$light-border: #d3d3d321;

	.drag-bar {
		background: darken($color: $light-border, $amount: 20);
		height: 100%;
		width: 100%;
	}

	&.vertical {
		width: 100%;
		height: 4px;
	}

	&:not(.vertical) {
		width: 4px;
		height: 100%;
	}

	&:not(.prevent-resize) {
		cursor: e-resize;
		background: lighten($color: $light-border, $amount: 30);

		&.vertical {
			.drag-bar {
				cursor: n-resize;
			}
		}

		&.drag-bar {
			&:hover,
			&.active {
				z-index: 100;
			}
		}
	}

	.overlay {
		position: fixed;
		inset: 0;
		background: transparent;
		z-index: 99;
	}
}
</style>
